<template>
  <div>
    <v-container style="margin-top: 10px">
      <div class="mb-10">
        Cette page vous permet de définir quels conseillers aura accès à vos données parcellaires. Vous pouvez déterminer la période sur laquelle ils pourront accéder à ces données. Vous pouvez à tout moment modifier ces dates ou supprimer l'accès.
      </div>

      <h3>Liste des conseillers</h3>
      <div style="margin-bottom:20px;">
        <v-card>
          <v-data-table
            :loading="!dataloaded"
            loading-text="Chargement des données...."
            :headers="headers"
            :items="conseillers_agri"
            item-key=""
            :options.sync="optionsTab"
            :page="6"
            class="my-awesome-table elevation-1"
            dense
          >
            <template v-slot:[`item.conseiller`]="{ item }">{{ item.nom_conseiller }} {{ item.prenom_conseiller }}</template>
            <template v-slot:[`item.validation`]="{ item }">
              <div v-if="item.validation_conseiller==null">
                <v-icon small class="mr-2">
                  mdi-clock-time-four-outline
                </v-icon>
              </div>
              <div v-else-if="item.validation_conseiller==true">
                <v-icon small class="mr-2" color="green">
                  mdi-check
                </v-icon> {{ item.date_validation }}
              </div>
              <div v-else>
                <v-icon small class="mr-2" color="red">
                  mdi-close
                </v-icon> {{ item.date_validation }}
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="modifyConseiller(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="confirmDeleteItem(item,'abo')">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-btn small color="info" class="mt-2 mb-50 white--text" @click="addConseillerPossible();">
            <v-icon small class="mr-2">
              mdi-plus-thick
            </v-icon>
            Nouveau conseiller
        </v-btn>
      </div>

      <v-card style="padding:20px;" v-if="show_conseiller">
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
          <form @submit.prevent="submit">
            <v-row style="mt-10">
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Conseiller"
                  rules="required"
                >
                <v-autocomplete
                  v-model="infos_conseiller.id_conseiller"
                  :items="list_conseillers"
                  item-text="nom_liste"
                  item-value="id_conseiller"
                  label="Conseiller*"
                  data-vv-name="Conseiller"
                  required
                  :error-messages="errors"
                />
                  <!--<v-select
                    v-model="infos_conseiller.id_conseiller"
                    :items="list_conseillers"
                    item-value="id_conseiller"
                    :error-messages="errors"
                    label="Conseiller"
                    data-vv-name="Conseiller"
                    required
                    autocomplete
                    :search-input.sync="searchConseiller"
                  >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.nom_conseiller }} {{ data.item.prenom_conseiller }} [{{ data.item.organisme }}]
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.nom_conseiller }} {{ data.item.prenom_conseiller }} [{{ data.item.organisme }}]
                  </template>
                  </v-select>-->
                </validation-provider>
              </v-col>
              <v-col cols="2">
                <validation-provider name="Date début" :rules="'required'" v-slot="{errors}" slim>
                  <v-menu
                    ref="menu_dt_debut"
                    :close-on-content-click="false"
                    :return-value.sync="infos_conseiller.date_debut"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date début*"
                        v-model="infos_conseiller.date_debut"
                        readonly
                        :error-messages="errors[0]"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="infos_conseiller.date_debut" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.menu_dt_debut.save(infos_conseiller.date_debut)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="2">
                <validation-provider name="Date fin" :rules="'required'" v-slot="{errors}" slim>
                  <v-menu
                    ref="menu_dt_fin"
                    :close-on-content-click="false"
                    :return-value.sync="infos_conseiller.date_fin"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Date fin*"
                        v-model="infos_conseiller.date_fin"
                        readonly
                        :error-messages="errors[0]"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="infos_conseiller.date_fin" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.menu_dt_fin.save(infos_conseiller.date_fin)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="3">
                <validation-provider
                    v-slot="{ errors }"
                    name="Service"
                    rules="required"
                  >
                    <v-text-field
                        v-model="infos_conseiller.service"
                        :error-messages="errors"
                        label="Service"
                        required
                        readonly
                    ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <div>Je souhaite partager :</div>
                <v-radio-group
                  v-model="type_selection_parcelle"
                  column
                >
                  <v-radio
                    label="Toutes les parcelles actuelles et futures"
                    value="all"
                  ></v-radio>
                  <v-radio
                    label="Toutes les parcelles sauf certaines"
                    value="allExcept"
                  ></v-radio>
                  <v-radio
                    label="Uniquement quelques parcelles"
                    value="some"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3" v-if="type_selection_parcelle=='allExcept'">
                <v-checkbox v-model="infos_conseiller.parcelles_exclues" v-for="elem in listParcelles" :key="elem.id" dense
                  :label="elem.name+' ('+elem.variety+')'"
                  :value="elem.id"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" v-if="type_selection_parcelle=='some'">
                <v-checkbox v-model="infos_conseiller.parcelles_inclues" v-for="elem in listParcelles" :key="elem.id" dense
                  :label="elem.name+' ('+elem.variety+')'"
                  :value="elem.id"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-btn
                class="mr-4 float-right"
                type="submit"
                small
                :disabled="invalid"
                @click="saveLienConseiller"
              >
                Enregistrer
              </v-btn>
            </v-row>
          <!--</div>-->
          </form>
        </validation-observer>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import consentementService from "@/service/consentement";
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} ne peut pas être vide',
})

extend('email', {
  ...email,
  message: 'Email doit être valide',
})

export default {
  name: "Conseillers_agri",
  components: {
    consentementService,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      code_agri_operateur: null,
      code_operateur: null,
      service: null,
      dataloaded: false,
      displayResult: true,
      filter: null,
      conseillers_agri: [],
      optionsTab: {itemsPerPage: 15,},
      headers: [
        { text: "Conseiller", value: "conseiller", filterable: true },
        { text: "Date de début", value: "date_debut"},
        { text: "Date de fin", value: "date_fin"},
        { text: "Service", value: "service" },
        { text: "Validation conseiller", value: "validation" },
        { text: "", value: "actions", sortable: false }
      ],
      show_conseiller: false,
      selected_conseiller: null,
      menu_dt_debut: false,
      menu_dt_fin: false,
      infos_agri: null,
      infos_conseiller: {id_agri_cons:null,id_conseiller:null,date_debut:null,date_fin:null,service:null, parcelles_inclues:[], parcelles_exclues:[]},
      list_conseillers: [],
      deleted_id: null,
      listParcelles: [],
      type_selection_parcelle: 'all'
    };
  },
  created: function() {
    var infos = this.$store.getters['consentement/InfosAgri']
    this.code_agri_operateur = infos.code_agri_operateur;
    this.code_operateur = infos.code_operateur;
    this.code_client = infos.code_client;
    this.service = infos.service;
  },
  async mounted() {
    this.getInfosConseillersAgri()
    this.getAgriculteur()
    this.getParcellesConsentement()
  },
  computed: {
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
  },
  watch: {
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
    type_selection_parcelle: function() {
      if(this.infos_conseiller.id_agri_cons==null){
        if (this.type_selection_parcelle == "all") {
          this.infos_conseiller.parcelles_inclues = ["all"];
          this.infos_conseiller.parcelles_exclues = [];
        } else if (this.type_selection_parcelle == "allExcept") {
          this.infos_conseiller.parcelles_inclues = ["all"];
          this.infos_conseiller.parcelles_exclues = [];
        } else if (this.type_selection_parcelle == "some") {
          this.infos_conseiller.parcelles_inclues = [];
          this.infos_conseiller.parcelles_exclues = [];
        }
      }
    }
  },

  methods: {
    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },
    async getInfosConseillersAgri() {
      this.conseillers_agri = await consentementService.getInfosConseillersAgri(this.code_agri_operateur,this.code_operateur,this.code_client);
      this.dataloaded = true;
    },
    async getAgriculteur() {
      this.infos_agri_api = await consentementService.getInfosAgriculteurById(this.code_agri_operateur,this.code_operateur,this.code_client);
      if(this.infos_agri_api.length>0){
        this.infos_agri = this.infos_agri_api[0]
        if(this.infos_agri.cgu!=process.env.VUE_APP_VERSION_CGU){
          this.infos_agri.cgu=null;
        }
      }
    },
    submit () {
      this.$refs.observer.validate()
    },
    addConseillerPossible(){
      if(this.infos_agri==null || this.infos_agri.cgu==null){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Opération impossible",description:"Avant de pouvoir ajouter un conseiller, il faut avoir rempli les informations vous concernant dans l'onglet "+this.$t('menu_conseillers.compte')+"."} });
      } else {
        this.show_conseiller=true;
        this.selected_conseiller=null;
        this.initializeConseiller();
        this.getListConseillersService()
      }
    },
    async saveLienConseiller(){
      var isDoneLien = null

      if ((this.type_selection_parcelle=='all')
              || (this.type_selection_parcelle=='some' && this.infos_conseiller.parcelles_inclues.length>0) 
              || (this.type_selection_parcelle=='allExcept' && this.infos_conseiller.parcelles_exclues.length>0)){

            if (this.type_selection_parcelle == "all") {
              this.infos_conseiller.parcelles_inclues = ["all"];
              this.infos_conseiller.parcelles_exclues = [];
            } else if (this.type_selection_parcelle == "allExcept") {
              this.infos_conseiller.parcelles_inclues = ["all"];
            } else if (this.type_selection_parcelle == "some") {
              var index = this.infos_conseiller.parcelles_inclues.indexOf("all");
              if (index > -1) { //suppression du all si présent
                this.infos_conseiller.parcelles_inclues.splice(index, 1);
              }

              this.infos_conseiller.parcelles_exclues = [];
            }
          isDoneLien = await consentementService.postAgriculteurConseiller(this.code_agri_operateur,this.code_operateur,this.code_client,this.infos_conseiller);


        if(isDoneLien != true){
          alert('Un problème est survenu.')
        } else {
          this.getInfosConseillersAgri()
          this.show_conseiller = false
        }
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Opération impossible",description:"Vous devez préciser les parcelles concernées."} });
      }
      
    },
    async getListConseillersService(){
      this.list_conseillers = await consentementService.getListConseillersService(this.service);
      this.infos_conseiller.service = this.service
      this.list_conseillers.forEach((item, index) => {
        this.list_conseillers[index]['nom_liste'] = item.nom_conseiller + ' ' + item.prenom_conseiller + ' [' + item.organisme + ']'
      });
    },
    initializeConseiller(){
        this.infos_conseiller={id_agri_cons:null,id_conseiller:null,date_debut:null,date_fin:null,service:null, parcelles_inclues:[], parcelles_exclues:[]}
    },
    modifyConseiller(item){
      this.getListConseillersService()
      this.show_conseiller= true
      
      if(typeof(item.parcelles_inclues)=='string'){
        item.parcelles_inclues = JSON.parse(item.parcelles_inclues)
        item.parcelles_exclues = JSON.parse(item.parcelles_exclues)
      }

      var parcelles_includes_ini = item.parcelles_inclues.slice();
      var parcelles_excludes_ini = item.parcelles_exclues.slice();

      if(item.parcelles_inclues.length>0 && item.parcelles_inclues[0]=='all'){
        this.type_selection_parcelle='all'
      }

      if(item.parcelles_inclues.length>0 && item.parcelles_inclues[0]=='all' && (item.parcelles_exclues==null || item.parcelles_exclues.length==0)){
        this.type_selection_parcelle='all'
        item.parcelles_inclues = []
      } else if(item.parcelles_exclues.length>0){
        this.type_selection_parcelle='allExcept'
      } else {
        this.type_selection_parcelle='some'
      }

      this.infos_conseiller={id_agri_cons:item.id_agri_cons,id_conseiller:item.id_conseiller,date_debut:item.date_debut,date_fin:item.date_fin,service:item.service,parcelles_inclues:parcelles_includes_ini,parcelles_exclues:parcelles_excludes_ini}


    },
    confirmDeleteItem(item){
      this.delete_id = item.id_agri_cons
      this.$store.dispatch("choix/setChoix", {
        question: "Etes-vous sûr de vouloir supprimer cet élément?",
        choix: {
          choix1: { text: "Oui", function: "deleteItem" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },
    async deleteItem(){
      this.closeChoix()
      var isDeleted = null
      isDeleted = await consentementService.deleteLienConseiller(this.delete_id);
    

      if(isDeleted == true){
            this.getInfosConseillersAgri()
      } else {
          alert('Un problème est survenu.')
      }
      this.delete_id = null;
    },
    async getParcellesConsentement(){
        this.listParcelles = await consentementService.getParcellesConsentement(this.code_agri_operateur,this.code_operateur,this.code_client,this.service);
    },

  }
};
</script>

<style>
</style>

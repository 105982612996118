<template>
  <div>
    <v-row fill-height>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 10 : 12" :offset="$vuetify.breakpoint.mdAndUp ? 1 : 0">        
        <v-container>
          <DocUser v-if="showDoc" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DocUser from "@/components/help/helpDoc.vue";

export default {
  name: "Help",
  components: {
    DocUser,
  },
  data() {
    return {
      menu : [
         
        ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showDoc() {
      return this.currentRouteName == "help";
    },
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    }
  }
};


</script>

<style>
.text-center {
  text-align: center;
}
</style>
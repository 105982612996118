<template>
  <div>
    <v-container style="margin-top: 10px">

    <!--API MILDIOU -->
      <v-card class="mb-15">
        <h3>API Mildiou</h3>
        <v-row class="pl-10 pt-5" v-if="infos_mildiou">
          <v-col cols="7">
            <div>Nb intégrateurs : {{infos_mildiou.nb_operateurs}}</div>
            <div>Nb parcelles : {{infos_mildiou.nb_parcelles}}</div>
            <!--<div>Surface totale : {{infos_bh.surface_parcelles}}</div>-->
            <v-simple-table
                v-if="infos_mildiou"
                fixed-header
                height="200px"
                style="width:90%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Etat calcul
                      </th>
                      <th class="text-left">
                        Nb Parcelles
                      </th>
                      <th class="text-left">
                        % Parcelles
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ligne in infos_mildiou.detail_etat_calculs"
                      :key="ligne.statut_msg"
                    >
                    
                      <td style="height:12px">
                        <v-chip
                          :color="getColorStatutMildiou(ligne.statut_msg)"
                          dark
                          small
                        >
                          {{ ligne.statut_msg }}
                        </v-chip>
                      </td>
                      <td style="height:12px">{{ ligne.nb_parcelles }}</td>
                      <td style="height:12px">{{ ligne.pourcentage_parcelles }}</td>
                    </tr>
                  </tbody>
                </template>
            </v-simple-table>
          </v-col>
            <v-col cols="5">
              <v-simple-table
                v-if="infos_mildiou"
                fixed-header
                height="200px"
                style="width:90%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Intégrateur
                      </th>
                      <th class="text-left">
                        Nb Parcelles
                      </th>
                      <th class="text-left">
                        % Parcelles
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ligne in infos_mildiou.liste_operateurs"
                      :key="ligne.name"
                    >
                    
                      <td style="height:12px">{{ ligne.name }}</td>
                      <td style="height:12px">{{ ligne.nb_parcelles }}</td>
                      <td style="height:12px">{{ ligne.pourcentage_parcelles }}</td>
                    </tr>
                  </tbody>
                </template>
            </v-simple-table>
            <br>
            <v-btn small color="info" class="mt-2 white--text">
                    <download-excel :data="data_status_mildiou" :name="getNomFichier()" >
                      Export Excel Statut parcelle
                      <v-icon small class="mr-2">
                        mdi-download
                      </v-icon>
                    </download-excel>
                  </v-btn>
          </v-col>
        </v-row>
      </v-card>
    <!--FIN API MILDIOU -->

    </v-container>
  </div>

</template>

<script>
import downloadExcel from "vue-json-excel";
import administrationTdbService from "@/service/administrationTdb";
import { getDate } from "@/helpers/functions";


export default {
  name: "Tdb",
  components: {
    downloadExcel,
    administrationTdbService,
    getDate
  },
  data() {
    return {
      infos_mildiou: null,
      data_status_mildiou : null, 
    };

  
  },
  async mounted() {

    this.infos_mildiou = await administrationTdbService.getTdbMildiou();
    this.infos_mildiou.nb_operateurs = 0
    this.infos_mildiou.nb_operateurs = this.infos_mildiou.liste_operateurs.length
    this.infos_mildiou.nb_parcelles = 0
    this.infos_mildiou.liste_operateurs.forEach((item) => {
        this.infos_mildiou.nb_parcelles += item.nb_parcelles
    });

    //DATA pour le EXPORT XLS dans le conteneur API-MILDIOU
    this.data_status_mildiou = await administrationTdbService.getStatusMildiou();


  },

  methods: {
    getColorStatutMildiou (statut) {
        if (statut == 'calcul à jour') return 'green'
        else return 'orange'
    }
  }
};
</script>

<style>
</style>

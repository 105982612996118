<template>
  <v-dialog
    v-model="errorState"
    persistent
    max-width="500"
    :transition="false"
    scrollable
  >
    <v-card>
      <v-card-title class="v-card-title-error"
        ><h2 class="text-center">
          {{ $tc("errors.title") }}
        </h2></v-card-title
      >

      <v-card-text class="pa-5">
      
        <v-list>
          <v-list-item
            dense
          >
            <div class="text-center font-weight-bold" style="white-space: pre-line">
                    {{ errorMessages.code }} - {{ errorMessages.description }}
            </div>

          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn small text @click.stop="unsetError">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ErrorModal",
  data() {
    return {};
  },

  methods: {
    unsetError() {
      this.$store.dispatch("errors/unsetError");
    },
  },

  computed: {
    errorMessages() {
      return this.$store.state.errors.message;
    },

    errorState() {
      return this.$store.state.errors.apiError;
    },
  },
};
</script>

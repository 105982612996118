import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    listes: [],
    parcelles_mildiou: [],
    parcelle_indices: [],
    parcelle_indices_horaires: [], 
    parcelle_indices_alt : [],
    parcelle_indices_horaires_alt: [], 
    parcelle_indices_risk_meteo: [], 
    fromDetailParcelle: null,
  
  },

  actions: {
    defineListes({ commit }, payload) {
        commit("defineListes", payload);
    },
    defineParcelles({ commit }, payload) {
      commit("defineParcelles", payload);
    },
    defineParcelleIndices({ commit }, payload) {
      commit("defineParcelleIndices", payload);
    },
    defineParcelleIndicesHoraires({ commit }, payload) {
      commit("defineParcelleIndicesHoraires", payload);
    },
    defineParcelleIndicesAlt({ commit }, payload) {
      commit("defineParcelleIndicesAlt", payload);
    },
    defineParcelleIndicesHorairesAlt({ commit }, payload) {
      commit("defineParcelleIndicesHorairesAlt", payload);
    },
    defineParcelleIndicesMeteoRiskDataTable({ commit }, payload) {
      commit("defineParcelleIndicesMeteoRiskDataTable", payload);
    },
    setSelectedParcelleFromDetail({ commit }, parcelle) { 
      commit("setSelectedParcelleFromDetail", parcelle);
    },
    reset: ({commit}) => {
      commit('reset');
    }
  },

  mutations: {
    defineListes (state, payload) {
      //state.listes = payload.liste

      var tab = state.parcelles_mildiou
      var liste = new Array()
      var listeConcat = new Array()
      var intitule = ''
      tab.forEach(element => {
        intitule = element.farmer+element.country+element.departement+element.id_integrateur+element.nom_integrateur+element.distributeur+element.risque+element.conseil
        +element.nom_agri+element.prenom_agri 
        if(!listeConcat.includes(intitule)){
          listeConcat.push(intitule)
          liste.push({farmer:element.farmer,
            country:element.country,
            departement:element.departement,
            id_integrateur:element.id_integrateur,
            nom_integrateur:element.nom_integrateur,
            distributeur:element.distributeur,
            risque:element.risque,
            conseil:element.conseil
            ,nom_agri:element.nom_agri,
            prenom_agri:element.prenom_agri
          })
        }
      });

      state.listes = liste
    },
    defineParcelles (state, payload) {
      state.parcelles_mildiou = payload.parcelles
    },
    defineParcelleIndices (state, payload) {
      state.parcelle_indices = payload.indices
    },
    defineParcelleIndicesHoraires (state, payload) {
      state.parcelle_indices_horaires = payload.indices
    },
    defineParcelleIndicesAlt (state, payload) {
      state.parcelle_indices_alt = payload.indices
    },
    defineParcelleIndicesHorairesAlt (state, payload) {
      state.parcelle_indices_horaires = payload.indices
    },
    defineParcelleIndicesMeteoRiskDataTable (state, payload) {
      state.parcelle_indices_risk_meteo = payload.indices
    },
    setSelectedParcelleFromDetail(state, parcelle) {
      state.fromDetailParcelle = parcelle;
    },

    reset: (state) => {
      state.listes = [];
      state.parcelles_mildiou = [];
      state.parcelle_indices = [];
      state.parcelle_indices_horaires = [];
      state.parcelle_indices_alt = [];
      state.parcelle_indices_horaires_alt= []; 
      state.parcelle_indices_risk_meteo= []; 
      state.fromDetailParcelle = null;
    }
  },
  getters: {
      FilterListes: (state) => (country,departements,agris,integrateurs,distributeurs) => {
        var tab = state.listes

        //filtre pays
        if(country!=null && country.length>0 && tab.length>0){
          tab = tab.filter(data => data.country === country)
        }

        //filtre departements
        var tab2 = []
        if(country == 'france' && departements!=null && departements.length>0 && tab.length>0){
          departements.map(function(value) {
            tab2 = tab2.concat(tab.filter(data => data.departement === value))
          });
          tab = tab2
        }

        //filtre agris
        var tab3 = []
        if(agris!=null && agris.length>0 && tab.length>0){
          agris.map(function(value) {
            tab3 = tab3.concat(tab.filter(data => data.farmer === value))
          });
          tab = tab3
        }

        //filtre integrateur
        var tab4 = []
        if(integrateurs!=null && integrateurs.length>0 && tab.length>0){
          integrateurs.map(function(value) {
            tab4 = tab4.concat(tab.filter(data => data.id_integrateur === value))
          });
          tab = tab4
        }

        //filtre distributeur
        var tab5 = []
        if(distributeurs!=null && distributeurs.length>0 && tab.length>0){
          distributeurs.map(function(value) {
            tab5 = tab5.concat(tab.filter(data => data.distributeur === value))
          });
          tab = tab5
        }
        
        return tab
      },
      FilterParcelles: (state) => (country,departements,agris,integrateurs,distributeurs,risque,conseil) => {
        var tab = state.parcelles_mildiou
        //filtre pays
        if(country!=null && country.length>0 && tab.length>0){
          tab = tab.filter(parcelle => parcelle.country === country)
        }

        //filtre departements
        var tab2 = []
        if(country == 'france' && departements!=null && departements.length>0 && tab.length>0){
          departements.map(function(value) {
            tab2 = tab2.concat(tab.filter(parcelle => parcelle.departement === value))
          });
          tab = tab2
        }

        //filtre agris
        var tab3 = []
        if(agris!=null && agris.length>0 && tab.length>0){
          agris.map(function(value) {
            tab3 = tab3.concat(tab.filter(parcelle => parcelle.farmer === value))
          });
          tab = tab3
        }

        //filtre integrateur
        var tab4 = []
        if(integrateurs!=null && integrateurs.length>0 && tab.length>0){
          integrateurs.map(function(value) {
            tab4 = tab4.concat(tab.filter(parcelle => parcelle.id_integrateur === value))
          });
          tab = tab4
        }

        //filtre distributeur
        var tab5 = []
        if(distributeurs!=null && distributeurs.length>0 && tab.length>0){
          distributeurs.map(function(value) {
            tab5 = tab5.concat(tab.filter(parcelle => parcelle.distributeur === value))
          });
          tab = tab5
        }

        //filtre risque
        var tab6 = []
        if(risque!=null && risque.length>0 && risque.length>0){
          risque.map(function(value) {
            tab6 = tab6.concat(tab.filter(parcelle => parcelle.risque === value))
          });
          tab = tab6
        }

        //filtre conseil
        var tab7 = []
        if(conseil!=null && conseil.length>0 && conseil.length>0){
          conseil.map(function(value) {
            tab7 = tab7.concat(tab.filter(parcelle => parcelle.conseil === value))
          });
          tab = tab7
        }

        return tab
        
      },
      FilterTabIndices: (state) => (type,reel,previ,date_debut,date_fin) => {
        var tab = []
        if(type=='hour'){
          tab = state.parcelle_indices_horaires
        } else {
          tab = state.parcelle_indices
        }

        var tab1 = []
        if(reel!=null && reel==true){
          tab1 = tab.filter(jour => jour.conseil != 'PASCONSEILPREVI')
        }

        var tab2 = []
        if(previ!=null && previ==true){
          tab2 = tab.filter(jour => jour.conseil == 'PASCONSEILPREVI')
        }

        tab = tab1.concat(tab2)

        var tab3 = tab
        if(date_debut!=null && date_debut!=''){
          tab3 = tab.filter(jour => new Date(jour.date) >= new Date(date_debut))
        }

        var tab4 = []
        if(date_fin!=null && date_fin!=''){
          tab4 = tab3.filter(jour => new Date(jour.date) <= new Date(date_fin))
        } else {
          tab4 = tab3
        }

        return tab4
      
      },

      FilterTabIndicesAlt: (state) => (type,reel,previ,date_debut,date_fin) => {
        var tab = []
        if(type=='hour'){
          tab = state.parcelle_indices_horaires_alt
        } else {
          tab = state.parcelle_indices_alt
        }

        // var tab1 = []
        // if(reel!=null && reel==true){
        //   tab1 = tab.filter(jour => jour.conseil != 'PASCONSEILPREVI')
        // }

        // var tab2 = []
        // if(previ!=null && previ==true){
        //   tab2 = tab.filter(jour => jour.conseil == 'PASCONSEILPREVI')
        // }

       // tab = tab1.concat(tab2)

        var tab3 = tab
        if(date_debut!=null && date_debut!=''){
          tab3 = tab.filter(jour => new Date(jour.date) >= new Date(date_debut))
        }

        var tab4 = []
        if(date_fin!=null && date_fin!=''){
          tab4 = tab3.filter(jour => new Date(jour.date) <= new Date(date_fin))
        } else {
          tab4 = tab3
        }

        return tab4
      
      },

      FilterTabIndicesMeteoRisk: (state) => (date_debut,date_fin) => {
        var tab = []
        tab = state.parcelle_indices_risk_meteo

        var tab1 = tab
        // Définir les heures de début et de fin pour les dates fournies
        if (date_debut != null && date_debut != '') {
          let startDate = new Date(date_debut);
          startDate.setHours(0, 0, 0, 0); // Début de la journée à l'heure prêt
          tab1 = tab.filter(jour => new Date(jour.date) >= startDate);
        }

        var tab2 = []
        if (date_fin != null && date_fin != '') {
          let endDate = new Date(date_fin);
          endDate.setHours(23, 59, 59, 999); // Fin de la journée à l'heure prêt
          tab2 = tab1.filter(jour => new Date(jour.date) <= endDate);
        } else {
          tab2 = tab1;
        }

        return tab2
      
      },

      fromDetailParcelle: (state) => state.fromDetailParcelle,
  }
};
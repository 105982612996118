import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import VueLodash from 'vue-lodash'; // application component import 
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import intersectionBy from "lodash/intersectionBy"
import "@/plugins/echarts";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.config.productionTip = false
Vue.use(VueLodash, { lodash: { get, isEqual, cloneDeep, isEmpty, partition, intersectionBy, extend, ValidationObserver, ValidationProvider, setInteractionMode, VuePlyr } });

//Code provenant de https://fr.vuejs.org/v2/guide/components-registration.html
// const requireComponent = require.context(
//   // Le chemin relatif du dossier composants
//   './components/global',
//   // Suivre ou non les sous-dossiers
//   false,
//   // L'expression régulière utilisée pour faire concorder les noms de fichiers de composant de base
//   // /Ads[A-Z]\w+\.(vue|js)$/
// )

// requireComponent.keys().forEach(fileName => {
//   // Récupérer la configuration du composant
//   const componentConfig = requireComponent(fileName)

//   // Récupérer le nom du composant en PascalCase
//   const componentName = upperFirst(
//      camelCase(
//         // Retrouver le nom du fichier indépendemment de la profondeur de dossier
//         fileName
//            .split('/')
//            .pop()
//            .replace(/\.\w+$/, '')
//      )
//   )

//   // Créer un composant global
//   Vue.component(
//      componentName,
//      // Chercher les options du composant dans `.default`, qui
//      // existera si le composant a été exporté avec `export default`,
//      // sinon revenez à la racine du module.
//      componentConfig.default || componentConfig
//   )
// });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')


//GESTION MAP LEAFLET
// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  //iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconRetinaUrl: require('@/assets/map_icon_blue.png'),  
  //iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  iconUrl: require('@/assets/map_icon_blue.png'),  
  //shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [26, 40], // size of the icon
  iconAnchor: [13, 40], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
})


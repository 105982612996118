<template>
  <div>
    <v-container style="margin-top: 10px">
      <h3>Détail de la parcelle</h3>
      <div style="margin-bottom:20px;" v-if="parcelle!=null">
        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              Informations parcellaires
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Agri :</span><span class="parcelle-card-val">{{parcelle.nom_agri}} {{parcelle.prenom_agri}} [{{parcelle.code_agri}}]</span></div>
                <div><span class="parcelle-card-title">Intégrateur :</span><span class="parcelle-card-val">{{parcelle.nom_integrateur}}</span></div>
                <div><span class="parcelle-card-title">Distributeur :</span><span class="parcelle-card-val">{{parcelle.nom_grpt}}</span></div>
                <div><span class="parcelle-card-title">Parcelle :</span><span class="parcelle-card-val">{{parcelle.nom_parcelle}} [{{parcelle.code_parcelle}}][{{parcelle.id_info_plot}}]</span></div>
                <div><span class="parcelle-card-title">Code postal :</span><span class="parcelle-card-val"> {{parcelle.code_postal}}</span></div>
                <div><span class="parcelle-card-title">Station :</span><span class="parcelle-card-val" v-if="parcelle.station!=null"> {{ parcelle.station.id_iot }} [{{ parcelle.station.nom_constructeur }} : {{ parcelle.station.code }}]</span></div>
                <div><span class="parcelle-card-title">Précédent :</span><span class="parcelle-card-val"> {{parcelle.precedent}} (irrigué : {{parcelle.irrig_precedent}})</span></div>
                <div><span class="parcelle-card-title">CIPAN :</span>
                  <span class="parcelle-card-val" v-if="JSON.parse(parcelle.caracteristiques_generales).cipan">
                    Implantation : {{JSON.parse(JSON.parse(parcelle.caracteristiques_generales).cipan).implantation}}<br>
                    Levée : {{JSON.parse(JSON.parse(parcelle.caracteristiques_generales).cipan).levee}}<br>
                    Destrcution : {{JSON.parse(JSON.parse(parcelle.caracteristiques_generales).cipan).destruction}}<br>
                    Développement : {{JSON.parse(JSON.parse(parcelle.caracteristiques_generales).cipan).developpement}}
                  </span>
                  <span class="parcelle-card-val" v-else>
                    -
                  </span>
                </div>
              </div>
              
            </v-card>
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-water
              </v-icon>
              Irrigation
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Durée du tour d'eau :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_generales).duree_tour_eau}}</span></div>
                <div><span class="parcelle-card-title">Dose d'irrigation (mm) :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_generales).dose_irrig}}</span></div>
                <div><span class="parcelle-card-title">Quota :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_generales).quota}}</span></div>
                <div><span class="parcelle-card-title">Matériel d'irrigation :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_generales).materiel_irrig}}</span></div>
              </div>
              
            </v-card>
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-multiplication
              </v-icon>
              Sol
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Code sol :</span><span class="parcelle-card-val"> {{parcelle.code_sol_arvalis}}</span></div>
                <div><span class="parcelle-card-title">Nom sol :</span><span class="parcelle-card-val"> {{parcelle.nom_sol_arvalis}}</span></div>
                <div><span class="parcelle-card-title">RU max :</span><span class="parcelle-card-val"> {{parcelle.ru_max}}</span></div>
                <div><span class="parcelle-card-title">RFU max :</span><span class="parcelle-card-val"> {{parcelle.rfu_max}}</span></div>
              </div>
              
            </v-card>
          </v-col>
          <v-col cols="5">
            <l-map
              :center="carte.center"
              :zoom="carte.zoom"
              class="map"
              ref="map"
              @update:zoom="zoomUpdated"
              @update:center="centerUpdated"
              style="margin-top:20px"
            >
              <l-tile-layer
                :url="carte.url"
              >
              </l-tile-layer>
              <l-marker
                v-for="marker in carte.markers"
                :key="marker.id"
                :lat-lng="marker.coordinates"
              >
                <l-icon 
                  ref="icon"
                  :icon-url="marker.imageUrl"
                >
                </l-icon>
              </l-marker>
            </l-map>

            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-sprout
              </v-icon>
              CULTURE
              <div class="parcelle-card" v-if="parcelle!=null">
                <div><span class="parcelle-card-title">Culture :</span><span class="parcelle-card-val"> {{parcelle.culture}}</span></div>
                <div><span class="parcelle-card-title">Date semis :</span><span class="parcelle-card-val"> {{parcelle.date_semis}}</span></div>
                <div v-if="['BT', 'BD', 'OP','MAIS_GRAIN','MAIS_FOURRAGE','PDT','EPINARD','HARICOT','SORGHO'].includes(parcelle.culture)">
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).variete}}</span></div>
                </div>
                <div v-if="['SOJA'].includes(parcelle.culture)">
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).variete}} ({{JSON.parse(parcelle.caracteristiques_culture).precocite}})</span></div>
                </div>
                <div v-if="['MAIS_DOUX'].includes(parcelle.culture)">
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).variete}} (ss:{{JSON.parse(parcelle.caracteristiques_culture).supersweet}})</span></div>
                </div>
                <div v-if="['MAIS_SEMENCE'].includes(parcelle.culture)">
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Précocité :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).precocite}}</span></div>
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Nb jours semis-for :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).nb_jour_semis_flor}}</span></div>
                  <div style="overflow:hidden;margin-bottom:5px;"><span class="parcelle-card-title">Gabarit :</span><span class="parcelle-card-val"> {{JSON.parse(parcelle.caracteristiques_culture).gabarit}}</span></div>
                </div>
              </div>
              
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-calendar-text-outline
              </v-icon>
              STADES
              <div class="parcelle-card" v-if="parcelle!=null">
                  <div v-for="stade in JSON.parse(parcelle.stades_calc)" :key="stade.code_arvalis"><div v-if="BH_noms_stades[stade.code_arvalis]"><span class="parcelle-card-title">{{BH_noms_stades[stade.code_arvalis]}}</span><span class="parcelle-card-val"> {{stade.date}} <v-icon small v-if="isStadeObs(stade.code_arvalis)==true">mdi-eye</v-icon></span><br></div></div> 
              </div>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-water
              </v-icon>
              IRRIGATION
              <div v-if="parcelle!=null && parcelle.irrigation!=null">
                  <!--<table>
                    <thead><tr><td style="width:120px">Date</td><td style="width:50px">Dose</td></tr></thead>
                    <tbody>
                      <tr v-for="irrig in JSON.parse(parcelle.irrigation)" :key="irrig.date">
                        <td>{{irrig.date}}</td>
                        <td>{{irrig.dose}}</td>
                      </tr>
                    </tbody>
                  </table>-->
                  <!--<template>
                    <v-data-table
                      :headers="headers_irrig"
                      :items="JSON.parse(this.parcelle.irrigation)"
                      class="elevation-1"
                      hide-default-footer
                      style="width:80%"
                    ></v-data-table>
                  </template>-->

                  <template>
                    <v-simple-table
                      fixed-header
                      height="200px"
                      style="width:80%"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Date
                            </th>
                            <th class="text-left">
                              Dose
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="irrig in JSON.parse(parcelle.irrigation)"
                            :key="irrig.date"
                          >
                            <td style="height:12px">{{ irrig.date }}</td>
                            <td style="height:12px">{{ irrig.dose }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div style="margin-bottom:20px;">
        <h3>Graphiques</h3>
        <div style="margin-bottom:20px;margin-top:20px;">
          <v-row>
            <v-col cols="8"><v-chart class="chart" :option="chartOptions"/></v-col>
          </v-row>
          <v-row>
            <v-col cols="8"><v-chart style="height:270px;" :option="chartOptionsDMHeatMap"/></v-col>
          </v-row>
        </div>
      </div>

      <div v-if="parcelle.bilan_hydrique!==null">
        <v-data-table
          :headers="headers_dataBH"
          :items="JSON.parse(parcelle.bilan_hydrique)"
          :items-per-page="table_items_per_page"
          :page="lastPage"
          :item-class= "rowStyle"
          class="my-awesome-table"
          dense 
        ></v-data-table>
        <v-btn small color="info" class="mt-2 mb-50 white--text">
            <download-excel :data="JSON.parse(parcelle.bilan_hydrique)">
              Export Excel 
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
          </download-excel>
        </v-btn>
      </div>

    </v-container>
  </div>
</template>


<script>
import parcellesBhService from "@/service/parcellesBh";
import VChart from "vue-echarts";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import downloadExcel from "vue-json-excel";
import {checkIsMobile} from "@/helpers/functions";


export default {
  name: "DetailParcelle_BH",
  components: {
    parcellesBhService,
    VChart,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    downloadExcel,
    checkIsMobile
  },
  data() {
    return {
      BH_noms_stades:null,
      parcelle : null,
      parcelle_pluies: null,
      info_pluies: null,
      isMobile: false,
      showModalIndicesBH:false,
      showModalGraphiqueBH:false,

      headers_dataBH: [
        { text: "Date", value: "Date"},
        { text: "Pluie (mm)", value: "Pluie"},
        { text: "Irrigation (mm)", value: "Irrigation"},
        { text: "Déficit en eau du sol (mm)", value: "Deficit en eau du sol"},
        { text: "RFU", value: "RFU"},
        { text: "RU", value: "RU"},
        { text: "Drainage profil", value: "Drainage profil"},    
        { text: "ETP", value: "ETP"},
      ],
      table_items_per_page : 10,
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 9,
        markers: [],
      },

      chartOptions: {
        title: {
          text: 'Bilan hydrique'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['RU', 'RFU', 'Déficit en eau du sol', 'Déficit en eau du sol prévisionnel', 'Stades', 'Irrigation', 'Drainage', 'Pluie'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          name: 'Déficit en eau du sol (mm)',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse:true
        },
        series: [
          {
            name: 'RU',
            type: 'line',
            data: [],
            lineStyle: {color: '#000'},
            showSymbol:false,
            itemStyle:{color:'#000'},
          },
          {
            name: 'Déficit en eau du sol',
            type: 'line',
            data: [],
            lineStyle: {color: '#0066cc'},
            showSymbol:false,
            itemStyle:{color:'#0066cc'},

            /*            z: -1, 
            areaStyle: {
                color: "red",
                origin: "start",
                opacity: 1 
            },*/
                        //z: -1, 
            areaStyle: {
                color: "red",
                origin: "auto",
                //opacity: 1 ,
                decal:20
            },
          },
          {
            name: 'Déficit en eau du sol prévisionnel',
            type: 'line',
            data: [],
            lineStyle: {color: '#0055cc',type: 'dashed'},
            showSymbol:false,
            itemStyle:{color:'#0066cc'},
            areaStyle: {
                color: "red",
                origin: "auto",
                decal:20
            },
          },
          {
            name: 'RFU',
            type: 'line',
            data: [],
            lineStyle: {color: '#ff0000'},
            showSymbol:false,
            itemStyle:{color:'#ff0000'},
            /*areaStyle: {
                color: 'white',
                origin: "start",
            },*/
            areaStyle: {
                color: 'white',
                origin: "auto",
                opacity: 1 
            },
          },
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'Irrigation',
            type: 'bar',
            data: [],
            itemStyle: {color: '#e2570f'},
            z:5
          },
          {
            name: 'Drainage',
            type: 'bar',
            data: [],
            itemStyle: {color: '#ffaf35'}
          },
          {
            name: 'Pluie',
            type: 'bar',
            data: [],
            itemStyle: {color: '#62b0fe'},
            z:5
          }
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '75%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: '10%', // Position according to the parent element (in the middle)
          bottom: '70%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 10,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'),
              width: 165,
              height: 32
          }
        }]
      },

      chartOptionsDMHeatMap:{
          title: {
          top: 30,
          left: 'center',
          text: 'Planning de réception des données météo'
        },
        tooltip: {
          show: true,
          formatter: function (a) {
              return 'Date : ' + a.value[0] + '<br>Pluie (mm) : ' + a.value[3] + '<br>Origine : ' + a.value[2]
            }
          },
        visualMap: [{
          dimension: 1,
          pieces: [
            {value:1, label: 'Données envoyées', color: '#2a9ca5'},
            {value:2, label: 'Données de station', color: '#40d16b'},
            {value:3, label: 'Données Arvalis', color: '#abd140'},
            {value:4, label: 'Données Arvalis prévisionnelles', color: '#f6a04e'},
          ],
          orient: 'horizontal',
          left: 'center',
          top : 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear()+'-01-01',new Date().getFullYear()+'-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: { show: true },
          dayLabel: {
            firstDay:1, 
            nameMap : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap : ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: []
        }
      }
    };
  },
  computed: {
    currentRouteName() {
      console.log("currentRouteName", this.$route.name);
      return this.$route.name;
    },
    /*parcelleSelected() {
      return this.$store.getters["parcelle/parcelleSelected"];
    }*/
    lastPage() {
      if(this.parcelle.bilan_hydrique!=null){
        return Math.ceil(JSON.parse(this.parcelle.bilan_hydrique).length/this.table_items_per_page);
      } else {
        return 1
      }
    }


  },
  watch: {
    $route(to, from) {
      this.loadParcelleSelected();
    }
  },
  async mounted() {
    //this.loadParcelleSelected();
    // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();    
    // Ajouter un écouteur d'événement pour surveiller les changements de taille de fenêtre
    //window.addEventListener('resize', this.checkIsMobile);

    fetch('./BH_noms_stades.json').then(response => response.json())
            .then((data) => this.BH_noms_stades = data);

    this.parcelle = await parcellesBhService.getInfosParcelleById(this.$route.params.id);
    this.parcelle = this.parcelle[0];

    this.carte.markers = [{
          id:this.parcelle.id_info_plot, 
          coordinates:[this.parcelle.latitude,this.parcelle.longitude], 
          imageUrl:require('@/assets/map_icon_red.png')
    }]
    this.carte.center = [this.parcelle.latitude,this.parcelle.longitude]

    var data = await parcellesBhService.getInfosPluiesParcelleById(this.$route.params.id);
    this.parcelle_pluies = data.pluies
    this.info_pluies = data.info

    if(this.parcelle.bilan_hydrique!==null){
      this.getTabChart()
      this.chartOptionsDMHeatMap.calendar.range = this.getTabChartDMHeatMapRange();
      this.chartOptionsDMHeatMap.series.data = this.getTabChartDMHeatMap();
    }

  },
  methods: {

    // checkIsMobile() {
    //   this.isMobile = window.innerWidth < 960; // Déterminer si l'écran est bien un mobile
    // },

    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    returnToSearch() {
      this.$router.push("/bh/tdb");
    },
    async loadParcelleSelected() {
      if (
        this.$route.params.id != null /*&&
        (this.parcelleSelected == null ||
          this.parcelleSelected.id != this.$route.params.id)*/
      ) {
        /*const parcelle = await parcellesChnService.getById(
          this.$route.params.id
        );
        this.$store.dispatch("parcelle/setParcelleSelected", parcelle);*/
      }
    },
    isStadeObs(code_stade){
      var isStadeObs = false
      if(this.parcelle.stades_obs != null){
        var array = JSON.parse(this.parcelle.stades_obs)
        array.forEach((item, index) => {
          if(item.code_arvalis == code_stade){
            isStadeObs = true
          }
        });
      }
      return isStadeObs
    },

    openModalAndGenerateChart(){
      this.showModalGraphiqueBH = true;


      
    },
    getTabChart(){
      var array_date=[]
      var array_irrigation=[]
      var array_pluie=[]
      var array_drainage=[]
      var array_deficit=[]
      var array_deficit_previ=[]
      var array_ru=[]
      var array_rfu=[]
      var array_stades=[]

      var bh = JSON.parse(this.parcelle.bilan_hydrique)
      
      bh.forEach(element => {
        array_date.push(element['Date']),
        array_irrigation.push([element.Date,parseFloat(element['Irrigation']).toFixed(2)])
        array_ru.push(parseFloat(element['RU']).toFixed(2))
        array_rfu.push(parseFloat(element['RFU']).toFixed(2))
        if(new Date(element['Date'].split("/").reverse().join("-")) <= new Date()){
          array_pluie.push([element.Date,parseFloat(element['Pluie']).toFixed(2)])
          array_drainage.push([element.Date,-parseFloat(element['Drainage profil ']).toFixed(2)])
          array_deficit.push([element.Date,parseFloat(element['Deficit en eau du sol']).toFixed(2)])
        } else if(element['type']=='previ'){
          array_deficit_previ.push([element.Date,parseFloat(element['Deficit en eau du sol']).toFixed(2)])
        }
      });

      var stades = JSON.parse(this.parcelle.stades_calc)
      stades.forEach(element => {
        if(this.BH_noms_stades[element.code_arvalis]){
          array_stades.push({ name: this.BH_noms_stades[element.code_arvalis], xAxis: element.date, lineStyle: {
                color: "#33ce29de",
                type: 'solid',
                width: 3                     
              }, label:{show:true,formatter: '{b}', rotate:90, position:'end',align:'left', offset:[7,-12]}})
        }
      });
      
      this.chartOptions.xAxis.data = array_date;
      this.chartOptions.series[0].data = array_ru;
      this.chartOptions.series[3].data = array_rfu;
      this.chartOptions.series[1].data = array_deficit;
      this.chartOptions.series[2].data = array_deficit_previ;
      this.chartOptions.series[4].markLine.data = array_stades;
      this.chartOptions.series[5].data = array_irrigation;
      this.chartOptions.series[6].data = array_drainage;
      this.chartOptions.series[7].data = array_pluie;
      
    },
    getTabChartDMHeatMap(){
      var array=[]
      var DM = this.parcelle_pluies
      DM.forEach(element => {
        var date = element['date'].split("/").reverse().join("-")
        var type = null
        var nomType = ''
        if(element['type']=='manual'){
          type = 1
          nomType = 'Données envoyées'
        } else if(element['type']=='station'){
          type = 2
          nomType = 'Données de station'
        } else {
          if(new Date(date) <= new Date (this.info_pluies[0].findesdonneesreelles)){
            type = 3
            nomType = 'Données Arvalis'
          } else if(new Date(date) > new Date (this.info_pluies[0].findesdonneesreelles) && new Date(date) <= new Date ()){
            type = 4
            nomType = 'Données Arvalis prévisionnelles'
          }
        }
        array.push([
          date,
          type,
          nomType,
          parseFloat(element['pluie']).toFixed(2),
        ])
      });

      return array
    },
    getTabChartDMHeatMapRange(){
      //recherche de l'année en se basant sur le bilan hydrique (annee = anne du dernier jour du BH)
      var bh = JSON.parse(this.parcelle.bilan_hydrique)
      var element = bh[bh.length -1]
      var tab_date = element['Date'].split("/")
      var year = tab_date[2]


      var debut = year+'-01-01'
      var fin = year+'-10-31'

      return [debut,fin]
    },
    rowStyle(item) {
      if (item.type == 'previ') {
        return ["orange-line"];
      } else if(item.type == 'freq') {
        return ["grey-line"];
      } else {
        return ''
      }
    },
  }
};
</script>

<style scoped>
.chart {
  height: 450px;
}
.map {
  height: 250px !important;
  width: 100% !important;
  position: relative !important;
  margin-bottom: 30px;
  z-index:0;
}

.leaflet-container {
    height: 250px;
    width: 100%;
  }
.leaflet-pane {
    z-index: 0
}


.parcelle-card-title{
  width: 200px !important;
}


</style>

import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    apiError: false,
    message: ''
  },
  mutations: {
    setError(state, payload) {
      Vue.set(state, 'message', payload.message);
      Vue.set(state, 'apiError', true);
    },
    unsetError(state) {
      Vue.set(state, 'apiError', false);
      Vue.set(state, 'message', '');
    },
    reset: (state) => {
      state.message = '';
      state.apiError = false;
    }
  },
  getters: {
    errorstate: state => state.apiError
  },
  actions: {
    setError({commit}, payload) {
      commit('setError', payload);
    },
    unsetError({commit}) {
      commit('unsetError');
    },
    reset: ({commit}) => {
      commit('reset');
    }
  }
};
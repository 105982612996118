import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  // bouchon
  async login(payload) { //payload
    return new Promise((resolve, reject) => {
      // Liste user
      fetch('./users.json')
        .then(response => response.json())
        .then(users => {
          const user = users.filter( u => u.login === payload.username);
          (user.length == 1)?resolve(user[0]) : reject(401);
        })
        .catch((error) => {
          if(error.status) {
            reject(error.status);
          } else {
          // error = error.substr
          error = 'errors.services.auth-service-error';
          reject(error);
        }
      })
      });
  },

  async resetMdp(mail) {

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}password/reset`, {
        method: 'POST',
        body: JSON.stringify({mail: mail}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async changeMdp(mail,oldMdp,newMdp) {

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}password/change`, {
        method: 'POST',
        body: JSON.stringify({mail: mail,oldMdp: oldMdp,newMdp: newMdp}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },



  // // envoie la réponse si l'utilisateur existe
  // async login(payload) { //payload
  //   return new Promise((resolve, reject) => {
  //     fetch(`http://autorisation-api-dev.arvalis-fr.com/v1/GetByLogin/applicationCode/DBX/login/n.castel@arvalis.fr/langue/FR-fr`, {
  //       method: 'GET',
  //       //body: JSON.stringify(payload),
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJnaXZlbl9uYW1lIjoiQVJWQUxJUy1GUlxcU1ZDX0FVVE9SSVNBVElPTl9CUk8iLCJqdGkiOiIxNjVlMTMyNC0yNTNhLTRmYzItOTQxNC0yNzM4NzJlMzlmYTQiLCJuYmYiOjE2Mjk5MDUwMzAsImV4cCI6MTYyOTkyMzAzMCwiaWF0IjoxNjI5OTA1MDMwfQ.lrfnrxfsq-mAXoRAFlogpoG_Lb0DZaKmSOIG3FS6VgU'
  //       },
  //     })
  //     .then((response) => {
  //       console.log('response', response);
  //       if (!response.ok) {
  //         throw (response);
  //       }
  //       resolve(response.json());
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //       if(error.status) {
  //         reject(error.status);
  //       } else {
  //         // error = error.substr
  //         error = 'errors.services.auth-service-error';
  //         reject(error);
  //       }
  //     });
  //   });
  // },


};

<template>
    <div ref="formulaire">

      <v-card style="padding:15px">

        <div style="overflow:hidden">
          <div>
            <v-row>
              <v-col cols="8">
                <v-icon>mdi-map-marker</v-icon>
                Date de semis et lieu : 
                
                <v-row>
                  <v-col cols="5">
                    <v-menu
                      ref="stadeMenuDate"
                      v-model="stadeMenuDate"
                      :close-on-content-click="false"
                      :return-value.sync="selectedDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectedDate"
                          label="Période de semis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedDate"
                        no-title
                        scrollable
                        range
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="stadeMenuDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.stadeMenuDate.save(selectedDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row dense  justify="center" align="center">
                  <v-col cols="3">
                    <v-select
                      v-model="selectedDepartement"
                      :items="listeDepartement"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="Departement(s)"
                      chips
                      multiple
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="1" align="center">
                    -
                  </v-col>
                  <v-col  cols="3">
                    <v-autocomplete
                      v-model="selectedStation"
                      :items="listeStations"
                      item-text="name"
                      item-value="code"
                      label="Station(s)"
                      chips
                      multiple
                      dense
                    />
                  </v-col>
                  <v-col cols="1" align="center">
                    -
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="selectedPra"
                      :items="listePra"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="PRA"
                      multiple
                      chips
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense  justify="center" align="center">
                  <v-col cols="5">
                    <v-select
                      v-model="selectedSolPanoramix"
                      :items="listeSolPanoramix"
                      menu-props="auto"
                      label="Sol Panoramix"
                      hide-details
                      multiple
                      chips
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="1" align="center">
                    ou
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="selectedSolBase"
                      :items="listeSolBase"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="Code base sol"
                      hide-details
                      multiple
                      chips
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <v-icon>mdi-note-text</v-icon>
                    Commentaire :
                    <v-textarea
                      v-model="commentaireRecalage"
                      solo
                      name="commentaireRecalage"
                      label="Commentaire"
                      rows="12"
                      row-height="20"
                      style="margin-top:10px;"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div style="margin-top:20px;">
            <v-icon>mdi-sprout</v-icon>
            Culture :
            <v-row dense style="margin-top:20px;">
              <v-col cols="3">
                <v-select
                  v-model="selectedMaladie"
                  :items="listeMaladie"
                  item-text="name"
                  item-value="code"
                  menu-props="auto"
                  label="Maladie recalée"
                  hide-details
                  chips
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense style="margin-top:20px;">
              <v-col>
                <v-radio-group v-model="selectedEspece" row>
                  <v-radio v-for="elem in listeEspece" :key="elem.id"
                    :label="elem.name"
                    :value="elem.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense>           
              <v-col cols="3" v-if="listeVariete!=[]">
                <v-select
                  v-model="selectedVariete"
                  :items="listeVariete"
                  item-text="Nom"
                  item-value="Nom"
                  menu-props="auto"
                  label="Variété"
                  hide-details
                  multiple
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="1" align="center">
                ou
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="selectedNoteSensibilite"
                  :items="listeNoteSensibilite"
                  menu-props="auto"
                  label="Note de sensibilité"
                  hide-details
                  multiple
                  chips
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <div style="margin-top:30px;">
            <v-icon>mdi-pencil</v-icon>
            Valeur de recalage :      
            <v-row dense style="margin-top:20px;">
              <v-col cols="1">
                <v-text-field
                  label="Valeur"
                  type="number"   
                  step="any"
                  ref="input"
                  v-model="valMaladieRecalage"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense style="margin-top:20px;">
              <v-col cols="5">
                <v-radio-group v-model="typeDemandeRecalage" row dense>
                  <v-radio
                    label="Nouvelle demande"
                    value="Nouvelle demande"
                    :disabled="disabledNewDemande"
                  ></v-radio>
                  <v-radio
                    label="Correction"
                    value="Correction"
                    :disabled="disabledCorrection"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>

          <v-btn small color="info" class="mt-2 mb-50 white--text" @click="verifEnvoiRecalageMaladie()" style="float:right">
              <v-icon small class="mr-2">
                mdi-upload
              </v-icon>
              Envoyer la demande
          </v-btn>

        </div>
        
      </v-card>
    </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Maladie_Form",
  components: {
    downloadExcel,
    recalageService
  },
  props: {
    infosRecalage: Object
  },
  data() {
    return {
      isLoading: false,
      disabledNewDemande: false,
      disabledCorrection: true,
      typeDemandeRecalage: 'Nouvelle demande',
      refRecalageCorrige: null,
      valMaladieRecalage: null,
      activePicker: null,
      stadeMenuDate: false,
      selectedDate: [],
      selectedMaladie: null,
      selectedDepartement: [],
      selectedStation: [],
      selectedEspece: null,
      selectedVariete: [],
      selectedSolPanoramix: [],
      selectedSolBase: [],
      selectedPra: [],
      selectedNoteSensibilite: [],
      listeDepartement: [],
      listeStations: [],
      listeEspece: [{id:17, code:'bth', name:'Blé tendre'},{id:40, code:'bdh', name:'Blé dur'},{id:27, code:'oh', name:'Orge d\'hiver'},{id:19, code:'triti', name:'Triticale'}],
      listeMaladie: [{code:'septo', name:'Septoriose'},{code:'rj', name:'Rouille jaune'},{code:'pv', name:'Piétin verse'},{code:'rb', name:'Rouille brune'}],      
      listeNoteSensibilite: [1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8,8.5,9],
      listeVariete: [],
      listeSolPanoramix: ['Argile', 'Argilo calcaire profond', 'Argilo calcaire superficiel', 'Craie de Champagne', 'Graviers', 'Limon', 'Limon argileux', 'Sables'],
      listeSolBase: [],
      listePra: [],
      commentaireRecalage: null,
      listeToutesVarietes: null,
      horsPerimetreIR: false
    };
  },
  async mounted() {
    const el = this.$refs['formulaire']
    el.scrollIntoView({ behavior: 'smooth'})

    if((this.$store.getters['recalage/ListeVarietes']()).length==0){
      this.listeToutesVarietes = await recalageService.getVarietesCapNotes();
      this.$store.commit('recalage/defineListe', {liste:this.listeToutesVarietes})
    } else {
      this.listeToutesVarietes = this.$store.getters['recalage/ListeVarietes']()
    }

    var listeDep = null
    if((this.$store.getters['recalage/ListeDepartements']()).length==0){
      listeDep = await recalageService.getDepartementsUtilisateur();
      this.$store.commit('recalage/defineListeDepartements', {listeDepartements:listeDep})
    } 
    this.listeDepartement = this.$store.getters['recalage/ListeDepartements']()

    var listeStations = null
    if((this.$store.getters['recalage/ListeStations']()).length==0){
      listeStations = await recalageService.getAllStations();
      this.$store.commit('recalage/defineListeStations', {listeStations:listeStations})
    } 
    this.listeStations = this.$store.getters['recalage/ListeStations']()

    var listeSols = null
    if((this.$store.getters['recalage/ListeSols']()).length==0){
      listeSols = await recalageService.getAllSols();
      this.$store.commit('recalage/defineListeSols', {listeSols:listeSols})
    } 
    this.listeSolBase = this.$store.getters['recalage/ListeSols']()

    var listePra = null
    if((this.$store.getters['recalage/ListePra']()).length==0){
      listePra = await recalageService.getAllPra();
      this.$store.commit('recalage/defineListePra', {listePra:listePra})
    } 
    this.listePra = this.$store.getters['recalage/ListePra']()
    
    if(this.infosRecalage!==null){
      this.selectedEspece=this.infosRecalage.culture
      this.selectedMaladie=this.infosRecalage.maladie
      this.refRecalageCorrige= this.infosRecalage.idRef,
      this.valMaladieRecalage= this.infosRecalage.jourdecalage,
      this.selectedDate= [this.infosRecalage.datedebut,this.infosRecalage.datefin],
      this.selectedDepartement= this.infosRecalage.departements.split(","),
      this.selectedStation= (this.infosRecalage.stations!=''?this.infosRecalage.stations.split(","):[])
      this.selectedVariete= this.infosRecalage.varietes.split(","),
      this.selectedNoteSensibilite= (this.infosRecalage.notesensibilite!=null && this.infosRecalage.notesensibilite!=''?this.infosRecalage.notesensibilite.split(",").map(function(item) {return parseFloat(item, 10);}):[]),
      this.selectedSolPanoramix= this.infosRecalage.typesolspanoramix.split(","),
      this.selectedSolBase= this.infosRecalage.typesolsarvalis.split(","),
      this.selectedPra= this.infosRecalage.pra.split(","),
      this.commentaireRecalage=this.infosRecalage.commentaire

      if(this.infosRecalage.idRef==null){
        this.typeDemandeRecalage= 'Nouvelle demande'
      } else {
        this.disabledNewDemande= true,
        this.disabledCorrection= false,
        this.typeDemandeRecalage= 'Correction'
      }
      
    } else {
      this.typeDemandeRecalage= 'Nouvelle demande'
      this.selectedEspece=17
    }
  },

  watch: {
    selectedEspece: function() {
      this.listeVariete = this.$store.getters['recalage/ListeVarietesEspece'](this.selectedEspece)
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
  },

  computed: {
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
  },

  methods: {
    async verifEnvoiRecalageMaladie () {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      if(this.selectedDate.length===2 && this.selectedMaladie!==null && this.valMaladieRecalage!==null && (this.selectedDepartement.length>0 || this.selectedStation.length>0 || this.selectedPra.length>0) && this.typeDemandeRecalage!=null){
        this.selectedDate.sort()
        var droits_recalage = this.$store.getters['recalage/DroitsRecalage']()
        var droits = 1
        if(this.selectedDepartement.length!=0){
          this.selectedDepartement.forEach(element => {
            if(!droits_recalage.includes(element)){
              droits = 0
            }
          });
        }
        if(this.selectedPra.length!=0){
          this.selectedPra.forEach(element => {
            var dep = element.substr(0,2)
            if(!droits_recalage.includes(dep)){
              droits = 0
            }
          });
        }

        this.horsPerimetreIR = false
        if(droits==0){
          this.confirmSaveRecalage()
          this.horsPerimetreIR = true
        } else {
          this.envoyerRecalageMaladie()
        }
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Formulaire incomplet",description:"Les informations suivantes sont obligatoires :\n- Plage de semis\n- Localisation (département(s) ou station(s) ou PRA(s))\n-Maladie recalée\n- Valeur du recalage\n- Type de relacage (nouveau ou correction)"} });
      }
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },

    confirmSaveRecalage(){
      this.$store.dispatch("choix/setChoix", {
        question: "Vous êtes sur le point de faire une demande de recalage sur une espèce ou des départements pour lesquels vous n'avez pas les droits. Confirmez vous vouloir effectuer l'enregistrement?",
        choix: {
          choix1: { text: "Oui", function: "envoyerRecalageMaladie" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },    
    async envoyerRecalageMaladie () {
      this.closeChoix()
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isSent = null
      var donneesRecalageMaladie = {valMaladieRecalage:this.valMaladieRecalage, selectedDate:this.selectedDate, selectedDepartement:this.selectedDepartement,selectedStation:this.selectedStation,selectedEspece:this.selectedEspece,selectedVariete:this.selectedVariete,selectedNoteSensibilite:this.selectedNoteSensibilite,selectedMaladie:this.selectedMaladie,selectedSolPanoramix:this.selectedSolPanoramix,selectedSolBase:this.selectedSolBase,selectedPra:this.selectedPra,commentaireRecalage:this.commentaireRecalage,horsPerimetreIR:this.horsPerimetreIR,typeDemandeRecalage:this.typeDemandeRecalage,refRecalageCorrige:this.refRecalageCorrige}
      isSent = await recalageService.envoyerRecalageMaladie(donneesRecalageMaladie);
      
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(isSent !== true){
          alert('Un problème est survenu.')
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"La demande de recalage a bien été enregistrée."} });
        this.$emit("reloadRecalage")
        this.horsPerimetreIR = false
      }
    },
    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },
  }
};
</script>

<style>
</style>

<template>
  <div>
    <v-container style="margin-top: 10px">
      
        <!-- Modal pour le INDICES CLIMATIQUES en MOBILE-->
        <v-dialog v-model="showModalIndicesClimatiqueMobile" max-height="90vh" height="90vh">
          <v-card>
            <v-card-title>
              <span>Indices Climatiques</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="searchWordIndice" append-icon="mdi-magnify" label="Recherche / date" single-line
                hide-details></v-text-field>
              <v-btn icon @click="showModalIndicesClimatiqueMobile = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table id="indices_meteo_tableau"  mobile-breakpoint="0" :headers="headers_temp_hum_heure_risk"
              :search="searchWordIndice" :items="indices_meteo_risk_data_table" class="elevation-1" :items-per-page="24"
              dense 
              :sort-desc="sortDescInd">
              <template v-slot:item="{ item }">
                <tr :key="item.datetime">
                  <td>{{ formatDateTime(item.datetime) }}</td>
                  <td :class="getHumClass(item.value_hum)" :title="getHumTooltip(item.value_hum)">
                    {{ item.value_hum || '-' }}</td>
                  <td :class="getTempClass(item.value_temp)" :title="getTempTooltip(item.value_temp)">
                    {{ item.value_temp || '-' }}</td>
                  <td>{{ item.indexconta }}</td>
                  <td>
                    <v-icon small class="mr-2" :color="getColorRisque(item.risque)"
                      :title="getInfoRisqueTooltip(item.risque)">
                      {{ getIconRisqueIndiceMeteo(item.risque) }}
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-btn small color="info" class="mt-2 white--text">
              <download-excel :data="indices_meteo_risk_data_table" :name="getNomFichier('day')"
                :fields="excelfields_temp_hum_heure_risk">
                Export Excel
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </download-excel>
            </v-btn>
          </v-card>
        </v-dialog>
        <br>
        <!---->
        <br>
        <div>
        <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
          <v-card-title>
            <span class="headline">Ajout météo à/aux parcelle(s)</span>
          </v-card-title>
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submitFormAddReplacement" class="row text-center">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <label for="form_date_start_replacement_meteo">Date de début</label><br>
                  <input style="background-color:white" type="date" id="form_date_start_replacement_meteo"
                    v-model="form_date_start_replacement_meteo" :disabled="form_check_box_gap_meteo" required
                    class="input_field" 
                    :min="getMinStartDate()"  
                    :max="getMaxEndDate()">
                </div>
                <div class="col-sm-6">
                  <label for="form_date_end_replacement_meteo">Date de fin</label><br>
                  <input style="background-color:white" type="date" id="form_date_end_replacement_meteo"
                    v-model="form_date_end_replacement_meteo" :disabled="form_check_box_gap_meteo" required
                    class="input_field" 
                    :min="getMinEndDate()"  
                    :max="getMaxEndDate()">
                </div>

                <div class="col-sm-6" style="margin-bottom: 15px; font-size:1.25rem">
                  <label>
                    <input type="checkbox" v-model="form_check_box_gap_meteo" @change="handleCheckboxChange"> Combler les manques météo
                  </label>
                </div>
                <div class="col-sm-6">
                  <p style="font-style:italic;color:grey;"> <v-icon color="warning">
  mdi-alert
</v-icon>- Sélectionner la/les parcelles avant de cliquer sur <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">Enregistrer</span>.</p>
                  <button type="submit" :disabled="invalid || loading_action_api || selectedItems.length === 0 " class="submit-button">
                      <span v-if="loading_action_api===true">Chargement...</span>
                      <span v-else>Enregistrer</span>
                  </button>
                </div>
                <p style="text-align: left">
                  VIGILANCE sur la fiabilité des indices maladies calculés sur la période d’ajout et les 2 semaines suivantes.
                  Les données météo utilisées sont des données spatialisées, basées sur du prévisionnel,  donc moins précises que les données météo de station. 
                </p>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </div>
        <h3>Selectionner la/les parcelles à corriger </h3>
      <v-card-title>
       
        <v-spacer></v-spacer>
        <v-text-field v-model="searchWord_ajouts_meteo" append-icon="mdi-magnify" label="Recherche" single-line
          hide-details></v-text-field> 
      </v-card-title>
      <v-data-table :search="searchWord_ajouts_meteo"  :headers="headers_ajout_meteo" :items="formattedDataAjouts" :items-per-page="10" :loading="!dataloaded" loading-text="Chargement des données...."
        class="elevation-1" show-select mobile-breakpoint="0" v-model="selectedItems"  item-key="id">
        <template v-slot:[`item.status`]="{ item }">
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-icon :color="item.status=='OK' ? 'green' : 'red'" v-on="on">
                {{  'mdi-circle' }}
            </v-icon>
        </template>
        <span>
            {{ item.status=='OK' ? 'Parcelle calculée' : 'Parcelle en erreur météo' }}
        </span>
    </v-tooltip>
</template>
    <template v-slot:[`item.indicesClimatiques`]="{ item }">
        <v-icon v-if="!item.loading" @click="chargerIndicesClimatiques(item)">mdi-eye</v-icon>
        <span v-else>
            <v-icon>mdi-loading</v-icon>
            Chargement en cours...
        </span>
    </template>
</v-data-table>

<br><br>
      <h3>Corrections météo en cours</h3>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="searchWord_correction" append-icon="mdi-magnify" label="Recherche" single-line
          hide-details></v-text-field> 
      </v-card-title>
      <v-data-table :search="searchWord_correction" :headers="headers_corrections_meteo" :items="formattedData"
        :items-per-page="10" item-key="key" class="elevation-1" :loading="!dataloaded"
        loading-text="Chargement des données...." mobile-breakpoint="0">
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon :color="item.status=='OK' ? 'green' : 'red'" v-on="on">
                {{  'mdi-circle' }}
              </v-icon>
            </template>
            <span>
              {{ item.status=='OK' ? 'Parcelle calculée' : 'Parcelle en erreur' }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editIReplacementMeteo(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItemReplacementMeteo(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.type !== 'Jours manquants'">
            <v-icon style="color:black !important; background-color:transparent !important;" small
              @click="editIReplacementMeteo(item)">
              mdi-pencil
            </v-icon>
            <v-icon style="color:black !important; background-color:transparent !important;" small
              @click="deleteItemReplacementMeteo(item)">
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <div style="background-color:rgb(225, 225, 225);margin:0.4rem">
        <p>
          Les corrections météo sont prioritaires sur toutes les autres données météo. SUPPRIMEZ une correction si la station est de nouveau fonctionnelle.
        </p>
      </div>

      <br>
      <hr>
      <br>



      <!--MODAL pour modification des trous meteos-->
      <v-dialog v-model="showModalEditReplacementMeteo" max-height="96vh">
        <div>
          <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
            <v-card-title>
              <span class="headline">Modification d'un ajout météo </span>
              <v-btn icon @click="showModalEditReplacementMeteo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text style="height: auto; margin: 0 auto;">
              <p v-if="select_replacement_meteo" style="margin: 0.5rem;">
                Vous modifiez l'ajout météo débutant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_start_replacement_meteo }}</span> et
                terminant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_end_replacement_meteo }}</span> concernant la parcelle : <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ select_replacement_meteo.name }}</span>
              </p>
              <p>
                Saisissez vos nouvelles dates :
              </p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submitFormPutReplacement" class="row text-center">
                  <!-- Champ caché pour l'ID de la parcelle -->
                  <input type="hidden" v-model="select_replacement_meteo.id">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <label for="form_date_start_replacement_meteo">Date de début</label><br>
                    <input style="background-color:white" type="date" id="form_date_start_replacement_meteo"
                      v-model="form_date_start_replacement_meteo" required class="input_field">
                  </div>
                  <div class="col-sm-6">
                    <label for="form_date_end_replacement_meteo">Date de fin</label><br>
                    <input style="background-color:white" type="date" id="form_date_end_replacement_meteo"
                      v-model="form_date_end_replacement_meteo" required class="input_field">
                  </div>
                  <div class="col-md-6 col-sm-10 offset-sm-1 offset-md-3 text-center">
                      <button type="submit" :disabled="invalid" class="submit-button">Modifier</button>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
      <!---->

      <!--MODAL pour Suppression des trous meteos-->
      <v-dialog v-model="showModalDeleteReplacementMeteo" max-height="96vh">
        <div>
          <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
            <v-card-title>
              <span class="headline">Suppression d'un ajout météo </span>
              <v-btn icon @click="showModalDeleteReplacementMeteo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text style="height: auto; margin: 0 auto;">
              <p v-if="select_replacement_meteo" style="margin: 0.5rem;">
                Vous vous apprêtez à supprimer l'ajout météo débutant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_start_replacement_meteo }}</span> et
                terminant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_end_replacement_meteo}}</span> concernant la parcelle : <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ select_replacement_meteo.name }}</span>
              </p>
              <p>
                Confirmez-vous cette suppression ?
              </p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submitFormDeleteReplacement" class="row text-center">
                  <!-- Champ caché pour l'ID de la parcelle -->
                  <input type="hidden" v-model="select_replacement_meteo.id">
                  <div class="col-md-6 col-sm-10 offset-sm-1 offset-md-3 text-center">
                      <button type="submit" :disabled="invalid" class="submit-button">Supprimer</button>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
      <!---->


    </v-container>
  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import * as echarts from 'echarts';
import VChart from 'vue-echarts';
import { getDate, checkIsMobile } from "@/helpers/functions";
import html2canvas from 'html2canvas';
import { ValidationObserver } from 'vee-validate';

export default {
  name: "DetailParcelle_Mildiou",
  components: {
    downloadExcel,
    parcellesMildiouService,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    VChart,
    getDate,
    checkIsMobile,
    ValidationObserver
  },
  data() {
    return {
      isMobile: false,
      loading_action_api:false,
      showModalIndicesClimatiqueMobile : false,
      showModalEditReplacementMeteo: false,
      showModalDeleteReplacementMeteo:false,

      initial_date_start_replacement_meteo: '',
      initial_date_end_replacement_meteo: '',        
      form_date_start_replacement_meteo:'',
      form_date_end_replacement_meteo:'',

      form_check_box_gap_meteo:false,
      form_date_end_gap_meteo:'',
      selectedItems:[],
      select_replacement_meteo: null,

      parcelle : [],
      droits: this.$store.state.user.user.droitsTPC,
      parcelle_indices_horaires: [],
      station: [],
      id_station_in_this_parcelle:'', 
      dataloaded: false,
      filter: null,
      parcelle_indices_hum_temp : [],
      indices_hum_temp_data : [],
      indices_meteo_risk_data_table : [],
      sortDescInd: true ,
      searchWordIndice: '',
      searchWord_ajouts_meteo: '',
      searchWord_correction: '',
      data_meteo_to_send:[],
      items_corrections_meteo:[],
      arr_id_plots:[],

      headers_temp_hum_heure_risk: [
        { text: 'Date et Heure', value: 'datetime' },
        { text: 'Hygro et/ou Pluie', value: 'value_hum' },
        { text: 'Température', value: 'value_temp' },
        { text: "Index de conta", value: "indexconta"},
        { text: 'Risque mildiou', value: 'risque' },

      ],

      excelfields_temp_hum_heure_risk: {
        "Date et Heure": "datetime",
        "Hygronométrie et/ou Pluie": "value_hum",
        "Température": "value_temp",
        "Index de contamination": "indexconta",
        "Risque mildiou": "risque",
        "Réserve de spores": "l10spores",
        "Poids de contamination": "l10poids" ,
        "Potentiel de sporulation": "l10spospo" ,
      },

      headers_corrections_meteo: [
        { text: '', value: 'status' },
        { text: 'Id parcelle', value: 'id' },
        { text: 'Parcelle', value: 'name' },
        { text: 'Agriculteur', value: 'farmer' },
        { text: 'Station', value: 'iot_id' },
        { text: 'Type', value: 'type' }, 
        { text: 'Date(s)', value: 'date' },
        { text: '', value: 'actions', sortable: false },
    ],



      headers_ajout_meteo: [ 
        { text: '', value: 'status' },
        { text: 'Id parcelle', value: 'id' },
        { text: 'Parcelle', value: 'parcelle' },
        { text: 'Agriculteur', value: 'farmer' },
        { text: 'Station', value: 'iot_id' },
        { text: 'Indices climatiques', value: 'indicesClimatiques', sortable: false },
      ],

    };
  },

  created(){
    // Initialiser la valeur parcelle ID dans les formulaires
    this.form_parcelle_irrig = this.parcelle.id;
    this.form_parcelle_obs = this.parcelle.id;
    this.form_parcelle_traitement = this.parcelle.id;
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    checkDroitLectureExterne() {
      return this.$store.getters['user/checkDroit']('EXTERNE','lecture')
    },

    // Données formatées pour le v-data-table de corrections météos en cours
    formattedData() {
      let formatted = [];

      this.items_corrections_meteo.forEach(item => {
        // Gérer les periods
        if (Array.isArray(item.periods) && item.periods.length > 0) {
          item.periods.forEach((period, index) => {
            formatted.push({
              id: item.id,
              status: item.status,
              name: item.name,
              farmer: item.farmer,
              iot_id: item.iot_id,
              type: 'Periode', // Type pour les périodes
              date: `Du ${period.begin} au ${period.end}`, // Combiner début et fin en une seule chaîne
              key: `${item.id}-period-${index}` // Clé unique
            });
          });
        }

        // Gérer les missing days
        if (Array.isArray(item.missing_days) && item.missing_days.length > 0) {
          formatted.push({
            id: item.id,
            status: item.status,
            name: item.name,
            farmer: item.farmer,
            iot_id: item.iot_id,
            type: 'Jours manquants', // Type pour les jours manquants
            date: item.missing_days.join(', '), // Toutes les dates des jours manquants en une seule chaîne
            key: `${item.id}-missing-days` // Clé unique pour les jours manquants
          });
        }
      });

      return formatted;
    },


    // Données formatées pour le v-data-table de ajouts météos
    formattedDataAjouts() {
      let formatted = [];
      this.items_corrections_meteo.forEach(item => {
        formatted.push({
          id: item.id,
          status:item.status,
          parcelle: item.name,
          farmer: item.farmer,
          iot_id: item.iot_id,
          station: item.station,
          indicesClimatiques: item.indicesClimatiques, // Concaténation des indices climatiques en une chaîne de caractères
          key: item.id // Utilisation de l'ID comme clé unique dans le futur v-data-table
        });
      });
      return formatted;
    },




  },

  watch: {
    
  },

  async mounted() {   

  // Vérifier la taille de l'écran une fois la page chargée
  this.isMobile = checkIsMobile();

    this.items_corrections_meteo = await parcellesMildiouService.getCorrectionsMeteo();
    this.dataloaded = true;

    this.id_station_in_this_parcelle = this.parcelle.iot_id;
    if(this.id_station_in_this_parcelle != null && this.id_station_in_this_parcelle != "") {
      this.station =  await parcellesMildiouService.getStation(this.id_station_in_this_parcelle);
      this.station = this.station[0];
    }

  

  },
  
  methods: {

    /**
     * Fonction pour transformer les données en format désiré pour futur envoi API et obtenir les plots et les periods
     */ 
    // transformDataForApi(tableau) {
    // // Initialisation des structures de données
    // let plots = [];
    // let groupes = [];
    
    // // Parcours du tableau d'objets
    // tableau.forEach(objet => {
    //     // Ajout de l'id dans le tableau des plots
    //     plots.push(objet.id);
        
    //     // Vérification si le groupe existe déjà
    //     let groupe = groupes.find(g => g.id_user === objet.id_integrateur);
    //     if (groupe) {
    //         // Le groupe existe, on ajoute le farmer à son tableau
    //         groupe.farmers.push(objet.farmer);
    //     } else {
    //         // Le groupe n'existe pas, on le crée avec le farmer
    //         groupes.push({
    //             id_user: objet.id_integrateur,
    //             farmers: [objet.farmer]
    //         });
    //     }
    //   });
      
    //   // Création de l'objet final
    //   let finalObject = {
    //       plots: plots,
    //       groupes: groupes
    //   };
      
    //   // Retour de l'objet final
    //   return finalObject;
    // },

     // Méthode pour vérifier si des éléments sont sélectionnés dans le v-data-table avant de submit
      // hasSelectedItems() {
      //   if (this.selectedItems.length === 0) {
      //     alert("Veuillez sélectionner au moins un élément avant de soumettre le formulaire.");
      //     return false;
      //   }
      //   return true;
      // },

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    getMinStartDate() {
        // Date minimale : 01/03 de l'année précédente
        let currentYear = new Date().getFullYear();
        let minYear = new Date().getMonth() >= 2 ? currentYear : currentYear - 1; // Si le mois actuel est mars ou ultérieur, utiliser l'année actuelle, sinon utiliser l'année précédente
        let minDate = new Date(minYear - 1, 2, 1); // Les mois sont indexés de 0 à 11, donc 2 représente mars
        return minDate.toISOString().split('T')[0]; // Format ISO YYYY-MM-DD
    },

    getMaxEndDate() {
      // Date maximale : J-1 de cette année
      let maxDate = new Date(); // Date d'aujourd'hui
      maxDate.setDate(maxDate.getDate() - 1); // Soustraire un jour
      return maxDate.toISOString().split('T')[0]; // Format ISO YYYY-MM-DD
    },

    getMinEndDate() {
      // Date minimale : Date de début sélectionnée
      return this.form_date_start_replacement_meteo; // Utilisez la date de début sélectionnée comme date minimale pour la date de fin
    },

    getColorRisque (statut) {
        if (statut == 10) return 'green'
        else if (statut == 50) return 'red'
        else if (statut == 40) return 'orange'
        else return 'grey'
    },

    getIconRisqueIndiceMeteo (risque) {
        if (risque == 10) return 'mdi-emoticon-happy-outline'
        else if (risque == 50) return 'mdi-emoticon-sad-outline'
        else if (risque == 40) return 'mdi-emoticon-neutral-outline'
        else return '-'
    },

    getInfoRisqueTooltip (statut) {
        if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante.\n  Il n'y a donc pas de risque de contamination sur la parcelle."
        else if (statut == 50) return "Le climat et la quantité de spores sont favorables,\n  il y a donc la présence d'un risque de contamination sur la parcelle."
        else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. \n Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de rester vigilant au cas où les conditions climatiques deviennent favorables.\n  Un risque de contamination serait alors présent."
        else return ''
    },

    goTo(nomDiv){
      this.$vuetify.goTo('#'+nomDiv)
    },

    getNomFichier(type){
      var now = new Date()
      now = getDate(now,'yyyymmdd')

      if(type == 'day'){
        return now+'_ExportMildiou_'+this.parcelle.farmer+'_parcelle_'+this.parcelle.id
      } else {
        return now+'_ExportMildiouHoraire_'+this.parcelle.farmer+'_parcelle_'+this.parcelle.id
      }
    },

    transformDataIndiceTableau(data) {
      let transformed = [];
      let groupedData = {};
      data.forEach(entry => {
        let date = entry.datetime;
        if (!groupedData[date]) {
          groupedData[date] = { datetime: date };
        }
        if (entry.indicator === 'HUM') {
          groupedData[date].value_hum = entry.value;
        } else if (entry.indicator === 'TEMP') {
          groupedData[date].value_temp = entry.value;
        }
      });
      for (let date in groupedData) {
        transformed.push(groupedData[date]);
      }
      return transformed;
    },


    getHumClass(value) {
      return {
        'hum-red': value === 250,
        'hum-green': value === 200
      };
    },

    getTempClass(value) {
      return {
        'temp-light-pink': value === 100,
        'temp-green': value === 110,
        'temp-light-red': value === 130,
        'temp-dark-red': value === 140,
        'temp-darker-red': value === 150,
        'temp-red': value === 190
      };
    },


    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}h${date.getMinutes().toString().padStart(2, '0')}`;
      return formattedDate;
    },

    getHumTooltip(value) {
      let tooltipText = 'Humidité ';
      
      if (value == 200) {
        tooltipText += '<87% et P = 0 mm \n Absence de risque ';
      } else if (value == 250) {
        tooltipText += '>= 87% ou P > 0 mm \n Présence de risque ';
      } 

      return tooltipText;
    },

    getTempTooltip(value) {
      let tooltipText = ' Température ';
      
      if (value == 110 ) {
        tooltipText += ' <6°C \n Absence de risque';
        //Défavorable : Arrêt du cycle de prolifération pouvant aller jusqu\'à la destruction d\'une partie des spores sous 4°C';
      } else if (value == 130 ) {
        tooltipText += ' entre 6°C et 10°C \n Augmentation (faible) ';
        //Favorable';
      } else if (value == 140) {
        tooltipText += ' entre 10°C et 14°C \n Augmentation (moyen)';
        //Favorable';
      } else if (value == 150) {
        tooltipText += ' entre 14°C et 18°C \n Augmentation (fort)';
        //Très favorable';
      } else if (value == 190) {
        tooltipText += ' entre 18°C et 30°C \n Augmentation (très fort)';
        //Très favorable, Optimum';
      } else if (value == 100) {
        tooltipText += ' >30°C \n Diminution du risque';
        //Très défavorable : destruction d\'une partie des spores';
      }

      return tooltipText;
    },

    // Méthode pour ajouter risque et indexconta à indices_hum_temp_data
    addRiskContaToIndMeteo(indices_horaires, indices_hum_temp) {
      let updatedIndicesHumTemp = [...indices_hum_temp];

      // Trouver la date de début dans indices_horaires
      let startDateIndicesRisque = indices_horaires[0].date;

      // Filtrer les éléments de notre variable indices HUM et TEMP à partir de la même date de début que celle de indices_horaires
      let filteredIndicesHumTemp = updatedIndicesHumTemp.filter(item => {
        let datetimeIndiceHumTemp = item.datetime;
        return datetimeIndiceHumTemp >= startDateIndicesRisque;
      });

      // Boucler sur les variables et ajouter le risque et index contaet autres indicateurs à notre variable filteredIndicesHumTemp
      for (let i = 0; i <= filteredIndicesHumTemp.length; i++) {
        let datetimeIndiceRisqueHoraire = new Date(indices_horaires[i].date + ' ' + indices_horaires[i].heure);
        
        // Recherche de la date correspondante dans filteredIndicesHumTemp
        let correspondingIndex = filteredIndicesHumTemp.findIndex(item => {
          let datetime2 = new Date(item.datetime);
          // Comparaison des dates
          return datetimeIndiceRisqueHoraire.getTime() === datetime2.getTime();
        });

        // Si une correspondance est trouvée, mettre à jour les valeurs en y ajoutant les nouvelles proprietees
        if (correspondingIndex !== -1) {
          filteredIndicesHumTemp[correspondingIndex].risque = indices_horaires[i].risque;
          filteredIndicesHumTemp[correspondingIndex].indexconta = indices_horaires[i].indexconta;
          filteredIndicesHumTemp[correspondingIndex].l10poids = indices_horaires[i].l10poids;
          filteredIndicesHumTemp[correspondingIndex].l10spospo = indices_horaires[i].l10spospo;
          filteredIndicesHumTemp[correspondingIndex].l10spores = indices_horaires[i].l10spores;
        }
      }

      return filteredIndicesHumTemp;
    },

    // Fonction pour mettre à jour les données après action CRUD sur les replacements meteo
    updateDataAfterAction(message_succes) {
    // Appel au service pour récupérer les corrections météo
    parcellesMildiouService.getCorrectionsMeteo().then(result => {
        // Mettez à jour les données du tableau ici
        this.items_corrections_meteo = result; // Assurez-vous que le service retourne les données

        this.$store.dispatch('general/setWaitingOverlay', {
            loading: false,
            fullpage: true
        });
        this.$store.dispatch('informations/setInfo', {
            message: {
                titre: "Action bien effectuée",
                description: `${message_succes}`
            }
        });
    })
},


    submitFormPutReplacement() {
      this.$store.dispatch('general/setWaitingOverlay', {
        loading: true,
        fullpage: true
      });
      // Créer un objet contenant les données du replacement
      let replacement_data = [{
        'select_begin_replacement_meteo': this.initial_date_start_replacement_meteo,
        "begin": this.form_date_start_replacement_meteo,
        "end": this.form_date_end_replacement_meteo
      }];
      let id_plot = this.select_replacement_meteo.id;

      //  Modification météo de la parcelle 
      parcellesMildiouService.editOneReplacementsMeteoForThePlot(id_plot, replacement_data)
        // Une fois que l'ajout bien réalisé, recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterAction('Modification de remplacement de parcelle effectué avec succès !');
        })

      // Réinitialiser les champs après la soumission du formulaire
      this.form_date_start_replacement_meteo = '';
      this.form_date_end_replacement_meteo = '';
      this.select_replacement_meteo = '';

      this.showModalEditReplacementMeteo = false;
    },

    submitFormAddReplacement() {
      // if(this.hasSelectedItems()){
              this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

        // Créer un nouveau tableau contenant seulement les ID des parcelles sélectionnées
        let selectedParcelleIds = this.selectedItems.map(item => item.id);

        // * Vérifier si l'utilisateur a coché la case pour combler les manques météo
        if (this.form_check_box_gap_meteo) {
          // Boucler sur chaque ID de parcelle sélectionné
          selectedParcelleIds.forEach(id => {
            // Appeler la fonction pour chaque ID de parcelle que l'utilisateur a sélectionné
            parcellesMildiouService.searchFoundMissingReplacementMeteoForPlot(id)
            .then(() => {
              this.updateDataAfterAction('Recherche et intégration des dates de données météos manquantes effectué avec succès !');
            })
          });

          // Réinitialiser les champs après la soumission du formulaire
          this.form_check_box_gap_meteo = false;
          selectedParcelleIds = [];
        } else {
          // Créer un objet contenant les données de l'ajout ( dates précises de début et de fin)
          let replacement_data = [{
            'begin': this.form_date_start_replacement_meteo,
            'end': this.form_date_end_replacement_meteo
          }];

          // Boucler sur chaque ID de parcelle sélectionné
          selectedParcelleIds.forEach(id => {
            // Appeler la fonction pour chaque ID de parcelle que l'utilisateur a sélectionné
            // ➕ Ajout météo de la parcelle
            parcellesMildiouService.addReplacementPlot(id, replacement_data)
              .then(() => {
                this.updateDataAfterAction('Ajout de remplacement de parcelle effectué avec succès !');
              })
          })

          this.form_date_start_replacement_meteo = '';
          this.form_date_end_replacement_meteo = '';
        }

      //}

    },

    handleCheckboxChange() {
        // Réinitialiser les valeurs des champs de date si le checkbox est coché
        if (this.form_check_box_gap_meteo) {
            this.form_date_start_replacement_meteo = '';
            this.form_date_end_replacement_meteo = '';
        }
    },

    editIReplacementMeteo(item) {
        // Extraire les dates de début et de fin à partir de la chaîne formatée
        let datePattern = /Du (\d{4}-\d{2}-\d{2}) au (\d{4}-\d{2}-\d{2})/;
      let matches = item.date.match(datePattern);

      if (matches) {
        // Stocker la date de début initiale
        this.initial_date_start_replacement_meteo = matches[1];
        this.initial_date_end_replacement_meteo = matches[2];

        // Assigner les dates extraites à la modal
        this.form_date_start_replacement_meteo = matches[1];
        this.form_date_end_replacement_meteo = matches[2];
      }
      this.select_replacement_meteo = item;
      this.showModalEditReplacementMeteo = true;
    },

    deleteItemReplacementMeteo(item){
      // Extraire les dates de début et de fin à partir de la chaîne formatée
      let datePattern = /Du (\d{4}-\d{2}-\d{2}) au (\d{4}-\d{2}-\d{2})/;
      let matches = item.date.match(datePattern);

      if (matches) {
        // Stocker la date de début initiale
        this.initial_date_start_replacement_meteo = matches[1];
        this.initial_date_end_replacement_meteo = matches[2];

        // Assigner les dates extraites à la modal
        this.form_date_start_replacement_meteo = matches[1];
        this.form_date_end_replacement_meteo = matches[2];
      }

      this.select_replacement_meteo = item;
      this.showModalDeleteReplacementMeteo = true;
    },

    submitFormDeleteReplacement() {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      let id_plot = this.select_replacement_meteo.id;
      let date_debut_periode = this.initial_date_start_replacement_meteo;
        
      //  Suppression 1 correction météo de la parcelle 
      parcellesMildiouService.deleteOneReplacementsMeteoForThePlot(id_plot, date_debut_periode)
        .then(() => {
          this.updateDataAfterAction('Suppression de remplacement de parcelle effectué avec succès !');
        })
        // Réinitialisation de la variable 
        this.showModalDeleteReplacementMeteo = false;
    },

    async chargerIndicesClimatiques(item) {
        // Définir la propriété loading de l'élément sur true pour generer un effet LOADING
        this.$set(item, 'loading', true);
        try {
          this.parcelle_indices_hum_temp = await parcellesMildiouService.getIndicesHumiditeTempPlot(item.id);
          // Pour le tableau INDICE de l'HUM et TEMP
          this.indices_hum_temp_data = this.transformDataIndiceTableau(this.parcelle_indices_hum_temp);
          this.showModalIndicesClimatiqueMobile = true;
          //Ajout du risque et conta aux données précedentes si données dispo dans INDICE HORAIRE
          if (this.parcelle_indices_horaires.length == 0) {
            this.parcelle_indices_horaires = await parcellesMildiouService.getIndicesHorairesParcelle(item.id);
            this.indices_meteo_risk_data_table = this.indices_hum_temp_data;
          }
          this.indices_meteo_risk_data_table = this.addRiskContaToIndMeteo(this.parcelle_indices_horaires, this.indices_hum_temp_data);
          item.loading = false;
        } catch (error) {
          item.loading = false;
        }
    },



  }// FIN de la PARTIE des METHODES

};
  

</script>

<style scoped>
.chart {
  height: 450px;
}
.float {
  float: left;
  margin-right: 20px;
}






.heatmapIndice{
  padding:1.5rem;
}

.hum-red {
  background-color: #ff0000;
}
.hum-green {
  background-color: #92d050;
}
.temp-green {
  background-color: #92d050;
}

.temp-blue {
  background-color: #00b0f0;
}
.temp-light-red {
  background-color: #ffa7a7;
}
.temp-dark-red {
  background-color: #ff7575;
}
.temp-darker-red {
  background-color: #ff5757;
}
.temp-red {
  background-color: #ff0000;
}

.temp-light-pink{
  background-color: #ffcece;
}

/* EN MOBILE  */
@media only screen and (max-width: 960px) {

  .v-dialog{
    height: 90vh !important;
    background-color: whitesmoke !important;
    padding:0.4rem;
  }

  .map_mobile{
    margin: 0 auto !important;
    height: 400px !important;
    width:400px !important;
    height:90vh !important;
    width:90vh !important;
  }

}

.input_field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }

  .submit-button {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: #82ac00;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .add_button{
    width:1.5rem !important;
    height:2.5rem !important;
    font-size:1.5rem !important;
    background-color:#82ac00 !important;
    margin:0.4rem !important;
  }

  i.icon_h3{
    font-size:1.15em !important;
  }

</style>
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    filter: null,
  },
  mutations: {
    setFilter(state, payload) {
      Vue.set(state, 'filter', payload);
    },
    reset(state) {
      Vue.set(state, 'filter', null);
    }

  },
  getters: {
    filter: state => state.filter
  },
  
  actions: {
    setFilter({commit}, payload) {
      commit('setFilter', payload);
    },
    reset({commit}) {
      commit('reset');
    }
  }
};
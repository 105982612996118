<template>
  <v-snackbar :value="visible" @input="setVisible" :timeout="2000" color="success">
    <div class="text-center">{{ $tc(message) }}</div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setVisible: "notificationSnackbar/setVisible",
    }),
  },

  computed: {
    ...mapGetters({
      visible: "notificationSnackbar/isVisible",
      message: "notificationSnackbar/getMessage",
    }),
  },
};
</script>
<template>
  <div>
    <v-row fill-height>
      <v-col cols="1">
        <menucontextuel :menu="selectMenu()"></menucontextuel>
      </v-col>

      <v-col cols="11">
        <TdbComponentMais v-if="showTdbMaisComponent" />
        <TdbComponentCap v-if="showTdbCapComponent" />
        <MesuresComponentCap v-if="showMesuresCapComponent" />
        <RecalageComponent v-if="showRecalageComponent" />
        <ParcelleComponent v-if="showDetailParcelleComponent" />
        <MycolisComponentCap v-if="showMycolisCapComponent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import TdbComponentMais from "@/components/stade/Tableaudebord_Mais.vue";
import TdbComponentCap from "@/components/stade/Tableaudebord_Cap.vue";
import MesuresComponentCap from "@/components/stade/Mesures_Cap.vue";
import MycolisComponentCap from "@/components/stade/Mycolis.vue";
import RecalageComponent from "@/components/stade/Recalage.vue";
import ParcelleComponent from '@/components/stade/Parcelle_Stade.vue';
import Menucontextuel from '@/components/Menucontextuel.vue';


export default {
  name: "Stade",
  components: {
    TdbComponentMais,
    TdbComponentCap,
    MesuresComponentCap,
    MycolisComponentCap,
    RecalageComponent,
    Register,
    ParcelleComponent,
    Menucontextuel
 
  },
  data() {
    return {
      menu : {
        mais:[],
        recalage:[],
        cap:[
          {
            id:1,
            chemin:"/stade/cap/mesures",
            nom:"Parcelles internes",
            icon: "mdi-chart-line"
          },
          {
            id:2,
            chemin:"/stade/cap/tableaudebord",
            nom:"Parcelles clients",
            icon: "mdi-format-list-text"
          },
          {
            id:3,
            chemin:"/stade/cap/mycolis",
            nom:"Mycolis",
            icon: "mdi-magnify-scan"
          }
        ]
      },
      selectedMenu : []
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showTdbMaisComponent() {
      return this.currentRouteName == "stade-tdb-mais";
    },
    showTdbCapComponent() {
      return this.currentRouteName == "stade-tdb-cap";
    },
    showMesuresCapComponent() {
      return this.currentRouteName == "stade-cap-mesures";
    },
    showMycolisCapComponent() {
      return this.currentRouteName == "mycolis";
    },
    showRecalageComponent() {
      return this.currentRouteName == "recalage";
    },
    showDetailParcelleComponent() {
      return this.currentRouteName == "detail-plot";
    }
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    },
    selectMenu(){
      if(this.showTdbMaisComponent){
        return this.menu['mais']
      }
      else if(this.showTdbCapComponent || this.showMesuresCapComponent || this.showMycolisCapComponent || this.showDetailParcelleComponent){
        return this.menu['cap']
      }
      else if(this.showRecalageComponent){
        return this.menu['recalage']
      }
    },
  }
};
</script>

<style>
</style>
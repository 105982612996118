<template>
  <v-container>
    <v-card style="padding:10px;" id="detail-conseilleragris">

      <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="searchWord"
            :loading="!dataloaded"
            loading-text="Chargement des données...."
            :headers="headers"
            :items="info_agris"
            item-key="id_agri_cons"
            :options.sync="optionsTab"
            :page="6"
            group-by="service"
            class="my-awesome-table"          
            dense
          >
            <template v-slot:[`item.validation`]="{ item }">
              <div v-if="item.validation_conseiller==null">
                <v-icon small class="mr-2">
                  mdi-clock-time-four-outline
                </v-icon>
              </div>
              <div v-else-if="item.validation_conseiller==true">
                <v-icon small class="mr-2" color="green">
                  mdi-check
                </v-icon> {{ item.date_validation }}
              </div>
              <div v-else>
                <v-icon small class="mr-2" color="red">
                  mdi-close
                </v-icon> {{ item.date_validation }}
              </div>
            </template>

          </v-data-table>
        </v-card>
    </v-card>
  </v-container>
</template>

<script>
import administrationConseillersService from "@/service/administrationConseillers";


export default {
  components: {
    administrationConseillersService,
  },
  props:{
    id_conseiller:Number,
  },
  data() {
    return {
      info_agris: [],
      dataloaded: false,
      filter: null,
      searchWord: "",
      optionsTab: {itemsPerPage: 15,},
      headers: [
        { text: "Nom", value: "nom_agri"},
        { text: "Prénom", value: "prenom_agri"},
        { text: "Service", value: "service" },
        { text: "Date début conseiller", value: "date_debut"},
        { text: "Date fin conseiller", value: "date_fin"},
        { text: "Validation", value: "validation"},
      ],
    }
  },
  watch: {
    id_conseiller: {
      immediate: true,
      handler() {
        if(this.id_conseiller!=null){
          this.getConseillerAgris();
        }
      },
    }
  },

  methods: {
    async getConseillerAgris() {
      this.info_agris = await administrationConseillersService.getInfosConseillerAgrisById(this.id_conseiller);
      this.dataloaded = true
      this.$vuetify.goTo('#detail-conseilleragris')
    },
  },
}
</script>

<style>

</style>
import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {

    async getInfosAgriculteurById(code_agri,operateur,client) {
        const local_dossier = await
          fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/${code_agri}/operateurs/${operateur}/clients/${client}`, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/json',
              ///////Authorization: `Bearer ${store.getters.tokenValue}`,
              //Accept: 'application/json',
            },
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((json) => {
            return json.data;
          })
            .catch((error) => {
              store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
            },
            );
        return local_dossier;
      },
    
      async postAgriculteur(code_agri,operateur,client,infos_agri) {
        infos_agri.cgu=process.env.VUE_APP_VERSION_CGU
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/${code_agri}/operateurs/${operateur}/clients/${client}`, {
          method: 'POST',
          body: JSON.stringify(infos_agri),
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${store.getters.tokenValue}`,
            Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
      },
  
    
      async getInfosConseillersAgri(code_agri,operateur,client) {
        var campagne = new Date().getFullYear();
        const local_dossier = await
          fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/${code_agri}/operateurs/${operateur}/clients/${client}/conseillers?campagne=${campagne}`, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/json',
              ///////Authorization: `Bearer ${store.getters.tokenValue}`,
              //Accept: 'application/json',
            },
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((json) => {
            return json.data;
          })
            .catch((error) => {
              store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
            },
            );
        return local_dossier;
      },
    
      async getListConseillersService(service) {
        const local_dossier = await
          fetch(`${process.env.VUE_APP_BASE_API}conseillers/services/${service}`, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/json',
              ///////Authorization: `Bearer ${store.getters.tokenValue}`,
              //Accept: 'application/json',
            },
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((json) => {
            return json.data;
          })
            .catch((error) => {
              store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
            },
            );
        return local_dossier;
      },
    
      async postAgriculteurConseiller(code_agri, operateur, client, infos_conseiller) {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/${code_agri}/operateurs/${operateur}/clients/${client}/conseillers`, {
          method: 'POST',
          body: JSON.stringify(infos_conseiller),
          headers: {
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${store.getters.tokenValue}`,
            Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
      },
    
    
      async deleteLienConseiller(ad_agri_cons) {
        // get a fully desribed parcelle from API
        // URL should be a composed string from Env constant and ressource name
    
        const local_dossier = await
          fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/conseillers/${ad_agri_cons}`, {
            method: 'DELETE',
            headers: {
              //'Content-Type': 'application/json',
              ///////Authorization: `Bearer ${store.getters.tokenValue}`,
              //Accept: 'application/json',
            },
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((json) => {
            return json.data;
          })
            .catch((error) => {
              store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
            },
            );
        return local_dossier;
      },

      async getParcellesConsentement(code_agri,operateur,client,service) {
        const local_dossier = await
          fetch(`${process.env.VUE_APP_BASE_API}agriculteurs/${code_agri}/operateurs/${operateur}/clients/${client}/services/${service}`, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/json',
              //Authorization: `Bearer ${store.getters.tokenValue}`,
              //Accept: 'application/json',
            },
          }).then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((json) => {
            return json.data;
          })
            .catch((error) => {
              store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
            },
            );
        return local_dossier;
      },

      async putValidationConseiller(id_valid_cons_agri) {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}conseillers/validation/${id_valid_cons_agri}`, {
          method: 'PUT',
          //body: JSON.stringify({}),
          headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
      },

      async deleteValidationConseiller(id_valid_cons_agri) {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}conseillers/validation/${id_valid_cons_agri}`, {
          method: 'DELETE',
          //body: JSON.stringify({}),
          headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
      },

};

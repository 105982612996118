<template>
    <div>
      <v-container style="margin-top:20px;">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :search="searchWord"
              :loading="!dataloaded"
              loading-text="Chargement des données...."
              :headers="headers"
              :items="agris"
              item-key="id"
              :options.sync="optionsTab"
              :page="6"
              class="my-awesome-table elevation-1"
              dense
              fixed-header
              height="550"
              mobile-breakpoint="0"
              
            >

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon v-if="item.validation_conseiller!=true" small class="mr-2" @click="addValidationConseiller(item.id_valid_cons_agri)">
                mdi-account-check
              </v-icon>
              <v-icon small class="mr-2" @click="deleteValidationConseiller(item.id_valid_cons_agri)">
                mdi-account-remove-outline
              </v-icon>
            </template>
            <template v-slot:[`item.exploitant`]="{ item }">{{ item.nom_agri }} {{ item.prenom_agri }} -  {{ item.raison_sociale }} [{{ item.code_agri_operateur }}]</template>
          </v-data-table>
        </v-card>
      </v-container>
    </div>
  </template>
  
  <script>
import consentementService from "@/service/consentement";
import administrationConseillersService from "@/service/administrationConseillers";
  
  export default {
    name: "Gestion_Agris",
    components: {
      consentementService,
      administrationConseillersService
    },
    data() {
      return {
        dataloaded: false,
        agris: [],
        headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
        { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
        { text: 'Raison sociale agri', value: 'raison_sociale', align: ' d-none' },
        { text: 'Code agri', value: 'code_agri_operateur', align: ' d-none' },
        //colonnes visibles
        { text: "Agriculteur", value: "exploitant", width: '120px', filterable: true},
        { text: "Validation", value: "validation_conseiller", width: '50px'},
        { text: "Date validation", value: "date_validation", width: '50px'},
        { text: "", value: "actions", sortable: false, width: '50px'},
      ],
      searchWord: "",
      optionsTab: {sortBy: [{ key: 'validation_conseiller', order: 'asc' }],},
      };
    },
  
    watch: {
      
  
    },
    computed: {
     
    },
  
    async mounted() {
      this.agris = await administrationConseillersService.getInfosConseillerAgrisById(this.$store.getters["user/user"].externeInfos.id_conseiller);
      this.dataloaded = true
    },
  
    methods: {
      async addValidationConseiller(id_valid_cons_agri){
        var isDoneValidation = null
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

        isDoneValidation = await consentementService.putValidationConseiller(id_valid_cons_agri);
         
        if(isDoneValidation != true){
            alert('Un problème est survenu.')
        } else {
          this.$router.go()
        }
        
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      },

      async deleteValidationConseiller(id_valid_cons_agri){
        var isDoneValidation = null
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

        isDoneValidation = await consentementService.deleteValidationConseiller(id_valid_cons_agri);
         
        if(isDoneValidation != true){
            alert('Un problème est survenu.')
        } else {
          this.$router.go()
        }
        
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      },
  
  
    }
  };
  
  </script>
<template>
  <v-container>
    <div class="mb-15">
        <v-row>
          <v-col>
            <v-switch
                v-model="demandesUtilisateur"
                label="Mes demandes"
                class="pa-3"
            ></v-switch>
          </v-col>
          <v-col>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :search="searchWord"
          :loading="!dataloaded"
          loading-text="Chargement des données...."
          :headers="headers"
          :items="liste_demandes_recalage"
          item-key="id"
          class="my-awesome-table elevation-1"
          dense
          fixed-header
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="duplicateRecalage(item)">
              mdi-content-duplicate
            </v-icon>
            <v-icon small class="mr-2" @click="modifyRecalage(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      <v-btn small color="info" class="mt-2 mb-50 white--text">
          <download-excel :data="liste_demandes_recalage_tous">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
      </v-btn>
      <v-btn small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="infosRecalage=null,sendDataFormRecalage()" :disabled='disabledRecalage'>
        <v-icon small class="mr-2">
          mdi-plus
        </v-icon>
        Nouvelle demande
      </v-btn>
    </div>
    
    <recalageFormComponent v-if="demandeRecalage" v-on:reloadRecalage="loadRecalage" :infosRecalage="infosRecalage" :key="componentKey"/>
  
  </v-container>
</template>

<script>
import recalageFormComponent from "@/components/stade/Recalage_Maladie_Form.vue";
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Maladie",
  components: {
    recalageFormComponent,
    downloadExcel,
    recalageService,
  },
  data() {
    return {
      login: this.$store.state.user.user.login.toLowerCase(),
      componentKey: 0,
      droits_recalage: null,
      disabledRecalage: true,
      demandeRecalage: false,
      demandesUtilisateur: true,
      liste_demandes_recalage: [],
      liste_demandes_recalage_tous: [],
      liste_demandes_recalage_utilisateur: [],
      dataloaded: false,
      searchWord: "",
      headers: [
        { text: "", value: "actions", sortable: false, width: '80px' },
        { text: 'Culture', value: 'culture'},
        { text: 'Maladie', value: 'maladie'},
        { text: 'Départements', value: 'departements'},
        { text: 'Debut semis', value: 'datedebut', width:'100px'},
        { text: 'Fin semis', value: 'datefin', width:'100px'},
        { text: 'Stations', value: 'stations'},
        { text: 'Sols P.', value: 'typesolspanoramix'},
        { text: 'Sols Arvalis', value: 'typesolsarvalis'},
        { text: 'PRA', value: 'nom_pra'},
        { text: 'Demandeur', value: 'demandeur'},
        { text: 'Note sensibilité', value: 'notesensibilite'},
        { text: 'Variétés', value: 'varietes'},
        { text: 'Décalage', value: 'jourdecalage'},
        { text: 'Type demande', value: 'typerecalage'},
        { text: 'Commentaire', value: 'commentaire', width:'250px'},
        { text: 'Date demande', value: 'date_demande'}
        ],
      infosRecalage: null
    };
  },
  async mounted() {
    this.loadRecalage()

    if((this.$store.getters['recalage/DroitsRecalage']())==null){
      this.droits_recalage = await recalageService.getDroitsRecalage();
      this.$store.commit('recalage/defineDroitsRecalage', {droits:this.droits_recalage})
    } else {
      this.droits_recalage = this.$store.getters['recalage/DroitsRecalage']()
    }

    if(this.droits_recalage.length!=0){
      this.disabledRecalage=false
    }

  },

  watch: {
    demandesUtilisateur: function(val) {
      if(val==true){
        this.liste_demandes_recalage = this.liste_demandes_recalage_utilisateur
      } else {
        this.liste_demandes_recalage = this.liste_demandes_recalage_tous
      }
    },
  },

  computed: {
    
  },


  methods: {
    async loadRecalage(){
      this.liste_demandes_recalage_tous = await recalageService.getDemandesRecalageMaladies();
      this.dataloaded=true

      this.liste_demandes_recalage_utilisateur = this.liste_demandes_recalage_tous.filter(demande => demande.codeagent_demandeur == this.login.replace('arvalis-fr\\',''));
      this.liste_demandes_recalage = this.liste_demandes_recalage_utilisateur

      this.demandeRecalage = false
    },
    duplicateRecalage(item){
      item.idRef=null
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    modifyRecalage(item){
      item.idRef=item.id
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    sendDataFormRecalage(item){
      this.demandeRecalage=false
      this.componentKey += 1;
      this.demandeRecalage=true
    }
  }
};
</script>

<style>
</style>

<template>
  <loading :active="isLoading"
          :is-full-page="fullPage"/>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: "WaitingOverlay",
  components: {
    Loading
  },
  data() {
    return {};
  },

  methods: {
  },

  computed: {
    isLoading() {
      return this.$store.getters['general/waitingOverlayLoading'];
    },

    fullPage() {
      return this.$store.getters['general/waitingOverlayFullPage'];
    },
  },
};
</script>

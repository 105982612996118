<template>
  <v-dialog
    v-model="infoState"
    persistent
    max-width="500"
    :transition="false"
    scrollable
    @click:outside="unsetInfo"
  >
    <v-card>
      <v-card-title class="v-card-title-info"
        ><h2 class="text-center">
          {{ infoMessages.titre }}
        </h2></v-card-title
      >

      <v-card-text class="pa-5">
      
        <v-list>
          <v-list-item
            dense
          >
            <div class="text-center font-weight-bold" style="white-space: pre-line; width:100%" v-html="infoMessages.description">
            </div>

          </v-list-item>
        </v-list>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "InfoModal",
  data() {
    return {};
  },

  methods: {
    unsetInfo() {
      this.$store.dispatch("informations/unsetInfo");
    },
  },

  computed: {
    infoMessages() {
      return this.$store.state.informations.message;
    },

   infoState() {
      return this.$store.state.informations.visible;
    },
  },
};
</script>
<template>
  <div>
    <v-row fill-height>
      <v-col>
        {{id}}
        <CompteComponent v-if="showCompteComponent" />
        <ConseillersComponent v-if="showConseillersComponent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompteComponent from "@/components/consentement/Compte.vue";
import ConseillersComponent from "@/components/consentement/Conseillers.vue";


export default {
  name: "Consentement",
  components: {
    CompteComponent,
    ConseillersComponent,
  },
  data() {
    return {
        id: this.$route.query.id
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showCompteComponent() {
      return this.currentRouteName == "consentement-compte";
    },
    showConseillersComponent() {
      return this.currentRouteName == "consentement-gestion";
    }
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    service:null,
    code_agri_operateur:null,
    code_operateur:null,
    code_client:null
  },

  actions: {
    defineAgri({ commit }, payload) {
        commit("defineAgri", payload);
    },
    reset: ({commit}) => {
      commit('reset');
    }
  },
  mutations: {
    defineAgri (state, payload) {
      state.service = payload.service.trim()
      state.code_agri_operateur = payload.code_agri_operateur.trim()
      state.code_operateur = payload.code_operateur.trim()
      state.code_client = payload.code_client.trim()
    },
    reset: (state) => {
      state.service = null,
      state.code_agri_operateur = null,
      state.code_operateur = null,
      state.code_client = null
    }
  },
  getters: {
    InfosAgri(state) {
        return {service:state.service,code_agri_operateur:state.code_agri_operateur,code_operateur:state.code_operateur,code_client:state.code_client};
    }
  }
};
import Vue from 'vue'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GraphChart, BarChart, LineChart, ScatterChart, HeatmapChart, GaugeChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  GraphicComponent,
  MarkLineComponent,
  VisualMapComponent,
  CalendarComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  GraphChart,
  BarChart,
  LineChart,
  ScatterChart,
  HeatmapChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  GraphicComponent,
  MarkLineComponent,
  VisualMapComponent,
  CalendarComponent
]);
Vue.component('chart', VChart)
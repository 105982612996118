<template>
  <div>
    <v-container style="margin-top: 10px">
      <h3>Liste des parcelles Maïs Grain</h3>
      <div style="margin-bottom:20px;">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord_mais_grain"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

        <!--https://codepen.io/wolfpup/pen/gOadmPx-->
          <v-data-table
            v-model="selected_mais_grain"
            :headers="headers_mais_grain"
            :items="filteredParcellesMaisGrain"
            item-key="code_parcelle"
            :search="searchWord_mais_grain"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showCommentaire(item)">
                mdi-comment-text-outline
              </v-icon>
            </template>

            <template v-slot:[`item.etat_calcul`]="{ item }">
              <v-chip
                :color="getColorStatutCalcul(item.etat_calcul)"
                dark
              >
                {{ item.etat_calcul }}
              </v-chip>
            </template>
            
            <template v-slot:header="{ }">
              <tr class="grey lighten-3">
                <th v-for="header in headers_mais_grain" :key="header.text">
                  <div v-if="filters_mais_grain.hasOwnProperty(header.value)">
                    <v-autocomplete
                      flat
                      hide-details
                      multiple
                      attach
                      chips
                      small-chips
                      deletable-chips
                      dense
                      clearable
                      :items="columnValueListMaisGrain(header.value)"
                      v-model="filters_mais_grain[header.value]"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 5">
                          <span> 
                            {{ item }} 
                          </span>
                        </v-chip>
                        <span v-if="index === 5" class="grey--text caption" > 
                          (+{{ filters_mais_grain[header.value].length - 5 }} others) 
                          </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </th>
              </tr>
            </template>

          </v-data-table>

        </v-card>
        <v-btn small color="info" class="mt-2 white--text">
          <download-excel :data="parcelles_stades_mais_grain">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
        </v-btn>
      </div>

    </v-container>

    <v-container style="margin-top: 10px">
      <h3>Liste des parcelles Maïs Fourrage</h3>
      <div style="margin-bottom:20px;">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord_mais_fourrage"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

        <!--https://codepen.io/wolfpup/pen/gOadmPx-->
          <v-data-table
            v-model="selected_mais_fourrage"
            :headers="headers_mais_fourrage"
            :items="filteredParcellesMaisFourrage"
            item-key="code_parcelle"
            :search="searchWord_mais_fourrage"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showCommentaire(item)">
                mdi-comment-text-outline
              </v-icon>
            </template>

            <template v-slot:[`item.etat_calcul`]="{ item }">
              <v-chip
                :color="getColorStatutCalcul(item.etat_calcul)"
                dark
              >
                {{ item.etat_calcul }}
              </v-chip>
            </template>
            
            <template v-slot:header="{ }">
              <tr class="grey lighten-3">
                <th v-for="header in headers_mais_fourrage" :key="header.text">
                  <div v-if="filters_mais_fourrage.hasOwnProperty(header.value)">
                    <v-autocomplete
                      flat
                      hide-details
                      multiple
                      attach
                      chips
                      small-chips
                      deletable-chips
                      dense
                      clearable
                      :items="columnValueListMaisFourrage(header.value)"
                      v-model="filters_mais_fourrage[header.value]"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 5">
                          <span> 
                            {{ item }}
                          </span>
                        </v-chip>
                        <span v-if="index === 5" class="grey--text caption" > 
                          (+{{ filters_mais_fourrage[header.value].length - 5 }} others) 
                          </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </th>
              </tr>
            </template>

          </v-data-table>

        </v-card>
        <v-btn small color="info" class="mt-2 white--text">
          <download-excel :data="parcelles_stades_mais_fourrage">
            Export excel
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
        </v-btn>
      </div>

    </v-container>

    <CommentaireComponent :dialog_init="dialog" :id_plot="selected_plot" :oad="oad" v-on:closeCommentaire="closeCommentaire"/>
  </div>
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesStadeService from "@/service/parcellesStade";
//import VChart from "vue-echarts";

export default {
  name: "Suivi_Stade_Mais",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesStadeService,
    //VChart
  },
  data() {
    return {
      //module commentaire
      oad: 'STADE',
      selected_plot: null,
      dialog: false,
      //fin module commentaire
      //tableau parcelles
      dataloaded_mais_grain: false,
      searchWord_mais_grain: "",
      dataloaded_mais_fourrage: false,
      searchWord_mais_fourrage: "",
      //fin tableau parcelles
      //parcelles_stades_mais: [],
      parcelles_stades_mais_grain: [],
      parcelles_stades_mais_fourrage: [],
      optionsTab: {itemsPerPage: 10,},
      headers_mais_grain: [
        { text: "", value: "actions", sortable: false, width: '80px' },
        { text: "Code client", value: "nom_user"},
        { text: "Code agriculteur", value: "nom_user"},
        { text: "Code parcelle", value: "code_parcelle_os", width: '100px' },
        { text: "Département", value: "code_dept" },
        { text: "Variété", value: "variete" },
        { text: "Date semis", value: "date_semis", width: '100px' },
        { text: "Levée", value: "stade_levee", width: '100px' },
        { text: "4 feuilles", value: "stade_4f", width: '100px' },
        { text: "6 feuilles", value: "stade_6f" },
        { text: "8 feuilles", value: "stade_8f", width: '100px' },
        { text: "10 feuilles", value: "stade_10f", width: '100px' },
        { text: "Floraison femelle", value: "stade_ff", width: '100px' },
        { text: "SLAG", value: "stade_slag", width: '100px' },
        { text: "H50", value: "stade_h50", width: '100px' },
        { text: "H45", value: "stade_h45", width: '100px' },
        { text: "H35", value: "stade_h35", width: '100px' },
        { text: "H32", value: "stade_h32", width: '100px' },
        { text: "Nb stades modifiées", value: "nb_stades_obs", width: '100px' },
        { text: "Etat calcul", value: "etat_calcul" }
      ],
      headers_mais_fourrage: [
        { text: "", value: "actions", sortable: false, width: '80px' },
        { text: "Code client", value: "nom_user"},
        { text: "Code agriculteur", value: "nom_user"},
        { text: "Code parcelle", value: "code_parcelle_os", width: '100px' },
        { text: "Département", value: "code_dept" },
        { text: "Variété", value: "variete" },
        { text: "Date semis", value: "date_semis", width: '100px' },
        { text: "Levée", value: "stade_levee", width: '100px' },
        { text: "4 feuilles", value: "stade_4f", width: '100px' },
        { text: "6 feuilles", value: "stade_6f" },
        { text: "8 feuilles", value: "stade_8f", width: '100px' },
        { text: "10 feuilles", value: "stade_10f", width: '100px' },
        { text: "Floraison femelle", value: "stade_ff", width: '100px' },
        { text: "SLAG", value: "stade_slag", width: '100px' },
        { text: "H32", value: "stade_h32", width: '100px' },
        { text: "Nb stades modifiées", value: "nb_stades_obs", width: '100px' },
        { text: "Etat calcul", value: "etat_calcul" }
      ],
      //multi-selection tableau
      selected_mais_grain: [],
      filters_mais_grain: {
        code_client: [],
        variete: [],
        departement: [],
			},
      selected_mais_fourrage: [],
      filters_mais_fourrage: {
        code_client: [],
        variete: [],
        departement: [],
			},
      //fin multi-selection tableau
    };
  },
  async mounted() {
    this.parcelles_stades_mais_grain = await parcellesStadeService.getInfosParcellesMaisGrain();
    this.parcelles_stades_mais_fourrage = await parcellesStadeService.getInfosParcellesMaisFourrage();
    this.dataloaded_mais_grain = true;
    this.dataloaded_mais_fourrage = true;
  },

  computed: {
    filteredParcellesMaisGrain() {
      return this.parcelles_stades_mais_grain.filter((d) => {
        return Object.keys(this.filters_mais_grain).every((f) => {
          return this.filters_mais_grain[f].length < 1 || this.filters_mais_grain[f].includes(d[f]);
        });
      });
    },
    filteredParcellesMaisFourrage() {
      return this.parcelles_stades_mais_fourrage.filter((d) => {
        return Object.keys(this.filters_mais_fourrage).every((f) => {
          return this.filters_mais_fourrage[f].length < 1 || this.filters_mais_fourrage[f].includes(d[f]);
        });
      });
    },
  },

  methods: {
    showCommentaire(item) {
      this.selected_plot=item.id_plot
      this.dialog=true
    },
    closeCommentaire() {
      this.dialog=false
      this.selected_plot=null
    },
    columnValueListMaisGrain(val) {
      return this.parcelles_stades_mais_grain.map((d) => d[val]);
    },
    columnValueListMaisFourrage(val) {
      return this.parcelles_stades_mais_fourrage.map((d) => d[val]);
    },
    getColorStatutCalcul (statut) {
        if (statut == 'ok') return 'green'
        else if (statut == 'ko') return 'red'
        else return 'orange'
    },
  }
};
</script>

<style>
</style>

<template>
  <div class="login-page">
    <v-alert
      :value="true"
      type="error"
      class="alert"
      :class="logFailed ? '' : 'hidden'"
      transition="scale-transition"
      >{{ $tc("login.bad-credentials") }}</v-alert
    >
    <v-sheet class="border-charte" elevation="2">
      <v-form
        v-if="hasError === false"
        class="form"
        id="login"
        v-model="valid"
        ref="form"
        @submit.prevent="login"
      >
        <v-container fluid>
          <div class="bg-charte">
            <div style="color:#fff;font-size:2.5em;text-align:center">VISIOFARM<sup>®</sup></div>
          </div>
          <v-text-field
            type="text"
            v-model="username"
            :rules="nameRules"
            :label="$tc('login.username')"
            required
            autocomplete="username"
            name="username"
          ></v-text-field>
          <v-text-field
            type="password"
            v-model="password"
            :label="$tc('login.password')"
            :rules="passwordRules"
            required
            autocomplete="password"
            name="password"
          ></v-text-field>
          <v-btn
            block
            color="success"
            :disabled="!valid"
            type="submit"
            :loading="loginLoading"
            >{{ $tc("login.connect") }}</v-btn
          >
          <!--<div class="row">
            <div class="col-12">
              <v-item-group>
                <v-item
                  class="message"
                  v-for="(link, index) in general"
                  :key="index"
                >
                  <router-link :to="{ name: link.destination }">
                    {{ $tc(link.label) }}
                  </router-link>
                </v-item>
              </v-item-group>
            </div>
          </div>-->
          <v-row dense class="mt-5">
            <v-col class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <span v-if="$root.$i18n.locale === 'dev'">DEV</span>
                    <img
                      v-else
                      class="img-lang mx-auto"
                      :src="`${baseRoute}img/icons/${$root.$i18n.locale}-32.png`"
                      width="20"
                      height="18"
                    />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    dense
                    v-for="(lang, i) in langs"
                    :key="`Lang${i}`"
                    @click="changeLocale(lang)"
                  >
                    <span class="mx-auto" v-if="lang === 'dev'">DEV</span>
                    <img
                      v-else
                      class="img-lang mx-auto"
                      :src="`${baseRoute}img/icons/${lang}-32.png`"
                      width="20"
                      height="18"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <a @click="mdpOublie=true">Mot de passe oublié?</a>


              <v-dialog
                v-model="mdpOublie"
                max-width="500"
                :transition="false"
                @click:outside="mdpOublie=false"
              >
                <v-card>
                  <v-card-title class="v-card-title-info"
                    ><h2 class="text-center">
                      Mot de passe oublié
                    </h2></v-card-title
                  >

                  <v-card-text class="pa-5" style="overflow:hidden">
                    <validation-observer
                      ref="observer"
                      v-slot="{ invalid }"
                    >
                      <form @submit.prevent="submit">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="email"
                        >
                          <v-text-field
                            v-model="mailMdpOulie"
                            :error-messages="errors"
                            label="Email"
                            outlined
                          ></v-text-field>
                        </validation-provider>
                        <v-btn
                          class="mr-4 float-right"
                          type="submit"
                          small
                          :disabled="invalid"
                          @click="resetMdp"
                        >
                          Envoyer un nouveau mot de passe
                        </v-btn>
                      </form>
                    </validation-observer>

                  </v-card-text>

                </v-card>
              </v-dialog>


            </v-col>
          </v-row>

        </v-container>
      </v-form>
    </v-sheet>
    <v-card class="login" v-if="hasError === true">
      <v-alert type="error" class="justify-center">{{
        $tc("errors.error-encountered")
      }}</v-alert>
      <v-card-text class="justify-center">
        {{ $tc("errors.server-error") }} :
        <br />
        {{ $tc(errorMessage) }}
        <br />
        {{ $tc("errors.try-later") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="hasError = false">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import authService from "@/service/auth";
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} ne peut pas être vide',
})

extend('email', {
  ...email,
  message: 'Email doit être valide',
})

export default {
  name: "login",
  components: {
    authService,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      valid: true,
      logFailed: false,
      hasError: false,
      errorMessage: "",
      baseRoute: process.env.VUE_APP_BASE_ROUTE,
      username: "",
      password: "",
      loginLoading: false,
      nameRules: [(v) => !!v || "Le nom d'utilisateur est requis"],
      passwordRules: [(v) => !!v || "Mot de passe requis"],
      general: [
        { label: "login.forgotpassword", destination: "passwordForgotten" },
      ],
      langs: ["fr"], //["fr", "en", "dev"],
      mdpOublie: false,
      mailMdpOulie: ''

    };
  },

  methods: {
    /* si l'utilisateur s'identifie correctement et que l'api
       répond correctement, il est redirigé vers la home,
       sinon un message s'affiche
    */
    login() {
      const login = this.username;
      const password = this.password;
      this.logFailed = false;
      this.hasError = false;
      this.loginLoading = true;
      this.$store
        .dispatch("login", { login, password })
        .catch((error) => {
          if (error.message === "401") {
            this.logFailed = true;
          } else {
            this.hasError = true;
            this.errorMessage = error.message;
          }
          this.loginLoading = false;
        })
        .then(() => {
          if (!this.logFailed && !this.hasError) {
            this.$store.dispatch("user/fetchUser");
            this.$router.push("/home").catch((err) => { console.log(err);});
          }
          this.loginLoading = false;
        });
    },
    changeLocale: function (lang) {
      this.$root.$i18n.locale = lang;
    },
    submit () {
      this.$refs.observer.validate()
    },
    async resetMdp() {
      this.mdpOublie = false;
      if(this.mailMdpOulie.indexOf('arvalis')>0){  
          this.$store.dispatch('informations/setInfo', { message: {titre:"Arvalis",description:"Pour le personnel Arvalis il suffit de saisir son code agent et son mot de passe habituel"} });
      } else {
        var result = await authService.resetMdp(this.mailMdpOulie);
        if(result['envoi']==1){
          this.$store.dispatch('informations/setInfo', { message: {titre:"Réinitialisation du mot de passe",description:"Un mail avec un nouveau mot de passe vous a été envoyé."} });
        } else {
          this.$store.dispatch('errors/setError', { message: {code:"",description:"Un problème est survenu. Veuillez recommencer. Si cela se reproduit merci de nous contacter."} });
        }
      }
    },

  },
};
</script>



import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';
import '@mdi/font/css/materialdesignicons.min.css';
import variables from "@/assets/style.scss";

require('@/assets/main.css');

Vue.use(Vuetify);

export default new Vuetify({

  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: variables.primary,
        secondary: variables.secondary,
        thirty: variables.thirty,
        accent: variables.accent,
        error: variables.error,
        info: variables.info,
        success: variables.success,
        warning: variables.warning,

        exploitation: variables.exploitation_color,
        parcelle: variables.parcelle_color,
        prix: variables.prix_color,
        protocole: variables.protocole_color,

        exploitation_light: variables.exploitation_color_light,
        parcelle_light: variables.parcelle_color_light,
        prix_light: variables.prix_color_light,
        protocole_light: variables.protocole_color_light,
      },
    },
  },
  /*lang: {
    locales: { fr, en },
    current: 'fr',
  },*/
  lang: {
    locales: { fr, en },
    current: 'fr',
  },
});

<template>
  <v-container style="margin-top:20px;">
    <!--FORM-->
    <div v-if="!isMobile">
      <v-row style="margin: 0 auto;" class="center mb-5; mx-auto">
        <v-col cols="2" offset="2" class="pt-0 pb-0">
          <v-select v-model="selectedIndicatorStation" 
          :items="listSelectIndicateur"
          :item-text="getTextSelectIndicator"
          item-value="value"
            menu-props="auto" 
            label="Etat station"
            hide-details 
            prepend-icon="mdi-wifi-marker" 
            single-line 
            chips
            multiple>
          </v-select>
        </v-col>
        <v-col cols="2" class="mx-auto pt-0 pb-0">
          <v-select v-model="selectedConstruct" 
          :items="listSelectConstructor" 
          item-value="value"
          item-text="value" 
          menu-props="auto" 
          label="Opérateur Météo" 
          hide-details 
          prepend-icon="mdi-tools" 
          single-line 
          chips 
          multiple></v-select>
        </v-col>
        <v-col cols="1" offset="1">
          <v-btn style="padding:0.5rem" @click="resetFiltres()" color="info" class="h-70">
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-rotate-left
                </v-icon>
              </template>
              <span>Effacer les filtres</span>
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
      <br><hr>
    </div>
    <!-- FIN FORM -->
    <!-- Bouton FILTRER pour mobile -->
    <h3 v-if="isMobile" style="margin-top: 20px; cursor: pointer;" color="info" @click="toggleModalFiltre">
      Filtrer <v-icon>mdi-filter-outline</v-icon>
    </h3>
        <!-- Modal pour les FILTRES en MOBILE -->
        <v-dialog v-model="showModalFiltreMobile" max-height="40vh">
          <div id="filtres_mobile" style="height:90%">
            <v-row style="margin: 0 auto;" class="center mb-5; mx-auto">
              <v-col cols="4">
                <v-select v-model="selectedIndicatorStation" :items="listSelectIndicateur"
                  :item-text="getTextSelectIndicator" item-value="value" menu-props="auto" label="Etat station"
                  hide-details prepend-icon="mdi-wifi-marker" single-line chips multiple>
                </v-select>
              </v-col>
              <v-col cols="4" offset="1">
                <v-select v-model="selectedConstruct" :items="listSelectConstructor" item-value="value"
                  item-text="value" menu-props="auto" label="Constructeur" hide-details prepend-icon="mdi-tools"
                  single-line chips multiple></v-select>
              </v-col>
              <v-col cols="1" >
                <v-btn style="padding:0.5rem" @click="resetFiltres()" color="info" class="h-70">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-rotate-left
                      </v-icon>
                    </template>
                    <span>Effacer les filtres</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
          </div>
        </v-dialog> <!-- FIN de la MODAL des FILTRES sur MOBILE-->

    <!-- INDICATEURS CLEES-->
    <div v-if="!isMobile" >
      <v-row style="font-size:1em" class="mt-3 mx-auto">

<v-col :cols="4">        <v-card class="info-card d-flex align-center justify-center">
        Nb de stations : <span style="font-weight: bold;">{{ nbStations }}</span>
      </v-card>
      </v-col>
      <v-col :cols="4">

      <v-card class="info-card d-flex align-center justify-center">
        Etat de réception complet : <span style="font-weight: bold;">{{ getGoodStation() }}</span>
      </v-card>
    </v-col>
      <v-col :cols="4">

      <v-card class="info-card d-flex align-center justify-center">
        Absence : <span style="font-weight: bold;">{{ getLowStation() }}</span>
      </v-card>
    </v-col>
        <!-- <v-col class="mx-auto" cols="3">
          <v-card class="info-card">Nb de stations : <br><span class="info-text">{{nbStations}}</span></v-card>
        </v-col>
        <v-col class="mx-auto" cols="3">
          <v-card class="info-card">Nb de stations actualisées sous 24h :
            <v-tooltip bottom max-width="350">
               <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on" >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Nombre de stations ayant reçu des données météo durant les 24 dernières heures.</span> 
            </v-tooltip><br><span class="info-text">{{getNumberOfStationOk24h()}}</span></v-card>
        </v-col>
        <v-col class="mx-auto" cols="3">
          <v-card class="info-card">Nb de stations selon leur état :
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on" >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Etat des stations selon la réception des données météo : <br>•	Complet : l’historique de données météo est complet et a été actualisé dans les 24 dernières heures.<br>• Partiel : identification d’un ou plusieurs trous de moins de 6h de données météo, sans impact sur le fonctionnement des calculs.<br>•	Absence : identification d’un ou plusieurs trous de 6h ou plus de données météo, bloquant les calculs. Il est nécessaire de les combler pour les relancer à contactez l’opérateur météo </span>
            </v-tooltip><br>
            <span class="info-text" style="font-size: 0.8em;text-align:center" >{{getGoodStation()}}<span style="font-size: 0.8em;text-align:center">(Complet)</span> | {{getMediumStation()}}<span style="font-size: 0.8em;text-align:center">(Partiel)</span> | {{getLowStation()}}<span style="font-size: 0.8em;text-align:center">(Absence)</span></span></v-card>
        </v-col> -->
      </v-row>
    </div>
    

      <div id="indicateur" v-if="isMobile" >
      <v-row style="font-size:1em" class="mt-3 mx-auto">
        <v-col :cols="6" >    
          <v-card class="info-card">Nb de stations : <span style="font-weight: bolder;">{{nbStations}}</span></v-card>    

          <v-card class="info-card">Etat de reception complet :  <span style="font-weight: bolder;">{{getGoodStation()}}</span><br></v-card>    

          <v-card class="info-card">Absence : <span style="font-weight: bolder;">{{getLowStation()}} </span><br></v-card>    
        </v-col>
        <v-col :offset="1" :cols="5">
            <v-btn style=" margin:0.4rem; width:90%" v-if="isMobile" color="info" @click="showModalMapMobile = true ; getPositionCarte(); showCarte = !showCarte">  <!-- Bouton MAP pour mobile -->
            <v-icon>
                mdi-map-marker
              </v-icon>Carte
            </v-btn>

            <v-btn style=" margin:0.4rem; width:90%" @click="resetFiltres()" color="info">
                  <!-- Bouton MAP pour mobile -->
              <v-icon>
                mdi-rotate-left
              </v-icon><span>Effacer les filtres</span>
            </v-btn>

        </v-col>
      </v-row>
        <!-- Modal pour la carte -->
        <v-dialog id="modal_carte" v-model="showModalMapMobile" max-height="96vh">
      <v-card>
        <v-card-title>
          <span class="headline">Localisation des stations</span>
          <v-btn icon @click="showModalMapMobile = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height:90vh">
          <!-- carte  -->
          <div style="margin-top:20px;" v-if="showCarte">
            <v-row>
              <div style="height:95% ; width:95%;margin:0 auto">
                <v-row>
                  <v-col class="mx-auto">
                    <!--
            https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
            https://vue2-leaflet.netlify.app/examples/custom-icons.html
            -->
                    <l-map :center="carte.center" :zoom="carte.zoom" class="map_mobile" ref="map"
                      @update:zoom="zoomUpdated" @update:center="centerUpdated">
                      <l-tile-layer :url="carte.url">
                      </l-tile-layer>
                      <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                        <l-icon ref="icon" :icon-url="marker.imageUrl">
                        </l-icon>
                        <l-popup :content="marker.infobulle" />
                      </l-marker>
                    </l-map>
                  </v-col>
                  <v-col v-if="selected_stations.length>0" cols="3">
                    <v-col style="margin-top:2rem; margin-left:0rem" v-if="!vision_all_station">
                      <button v-on:click="vision_all_station =!vision_all_station"
                        style="padding:1rem; text-align:center; background-color:#7EC700;color:white;border-radius:12px; font-weight:bold; margin:0.5rem"
                        type="button" @click="getPositionCarte()">
                        <v-icon>mdi-filter-remove</v-icon> Afficher toutes les stations
                      </button>
                    </v-col>
                    <v-col style="margin-top:2rem; margin-left:0rem" v-else>
                      <button v-on:click="vision_all_station =!vision_all_station"
                        style="padding:1rem; text-align:center ; background-color:#7EC700;color:white;border-radius:12px; font-weight:bold; margin:0.5rem"
                        type="button" @click="getPositionCarte()">
                        <v-icon>mdi-target-variant</v-icon> Afficher uniquement les stations sélectionnées
                      </button>
                    </v-col>
                  </v-col>
                </v-row>
              </div>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog><!--FIN MODAL de la MAP en MOBILE-->
    </div>
    
    <!-- FIN INDICATEURS CLEES-->
    <br>
    <!--MAP-->
    <section v-if="!isMobile">
          <h3 style="margin-top:20px;" v-on:click="showCarte = !showCarte">
      Localisation des stations
      <v-icon small class="mr-2" v-if="!showCarte">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon>
    </h3>
    <div style="margin-top:20px;" v-if="showCarte">
      <v-row>
        <v-col class="mx-auto" cols="8">
          <!--
            https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
            https://vue2-leaflet.netlify.app/examples/custom-icons.html
            -->
          <l-map :center="carte.center" :zoom="carte.zoom" class="map" ref="map" @update:zoom="zoomUpdated"
            @update:center="centerUpdated">
            <l-tile-layer :url="carte.url">
            </l-tile-layer>
            <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
              <l-icon ref="icon" :icon-url="marker.imageUrl">
              </l-icon>
              <l-popup :content="marker.infobulle" />
            </l-marker>
          </l-map>
        </v-col>
        <v-col v-if="selected_stations.length>0" cols="3">
          <v-col  style="margin-top:2rem; margin-left:0rem" v-if="!vision_all_station">
            <button v-on:click="vision_all_station =!vision_all_station" style="padding:1rem; text-align:center; background-color:#7EC700;color:white;border-radius:12px; font-weight:bold; margin:0.5rem" type="button" @click="getPositionCarte()"><v-icon >mdi-filter-remove</v-icon>  Afficher toutes les stations </button>
          </v-col>
          <v-col style="margin-top:2rem; margin-left:0rem" v-else>
            <button v-on:click="vision_all_station =!vision_all_station" style="padding:1rem; text-align:center ; background-color:#7EC700;color:white;border-radius:12px; font-weight:bold; margin:0.5rem" type="button" @click="getPositionCarte()"><v-icon >mdi-target-variant</v-icon> Afficher uniquement les stations sélectionnées </button>
          </v-col>
        </v-col>
      </v-row>
    </div>
    </section>

    <!--FIN MAP-->
    <br>
    
    <!--TABLEUR ALL STATIONS-->
    <h3 style="margin-top:20px;">Liste des stations</h3>
    <div style="margin-bottom:20px;">
    
      <v-card>
        <v-card-title> 
          <div>
            <p style="font-style:italic;color:grey;"> &#8595; - Sélectionner les stations à afficher sur la carte des stations.</p>
          </div>
          <v-spacer></v-spacer>
          <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
            hide-details></v-text-field>
        </v-card-title>
        <v-data-table   
        :search="searchWord" 
        :loading="!dataloaded"      
        loading-text="Chargement des données...."
        :headers="isMobile ? headers_mobile : headers" 
          :items="stations_list" 
          item-key="id"
          show-select
          class="my-awesome-table elevation-1" v-model="selected_stations"
          dense
          mobile-breakpoint="0" 
          fixed-header>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showItem(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.device_name`]="{ item }">N°{{ item.id }} - {{ item.device_name }}</template>
          <template v-slot:[`item.constructor_name`]="{ item }">{{ item.constructor_name }}</template>
          <template v-slot:[`item.identification`]="{ item }">{{ item.identification }}</template>
          <template v-slot:[`item.indicateur`]="{ item }">
            <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" :color="getColorStation(item.indicateur)" v-bind="attrs" v-on="on">
                    {{getIconStation(item.indicateur)}}
                  </v-icon>
                </template>
                <span v-html="getInfoStation(item.indicateur)"></span>
              </v-tooltip>
          </template>
        </v-data-table> 
      </v-card>
      <v-btn small color="info" class="mt-2 white--text">
        <download-excel :data="stations_list" :name="getNomFichier('hour')">
          Export Excel
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>
      <v-btn small color="info" class="mt-2 ml-2 white--text">
        <download-excel :data="stations_authorisations" name="Autorisations des stations" >
          Autorisations stations
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>
    </div>
    <!--FIN TABLEUR ALL STATIONS-->

  </v-container>
</template>

<script>


import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import VChart from "vue-echarts";
import { getDate,checkIsMobile } from "@/helpers/functions";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "Tdb_Mildiou_Meteo",
  components: {
    downloadExcel,
    parcellesMildiouService,
    VChart,
    getDate,
    checkIsMobile,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      isMobile: false,
      showModalFiltreMobile:false,
      showModalMapMobile:false,
      dataloaded: false,
      showCarte: false,
      searchWord: "",
      liste_possibilites: null,
      stations_list : [],
      stations_authorisations : [],
      listSelectIndicateur: [],
      listSelectConstructor : [],
      listes:{},
      disabledDepartement: false,
      selectedConstruct: [],
      selectedIndicatorStation: [],
      selected_stations: [],
      nbStationsGood : [],
      nbStationsMedium : [],
      nbStationsLow : [],
      nbAgris: 0,
      nbStations: 0,
      nbStationOk24h: 0,
      surfaceTotale: 0,
      nbHeuresPasAJour: 6,
      tableauRisque: null,
      optionsTab: {itemsPerPage: -1,},
      selected: [],
      vision_all_station : true,
      checkDroitLectureExterne : false,
      
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Code station ARVALIS', value: 'id', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, align:"center",width: '36px'},
        { text: "Nom station", value: "device_name", align:"center",width: '240px'},
        { text: "Code station", value: "identification", align: " d-none",width: '140px'},
        { text: "Opérateur météo ", value: "constructor_name", align:"center",width: '150px'},
        { text: "Etat station", value: "indicateur", align:"center",width: '40px' },
        { text: "Nb d'heures manquantes", value: "nbheuresmanquantes", align:"center",width: '100px' },
        { text: "Premières données réelles", value: "first_data", align:"center",width: '150px' },
        { text: "Dernières données réelles", value: "last_data", align:"center",width: '130px'},
        { text: "Dernières données prévisionnelles", value: "last_data_previ", align:"center",width: '150px' }
      ],

      headers_mobile: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Code station ARVALIS', value: 'id', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, align:"center",width: '24px'},
        { text: "Nom station", value: "device_name", align:"center"},
        { text: "Code station", value: "identification", align: " center"},
        { text: "Opérateur météo ", value: "constructor_name", align:"center"},
        { text: "Etat station", value: "indicateur", align:"center",width: '40px' }
      ],

      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },
    };

  },


  watch: {
      selectedIndicatorStation: function () {
        //this.filterListes()
        this.filterStations()
      },
      selectedConstruct: function () {
        //this.filterListes()
        this.filterStations()
      },
      stations_list: function () {
        this.getIndicateurs()
        this.getPositionCarte()
      },
      selected_stations: function() {
        this.getPositionCarte()
      }
    },

    computed: {

    },

  async mounted() {
     // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();
    // Ajouter un écouteur d'événement pour surveiller les changements de taille de fenêtre
   // window.addEventListener('resize', this.checkIsMobile);

    this.stations_authorisations = await parcellesMildiouService.getInfosAuthorisationsAllStations();
    
    this.stations_list = await parcellesMildiouService.getListesAllStations(this.$route.params.id);
    this.checkDroitLectureExterne = this.$store.getters['user/checkDroit']('EXTERNE','lecture')

    // * Gestion du champ 'identification' à masquer ou non
    if ( this.stations_list && this.checkDroitLectureExterne) { // IF DATA LOADED & user/checkDroit('EXTERNE','lecture')
      this.stations_list = this.stations_list.map(({identification, ...keepAttrs}) => keepAttrs)
    } else {
      var index_identification = this.headers.findIndex(element => element.value === 'identification')
      this.headers[index_identification].align = ' center';
    }

    this.$store.commit('station/defineStationsList',{stations:this.stations_list});
    
    this.listSelectIndicateur = this.$store.getters['station/DefineListSelect']('indicateur')
    this.listSelectConstructor = this.$store.getters['station/DefineListSelect']('constructor_name')

    this.dataloaded = true;
    },

    methods: {

    // checkIsMobile() {
    //   this.isMobile = window.innerWidth < 960; // Déterminer si l'écran est bien un mobile
    // },

    toggleModalFiltre() {
      this.showModalFiltreMobile = !this.showModalFiltreMobile;
    },

      showItem(id_station, type = null) {
        // Stockage de la parcelle sélectionnée dans le store
        //this.$store.dispatch("parcelle/setParcelleSelected", parcelle);
        let route = this.$router
          .resolve({
            name: 'detail-station',
            params: {
              id: id_station
            }
          });
        if (type == 'href') {
          return route.href;
        } else {
          window.open(route.href);
        }
      },
      // 
      async filterStations() {
        this.stations_list = this.$store.getters['station/FilterStations'](this.selectedIndicatorStation, this.selectedConstruct)
      },

      resetFiltres() {
        //this.selectedDepartement = []
        this.selectedConstruct = []
        this.selectedIndicatorStation = []
      },

      getNumberOfStationOk24h() {
        let today = new Date();
        let nbrOfstation = 0;
        let difference = 0;
        let date_to_compare = new Date();

        this.stations_list.forEach(element => {

          date_to_compare = new Date(element['last_data']);
          difference = Math.abs(date_to_compare - today) / 1000 / 3600;
          if (difference < 24) {
            nbrOfstation++;
          }
        });
        return nbrOfstation;
      },

      //  MAP

      getPositionCarte() {
    var array = [];
    var sumLat = 0;
    var sumLong = 0;
    var nbStation = 0;

    var selectedStationIds = this.selected_stations.map(station => station['id']);
    var filteredStations = this.stations_list.filter(station => {
        return this.vision_all_station || selectedStationIds.includes(station['id']);
    });

    filteredStations.forEach(station => {
        if (station['latitude'] != null && station['longitude'] != null) {
            var randomLatitude = (Math.round(Math.random()) * 2 - 0.5) / 10000;
            var randomLongitude = (Math.round(Math.random()) * 2 - 0.5) / 10000;

            var icon = selectedStationIds.includes(station['id']) ? "blue3" : this.getColorCarte(station);

            array.push({
                id: station['id'],
                coordinates: [parseFloat(station['latitude']) + randomLatitude, parseFloat(station['longitude']) + randomLongitude],
                imageUrl: require('@/assets/map_icon_' + icon + '.png'),
                infobulle: '<a href="' + this.showItem(station['id'], 'href') + '" target="_blank">Voir le détail de la station</a><br>Code station ARVALIS - nom: ' + station['device_name'] +
                    '<br> Opérateur météo : ' + station['constructor_name'] +
                    '<br> Dernière données réelles reçue le : ' + station['last_data'] +
                    '<br> Nombre d\'heures manquantes : ' + station['nbheuresmanquantes'] +
                    '<br>  '
            });

            sumLat += parseFloat(station['latitude']);
            sumLong += parseFloat(station['longitude']);
            nbStation++;
        }
    });

    if (nbStation > 0) {
        var centerLat = sumLat / nbStation;
        var centerLong = sumLong / nbStation;

        this.carte.markers = array;
        this.carte.zoom = 7;
        this.carte.center = [centerLat, centerLong];
    }
},



      getColorCarte(element) {
        //A AJOUTER : VERIF PAS EN ERREUR EN PLUS DU FAIT QUE LES DONNEES METEO DOIVENT ETRE RECENTES
        if (element.indicateur == 0) return 'green'
        else if (element.indicateur == 1) return 'yellow'
        else if (element.indicateur == 2) return 'red'
        else return 'grey'
      },

      getTextSelectIndicator(item) {
        if ( item.value== 0) return 'COMPLET'
        else if (item.value== 1) return 'PARTIEL'
        else if ( item.value == 2) return 'ABSENCE'
      },

      zoomUpdated(zoom) {
        this.zoom = zoom;

      },

      centerUpdated(center) {
        this.center = center;
      },
      //FIN MAP

      getNomFichier() {
        var now = new Date()
        now = getDate(now, 'yyyymmdd')

        return now + '_Export_total_station'
      },

      getIconStation(indicateur) {
        if (indicateur == 0) return 'mdi-wifi-marker'
        else if (indicateur == 1) return 'mdi-wifi-strength-2'
        else if (indicateur == 2) return 'mdi-wifi-strength-1-alert'
        else return 'mdi-wifi-off'
      },

      getColorStation(indicateur) {
        if (indicateur == 0) return 'green'
        else if (indicateur == 1) return 'yellow'
        else if (indicateur == 2) return 'red'
        else return 'grey'
      },

      getInfoStation (indicateur) {
        if ( indicateur== 0) return 'Complet : l’historique de données météo est complet et a été actualisé dans les 24 dernières heures'
        else if (indicateur== 1) return 'Partiel : identification d’un ou plusieurs trous de moins de 6h de données météo, sans impact sur le fonctionnement des calculs'
        else if ( indicateur == 2) return 'Absence : identification d’un ou plusieurs trous de 6h ou plus de données météo, bloquant les calculs. Il est nécessaire de les combler pour les relancer à contactez l’opérateur météo '
      },

      getGoodStation() {
        let nbStationsGood = this.stations_list.filter(item => item.indicateur == 0).length;
        return nbStationsGood;
      },

      getMediumStation() {
        let nbStationsMedium = this.stations_list.filter(item => item.indicateur == 1).length;
        return nbStationsMedium;
      },

      getLowStation() {
        let nbStationsLow = this.stations_list.filter(item => item.indicateur == 2).length;
        return nbStationsLow;
      },

      getIndicateurs() {
        this.nbStations = this.stations_list.length;
      }

    
  }
  // MAP
};
</script>
<style scoped>


/* EN MOBILE  */
@media only screen and (max-width: 960px) {

  #indicateur .info-card{
    min-height: 1rem;
      height: auto !important;
      text-align: left;
      margin:0.4rem;
  }

.v-dialog{
  height: 90vh !important;
  background-color: whitesmoke !important;
  padding:0.4rem;
}

.map_mobile{
    margin: 0 auto !important;
    height: 400px !important;
    height:80vh !important;
    width:90% !important;
  }

}
/*MAP*/
.map {
    height: 600px !important;
    width: 700px !important;
    position: relative !important;
    z-index:0;
}
</style>


import Vue from 'vue';

export default {
  namespaced: true,
  state: {
     meteo_data_hourly:[], 
    stations_list:[] 
  },

  actions: {
 
    defineMeteoDataHourly({ commit }, payload) {
      commit("defineMeteoDataHourly", payload); 
    },
    defineMeteoDataDaily({ commit }, payload) {
      commit("defineMeteoDataDaily", payload); 
    },
    defineStationsList({ commit }, payload) {
      commit("defineStationsList", payload); 
    },
    reset: ({commit}) => {
      commit('reset');
    }
  },
  mutations: {

    defineMeteoDataHourly (state, payload) {
      state.meteo_data_hourly = payload.indices
    },
    defineMeteoDataDaily (state, payload) {
      state.meteo_data_daily = payload.indices
    },
    defineStationsList (state, payload) {
      state.stations_list = payload.stations
    },

    reset: (state) => {
      state.meteo_data_hourly=[];
      state.meteo_data_daily= [];
      state.stations_list=[]; 
    }
  },
  getters: {

      FilterTabIndicesStations: (state) => (type,reel,previ,date_debut,date_fin) => {
        var tab = []
        if(type=='hour'){
          tab = state.meteo_data_hourly
        } else {
          tab = state.meteo_data_daily
        }

        var tab1 = []
        if(reel!=null && reel==true){
          tab1 = tab.filter(i => i.type == 'actual')
        }

        var tab2 = []
        if(previ!=null && previ==true){
          tab2 = tab.filter(i => i.type != 'actual')
        }

        tab = tab1.concat(tab2)

        var tab3 = tab
        if(date_debut!=null && date_debut!=''){
          tab3 = tab.filter(i => new Date(i.date) >= new Date(date_debut))
        }

        var tab4 = []
        if(date_fin!=null && date_fin!=''){
          tab4 = tab3.filter(i => new Date(i.date) <= new Date(date_fin))
        } else {
          tab4 = tab3
        }

        return tab4
        
      },

      FilterStations: (state) => (indicateur,constructor) => {
        var tab = state.stations_list
      
        //filtre indicateur station
        var tab3 = []
        if(indicateur!=null && indicateur.length>0 && tab.length>0){
          indicateur.map(function(value) {
            tab3 = tab3.concat(tab.filter(station => station.indicateur === value))
          });
          tab = tab3
        }

        //filtre constructeur station
        var tab4 = []
        if(constructor!=null && constructor.length>0 && tab.length>0){
          constructor.map(function(value) {
            tab4 = tab4.concat(tab.filter(station => station.constructor_name === value))
          });
          tab = tab4
        }

        return tab
        
      },

      DefineListSelect: (state) => (selection)=>{

        var tab = [];
        state.stations_list.forEach(element => { 
          tab.push({value:element[selection]})
        });

      
        //Conserver les filtres et selections
        return tab
      },

  }
};
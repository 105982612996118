import auth from '../api/auth';
import jwt_decode from "jwt-decode";

export default ({
  namespaced: false,
  state: {
    status: '',
    token: localStorage.getItem('token') || '',

  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, response) {
      state.status = 'success';
      state.token = response.token;
    
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
    },
    reset: (state) => {
      state.status = '';
      state.token = '';
      localStorage.removeItem('token');
    }
  },
  actions: {
    // Demande si l'utilisateur existe bien en base
    async login({ commit }, user) {
      commit('auth_request');
      try {
        //const { token, users } = await auth.login(user);
        const response = await auth.login(user);
        localStorage.setItem('token', response.token);
        commit('auth_success', response);
        commit('user/load_user', response.user);
      } catch (err) {
        throw new Error(err)
      }
    },

      // Demande si l'utilisateur de substitution existe bien en base
      async loginInvite({ commit }, user) {
        commit('auth_request');
        try {
          //const { token, users } = await auth.login(user);
          const response = await auth.loginInvite(user);
          localStorage.setItem('token', response.token);
          commit('auth_success', response);
          commit('user/load_user', response.user);
        } catch (err) {
          throw new Error(err)
        }
      },

    // register({ commit }, user) {
    //   commit('auth_request');
    //   fetch('http://localhost:8080/register', {
    //     method: 'POST',
    //     body: JSON.stringify(user),
    //   })
    //     .then(data => data.json())
    //     .then((resp) => {
    //       const { token, user } = resp;
    //       localStorage.setItem('token', token);
    //       commit('auth_success', token, user);
    //     });
    // },
    logout({ commit }) {
      commit('logout');
      localStorage.removeItem('token');
    },
    reset: ({ commit }) => {
      commit('reset')
    }
  },
  getters: {

    isLoggedIn (state) {
      return function () {
        let retour = false;
        if (state.token) {
          try {
            const decoded = jwt_decode(state.token);
            if (Date.now() >= decoded.exp * 1000) {
              localStorage.removeItem('token');
              retour = false;
            }
            else
            {
              retour = true;
            }
          } catch (err) {
            return false;
          }
        }

        return retour
      }

    },
    //isLoggedIn: state =>  !!state.token,
    authStatus: state => state.status,
    tokenValue: state => state.token,
  },

});

<template>
  <v-container style="margin-top:20px;">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchWord"
          append-icon="mdi-magnify"
          label="Recherche par mot clé"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table   
        :search="searchWord" 
        :loading="!dataloaded"      
        loading-text="Chargement des données...."
        :headers="headers" 
        :items="listeStations" 
        item-key="CodeStation"
        dense
        fixed-header>
      </v-data-table>
    </v-card>

    <div style="margin-top:20px;">
      <!--
        https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
        https://vue2-leaflet.netlify.app/examples/custom-icons.html
        -->
      <l-map
        :center="carte.center"
        :zoom="carte.zoom"
        class="map"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        
      >
        <l-tile-layer
          :url="carte.url"
        >
        </l-tile-layer>
        <l-marker
          v-for="marker in carte.markers"
          :key="marker.id"
          :lat-lng="marker.coordinates"
        >
          <l-icon 
            ref="icon"
            :icon-url="marker.imageUrl"
            :icon-size="[15, 25]"
          >
          </l-icon>
          <l-popup :content="marker.infobulle"/>
        </l-marker>
      </l-map>
    </div>
  </v-container>
  
</template>

<script>


import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
import VChart from "vue-echarts";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "Tdb_StationsMF",
  components: {
    downloadExcel,
    recalageService,
    VChart,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      dataloaded: false,
      listeStations: [],
      searchWord: '',
      headers: [
        //colonnes visibles
        { text: "Code station", value: "CodeStation"},
        { text: "Nom", value: "NomComplet"},
        { text: "Date début", value: "DonneesDateDebut"},
        { text: "Date fin", value: "DonneesDateFin"},
        { text: "Date fin prévi", value: "DonneesPrevFin"},
        { text: "Propriétaire", value: "Proprietaire"},
        { text: "Fréquence", value: "Freq"}
      ],
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },
    };

  },

  watch: {  },

  computed: {},

  async mounted() {


    var listeStations = null
    if((this.$store.getters['recalage/ListeStationsDetaillee']()).length==0){
      listeStations = await recalageService.getAllStations();
      this.$store.commit('recalage/defineListeStations', {listeStations:listeStations})
    } 
    this.listeStations = this.$store.getters['recalage/ListeStationsDetaillee']()
    this.dataloaded = true

    this.getPositionCarte()
  },

  methods: {
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    getPositionCarte(){
      //markers sur carte
      var array = []
      this.listeStations.forEach(element => {
          array.push({
            id:element['CodeStation'], 
            coordinates:[element['Coordonnees']['Latitude'],element['Coordonnees']['Longitude']], 
            imageUrl:require('@/assets/map_icon_blue.png'), 
            infobulle:'Code station: '+element['CodeStation']+'<br>Nom: '+element['NomComplet']+'<br>Date début: '+element['DonneesDateDebut']+'<br>Date fin: '+element['DonneesDateFin']+'<br>Date fin prévi: '+element['DonneesPrevFin']+'<br>Propriétaire : '+element['Proprietaire']+'<br>Fréquence: '+element['Freq']+'<br>Latitude: '+element['Coordonnees']['Latitude']+'<br>Longitude: '+element['Coordonnees']['Longitude']+'<br>Altitude: '+element['Coordonnees']['Altitude']})    
      });
      
      this.carte.markers = array
    },



  }
};
</script>
<style scoped>

/*MAP*/
.map {
    height: 600px !important;
    width: 700px !important;
    position: relative !important;
    z-index:0;
  }



</style>


import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    visible: false,
    message: ''
  },
  mutations: {
    setInfo(state, payload) {
      Vue.set(state, 'message', payload.message);
      Vue.set(state, 'visible', true);
    },
    unsetInfo(state) {
      Vue.set(state, 'visible', false);
      Vue.set(state, 'message', '');
    },
    reset: (state) => {
      state.message = '';
      state.visible = false;
    }
  },
  getters: {
    infostate: state => state.visible
  },
  actions: {
    setInfo({commit}, payload) {
      commit('setInfo', payload);
    },
    unsetInfo({commit}) {
      commit('unsetInfo');
    },
    reset: ({commit}) => {
      commit('reset');
    }
  }
};
import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  // we load data from an API
  async getAll() {
    return await this.find({});
  },
  async find(params) {
    const url = new URL(`${process.env.VUE_APP_BASE_API}equipments`);
    url.search = handleServiceParams(params);
    return await fetch(url, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        //Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(json =>
      // we map every json object to the JS object
      json.data.map((item) => {
        const a = new Equipment(item);
        return a;
      })
    )
    .catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
  },

  async getInfosParcelles(date=null,id=null) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    var query = '';
    var year = new Date().getFullYear();
    if(date != null && date.indexOf(year)!=-1){
      query = '?date_calcul='+date;
    }

    var path_param = '';
    if(id != null){
      path_param = id+'/';
    }
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${path_param}results`+query, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosParcellesErreurs(date=null) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    var query = '';
    var year = new Date().getFullYear();
    if(date != null && date.indexOf(year)!=-1){
      query = '?date_calcul='+date;
    }


    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/results/errors`+query, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getListeDatesCalcul(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    var path_param = '';
    if(id != undefined){
      path_param = id+'/';
    }

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${path_param}dates_calcul`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
         Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        //json.data=[{"date_calcul":"2021-09-22"},{"date_calcul":"2021-09-23"},{"date_calcul":"2021-09-27"}];
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getMesuresParcelles(date=null,id=null) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    var query = '';
    var year = new Date().getFullYear();
    if(date != null && date.indexOf(year)!=-1){
      query = '?date_calcul='+date;
    }

    var path_param = '';
    if(id != null){
      path_param = id+'/';
    }
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${path_param}results_daily`+query, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getDocumentValidation(id=null) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${id}/rapports`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  /*async getScenariiCorrection(id) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/${id}/scenarii_correction`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        //Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },*/

  /*async postMasque(id, correction) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/${id}`, {
      method: 'POST',
      body: JSON.stringify(correction),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },*/

  async postDemandeCorrection(tparcelles) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/demandes_correction`, {
      method: 'POST',
      body: JSON.stringify(tparcelles),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async postChoixCorrection(id, choix) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/${id}/validation_correction`, {
      method: 'POST',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async getHistoCorrection() {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/correction_histo`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async postDemandeRecalcul(tparcelles) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}chns/demandes_recalcul`, {
      method: 'POST',
      body: JSON.stringify(tparcelles),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async getFilesCorrection() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/fichiers_correction`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getDeltaEvolution(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${id}/delta_evolution`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getLastSolMeasures(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}chns/${id}/last_sol_measures`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

};

<template>
  <div>
    <v-container style="margin-top: 10px">
    <!--API Bilan hydrique -->
      <v-card class="mb-15">
        <h3>API Bilan hydrique</h3>
        <v-row class="pl-10 pt-5">
          <v-btn small color="info" class="mt-2 white--text">
            <download-excel 
              :fetch="getBhFacturation"

              :before-generate = "waiting_true"
              :before-finish   = "waiting_false"
              >
              Export Facturation 
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
          </download-excel>
          </v-btn>
        </v-row>
        <v-row class="pl-10 pt-5" v-if="infos_bh">
          <v-col cols="5">
            <div>Nb intégrateurs : {{infos_bh.nb_operateurs}}</div>
            <div>Nb parcelles : {{infos_bh.nb_parcelles}}</div>
            <div>Surface totale : {{infos_bh.surface_parcelles}}</div>
            <v-simple-table
              v-if="infos_bh"
              fixed-header
              height="200px"
              style="width:80%"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Etat calcul
                    </th>
                    <th class="text-left">
                      Nb Parcelles
                    </th>
                    <th class="text-left">
                      % Parcelles
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="ligne in infos_bh.detail_etat_calculs"
                    :key="ligne.statut_calcul_bh"
                  >
                  
                    <td style="height:12px">
                      <v-chip
                        :color="getColorStatutBh(ligne.statut_calcul_bh)"
                        dark
                        small
                      >
                        {{ ligne.statut_calcul_bh }}
                      </v-chip>
                    </td>
                    <td style="height:12px">{{ ligne.nb_parcelles }}</td>
                    <td style="height:12px">{{ ligne.pourcentage_parcelles }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table
              v-if="infos_bh"
              fixed-header
              height="200px"
              style="width:80%"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Intégrateur
                    </th>
                    <th class="text-left">
                      Nb Parcelles
                    </th>
                    <th class="text-left">
                      % Parcelles
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="ligne in infos_bh.liste_operateurs"
                    :key="ligne.nom_operateur"
                  >
                  
                    <td style="height:12px">{{ ligne.nom_operateur }}</td>
                    <td style="height:12px">{{ ligne.nb_parcelles }}</td>
                    <td style="height:12px">{{ ligne.pourcentage_parcelles }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="6">
              <v-simple-table
                v-if="infos_bh"
                fixed-header
                height="280px"
                style="width:80%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Culture
                      </th>
                      <th class="text-left">
                        Nb Parcelles
                      </th>
                      <th class="text-left">
                        Surface
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="ligne in infos_bh.detail_culture"
                      :key="ligne.culture"
                    >
                      <td style="height:12px">{{ ligne.culture }}</td>
                      <td style="height:12px">{{ ligne.nb_parcelles }}</td>
                      <td style="height:12px">{{ ligne.surface }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
    <!-- FIN API Bilan hydrique -->

    </v-container>
  </div>

</template>

<script>
import downloadExcel from "vue-json-excel";
import administrationTdbService from "@/service/administrationTdb";
import { getDate } from "@/helpers/functions";


export default {
  name: "Tdb-BH",
  components: {
    downloadExcel,
    administrationTdbService,
    getDate
  },
  data() {
    return {
      infos_bh: null,
      facturation_bh: [],
      headers_bh: [
        { text: "Culture", value: "culture"},
        { text: "Nb parcelles", value: "nb_parcelles"},
        { text: "Surface", value: "surface" }
      ]
    };

  
  },
  async mounted() {
    this.infos_bh = await administrationTdbService.getTdbBh();
    this.infos_bh.nb_operateurs = 0
    this.infos_bh.nb_operateurs = this.infos_bh.liste_operateurs.length
    this.infos_bh.nb_parcelles = 0
    this.infos_bh.detail_culture.forEach((item) => {
        this.infos_bh.nb_parcelles += item.nb_parcelles
    });
    this.infos_bh.surface_parcelles = 0
    this.infos_bh.detail_culture.forEach((item) => {
        this.infos_bh.surface_parcelles += parseFloat(item.surface)
    });
    this.infos_bh.surface_parcelles = Math.round(this.infos_bh.surface_parcelles,2)

    //this.facturation_bh = await administrationTdbService.getFacturationBh()
  },

  methods: {
    getColorStatutBh (statut) {
        if (statut == 'ok') return 'green'
        else if (statut == 'ko') return 'red'
        else return 'orange'
    },
    async getBhFacturation (){
      this.facturation_bh = await administrationTdbService.getFacturationBh()
      return this.facturation_bh
    },
    waiting_true(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
    },
    waiting_false(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    }
  }
};
</script>

<style>
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profil from '../views/auth/Profil.vue'
import Login from "../views/auth/Login.vue";
import PasswordForgotten from "../views/auth/PasswordForgotten.vue";
import Chn from "@/views/Chn.vue";
import Mildiou from "@/views/Mildiou.vue";
import Station from "@/views/Station.vue";
import Bilanhydrique from "@/views/Bilanhydrique.vue";
import Stade from "@/views/Stade.vue";
import Administration from "@/views/Administration.vue";
import Gestion from "@/views/Gestion.vue";
import Consentement from "@/views/Consentement.vue";
import Help from "@/views/Help.vue";
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authorize: []}
   },
   {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { authorize: ['*']}
   },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { authorize: []},
    beforeEnter: (to, from, next) => {

      if (localStorage.getItem("token") !== null) {
        next({ path: "/home" });
      } else {
        next();
      }
    }
  },
  {
    path: "/passwordForgotten",
    name: "passwordForgotten",
    component: PasswordForgotten,
    meta: { authorize: ['*']}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { authorize: ['*']}
  },
  {
    path: '/profil',
    name: 'profil',
    component:Profil,
    meta: { authorize: ['*']}
  },
  {
    path: '/chn',
    name: 'chn',
    component: Chn,
    meta: { authorize: ['CHN']},
    children: [
      {
        path: 'suivi',
        name: 'chn-suivi',
        component: Chn,
        meta: { authorize: ['CHN']},
      },
      {
        path: 'parcelle/:id',
        name: 'chn-detail-parcelle',
        component: Chn,
        meta: { authorize: ['CHN']},
      }
    ]
  },
  {
    path: '/bilanhydrique',
    name: 'bilanhydrique',
    component:Bilanhydrique,
    meta: { authorize: ['BH']},
    children: [
      {
        path: 'tableaudebord',
        name: 'bh-tdb',
        component: Bilanhydrique,
        meta: { authorize: ['BH']},
      },
      {
        path: 'parcelle/:id',
        name: 'bh-detail-parcelle',
        component: Bilanhydrique,
        meta: { authorize: ['BH']},
      }
      // ,
      // {
      //   path: 'gestion_parcelles',
      //   name: 'bh-nouvelle-parcelle',
      //   component: Bilanhydrique,
      //   meta: { authorize: ['BH']},
      // }
    ]
  },
  {
    path: '/mildiou',
    name: 'mildiou',
    component: Mildiou,
    meta: { authorize: ['MILDIOU']},
    children: [
      {
        path: 'tableaudebord',
        name: 'mildiou-tdb',
        component: Mildiou,
        meta: { authorize: ['MILDIOU']},
      },
      {
        path: 'parcelle/:id',
        name: 'mildiou-detail-parcelle',
        component: Mildiou,
        meta: { authorize: ['MILDIOU']},
      },
      {
        path: 'gap_meteo',
        name: 'gap_meteo',
        component: Mildiou,
        meta: { authorize: ['MILDIOU']},
      },
      {
        path: 'gestion_parcelles',
        name: 'gestion_parcelles',
        component: Mildiou,
        meta: { authorize: ['MILDIOU-ecriture']},
      }
    ]
  },
  {
    path: '/meteo',
    name: 'station',
    component: Station,
    meta: { authorize: ['METEO']},
    children: [
      {
        path: 'tableaudebord',
        name: 'meteo-tdb',
        component: Station,
        meta: { authorize: ['METEO']},
      },
      {
        path: 'station/:id',
        name: 'detail-station',
        component: Station,
        meta: { authorize: ['METEO']},
      },
      {
        path: 'tableaudebordmf',
        name: 'meteofrance-tdb',
        component: Station,
        meta: { authorize: ['STADES']},
      },
      {
        path: 'stationmf/:id',
        name: 'detail-stationmf',
        component: Station,
        meta: { authorize: ['STADES']},
      }
    ]
  },
  {
    path: '/stade',
    name: 'stade',
    component: Stade,
    meta: { authorize: ['STADES']},
    children: [
      {
        path: 'mais/tableaudebord',
        name: 'stade-tdb-mais',
        component: Stade,
        meta: { authorize: ['STADES']},
      },
      {
        path: 'cap/tableaudebord',
        name: 'stade-tdb-cap',
        component: Stade,
        meta: { authorize: ['STADES']},
      },
      {
        path: 'cap/mesures',
        name: 'stade-cap-mesures',
        component: Stade,
        meta: { authorize: ['STADES']},
      },
      {
        path: 'cap/mycolis',
        name: 'mycolis',
        component: Stade,
        meta: { authorize: ['ADMIN']},
      },
      {
        path: 'recalage',
        name: 'recalage',
        component: Stade,
        meta: { authorize: ['STADES']},
      },
      {
        path: 'plot/:id',
        name: 'detail-plot',
        component: Stade,
        meta: { authorize: ['STADES']},
      }
    ]
  },
  {
    path: '/administration',
    name: 'administration',
    component: Administration,
    meta: { authorize: ['ADMIN']},
    children: [
      {
        path: 'tableaudebord/bh',
        name: 'admin-tdb-bh',
        component: Administration,
        meta: { authorize: ['ADMIN']},
      },
      {
        path: 'tableaudebord/stade',
        name: 'admin-tdb-stade',
        component: Administration,
        meta: { authorize: ['ADMIN']},
      },
      {
        path: 'tableaudebord/pdt',
        name: 'admin-tdb-pdt',
        component: Administration,
        meta: { authorize: ['ADMIN']},
      },
      {
        path: 'conseillers',
        name: 'admin-conseillers',
        component: Administration,
        meta: { authorize: ['ADMIN']},
      },
    ]
  },
  {
    path: '/gestion',
    name: 'gestion',
    component: Gestion,
    meta: { authorize: ['EXTERNE']},
    children: [
      {
        path: 'agriculteurs',
        name: 'gestion-agris',
        component: Gestion,
        meta: { authorize: ['EXTERNE']},
      },
    ]
  },
  {
    path: '/help',
    name: 'help',
    component:Help,
    meta: { authorize: ['*']}
  },
  {
    path: '/consentement',
    name: 'consentement',
    component: Consentement,
    meta: { authorize: []},
    children: [
      {
        path: 'compte',
        name: 'consentement-compte',
        component: Consentement,
        meta: { authorize: []},
      },
      {
        path: 'gestion',
        name: 'consentement-gestion',
        component: Consentement,
        meta: { authorize: []},
      },
    ]
  }

]

const router = new VueRouter({
  routes
})



router.beforeEach(async (to, from, next) => {

  const { authorize } = to.meta;

  if (authorize == undefined || authorize.length === 0) {
    // Si aucune info,  c'est qu'il n'y a pas de restriction --> On continue!
    next();
  }
  else {
   // console.log(JSON.stringify("Authorize : " + authorize));
    if (!store.getters.isLoggedIn()) {
        store.dispatch("logout");
        next({ path: "/login" });
    }
    else {
      await store.dispatch("user/fetchUser");
      const currentUser = store.state.user.user;

      if ((currentUser) == null) {
        next({ path: "/login" });
      }
      else {
        const userRight = store.getters["user/currentRights"];
         // const userRight = store.getters["user/currentRights"].map(value => value.code);

       // console.log('USER RIGHTS ' + JSON.stringify(userRight));

        let intersection = authorize.filter(x => userRight.includes(x));
        if ((intersection.length > 0 || authorize.includes("*"))) {
          next();
        }
        else {
          next({ path: "/" });
        }
      }
    }
  }

})

export default router

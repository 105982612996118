<template >
    <!--<v-navigation-drawer
        absolute
        permanent
        left
        style="position:fixed;top:40px;"
    >
        <v-sheet color="grey lighten-5" height="100%" width="100%">
        <div style="width: 80%; margin-left: 10%;padding-top:20px;">
            <div v-for="itemmenu in menu" :key="itemmenu.id">
              <router-link :to="itemmenu.chemin">
                {{itemmenu.nom}}
              </router-link> <br>
              </div>
        </div>
        </v-sheet>
    </v-navigation-drawer>-->

    <!--:mini-variant.sync="mini"-->
    <div v-if="menu.length>0">
    <v-navigation-drawer
        
        permanent
        expand-on-hover
        style="position:fixed;top:40px;z-index:2"
      >

      <v-list
        nav
        dense
      >
        <v-list-item link v-for="itemmenu in navigationAuthorised(menu)" :key="itemmenu.id">
          <router-link :to="itemmenu.chemin">
            <v-list-item-icon>
              <v-icon :color="isActivePage(itemmenu.chemin)=='oui'?'#0097ac':''">{{itemmenu.icon}}</v-icon>
            </v-list-item-icon>
          </router-link>
          <router-link :to="itemmenu.chemin">
            <v-list-item-title :style="isActivePage(itemmenu.chemin)=='oui'?'font-weight: bold;text-decoration: underline;':''">{{itemmenu.nom}}</v-list-item-title>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name:"menucontextuel",
  props:{
    menu:Array
  },
  data () {
    return {
      mini: true,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn() ; //&& this.$store.getters["user/user"];
    }
  },
  methods:{
    test(chemin) {
      this.$router.push(chemin);
    },
    navigationAuthorised(item) {
      const userRight = this.isLoggedIn ? this.$store.getters["user/currentRights"]: []; 
      let retour = [];
      item.forEach(element => {
        // Récupération de la route et des droits associés
        const { route } = this.$router.resolve({
          path: element.chemin
        });
        if (route !== undefined) {
          const { authorize } = route.meta || {};
          const rules = authorize == undefined ? [] : authorize;
          if (rules.length == 0) {
            retour.push(element);
          } else {
            let intersection = rules.filter(x => userRight.includes(x));
            if (this.isLoggedIn && (intersection.length >0 || rules.includes("*") )){
              retour.push(element);
            }
          }
        }
      });
      return retour;
    },
    isActivePage(chemin){
      var isActive = 'non'
      if(chemin==this.$route.path){
        isActive = 'oui'
      }
      return isActive
    }
  }
}
</script>

<style>

</style>
import auth from '../api/auth';

export default {
  namespaced: true,
  state: {
    user: null,
    login_user: null,
    profils: [],
    currentProfil: {},
    currentWriteRights: [],
    currentReadRights: [],
  },
  mutations: {
    load_user(state, user) {
      state.user = user;
      state.profils = user.profilsADPU.map(value => value.code);
      state.currentProfil = user.profilsADPU.find(p => {
        return p.isDefaut
      })
      state.login_user = user.login.replace(/^ARVALIS-FR\\/, '');
    },
    changeCurrentProfil(state, newProfil) {
      state.currentProfil = newProfil;
    },
    reset(state) {
      state.user = null;
      state.login_user = null;
      state.profils = [];
      state.currentProfil = {};
      state.currentWriteRights = [],
        state.currentReadRights = []
    },
    validate_cgu(state) {
      state.user.externeInfos.cgu = process.env.VUE_APP_VERSION_CGU;
    }
  },
  getters: {
    user: state => state.user,
    login_user: state => state.login_user,

    currentWriteRights(state) {
      if (state.user != null) {
        //return  state.currentProfil.droits.filter((right) =>  {
        return state.user.droitsTPC.filter((right) => {
          if (right.ecriture) {
            return right
          }
        });
      }
      return [];
    },

    currentReadRights(state) {
      if (state.user != null) {
        //return  state.currentProfil.droits.
        return state.user.droitsTPC.
        filter((right) => {
          if (right.lecture) {
            return right
          }
        });
      }
      return [];
      // profils: state => state.profils,
    },

    currentRights(state) {
      if (state.user != null) {
        //return  state.currentProfil.droits.
        let arr_rights = [];

        state.user.droitsTPC.
        map((right) => {
          if (right.ecriture) {
            arr_rights.push(right.code + '-ecriture')
          }
          if (right.lecture) {
            arr_rights.push(right.code)
          }
        });
        return arr_rights
      }
      // return state.user.droitsTPC.filter((right) => {
      //   console.log(right);
      //   if (right.ecriture) {
      //     console.log('---ecriture----')
      //     return right.code +'-ecriture'
      //   } else if (right.lecture) {
      //     console.log('----lecture----')
      //     return right.code +'-lecture'
      //   }
      // });
      return [];
      // profils: state => state.profils,
    },

    getDroitsExterne: (state) => (service) => {
      var idConseiller = null
      var tab = state.user.externeInfos

      if (state.user.externe == 1) {
        tab = tab.filter(data => data.service === service)

        if (tab.length > 0) {
          idConseiller = tab[0].id_conseiller
        }

      }
      return idConseiller;

    },

    isCguValid(state) {
      var cguValid = true

      if (state.user != null && state.user.externe == 1) {
        var cgu = state.user.externeInfos.cgu

        if (cgu == null || (cgu != process.env.VUE_APP_VERSION_CGU)) {
          cguValid = false
        }

      }
      return cguValid;
    },

    isArvalis(state) {
      var isArvalis = 0
      if (state.user.email) {
        var login = state.user.email.toLowerCase()
        if (login.indexOf('arvalis') >= 0) {
          isArvalis = 1
        }
      }

      return isArvalis;
    },


    login(state) {
      var login = state.user.login.toLowerCase().split('\\')[1]
      return login
    },

    checkDroit: (state) => (droit, type) => {
      var droits = state.user.droitsTPC
      var index = droits.findIndex(item => item.code === droit)
      if (index >= 0 && droits[index][type] == true) {
        return true
      } else {
        return false
      }

    }


  },
  actions: {

    async fetchUser({
      state,
      commit
    }) {
      // On recharge l'utilisateur si pas déjà chargé
      if (state.user == null) {
        try {
          //const { token, users } = await auth.login(user);
          const response = await auth.getUser();
          commit('load_user', response.data);
        } catch (err) {
          throw new Error(err)
        }
      }

    },
    reset: ({
      commit
    }) => {
      commit('reset')
    },
    changeCurrentProfil({
      commit
    }, newProfil) {
      commit('changeCurrentProfil', newProfil);
    },
    validate_cgu({
      commit
    }) {
      commit('validate_cgu');
    }
  }

};
export default {
    namespaced: true,

    state: {
        visible: false,
        message: null,
    },
    getters: {
        isVisible: state => state.visible,
        getMessage: state => state.message
    },
    mutations: {
        setVisible: (state, value) => {
            state.visible = value
        },
        setMessage: (state, value) => {
            state.message = value
        },
    },
    actions: {
        show({ commit }, payload) {
            commit("setMessage", payload.message);
            commit("setVisible", true);
        },
    }
};
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"mb-10"},[_vm._v(" Cette page vous permet de définir quels conseillers aura accès à vos données parcellaires. Vous pouvez déterminer la période sur laquelle ils pourront accéder à ces données. Vous pouvez à tout moment modifier ces dates ou supprimer l'accès. ")]),_c('h3',[_vm._v("Liste des conseillers")]),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('v-card',[_c('v-data-table',{staticClass:"my-awesome-table elevation-1",attrs:{"loading":!_vm.dataloaded,"loading-text":"Chargement des données....","headers":_vm.headers,"items":_vm.conseillers_agri,"item-key":"","options":_vm.optionsTab,"page":6,"dense":""},on:{"update:options":function($event){_vm.optionsTab=$event}},scopedSlots:_vm._u([{key:"item.conseiller",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nom_conseiller)+" "+_vm._s(item.prenom_conseiller))]}},{key:"item.validation",fn:function(ref){
var item = ref.item;
return [(item.validation_conseiller==null)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-clock-time-four-outline ")])],1):(item.validation_conseiller==true)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(item.date_validation)+" ")],1):_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(item.date_validation)+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.modifyConseiller(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteItem(item,'abo')}}},[_vm._v(" mdi-close-thick ")])]}}],null,true)})],1),_c('v-btn',{staticClass:"mt-2 mb-50 white--text",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.addConseillerPossible();}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus-thick ")]),_vm._v(" Nouveau conseiller ")],1)],1),(_vm.show_conseiller)?_c('v-card',{staticStyle:{"padding":"20px"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{staticStyle:{}},[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Conseiller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.list_conseillers,"item-text":"nom_liste","item-value":"id_conseiller","label":"Conseiller*","data-vv-name":"Conseiller","required":"","error-messages":errors},model:{value:(_vm.infos_conseiller.id_conseiller),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "id_conseiller", $$v)},expression:"infos_conseiller.id_conseiller"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"Date début","rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu_dt_debut",attrs:{"close-on-content-click":false,"return-value":_vm.infos_conseiller.date_debut,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.infos_conseiller, "date_debut", $event)},"update:return-value":function($event){return _vm.$set(_vm.infos_conseiller, "date_debut", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date début*","readonly":"","error-messages":errors[0]},model:{value:(_vm.infos_conseiller.date_debut),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "date_debut", $$v)},expression:"infos_conseiller.date_debut"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.infos_conseiller.date_debut),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "date_debut", $$v)},expression:"infos_conseiller.date_debut"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu_dt_debut.save(_vm.infos_conseiller.date_debut)}}},[_vm._v("OK")])],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"Date fin","rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu_dt_fin",attrs:{"close-on-content-click":false,"return-value":_vm.infos_conseiller.date_fin,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.infos_conseiller, "date_fin", $event)},"update:return-value":function($event){return _vm.$set(_vm.infos_conseiller, "date_fin", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date fin*","readonly":"","error-messages":errors[0]},model:{value:(_vm.infos_conseiller.date_fin),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "date_fin", $$v)},expression:"infos_conseiller.date_fin"}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.infos_conseiller.date_fin),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "date_fin", $$v)},expression:"infos_conseiller.date_fin"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu_dt_fin.save(_vm.infos_conseiller.date_fin)}}},[_vm._v("OK")])],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Service","required":"","readonly":""},model:{value:(_vm.infos_conseiller.service),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "service", $$v)},expression:"infos_conseiller.service"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_vm._v("Je souhaite partager :")]),_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.type_selection_parcelle),callback:function ($$v) {_vm.type_selection_parcelle=$$v},expression:"type_selection_parcelle"}},[_c('v-radio',{attrs:{"label":"Toutes les parcelles actuelles et futures","value":"all"}}),_c('v-radio',{attrs:{"label":"Toutes les parcelles sauf certaines","value":"allExcept"}}),_c('v-radio',{attrs:{"label":"Uniquement quelques parcelles","value":"some"}})],1)],1),(_vm.type_selection_parcelle=='allExcept')?_c('v-col',{attrs:{"cols":"3"}},_vm._l((_vm.listParcelles),function(elem){return _c('v-checkbox',{key:elem.id,attrs:{"dense":"","label":elem.name+' ('+elem.variety+')',"value":elem.id},model:{value:(_vm.infos_conseiller.parcelles_exclues),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "parcelles_exclues", $$v)},expression:"infos_conseiller.parcelles_exclues"}})}),1):_vm._e(),(_vm.type_selection_parcelle=='some')?_c('v-col',{attrs:{"cols":"3"}},_vm._l((_vm.listParcelles),function(elem){return _c('v-checkbox',{key:elem.id,attrs:{"dense":"","label":elem.name+' ('+elem.variety+')',"value":elem.id},model:{value:(_vm.infos_conseiller.parcelles_inclues),callback:function ($$v) {_vm.$set(_vm.infos_conseiller, "parcelles_inclues", $$v)},expression:"infos_conseiller.parcelles_inclues"}})}),1):_vm._e()],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-4 float-right",attrs:{"type":"submit","small":"","disabled":invalid},on:{"click":_vm.saveLienConseiller}},[_vm._v(" Enregistrer ")])],1)],1)]}}],null,false,3510291703)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="reset-page">
        <v-form class="form" v-model="valid" @submit.prevent="register">
          <img src="@/assets/logo-app.png"/>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Nom"
            required
          ></v-text-field>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
           <v-text-field
            :append-icon="see ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="see ? 'text' : 'password'"
            name="input-10-2"
            label="Mot de passe"
            value=""
            class="input-group--focused"
            @click:append="see = !see"
          ></v-text-field>
            <v-btn
            type="submit"
            class="mdp"
            :disabled="!valid"
            @click="register">
            Enregistrer</v-btn>
        </v-form>
      </div>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Le nom est obligatoire',
      ],
      email: '',
      emailRules: [
        v => !!v || 'l\'email est obligatoire',
        v => /.+@.+\..+/.test(v) || 'l\'email n\'est pas valide',
      ],
      see: false,
      password: 'Password',
       rules: {
          required: value => !!value || 'Obligatoire',
        },
      is_admin: null,
    };
  },
  methods: {
    register: function () {
      const data = {
        name: this.name,
        email: this.email,
        password: this.password,
        is_admin: this.is_admin,
      };
      this.$store
        .dispatch('register', data)
        .then(() => this.$router.push('/'))
    },
  },
};
</script>


import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    parcelle: null,
  },
  mutations: {
    setParcelleSelected(state, payload) {
      Vue.set(state, 'parcelle', payload);
    },
    reset(state) {
      Vue.set(state, 'parcelle', null);
    }

  },
  getters: {
    parcelleSelected: state => state.parcelle
  },
  
  actions: {
    setParcelleSelected({commit}, payload) {
      commit('setParcelleSelected', payload);
    },
    reset({commit}) {
      commit('reset');
    }
  }
};
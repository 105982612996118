import store from '@/store/index';
export default {
  // bouchon
  // async login(payload) { //payload
  //   return new Promise((resolve, reject) => {
  //     // Liste user
  //     fetch('./users.json')
  //       .then(response => response.json())
  //       .then(users => {
  //         const user = users.filter( user => user.login === payload.username);
  //         let response = {};
  //         if (user.length == 1) {
  //           response.user= user[0];
  //           response.token= user[0].id;
  //           resolve(response);
  //         }
  //         else
  //         {
  //           reject(401)
  //         }
  //       })
  //       .catch((error) => {
  //         if(error.status) {
  //           reject(error.status);
  //         } else {
  //         // error = error.substr
  //         error = 'errors.services.auth-service-error';
  //         reject(error);
  //       }
  //     })
  //     });
  // }

  // // envoie la réponse si l'utilisateur existe
  async login(payload) { //payload
    return new Promise((resolve, reject) => {
      fetch(`${process.env.VUE_APP_BASE_API}login`, {
       method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw (response);
        }
        resolve(response.json());
      })
      .catch((error) => {
        console.log('error', error);
        if(error.status) {
          reject(error.status);
        } else {
          // error = error.substr
          error = 'errors.services.auth-service-error';
          reject(error);
        }
      });
    });
},

  // // envoie la réponse si l'utilisateur de substitution existe
  async loginInvite(payload) { //payload
    return new Promise((resolve, reject) => {
      fetch(`${process.env.VUE_APP_BASE_API}loginInvite`, {
       method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw (response);
        }
        resolve(response.json());
      })
      .catch((error) => {
        console.log('error', error);
        if(error.status) {
          reject(error.status);
        } else {
          // error = error.substr
          error = 'errors.services.auth-service-error';
          reject(error);
        }
      });
    });
},

async getUser() { //
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_BASE_API}user`, {
     method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw (response);
      }
      resolve(response.json());
    })
    .catch((error) => {
      console.log('error', error);
      if(error.status) {
        reject(error.status);
      } else {
        // error = error.substr
        error = 'errors.services.auth-service-error';
        reject(error);
      }
    });
  });
},

}

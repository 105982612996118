<template>
  <div>
    <v-row fill-height>
      <v-col>
        <menucontextuel :menu="selectMenu()"></menucontextuel>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 11 : 12">
        <TdbMeteoComponent v-if="showTdbMeteoComponent" />
        <StationComponent v-if="showDetailMeteoComponent" />
        <TdbMeteoMFComponent v-if="showTdbMeteoMFComponent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import TdbMeteoComponent from "@/components/station/Tableaudebord.vue";
import StationComponent from "@/components/station/Station.vue";
import TdbMeteoMFComponent from "@/components/station/Tableaudebord_MF.vue";
import Menucontextuel from '../components/Menucontextuel.vue';

export default {
  name: "Meteo",
  components: {
    TdbMeteoComponent,
    StationComponent,
    TdbMeteoMFComponent,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : {
        station:[
          {
              id:1,
              chemin:"/meteo/tableaudebord",
              nom:"Tableau de bord",
              icon: "mdi-format-list-text"
          }/*,
          {
              id:2,
              chemin:"/meteo/station/:id",
              nom:"Détail d'une station",
              icon: "mdi-magnify"
          }*/
        ],
        stationmf:[
          {
              id:1,
              chemin:"/meteo/tableaudebordmf",
              nom:"Tableau de bord",
              icon: "mdi-format-list-text"
          }
        ]
      },
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showTdbMeteoComponent() {
      return this.currentRouteName == "meteo-tdb";
    },
    showDetailMeteoComponent() {
      return this.currentRouteName == "detail-station";
    },
    showTdbMeteoMFComponent() {
      return this.currentRouteName == "meteofrance-tdb";
    }
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    },
    selectMenu(){
      if(this.showTdbMeteoComponent || this.showDetailMeteoComponent){
        return this.menu['station']
      }
      else if(this.showDetailMeteoMFComponent){
        return this.menu['stationmf']
      }
    },
  }
};
</script>

<style>
</style>
export default class Equipment{
  constructor(rawData = {}) {
    this.id = rawData.equipment_id;
    this.site_name = rawData.site_name;
    this.service_name = rawData.service_name;
    this.equipment_type_name = rawData.equipment_type_name;
    this.manufacturer = rawData.manufacturer;
    this.model = rawData.model;
    this.serial_number = rawData.serial_number;
    this.arvalis_code = rawData.arvalis_code;
    this.manufacture_year = rawData.manufacture_year;
    this.purchase_year = rawData.purchase_year;
    this.suspension_year = rawData.suspension_year;
    this.is_bpe = rawData.is_bpe;
    this.person_in_charge = rawData.person_in_charge;
    this.global_state = rawData.global_state;
    this.person_substitute = rawData.person_substitute;
    this.picture_path = rawData.picture_path;
    this.creation_at = rawData.creation_at;
    //this.update_at = rawData.update_at;
    this.update_at ? new Date(this.update_at) : null
    this.login = rawData.login;
    this.period_use = rawData.period_use;
    this.comment = rawData.comment;
    // "period_use": [
    //  {
    //   "period_id": -39592581,
    //   "period_value": "in ea id commodo veniam"
    //  },
    //  {
    //   "period_id": -21502701,
    //   "period_value": "occaecat ali"
    //  }
    //],
  


  }
  s
}

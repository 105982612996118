import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    droitsRecalage: null,
    liste: [],
    listeDepartements: [],
    listeStations: [] ,
    listeSols: [] ,
    listePra: []  
  },

  actions: {
    defineDroitsRecalage({ commit }, payload) {
      commit("defineDroitsRecalage", payload);
    },
    defineListe({ commit }, payload) {
      commit("defineListe", payload);
    },
    defineListeDepartements({ commit }, payload) {
      commit("defineListeDepartements", payload);
    },
    defineListeStations({ commit }, payload) {
      commit("defineListeStations", payload);
    },
    defineListeSols({ commit }, payload) {
      commit("defineListeSols", payload);
    },
    defineListePra({ commit }, payload) {
      commit("defineListePra", payload);
    },
    reset: ({commit}) => {
      commit('reset');
    }
  },
  mutations: {
    defineDroitsRecalage (state, payload) {
      var data = payload.droits
      var droits = new Array()
      
      data.forEach(element => (
        droits.push(element.departement)
      ));
      state.droitsRecalage = droits
    },
    defineListe (state, payload) {
      state.liste = payload.liste
    },
    defineListeDepartements (state, payload) {
      state.listeDepartements = payload.listeDepartements
    },
    defineListeStations (state, payload) {
      state.listeStations = payload.listeStations
    },
    defineListeSols (state, payload) {
      state.listeSols = payload.listeSols
    },
    defineListePra (state, payload) {
      state.listePra = payload.listePra
    },
    reset: (state) => {
      state.droitsRecalage = [];
      state.liste = [];
      state.listeDepartements = [];
      state.listeStations = [];
      state.listeSols = [];
      state.listePra = [];
    }
  },
  getters: {
      DroitsRecalage : (state) => () => {
        return state.droitsRecalage
      },
      ListeVarietes: (state) => () => {
        return state.liste
      },
      ListeVarietesEspece: (state) => (espece) => {
        var liste = state.liste

        liste = liste.filter(data => data.idEspece === espece.toString())

        return liste
      },
      ListeDepartements: (state) => () => {
        var liste = state.listeDepartements
        var result = []

        var optgroup = ''
        liste.forEach(element => {
          if(element.nom_region!=optgroup){
            optgroup = element.nom_region
            result.push({header: element.nom_region})
          }
          result.push({name: element.nom_dept, code: element.code_dept})
        });
        //liste = liste.filter(data => data.espece.toLowerCase() === espece)

        return result
      },
      ListeStations: (state) => () => {
        var liste = state.listeStations
        var result = []

        liste.forEach(element => {
          result.push({name: element.NomComplet+' ('+element.CodeStation+')', code: element.CodeStation})
        });
        //liste = liste.filter(data => data.espece.toLowerCase() === espece)

        return result
      },
      ListeStationsDetaillee: (state) => () => {
        var liste = state.listeStations

        return liste
      },
      ListeSols: (state) => () => {
        var liste = state.listeSols
        var result = []

        liste.forEach(element => {
          result.push({name: element.nom_arvalis+' ('+element.id+')', code: element.id})
        });
        //liste = liste.filter(data => data.espece.toLowerCase() === espece)

        return result
      },
      ListePra: (state) => () => {
        var liste = state.listePra

        var result = []

        liste.forEach(element => {
          result.push({name: element.nom+' ('+element.code_pra+')', code: element.code_pra})
        });

        return result
      }
  }
};
<template>
  <div>
    <v-container style="margin-top: 10px">
      <h3>Liste des conseillers externes</h3>
      <div style="margin-bottom:20px;">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="searchWord"
            :loading="!dataloaded"
            loading-text="Chargement des données...."
            :headers="headers"
            :items="conseillers"
            item-key="id"
            :options.sync="optionsTab"
            :page="6"
            class="my-awesome-table elevation-1"
            dense
          >

            <template v-slot:[`item.conseiller`]="{ item }">{{ item.nom_conseiller }} {{ item.prenom_conseiller }}</template>
            <template v-slot:[`item.detail`]="{ item }">
              {{item.nb_agris}}
              <v-icon v-if="(item.nb_agris_valid+item.nb_agris_refus+item.nb_agris_attente)>0" small class="mr-2" @click="showAgris(item)">
                mdi-eye
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showConseiller(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="confirmDeleteConseiller(item)">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        
        <v-btn small color="info" class="mt-2 mb-50 white--text" @click="show_conseiller=true;selected_conseiller=null">
            <v-icon small class="mr-2">
              mdi-plus-thick
            </v-icon>
            Nouveau conseiller
        </v-btn>
        <v-btn small color="info" class="mt-2 ml-3 mb-50 white--text">
          <download-excel :fetch="getInfosConseillersExport">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </div>
      
      <v-row v-if="show_conseiller">
          <ConseillerDetailComponent :id_conseiller="selected_conseiller"/>
      </v-row>

      <v-row v-if="show_agris">
          <ConseillerAgrisDetailComponent :id_conseiller="selected_conseiller"/>
      </v-row>

    </v-container>

    
  </div>

</template>

<script>
import administrationConseillersService from "@/service/administrationConseillers";
import ConseillerDetailComponent from "@/components/administration/ConseillerDetail.vue";
import ConseillerAgrisDetailComponent from "@/components/administration/ConseillerAgrisDetail.vue";
import downloadExcel from "vue-json-excel";

export default {
  name: "Conseillers",
  components: {
    administrationConseillersService,
    ConseillerDetailComponent,
    ConseillerAgrisDetailComponent,
    downloadExcel
  },
  data() {
    return {
      dataloaded: false,
      filter: null,
      searchWord: "",
      optionsTab: {itemsPerPage: 15,},
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: "Nom", value: "nom_conseiller", align: ' d-none'},
        { text: "Prénom", value: "prenom_conseiller", align: ' d-none'},
        //colonnes visibles
        { text: "", value: "detail", width: '20px'},
        { text: "Id", value: "id_conseiller"},
        { text: "Conseiller", value: "conseiller"},
        { text: "Organisme", value: "organisme" },
        { text: "Type", value: "type_conseiller" },
        { text: "Nb agris validés", value: "nb_agris_valid" },
        { text: "Nb agris refusés", value: "nb_agris_refus" },
        { text: "Nb agris en attente", value: "nb_agris_attente" },
        { text: "", value: "actions", sortable: false },
      ],
      conseillers: [],
      selected_conseiller: null,
      show_conseiller: false,
      show_agris: false,
      delete_id: null
    };
  },
  async mounted() {
    this.conseillers = await administrationConseillersService.getInfosConseillers();
    this.dataloaded = true;
  },
  computed: {
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
  },
  watch: {
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
  },

  methods: {
    async getInfosConseillersExport(){
      return await administrationConseillersService.getInfosConseillersExport();
    },
    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },
    showConseiller(item) {
      this.show_conseiller = true,
      this.selected_conseiller=item.id_conseiller
    },
    showAgris(item) {
      this.show_agris = true,
      this.selected_conseiller=item.id_conseiller
    },
    confirmDeleteConseiller(item){
      this.delete_id = item.id_conseiller
      this.$store.dispatch("choix/setChoix", {
        question: "Etes-vous sûr de vouloir supprimer cet élément?",
        choix: {
          choix1: { text: "Oui", function: "deleteConseiller" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },
    async deleteConseiller(){
        this.closeChoix()
        var isDeleted = null
        isDeleted = await administrationConseillersService.deleteConseiller(this.delete_id);

        if(isDeleted == true){
          this.conseillers = await administrationConseillersService.getInfosConseillers();
        } else {
            alert('Un problème est survenu.')
        }
        this.delete_id = null;
    },
  }
};
</script>

<style>
</style>

<template>
  <div>
    <v-container style="margin-top:20px;">
      <div v-if="!isMobile" id="filtres"><!--FILTRES DESKTOP-->
        <v-row class="mb-5">
          <v-col cols="3">
            <v-select
                v-model="selectedCulture"
                :items="listes"
                item-text="culture"
                menu-props="auto"
                label="Culture"
                hide-details
                prepend-icon="mdi-sprout"
                single-line
                chips
                multiple
                dense
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="selectedDepartement"
                :items="listes"
                item-text="departement"
                menu-props="auto"
                label="Département"
                hide-details
                prepend-icon="mdi-scatter-plot"
                single-line
                chips
                multiple
                dense
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="selectedAgri"
              :items="listes"
              :item-text="getAgriText"
              item-value="code_agri"
              label="Agri"
              prepend-icon="mdi-account"
              single-line
              chips
              multiple
              dense
            />
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col cols="3">
            <v-autocomplete
              v-model="selectedIntegrateur"
              :items="listes"
              item-text="nom_operateur"
              item-value="id_operateur"
              label="Intégrateur"
              prepend-icon="mdi-code-tags"
              single-line
              chips
              multiple
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="selectedDistributeur"
              :items="listes"
              item-text="nom_grpt"
              item-value="id_grpt"
              label="Distributeur"
              prepend-icon="mdi-arrow-decision"
              single-line
              chips
              multiple
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-btn @click="resetFiltres()" class="acces-rapide">
            <v-icon small class="mr-2">
              mdi-rotate-left
            </v-icon>
            Effacer les filtres</v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-15">
          <v-col cols="2">
            <v-card class="info-card">Nb d'agriculteurs :<br><span class="info-text">{{nbAgris}}</span></v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Surface (ha) :<br><span class="info-text">{{surfaceTotale}}</span></v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Nb de parcelles :<br><span class="info-text">{{nbParcelles}}</span></v-card>
          </v-col>
        </v-row> 
      </div><!--FIN FILTRES DESKTOP-->


      <!-- Bouton FILTRER pour mobile -->
      <h3 v-if="isMobile" style="margin-top: 20px; cursor: pointer;" @click="toggleModalFiltre">
        Filtrer <v-icon>mdi-filter-outline</v-icon>
      </h3>
      <!-- Modal pour les FILTRES en MOBILE -->
      <v-dialog v-model="showModalFiltreMobile" max-height="60vh">
        <v-card id="filtres_mobile" style="height:90%">
          <v-card-title>
            <span class="headline">Filtres</span>
          </v-card-title>
          <v-row class="mb-5">
            <v-col cols="5">
              <v-select v-model="selectedCulture" :items="listes" item-text="culture" menu-props="auto" label="Culture"
                hide-details prepend-icon="mdi-sprout" single-line chips multiple dense></v-select>
            </v-col>
            <v-col cols="5">
              <v-select v-model="selectedDepartement" :items="listes" item-text="departement" menu-props="auto"
                label="Département" hide-details prepend-icon="mdi-scatter-plot" single-line chips multiple dense>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="5">
              <v-autocomplete v-model="selectedAgri" :items="listes" :item-text="getAgriText" item-value="code_agri"
                label="Agri" prepend-icon="mdi-account" single-line chips multiple dense />
            </v-col>
            <v-col cols="5">
              <v-autocomplete v-model="selectedIntegrateur" :items="listes" item-text="nom_operateur"
                item-value="id_operateur" label="Intégrateur" prepend-icon="mdi-code-tags" single-line chips multiple
                dense />
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="5">
              <v-autocomplete v-model="selectedDistributeur" :items="listes" item-text="nom_grpt" item-value="id_grpt"
                label="Distributeur" prepend-icon="mdi-arrow-decision" single-line chips multiple dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="1" >
              <v-btn @click="showModalFiltreMobile =!showModalFiltreMobile" color="info" class="h-70 " style=" margin:0.5rem ; padding:1rem ; height: 1rem">
                OK
              </v-btn>
            </v-col>
            <v-col offset="2" cols="5">
              <!-- Bouton pour RESET les filtres-->
              <v-btn @click="resetFiltres()" color="info">
                <v-icon>
                  mdi-rotate-left
                </v-icon><span>Effacer les filtres</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog> <!-- FIN de la MODAL des FILTRES sur MOBILE-->

      <section id="indicators">
        <div v-if="isMobile">
        <v-row class="mt-3">
          <v-col :cols="7">
            <v-card class="info-card">Nb d'agriculteurs :<span style="font-weight:bolder"
                class="info-text">{{nbAgris}}</span></v-card>
            <v-card class="info-card">Surface (ha) :<span style="font-weight:bolder"
                class="info-text">{{surfaceTotale}}</span></v-card>
            <v-card class="info-card">Nb de parcelles :<span style="font-weight:bolder"
                class="info-text">{{nbParcelles}}</span></v-card>
          </v-col>
          <v-col :cols="5">
            <v-btn color="info" @click="showModalMapMobile = true; showCarte = !showCarte">
              <!-- Bouton MAP pour mobile -->
              <v-icon>
                mdi-map-marker
              </v-icon>Carte
            </v-btn>
            <!-- <v-btn @click="showModalRisqueMobile = true ; getIndicateurs()" color="info">
              Résumé . . . 
            </v-btn> -->
            <!-- Bouton pour RESET les filtres-->
            <v-btn @click="resetFiltres()" color="info">
              <v-icon>
                mdi-rotate-left
              </v-icon><span>Effacer les filtres</span>
            </v-btn>

          </v-col>
        </v-row>
        </div>



      </section>


      






















      <br>
      Nombre de parcelles selon épuisement de la RFU
      <v-data-table
          :headers="headers_epuisementRfu"
          :items="tabEpuisementRfu"
          item-key="category"
          class="my-awesome-table"
          :item-class= "rowStyle"
          hide-default-footer
          dense
          fixed-left
          :mobile-breakpoint="0"
        >
        <!--<template v-slot:[`item.SOJA`]="{ item }">-->
        <template v-for="header in headers_epuisementRfu" v-slot:[`item.${header.value}`]="{ item }">
          <div :key="header.value" :class="selectedCell==item.category+header.value?'selectedCell':''" @click="cellClicked(item.category,header.value)">{{ item[header.value] }}</div>
        </template>
        <!--<template v-slot:item="{ item }">
            <tr>
                <td :class="selectedRows.indexOf(item.category)>-1?'cyan':''" @click="rowClicked(item)">{{item.BT}}</td>
                <td>{{item.SOJA}}</td>
                <td>{{item.PDT}}</td>
            </tr>
        </template>-->
        <!--<template v-slot:[`item.SOJA`]="{ item }">
          {{ item.za }}<v-btn
        class="ma-2"
        text
        icon
        color="red lighten-2"
        @click="resetFiltres()"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
        </template>-->
      </v-data-table>
    </v-container>


    <v-container style="margin-top: 10px">
      <h3>Liste des parcelles Bilan hydrique</h3>
      <div v-show="displayResult">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="searchWord"
            :loading="!dataloaded"
            loading-text="Chargement des données...."
            :headers="isMobile ? headers_mobile : headers"
            :items="parcelles_bh"
            item-key="id_info_plot"
            :options.sync="optionsTab"
            :page="6"
            group-by="nom_operateur"
            class="my-awesome-table"
            mobile-breakpoint="0"
            dense
          >

            <template v-slot:[`item.station`]="{ item }">
                <div v-if="item.station!=null">{{ item.station.id_iot }}<br>[{{ item.station.nom_constructeur }} : {{ item.station.code }}]</div>
            </template>
            <template v-slot:[`item.statut_calcul_bh`]="{ item }">
              <v-chip
                :color="getColorStatutBh(item.statut_calcul_bh)"
                dark
              >
                {{ item.statut_calcul_bh }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showItem(item)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="showCommentaire(item)">
                mdi-comment-text-outline
              </v-icon>
            </template>
            <template v-slot:[`item.risque`]="{ item }">
              <div v-if="getRisqueBh(item) == 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }" v-if="item.warnings!='[]'">
                    <v-icon color="red" v-bind="attrs" v-on="on">
                      mdi-water-alert
                    </v-icon>
                  </template>
                  <span style="width:400px;">RFU épuisée</span>
                </v-tooltip>
              </div>
              <div v-else-if="getRisqueBh(item)<=7">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }" v-if="item.warnings!='[]'">
                    <v-icon color="orange" v-bind="attrs" v-on="on">
                      mdi-water-alert
                    </v-icon>
                    {{getRisqueBh(item)}}
                  </template>
                  <span style="width:400px;">Nombre de jours avant épuisement de la RFU</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }" v-if="item.warnings!='[]'">
                    <v-icon color="green" v-bind="attrs" v-on="on">
                      mdi-water-check
                    </v-icon>
                  </template>
                  <span style="width:400px;">Pas de risque d'épuisement de la RFU dans les 7 jours</span>
                </v-tooltip>
              </div>
              <!--<v-icon small class="mr-2" :color="getRisqueBh(item)" v-else>
                mdi-water-alert
              </v-icon>-->
            </template>
            <template v-slot:[`item.agriculteur`]="{ item }">{{ item.nom_agri }} {{ item.prenom_agri }} [{{ item.code_agri }}]</template>
            <template v-slot:[`item.parcelle`]="{ item }">{{ item.nom_parcelle }} [{{ item.code_parcelle }}] [{{ item.id_info_plot }}]</template>
            <template v-slot:[`item.stade_actuel`]="{ item }">{{ BH_noms_stades[item.stade_actuel] }}</template>
          </v-data-table>
        </v-card>
        <v-btn small color="info" class="mt-2 mb-5 white--text">
          <download-excel :fetch="exportParcelles" :fields="excelfields">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
        </v-btn>
        

        <CommentaireComponent :dialog_init="dialog" :id_plot="selected_plot" :oad="oad" v-on:closeCommentaire="closeCommentaire"/>
      </div>
    </v-container>

    <v-dialog id="modal_carte" v-model="showModalMapMobile" max-height="600px"><!--MAP MOBILE-->
      <!--
        https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
        https://vue2-leaflet.netlify.app/examples/custom-icons.html
        -->
        <v-card>
              <v-card-title>
                  <span class="headline">Localisation </span>
                  <v-btn icon @click="showModalMapMobile = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text style="height:90vh">
                <!-- carte  -->
                <l-map
                  :center="carte.center"
                  :zoom="carte.zoom"
                  class="map"
                  ref="map"
                  @update:zoom="zoomUpdated"
                  @update:center="centerUpdated"
                  
                >
                  <l-tile-layer
                    :url="carte.url"
                  >
                  </l-tile-layer>
                  <l-marker
                    v-for="marker in carte.markers"
                    :key="marker.id"
                    :lat-lng="marker.coordinates"
                  >
                    <l-icon 
                      ref="icon"
                      :icon-url="marker.imageUrl"
                    >
                    </l-icon>
                    <l-popup :content="marker.infobulle"/>
                  </l-marker>
                </l-map>
              </v-card-text>
          </v-card>
              
        </v-dialog><!--FIN MODAL de la MAP en MOBILE-->

    <v-container v-if="!isMobile" style="margin-top:20px;"><!--MAP DESKTOP-->
      <!--
        https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
        https://vue2-leaflet.netlify.app/examples/custom-icons.html
        -->
      <l-map
        :center="carte.center"
        :zoom="carte.zoom"
        class="map"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        
      >
        <l-tile-layer
          :url="carte.url"
        >
        </l-tile-layer>
        <l-marker
          v-for="marker in carte.markers"
          :key="marker.id"
          :lat-lng="marker.coordinates"
        >
          <l-icon 
            ref="icon"
            :icon-url="marker.imageUrl"
          >
          </l-icon>
          <l-popup :content="marker.infobulle"/>
        </l-marker>
      </l-map>
    </v-container><!--FIN MAP DESKTOP-->

  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesBhService from "@/service/parcellesBh";
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import CommentairesService from "@/service/commentaires";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { checkIsMobile, getNewDateFromFr } from "@/helpers/functions";


export default {
  name: "Tdb_BH",
  components: {
    downloadExcel,
    parcellesBhService,
    CommentaireComponent,
    CommentairesService,
    checkIsMobile,
    getNewDateFromFr,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      selectedCell: null,
      oad: 'BH',
      selected_plot: null,
      dialog: false,
      dataloaded: false,
      displayResult: true,
      filter: null,
      searchWord: "",
      optionsTab: {itemsPerPage: 15,},
      parcelles_bh: [],
      listes: [],
      selectedCulture: [],
      selectedDepartement: [],
      selectedAgri: [],
      selectedIntegrateur: [],
      selectedDistributeur: [],
      nbAgris: 0,
      nbParcelles: 0,
      surfaceTotale: 0,
      tabEpuisementRfu: [],
      BH_noms_stades:null,
      isMobile: false,
      showModalFiltreMobile: false,
      showModalMapMobile: false,
      showCarte: false,



      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom parcelle', value: 'nom_parcelle', align: ' d-none' },
        { text: 'Code parcelle', value: 'code_parcelle', align: ' d-none' },
        { text: 'Id parcelle', value: 'id_info_plot', align: ' d-none' },
        { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
        { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
        { text: 'Code agri', value: 'code_agri', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, width: '80px' },
        { text: "Intégrateur", value: "nom_operateur"},
        { text: "Distributeur", value: "nom_grpt"},
        { text: "Agriculteur", value: "agriculteur", width: '150px'},
        { text: "Parcelle", value: "parcelle", filterable: true },
        { text: "Département", value: "departement"},
        { text: "Culture", value: "culture" },
        { text: "Calcul", value: "statut_calcul_bh" },
        { text: "", value: "risque", sortable: false, width: '80px' },
        { text: "Date semis", value: "date_semis" },
        { text: "Stade actuel", value: "stade_actuel" },
        { text: "Sol", value: "nom_sol_arvalis", width: '150px'},
        { text: "RU", value: "ru_max" },
        { text: "RFU", value: "rfu_max" },
        { text: "Date calcul", value: "date_calcul_bh" },
        { text: "Surface", value: "surface_irrig" },
        { text: "Station", value: "station" }      
      ],

      headers_mobile: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom parcelle', value: 'nom_parcelle', align: ' d-none' },
        { text: 'Code parcelle', value: 'code_parcelle', align: ' d-none' },
        { text: 'Id parcelle', value: 'id_info_plot', align: ' d-none' },
        { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
        { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
        { text: 'Code agri', value: 'code_agri', align: ' d-none' },
        { text: "Intégrateur", value: "nom_operateur"},
        //colonnes visibles
        { text: "", value: "actions", sortable: false, width: '60px' },
        { text: "Agriculteur", value: "agriculteur"},
        { text: "Parcelle", value: "parcelle", filterable: true },
        { text: "Culture", value: "culture" },
        { text: "Risque", value: "risque", sortable: false, width: '40px' } 
      ],

      headers_epuisementRfu: [],
      excelfields: {
        'Intégrateur':'nom_operateur',
        'Distributeur':'nom_grpt',
        'Nom agriculteur':'nom_agri',
        'Prénom agriculteur':'prenom_agri',
        'Code agriculteur':'code_agri',
        'Id agriculteur':'id_agri',
        'Nom parcelle':'nom_parcelle',
        'Code parcelle':'code_parcelle',
        'Id parcelle':'id_info_plot',
        'Culture':'culture',
        'Département':'departement',
        'Date semis':'date_semis',
        'Sol':'nom_sol_arvalis',
        'RU':'ru_max',
        'RFU':'rfu_max',
        'Somme irrigation':'somme_irrig',
        'Epuisement RFU (%)':'epuisement_rfu',
        'Surface':'surface_irrig',
        'Latitude':'latitude',
        'Longitude':'longitude',
        'Précédent':'precedent',
        'Irrigation précédent':'irrig_precedent',
        'Date création':'p_dt_crea',
        'Stade actuel':'stade_actuel',
        'Stade actuel date':'date_stade_actuel',
        'Stade suivant':'stade_suivant',
        'Stade suivant date':'date_stade_suivant',
        'Stades':'stades'
      },
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },
    };
  },
  async mounted() {

   // Vérifier la taille de l'écran une fois la page chargée
   this.isMobile = checkIsMobile();

    // Ecouteur d'événement pour surveiller les changements de taille de fenêtre
    //window.addEventListener('resize', this.checkIsMobile);

    fetch('./BH_noms_stades.json').then(response => response.json())
            .then((data) => this.BH_noms_stades = data);

    this.listes = await parcellesBhService.getListes();
    this.$store.commit('bilanhydrique/defineListes', {liste:this.listes})

    //this.filterListes()

    this.parcelles_bh = await parcellesBhService.getInfosParcelles();
    this.$store.commit('bilanhydrique/defineParcelles', {liste:this.parcelles_bh})
    this.parcelles_bh = this.$store.getters['bilanhydrique/FilterParcelles'](this.selectedCulture,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,null)

    this.dataloaded = true;
  },

  watch: {
    selectedCulture: function(val) {
      //this.filterListes()
      this.filterParcelles()
    },
    selectedDepartement: function() {
      //this.filterListes()
      this.filterParcelles()
    },
    selectedAgri: function() {
      //this.filterListes()
      this.filterParcelles()
    },
    selectedIntegrateur: function() {
      //this.filterListes()
      this.filterParcelles()
    },
    selectedDistributeur: function() {
      //this.filterListes()
      this.filterParcelles()
    },
    parcelles_bh: function() {
      this.getIndicateurs()
      this.getPositionCarte()
      this.getTabEpuisementRfu()
    },
  },


  methods: {
    exportParcelles (){
      var now = new Date()
      var parcelles = this.parcelles_bh;
      parcelles.forEach(element => {
        var stade_actuel = ''
        var date_stade_actuel = ''
        var stade_suivant = ''
        var date_stade_suivant = ''
        var concat_stades = new Array()
        var stades = JSON.parse(element.stades_calc);
        for (let i = 0; i < stades.length; i++) {
          var nom_stade = this.BH_noms_stades[stades[i].code_arvalis]
          concat_stades.push(nom_stade+':'+stades[i].date)
          var date_stade = getNewDateFromFr(stades[i].date)
          if(now >= date_stade){
            stade_actuel = nom_stade
            date_stade_actuel = stades[i].date
          }
          if(now < date_stade && stade_suivant == ''){
            stade_suivant = nom_stade
            date_stade_suivant = stades[i].date
          }
        }
        element.stade_actuel = stade_actuel
        element.date_stade_actuel = date_stade_actuel
        element.stade_suivant = stade_suivant
        element.date_stade_suivant = date_stade_suivant
        element.stades = concat_stades.join('|')
      });

      return parcelles

    },
    /*async filterListes(){
      this.listes = this.$store.getters['bilanhydrique/FilterListes'](this.selectedCulture,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur)
    },*/


    updateDisplayedHeaders() {
      this.checkIsMobile(); // Vérifie si l'écran est mobile
      // Définir les headers à afficher en fonction de la taille de l'écran
      this.displayedHeaders = this.isMobile ? this.headers_plots_mobile : this.headers;
    },

    toggleModalFiltre() {
    this.showModalFiltreMobile = !this.showModalFiltreMobile;
  },

    async filterParcelles(){
      this.parcelles_bh = this.$store.getters['bilanhydrique/FilterParcelles'](this.selectedCulture,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,null)
    },
    getAgriText(item) {
      var text = '['+item.code_agri+']'
      if(item.nom_agri!=null){
        text = item.nom_agri+' '+item.prenom_agri+' '+text
      }
      return text;
    },
    resetFiltres(){
      this.selectedCulture = [],
      this.selectedDepartement = [],
      this.selectedAgri = [],
      this.selectedIntegrateur = [],
      this.selectedDistributeur = [],
      this.selectedCell = null
    },
    getIndicateurs(){
      const uniqueArr = [... new Set(this.parcelles_bh.map(data => data.code_agri))]
      this.nbAgris = uniqueArr.length

      this.nbParcelles = this.parcelles_bh.length
      
      this.surfaceTotale = Math.round(this.parcelles_bh.reduce((sum, data) => sum + parseFloat(data.surface_irrig), 0),2)
    },
    getPositionCarte(){
      //markers sur carte
      var array = []
      this.parcelles_bh.forEach(element => {
        if(element['latitude']!=null && element['longitude']!=null){
          var agri = '['+element['code_agri']+']'
          if(element['nom_agri']!=''){
            agri = element['nom_agri']+' '+element['prenom_agri']+' '+agri
          }
          var parcelle = '['+element['code_parcelle']+']['+element['id_info_plot']+']'
          if(element['nom_parcelle']!=null){
            parcelle = element['nom_parcelle']+' '+parcelle
          }
          var epuisement_rfu = this.getRisqueBh(element);
          if(epuisement_rfu>7){
            epuisement_rfu = '>7'
          }
          array.push({
            id:element['id'], 
            coordinates:[element['latitude'],element['longitude']], 
            imageUrl:require('@/assets/map_icon_'+this.colorCulture(element['culture'])+'.png'), 
            infobulle:'<a href="'+this.showItem(element,'href')+'" target="_blank">Voir le détail de la parcelle</a><br>Parcelle: '+parcelle+'<br>Culture: '+element['culture']+'<br>Date semis: '+element['date_semis']+'<br>Stade actuel: '+this.BH_noms_stades[element['stade_actuel']]+'<br>Sol: '+element['nom_sol_arvalis']+'<br>RU max - RFU max : '+element['ru_max']+' - '+element['rfu_max']+'<br>Intégrateur: '+element['nom_operateur']+'<br>Distributeur: '+element['nom_grpt']+'<br>Agriculteur: '+agri+'<br>Nb jours avant épuisement RFU: '+epuisement_rfu+'<br>Dose totale d\'irrigation: '+element['somme_irrig']})
        }
      });
      
      this.carte.markers = array
    },
    getTabEpuisementRfu(){
      var tab = {hors_bh: {category: 'Hors bilan', total:0},sup70: {category: '>70%', total:0},intermediaire4070: {category: 'Entre 40 et 70%', total:0},inf40: {category: '<40%', total:0},total_culture: {category: 'Total', total:0}};
      this.parcelles_bh.forEach(element => {

        (tab['total_culture'][element['culture']]!=undefined)? (tab['total_culture'][element['culture']]+= 1) : (tab['total_culture'][element['culture']]) = 1;
        tab['total_culture']['total'] += 1

        if(element['epuisement_rfu']==null){
          (tab['hors_bh'][element['culture']]!=undefined)? (tab['hors_bh'][element['culture']]+= 1) : (tab['hors_bh'][element['culture']]) = 1;
          tab['hors_bh']['total'] += 1
        } else if (element['epuisement_rfu']>70){
          (tab['sup70'][element['culture']]!=undefined)? (tab['sup70'][element['culture']]+= 1) : (tab['sup70'][element['culture']]) = 1;
          tab['sup70']['total'] += 1
        } else if (element['epuisement_rfu']<40){
          (tab['inf40'][element['culture']]!=undefined)? (tab['inf40'][element['culture']]+= 1) : (tab['inf40'][element['culture']]) = 1;
          tab['inf40']['total'] += 1
        } else {
          (tab['intermediaire4070'][element['culture']]!=undefined)? (tab['intermediaire4070'][element['culture']]+= 1) : (tab['intermediaire4070'][element['culture']]) = 1;
          tab['intermediaire4070']['total'] += 1
        }
      })

      var tab2 = [tab['hors_bh'],tab['sup70'],tab['intermediaire4070'],tab['inf40'],tab['total_culture']]

      this.tabEpuisementRfu = tab2

      var cultures = this.parcelles_bh.map(item => item.culture)
                      .filter((value, index, self) => self.indexOf(value) === index)
      this.headers_epuisementRfu = [{ text: "", value: "category", filterable: true, width: '120px' }]
      cultures.forEach(element => {
        this.headers_epuisementRfu.push({ text: element, value: element, width: '80px'})
      })
      this.headers_epuisementRfu.push({ text: "TOTAL", value: "total", filterable: true, width: '80px'})
    },
    //suprression de :item-class="row_classes" dans v-data-table
    /*row_classes(item) {
      const selectedParcelle = (this.selectedParcelle = this.$store.getters[
        "parcelle/parcelleSelected"
      ]);

      if (
        this.selectedParcelle !== null &&
        item.id === this.selectedParcelle.id
      ) {
        return "selectedParcelleRow"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },*/
    showItem(parcelle, type=null) {
      // Stockage de la parcelle sélectionnée dans le store
      //this.$store.dispatch("parcelle/setParcelleSelected", parcelle);
      /*this.$router
        .push("/bilanhydrique/parcelle/" + parcelle.id_info_plot)
        .catch(err => {
          console.log("err", err);
        });*/

      /*let route = this.$router
        .resolve({ name: 'bh-detail-parcelle', params: { id: parcelle.id_info_plot } });
      window.open(route.href);*/

      let route = this.$router
        .resolve({ name: 'bh-detail-parcelle', params: { id: parcelle.id_info_plot } });

      if(type=='href'){
        return route.href;
      } else {
        window.open(route.href);
      }


    },
    showCommentaire(item) {
      this.selected_plot=item.id_info_plot
      this.dialog=true
    },
    closeCommentaire() {
      this.dialog=false
      this.selected_plot=null
    },
    getColorStatutBh (statut) {
        if (statut == 'ok') return 'green'
        else if (statut == 'ko') return 'red'
        else return 'orange'
    },
    getRisqueBh (item) {
      var nbjours = 100
      if(item.deficitj>=1){
        nbjours = 0
      } else {
        for (let i = 7; i >=1; i--) {
          if(item['deficitj'+i]>=1){
            nbjours = i
          }
        }
      }
      return nbjours   
    },
    colorCulture(culture){
      var tabColor = {MAIS_FOURRAGE: 'blue', MAIS_GRAIN: 'blue', MAIS_SEMENCE: 'blue2', MAIS_DOUX: 'blue3', PDT: 'green', BT: 'orange', BD: 'orange', OP: 'orange', SOJA: 'pink', HARICOT: 'green3', EPINARD: 'green2', SORGHO: 'brown'}
      if(tabColor[culture] != undefined){
        return tabColor[culture]  
      }
      return 'grey'
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    rowStyle(item) {
      if (item.category == 'Total') {
        return ["total-line"];
        //return "orange"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.category == '<40%') {
        return ["green-line"];
      } else if (item.category == '>70%') {
        return ["red-line"];
      } else if (item.category == 'Entre 40 et 70%') {
        return ["orange-line"];
      } else {
        return ["grey-line"];
      }
    },
    cellClicked(category,culture) {
      if(this.selectedCell==null){
        this.selectedCell = category+culture
        if(culture=='total'){
          culture = null
        } else {
          culture = [culture]
        }
        this.parcelles_bh = this.$store.getters['bilanhydrique/FilterParcelles'](culture,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,category)
      } else {
        this.selectedCell = null
        this.parcelles_bh = this.$store.getters['bilanhydrique/FilterParcelles'](this.selectedCulture,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,null)
      }
    },
  }
};
</script>

<style scoped>
  .input {
    margin: 5px;
  }
 .map {
    height: 500px !important;
    width: 600px !important;
    position: relative !important;
    z-index:0;
 }
 .leaflet-container {
    height: 500px;
    width: 600px;
  }
 .leaflet-pane {
    z-index: 0
 }
 .selectedCell{
   background-color: aquamarine;
 }
 #indicators .info-card {
  font-size: 0.75rem;
  height: auto;
}


 /* EN MOBILE  */
@media only screen and (max-width: 960px) {

  .map{
    width:100% !important;
  }

  #indicators div.info-card {
    min-height: 1rem;
      height: auto !important;
      text-align: left;
      margin: 0.4rem;
      padding:5px !important;
  }
  #indicators .info-card {
    min-height: 1rem;
      height: auto !important;
      text-align: left;
      margin:0.4rem;
      padding:5px !important;
  }

  #indicators button {
    height: 42px;
    width: 90%;
    margin: 0.4rem;
  }

}


</style>

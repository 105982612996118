<template>
  <div>
    <v-container style="margin-top: 10px">

      <!--CALCULS MILDIOU-->
      <h3 v-if="checkDroits('ADMIN','lecture')" v-on:click="showCalculsPlot = !showCalculsPlot">
        Calculs (Admin)
        <v-icon small class="mr-2" v-if="!showCalculsPlot">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div style="margin:20px 0px;" v-if="showCalculsPlot">
        <v-row>
          <v-col offset="1" cols="11">
            <v-btn small color="info" class="mt-2 white--text" @click="launchCalculsMildiou()">
              Lancer calculs
              <v-icon small class="mr-2">
                mdi-code-json
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <br>
        <div v-if="data_calcul" class="double_accordion">
          <div v-for="(section, index) in section_data" :key="index" class="first_lvl_accordion">
            <div class="double_accordion_header" @click="toggleFirstLvlData(index)">
              {{ section.title }}
            </div>
            <div v-if="section.isExpanded" class="double_accordion_content">
              <div v-for="(subSection, subIndex) in section.subSections" :key="subIndex"
                class="double_accordion_subsection">
                <div class="double_accordion_subheader" @click="toggleSubLvlData(index, subIndex)">
                  {{ subSection.title }}
                </div>
                <div v-if="subSection.isExpanded" class="double_accordion_subcontent">
                  <v-data-table v-if="subSection.need_v_data_table" :headers="subSection.headers_data"
                    :items="subSection.items_data">
                  </v-data-table>
                  <div v-if="!subSection.need_v_data_table">
                    <p v-for="(paragraph, index) in  subSection.content" :key="index">
                      {{ paragraph }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data_calcul" class="double_accordion">
          <div v-for="(section, index) in section_calculs" :key="index" class="first_lvl_accordion">
            <div class="double_accordion_header" @click="toggleFirstLvlCalcul(index)">
              {{ section.title }}
            </div>
            <div v-if="section.isExpanded" class="double_accordion_content">
              <div v-for="(subSection, subIndex) in section.subSections" :key="subIndex"
                class="double_accordion_subsection">
                <div class="double_accordion_subheader" @click="toggleSubLvlCalcul(index, subIndex)">
                  {{ subSection.title }}
                </div>
                <div v-if="subSection.isExpanded" class="double_accordion_subcontent">
                  <v-data-table v-if="subSection.need_v_data_table" :headers="subSection.headers_data"
                    :items="subSection.items_data">
                  </v-data-table>
                  <div v-if="!subSection.need_v_data_table">
                    <p v-for="(paragraph, index) in  subSection.content" :key="index">
                      {{ paragraph }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!--FIN CALCULS MILDIOU-->
      
      <section v-if="isMobile">  <!--CARACTERISTIQUE PARCELLAIRE en MOBILE -->
        <h3 style="margin-top:10px;"> Parcelle </h3>
        <v-card class="mt-5 pl-5 pt-2 pb-2">
          <v-row>
            <v-col :cols="9">
          <v-icon small class="mr-2">
            mdi-lightbulb-on-outline
          </v-icon>
          Préconisation
          <div class="parcelle-card">
            <div v-if="parcelle!=null"><span :style="getStylePreconisation()" v-html="getPreconisation()"></span>
            </div>
          </div>
            </v-col>
              <v-col :cols="2">
              <v-btn color="info" @click="showModalMapMobile = true ; getPositionCarte(); showCarte = !showCarte">
              <!-- Bouton MAP pour mobile -->
     
                <v-icon>
                mdi-map-marker
              </v-icon>Carte
            </v-btn>

            </v-col>
          </v-row>
          <br>
          <v-icon small class="mr-2">
            mdi mdi-calculator-variant-outline
          </v-icon>
          Calculs
          <div>
            Situation des calculs : {{parcelle.statut_msg}}
          </div>
          <br>
          <div class="parcelle-card">
            <div><span class="parcelle-card-title">Parcelle :</span><span
                class="parcelle-card-val">{{parcelle.plot_name}} [{{parcelle.id}}]</span></div>
            <div><span class="parcelle-card-title">Agriculteur :</span><span class="parcelle-card-val">{{parcelle.nom_agri}}
                {{parcelle.prenom_agri}}
                [{{parcelle.farmer}}]</span></div>
            <div><span class="parcelle-card-title">Raison sociale :</span><span
                class="parcelle-card-val">{{parcelle.raison_sociale}} </span></div>
            <div><span class="parcelle-card-title">Commune (code postal) :</span><span class="parcelle-card-val">
                {{parcelle.city}} ({{parcelle.zipcode}})</span></div>
            <div><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val">
                {{parcelle.variete}}</span></div>
            <div><span class="parcelle-card-title">Intégrateur :</span><span
                class="parcelle-card-val">{{parcelle.nom_integrateur}}</span></div>
            <div><span class="parcelle-card-title">Distributeur :</span><span
                class="parcelle-card-val">{{parcelle.distributeur}}</span></div>
            <div><span class="parcelle-card-title">Surface (ha) :</span><span class="parcelle-card-val">
                {{parcelle.area}}</span></div>
          </div>
          <br>
          <v-btn @click="showModalIndicesMaladiesJournaliers = true ;" style=" margin: 0.4rem;" color="info">
            Indices Maladies Journaliers
          </v-btn><!-- Bouton pour INDICES MALADIES JOURNALIERS-->

          <v-btn @click="launchCalculsMildiouForIndicesHoraires() ; showModalIndicesMaladiesHoraires = true  " style=" margin: 0.4rem;" color="info">
            Indices maladies horaires
          </v-btn><!-- Bouton pour INDICES MALADIES HORAIRES-->
        </v-card>
        <div v-if="isMobile">
          <!-- Modal pour le INDICES MALADIES JOURNALIERS en MOBILE-->
          <v-dialog v-model="showModalIndicesMaladiesJournaliers" max-height="90vh" height="90vh">
            <v-card>
              <v-card-title>
                <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                  <span>Données prévisionnelles</span>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
                  hide-details></v-text-field>  <v-btn icon @click="showModalIndicesMaladiesJournaliers = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                :headers="headers_mobile" :items="parcelle_indices" item-key="index" :options.sync="optionsTab"
                :sort-by="sortByTab" :sort-desc="sortDescTab" class="my-awesome-table elevation-1"
                :item-class="rowStyle"  dense >

                <template v-slot:[`item.risque`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
                        {{getIconRisque(item.risque)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoRisque(item.risque)"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.parcelleprotegee`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs"
                        v-on="on">
                        {{getIconProtection(item.parcelleprotegee)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="showItem(item)">
                    mdi-eye
                  </v-icon>
                </template>
                <template
                  v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
                <template
                  v-slot:[`item.resistance_lessivage`]="{ item }">{{ getTexteLessivage(item.resistance_lessivage) }}</template>
              </v-data-table>
            </v-card>
              <v-btn small color="info" class="mt-2 white--text">
              <download-excel :data="parcelle_indices" :name="getNomFichier('day')" :fields="excelfields">
                Export Excel
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </download-excel>
            </v-btn>
          </v-dialog>
        </div><!--FIN des INDICES en MOBILE-->
        
        <div v-if="isMobile">
          <!-- Modal pour le INDICES MALADIES HORAIRES en MOBILE -->
          <v-dialog v-model="showModalIndicesMaladiesHoraires" max-height="90vh">
          
            <v-card>
              <v-card-title>
                <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                  <span>Données prévisionnelles</span>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
                hide-details></v-text-field>
                  <v-btn icon @click="showModalIndicesMaladiesHoraires = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-data-table :loading="!dataloaded" loading-text="Chargement des données...."
              :search="searchWord" :headers="headersHoraires" :items="parcelle_indices_horaires" item-key="index"
                :sort-by="['datetime']" :sort-desc="sortDescTabHoraires" class="my-awesome-table elevation-1"
                :item-class="rowStyle" :items-per-page="24"  :footer-props="{'items-per-page-options': [24, 50, 75, 100]}" dense>
                <template v-slot:[`item.risque`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
                        {{getIconRisque(item.risque)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoRisque(item.risque)"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.parcelleprotegee`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs"
                        v-on="on">
                        {{getIconProtection(item.parcelleprotegee)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="showItem(item)">
                    mdi-eye
                  </v-icon>
                </template>
                <template
                  v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
              </v-data-table>
            </v-card>
              <v-btn small color="info" class="mt-2 white--text">
              <download-excel :data="parcelle_indices_horaires" :name="getNomFichier('hour')"
                :fields="excelfieldsHoraires">
                Export Excel
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </download-excel>
            </v-btn>
          </v-dialog>
        </div><!--FIN des INDICES en MOBILE-->

      </section><!-- FIN - PARCELLAIRE en MOBILE  -->

        <!-- Modal pour la carte -->
        <v-dialog id="modal_carte" v-model="showModalMapMobile" max-height="96vh">
          <v-card>
              <v-card-title>
                  <span class="headline">Localisation de la parcelle</span><v-btn icon @click="showModalMapMobile = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text style="height:90vh">
                  <!-- carte  -->
                  <div style="margin-top:20px;" v-if="showCarte">
                <v-row>
                  <div style ="height:95% ; width:95%;margin:0 auto" >
                    <l-map
                      :center="carte.center" :zoom="carte.zoom" class="map_mobile" ref="map" @update:zoom="zoomUpdated" @update:center="centerUpdated"
                    >
                      <l-tile-layer
                        :url="carte.url"
                      >
                      </l-tile-layer>
                      <l-marker
                        v-for="marker in carte.markers"
                        :key="marker.id"
                        :lat-lng="marker.coordinates"
                      >
                        <l-icon 
                          ref="icon"
                          :icon-url="marker.imageUrl"
                        >
                        </l-icon>
                        <l-popup :content="marker.infobulle"/>
                      </l-marker>
                    </l-map>
                  </div>
                </v-row>
              </div>
              </v-card-text>
          </v-card>
        </v-dialog><!--FIN MODAL de la MAP en MOBILE-->
      
      <section v-if="isMobile"> <!-- station meteo en MOBILE  -->
        <h3 style="margin-top:10px;">
          <v-icon  small class="mr-2 icon_h3">
            mdi-calendar-text-outline
          </v-icon>Météo
        </h3>
        <div class="parcelle-card">
            <v-card v-if="(this.id_station_in_this_parcelle != null && this.id_station_in_this_parcelle != '')" class="pl-5 pt-2 pb-2">
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Nom de la station :</span><span
                    class="parcelle-card-val">{{station.device_name}}</span>
                </div>
                <div><span class="parcelle-card-title">Code station Arvalis :</span><span
                    class="parcelle-card-val">{{station.id}}</span>
                </div>
                <div><span class="parcelle-card-title">Dernière réception météo (UTC) :</span> {{station.last_data}}
                  <span class="parcelle-card-val"></span></div>
                  <div v-if="checkDroits('METEO','lecture')">
                    <v-btn @click="showItem(id_station_in_this_parcelle)" class="acces-rapide">
                      <v-icon small class="mr-2">
                        mdi-eye
                      </v-icon>
                      Voir la station
                    </v-btn>
                  </div>
              </div>
            </v-card>
        </div>
        <v-row id='donnees_meteo'>
            <v-col :class="isMobile ? 'cols-12' : 'cols-8 mx-auto'">
              <v-chart style="height:250px" :option="chartOptionsDMHeatMap" />
            </v-col>
          </v-row>
          <v-btn color="info" style="margin: 0.4rem;" @click="chargerIndicesClimatiques()">
            <span v-if="!loading">Indices climatiques</span>
            <span v-else>
              <v-icon>mdi-loading</v-icon>
              Chargement en cours...
            </span>
          </v-btn>
          <br>
            <!-- Modal pour le INDICES CLIMATIQUES en MOBILE-->
            <v-dialog v-model="showModalIndicesClimatiqueMobile" max-height="90vh" height="90vh">
              <v-card>
                <v-card-title>
                  <span>Indices Climatiques</span>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchWordIndice" append-icon="mdi-magnify"
                    label="Recherche / date" single-line hide-details></v-text-field> <v-btn icon @click="showModalIndicesClimatiqueMobile = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table id="indices_meteo_tableau" mobile-breakpoint="0" :headers="headers_temp_hum_heure_risk"
                  :items="indices_meteo_risk_data_table" class="elevation-1"
                  :items-per-page="24"  :footer-props="{'items-per-page-options': [24, 50, 75, 100]}" dense :sort-by="sortByIndDate" :sort-desc="sortDescInd">
                  <template v-slot:item="{ item }">
                    <tr :key="item.date">
                      <td>{{ formatDateTime(item.date) }}</td>
                      <td :class="getHumClass(item.indice_hum)" :title="getHumTooltip(item.indice_hum)">
                        {{ item.description_hum || '-' }}</td>
                      <td :class="getTempClass(item.indice_temp)" :title="getTempTooltip(item.indice_temp)">
                        {{ item.description_temp || '-' }}</td>
                      <td>{{ item.indexconta }}</td>
                      <td>
                        <v-icon small class="mr-2" :color="getColorRisque(item.risque)"
                          :title="getInfoRisqueTooltip(item.risque)">
                          {{ getIconRisqueIndiceMeteo(item.risque) }}
                        </v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
                <v-btn small color="info" class="mt-2 white--text">
                  <download-excel :data="indices_meteo_risk_data_table" :name="getNomFichier('day')"
                    :fields="excelfields_temp_hum_heure_risk">
                    Export Excel
                    <v-icon small class="mr-2">
                      mdi-download
                    </v-icon>
                  </download-excel>
                </v-btn>
              
            </v-dialog><br>
      </section><!-- FIN - STATION METEO en MOBILE  -->

        <v-dialog v-model="showModalAddStades" max-height="90vh">
          <v-card>
              <v-card-title>
                <span class="headline">Ajout de stades </span>
                <v-btn icon @click="showModalAddStades = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text style="height:auto ; margin: 0 auto">
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form @submit.prevent="submitFormAddStade" style="display: flex;
                      flex-direction: column;
                      flex-wrap: nowrap;
                      justify-content: space-between;">
                    
                    <div style="margin-bottom: 15px;">
                      <label>Plantation :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.plantation" >
                    </div>

                    <div style="margin-bottom: 15px;">
                      <label>Levée à 30% :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.levee30" >
                    </div>

                    <div style="margin-bottom: 15px;">
                      <label>Croissance active :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.croissanceactive" >
                    </div>

                    <div style="margin-bottom: 15px;">
                      <label>Végétation stabilisée :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.croissancestabilisee" >
                    </div>
                    <div style="margin-bottom: 15px;">
                      <label>Début de sénescence :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.debutsenescence" >
                    </div>
                    <div style="margin-bottom: 15px;">
                      <label>Défanage :</label>
                      <input class="input_field" type="date" v-model="parcelle_stades.defanage" >
                    </div>      
                    <div style="margin-bottom: 15px;">
                        <label for="form_parcelle_stades">ID de la parcelle observée (ID : {{parcelle.id}})</label><br>
                        <input type="text" id="form_parcelle_stades" v-model="form_parcelle_stades" required class="input_field">
                    </div>
                    <button type="submit" :disabled="invalid" class="submit-button">Enregistrer</button>
                    
                  </form>
                </validation-observer>
              </v-card-text>

          </v-card>
        </v-dialog><!--FIN MODAL de l'ajout de stades -->


      <v-row v-if="isMobile">
        <!-- STADES TRAITEMENTS IRRIGATIONS OBSERVATIONS -->
        <v-col :cols="12">
          <h3>  
            <v-icon small class="mr-2 icon_h3">
                  mdi-calendar-text-outline
                </v-icon>   
              Stades
            </h3>
              <v-card class="pl-5 pt-2 pb-2">
                <div class="parcelle-card">
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.plantation") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.plantation}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.levee30") }} :</span><span
                      class="parcelle-card-val">{{parcelle_stades.levee30}}</span></div>

                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.croissanceactive") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.croissanceactive}}</span></div>

                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.croissancestabilisee") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.croissancestabilisee}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.debutsenescence") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.debutsenescence}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.defanage") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.defanage}}</span></div>
                </div>
                  <v-btn v-if="isMyPlot" @click="GoPageGestionParcelle('stade')"  class="add_button pl-5 add_intervention">
              +
                </v-btn>
              </v-card>
            </v-col>
        <!-- Modal pour l'ajout de stades -->
        <v-col :cols="12">
          <h3>     
            <v-icon small class="mr-2 icon_h3">
                mdi-tractor
              </v-icon>
              Traitements
            </h3>
          <v-card class="mt-5 pt-2 pb-2" id='traitements'>
            <div v-if="parcelle!=null && parcelle.traitements!=null">
                <template>
                  <v-simple-table fixed-header height="200px"
                    style="width:100%">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Date (UTC)</th>
                          <th class="text-left">Produit</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Dose</th>
                          <th class="text-left">Rémanence (j)</th>
                          <th class="text-left">Rémanence modifiée</th>
                          <th class="text-left">Lessivage (mm)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="trait in sortTab(JSON.parse(parcelle.traitements),'treated_at')"
                          :key="trait.treated_at">
                          <td style="height:12px">{{ getDateHeure(trait.treated_at) }}</td>
                          <td style="height:12px">{{ trait.product_name }}</td>
                          <td style="height:12px">{{ trait.product_type }}</td>
                          <td style="height:12px">{{ trait.product_dose }}{{trait.dose_unit}}</td>
                          <td style="height:12px">{{ trait.standard_persistence }}</td>
                          <td style="height:12px">{{ trait.modified_persistence  }}</td>
                          <td style="height:12px">{{ trait.washout_resistance }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>
              <v-btn v-if="isMyPlot" @click="GoPageGestionParcelle('traitement')" class="add_button pl-5 add_intervention">
              +
            </v-btn>

            <br><br>
            <p style=" font-weight:bolder; font-size: 16px !important;">Traitement(s) évité(s)</p>
          </v-card>
        </v-col>
        <v-col :cols="12">        
          <h3 style="margin-top:10px;">
              <v-icon small class="mr-2  icon_h3">
                mdi-water
              </v-icon>
              Irrigations<v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>L’irrigation impacte les indices de risque mildiou pour chaque jour où elle est présente de 19h à
                00h J+1 (UTC), soit de 21h à 2h J+1 (heure locale en France métropolitaine) : 6 heures de risque sont
                additionnées sur cette période.</span>
            </v-tooltip>
            </h3>            
          <v-card class="mt-5 pt-2 pb-2 pl-5">
            <div  v-if="parcelle!=null && parcelle.irrigations!=null">
              <template>
                <v-simple-table fixed-header :height="isMobile ? 'auto' : '180px'" style="width:80% ;margin: 0 auto">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Date debut (UTC)
                        </th>
                        <th class="text-left">
                          Date fin (UTC)
                        </th>
                        <th class="text-left">
                          Quantité (mm)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="irrig in sortTab(JSON.parse(parcelle.irrigations),'begin_at')" :key="irrig.begin_at">
                        <td style="height:12px">{{ getDateHeure(irrig.begin_at) }}</td>
                        <td style="height:12px">{{ getDateHeure(irrig.end_at) }}</td>
                        <td style="height:12px">{{ irrig.water_dose }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </div>
            <v-btn v-if="isMyPlot" class="add_button pl-5 add_intervention" @click="GoPageGestionParcelle('irrigation')"> + </v-btn>
          </v-card>
        </v-col>  
        <v-col :cols="12">  <h3 style="margin-top:10px;">
              <v-icon small class="mr-2 icon_h3">
                mdi-eye
              </v-icon>
              Observations
            </h3>
          <v-card class="mt-5 pt-2 pb-2 pl-5">
            <div style="text-align:center" v-if="parcelle!=null && parcelle.observations!=null && JSON.parse(parcelle.observations).length>0">
              Dates d'observation de mildiou dans la parcelle :
              <div v-for="date in JSON.parse(parcelle.observations)" :key="date">{{date}}</div>
            </div>
            <div v-else>
              Aucune observation
            </div>
            <v-btn v-if="isMyPlot" class="add_button pl-5 add_intervention" @click="GoPageGestionParcelle('observation')"> + </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <!--FIN TRAITEMENTS IRRIGATIONS OBSERVATIONS -->

      <h3 v-if="!isMobile" v-on:click="showDetailParcelle = !showDetailParcelle">
        Parcelle
        <v-icon small class="mr-2" v-if="!showDetailParcelle">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div style="margin-top:20px;" v-if="showDetailParcelle &&!isMobile">
        <v-row>
          <v-col cols="6">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              INFORMATIONS PARCELLAIRES
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Agri :</span><span
                    class="parcelle-card-val">{{parcelle.nom_agri}} {{parcelle.prenom_agri}}
                    [{{parcelle.farmer}}]</span></div>
                <div><span class="parcelle-card-title">Raison sociale :</span><span
                    class="parcelle-card-val">{{parcelle.raison_sociale}} </span></div>
                <div><span class="parcelle-card-title">Intégrateur :</span><span
                    class="parcelle-card-val">{{parcelle.nom_integrateur}}</span></div>
                <div><span class="parcelle-card-title">Distributeur :</span><span
                    class="parcelle-card-val">{{parcelle.distributeur}}</span></div>
                <div><span class="parcelle-card-title">Parcelle :</span><span
                    class="parcelle-card-val">{{parcelle.plot_name}} [{{parcelle.id}}]</span></div>
                <div><span class="parcelle-card-title">Commune (code postal) :</span><span class="parcelle-card-val">
                    {{parcelle.city}} ({{parcelle.zipcode}})</span></div>
                <div><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val">
                    {{parcelle.variete}}</span></div>
                <div><span class="parcelle-card-title">Résistance variétale (seuil poids de conta.) :</span><span
                    class="parcelle-card-val"> {{parcelle.seuiltrait}}</span></div>
                <div><span class="parcelle-card-title">Dernière réception météo (UTC) :</span><span
                    class="parcelle-card-val"> {{parcelle.date_deb_previsions}}</span></div>
                <div><span class="parcelle-card-title">Surface (ha) :</span><span class="parcelle-card-val">
                    {{parcelle.area}}</span></div>
              </div>
            </v-card>
            <v-btn style="margin-top:2rem" @click="goTo('traitements')" class="acces-rapide">
              <v-icon small class="mr-2">
                mdi-eye
              </v-icon>
              VOIR LES INTERVENTIONS
            </v-btn>

            <!--SCENARIO FORCE - ADMIN -->
            <v-col v-if="checkDroits('ADMIN','lecture')">
              <v-card class="pl-5 pt-2 pb-2">
                <v-icon small class="mr-2">
                  mdi-script-text-outline
                </v-icon>
                FORCER UN SCENARIO
                <div class="parcelle-card">
                  <br>
                  <v-select style="width:80%" v-model="selectedOptionScenario" :items="scenario_code" item-text="code"
                    item-value="code" @change="modal_scenario_code_change"
                    label="Selectionnez le code de scenario à appliquer"></v-select>
                </div>
              </v-card>
            </v-col>
            <!--FIN SCENARIO FORCE - ADMIN -->

          </v-col>
          <v-col cols="6">
            <v-col>
              <v-card class="pl-5 pt-2 pb-2">
                <v-icon small class="mr-2">
                  mdi-calendar-text-outline
                </v-icon>
                STADES
                <div class="parcelle-card">
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.plantation") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.plantation}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.levee30") }} :</span><span
                      class="parcelle-card-val">{{parcelle_stades.levee30}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.croissanceactive") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.croissanceactive}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.croissancestabilisee") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.croissancestabilisee}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.debutsenescence") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.debutsenescence}}</span></div>
                  <div><span class="parcelle-card-title">{{ $tc("mildiou.stades.defanage") }} :</span><span
                      class="parcelle-card-val"> {{parcelle_stades.defanage}}</span></div>
                </div>
              <v-btn v-if="isMyPlot" @click="GoPageGestionParcelle('stade')" class="add_button pl-5 add_intervention">
              +
              </v-btn>
              </v-card>
            </v-col>

            <v-col v-if="(this.id_station_in_this_parcelle != null && this.id_station_in_this_parcelle != '')">
              <v-card class="pl-5 pt-2 pb-2">
                <v-icon small class="mr-2">
                  mdi-calendar-text-outline
                </v-icon>
                STATION
                <div class="parcelle-card">
                  <div><span class="parcelle-card-title">Nom de la station :</span><span
                      class="parcelle-card-val">{{station.device_name}}</span>
                  </div>
                  <div><span class="parcelle-card-title">Code station Arvalis :</span><span
                      class="parcelle-card-val">{{station.id}}</span>
                  </div>
                  <div>
                    <div><span class="parcelle-card-title">Opérateur météo :</span><span
                        class="parcelle-card-val">{{station.constructor_name}}</span></div><span
                      v-if="!checkDroitLectureExterne" class="parcelle-card-title">Code station Opérateur météo
                      :</span><span v-if="!checkDroitLectureExterne"
                      class="parcelle-card-val">{{station.identification}}</span>
                  </div>
                  <div v-if="checkDroits('ADMIN','lecture')">
                    <div><span class="parcelle-card-title">Last move date :</span><span
                        class="parcelle-card-val">{{station.lastmovedate}} </span></div>
                    <div><span class="parcelle-card-title">Date création :</span><span
                        class="parcelle-card-val">{{station.p_dt_crea}} </span></div>
                  </div>
                  <div><span class="parcelle-card-title">Dernière réception météo (UTC) :</span> {{station.last_data}}
                    <span class="parcelle-card-val"></span></div>
                    <div v-if="checkDroits('METEO','lecture')">
                      <v-btn @click="showItem(id_station_in_this_parcelle)" class="acces-rapide">
                        <v-icon small class="mr-2">
                          mdi-eye
                        </v-icon>
                        Voir la station
                      </v-btn>
                    </div>
                </div>
              </v-card>
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">

          </v-col>
        </v-row>

      </div>

      <section v-if="!isMobile">
      <h3 style="margin-top:10px;"> Préconisations </h3>
      <div>
        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-lightbulb-on-outline
              </v-icon>
              Préconisation
              <div class="parcelle-card">
                <div v-if="parcelle!=null"><span :style="getStylePreconisation()" v-html="getPreconisation()"></span>
                </div>
              </div>
            </v-card>
          </v-col>
          <!-- <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-comment-text
              </v-icon>
              Commentaires
              <div class="parcelle-card">
                <div></div>
              </div>
            </v-card>
          </v-col> -->
          <v-col cols="10">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi mdi-calculator-variant-outline
              </v-icon>
              Calculs
              <div>
                Situation des calculs : {{parcelle.statut_msg}}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      </section>


      <section v-if="!isMobile">
        <h3 style="margin-top:10px;"> Mildiou </h3>
        <v-row>
          <v-col style="margin: 0 auto;" cols="8">
            <v-chart style="height:250px" :option="chartOptionsHeatMap" />
          </v-col>
        </v-row>
        <!--Switcher pour affichage des tableaux ou du graph-->
        <div id="switch-container">
          <span id="label-left">Tableau</span>
          <v-switch v-model="afficherGraph" id="switch"></v-switch>
          <span id="label-right">Graphique</span>
        </div>
        <div v-if="!afficherGraph">
            <v-tabs v-model="tabTypeData">
              <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#day">Données journalières</v-tab>
              <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#hour"
                @click="launchCalculsMildiouForIndicesHoraires">Données horaires</v-tab>
            </v-tabs>

            <div style="overflow:hidden;">
              <!-- <v-checkbox v-model="view_reel" label="Réel" class="float mt-5"></v-checkbox>
            <v-checkbox v-model="view_previ" label="Prévisionnel" class="float mt-5"></v-checkbox> -->

              <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="indices_date_debut" label="Date début" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
                </template>
                <v-date-picker v-model="indices_date_debut"></v-date-picker>
              </v-menu>

              <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="indices_date_fin" label="Date fin" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
                </template>
                <v-date-picker v-model="indices_date_fin"></v-date-picker>
              </v-menu>
            </div>

            <v-tabs-items v-model="tabTypeData">
              <v-tab-item value="day">

                <v-card>
                  <v-card-title>
                    <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                      <span>Données prévisionnelles</span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
                      hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                    :headers="headers" :items="parcelle_indices" item-key="index" :options.sync="optionsTab"
                    :sort-by="sortByTab" :sort-desc="sortDescTab" class="my-awesome-table elevation-1"
                    :item-class="rowStyle" dense>

                    <template v-slot:[`item.risque`]="{ item }">
                      <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
                            {{getIconRisque(item.risque)}}
                          </v-icon>
                        </template>
                        <span v-html="getInfoRisque(item.risque)"></span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.parcelleprotegee`]="{ item }">
                      <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs"
                            v-on="on">
                            {{getIconProtection(item.parcelleprotegee)}}
                          </v-icon>
                        </template>
                        <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="showItem(item)">
                        mdi-eye
                      </v-icon>
                    </template>
                    <template
                      v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
                    <template
                      v-slot:[`item.resistance_lessivage`]="{ item }">{{ getTexteLessivage(item.resistance_lessivage) }}</template>
                  </v-data-table>
                </v-card>

                <v-btn small color="info" class="mt-2 white--text">
                  <download-excel :data="parcelle_indices" :name="getNomFichier('day')" :fields="excelfields">
                    Export Excel
                    <v-icon small class="mr-2">
                      mdi-download
                    </v-icon>
                  </download-excel>
                </v-btn>

              </v-tab-item>

              <v-tab-item value="hour">
                <v-card>
                  <v-card-title>
                    <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                      <span>Données prévisionnelles</span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
                      hide-details></v-text-field>
                  </v-card-title>

                  <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                    :headers="headersHoraires" :items="parcelle_indices_horaires" item-key="index"
                    :sort-by="['datetime']" :sort-desc="sortDescTabHoraires" class="my-awesome-table elevation-1"
                    :item-class="rowStyle" :footer-props="{'items-per-page-options': [24, 25, 50, 75, 100]}" dense>
                    <template v-slot:[`item.risque`]="{ item }">
                      <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
                            {{getIconRisque(item.risque)}}
                          </v-icon>
                        </template>
                        <span v-html="getInfoRisque(item.risque)"></span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.parcelleprotegee`]="{ item }">
                      <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs"
                            v-on="on">
                            {{getIconProtection(item.parcelleprotegee)}}
                          </v-icon>
                        </template>
                        <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="showItem(item)">
                        mdi-eye
                      </v-icon>
                    </template>
                    <template
                      v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
                  </v-data-table>
                </v-card>

                <v-btn small color="info" class="mt-2 white--text">
                  <download-excel :data="parcelle_indices_horaires" :name="getNomFichier('hour')"
                    :fields="excelfieldsHoraires">
                    Export Excel
                    <v-icon small class="mr-2">
                      mdi-download
                    </v-icon>
                  </download-excel>
                </v-btn>

              </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else>
          <section v-if="!isMobile">
            <div style="margin-bottom:20px;margin-top:20px;">
              <v-row>
                <v-col :offset="1" :cols="11" style="margin: 0 auto;">
                  <v-chart with="100%" class="chart" :option="chartOptions" />
                </v-col>
              </v-row>
            </div>
          </section>
        </div>

      </section>
      <br>

    <section>
      <h3 style="margin-top:10px;"> Infos météo </h3>
      <v-row v-if="!isMobile">
        <v-col offset="1" cols="10">
          <v-chart style="height:250px" :option="chartOptionsDMHeatMap" />
        </v-col>
      </v-row>
      <!--Form pour Ajout de données météo à la parcelle TODO POUR TECHNICIEN AUTORISE UNIQUEMENT-->
      <div>
        <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
          <v-card-title>
            <span class="headline">Ajout météo à la parcelle </span>
          </v-card-title>
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submitFormAddReplacement" class="row text-center">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <label for="form_date_start_replacement_meteo">Date de début</label><br>
                  <input style="background-color:white" type="date" id="form_date_start_replacement_meteo"
                    v-model="form_date_start_replacement_meteo" :disabled="form_check_box_gap_meteo" required
                    class="input_field">
                </div>
                <div class="col-sm-6">
                  <label for="form_date_end_replacement_meteo">Date de fin</label><br>
                  <input style="background-color:white" type="date" id="form_date_end_replacement_meteo"
                    v-model="form_date_end_replacement_meteo" :disabled="form_check_box_gap_meteo" required
                    class="input_field">
                </div>

                <div class="col-sm-6" style="margin-bottom: 15px;font-size:1.25rem">
                  <label>
                    <input type="checkbox" v-model="form_check_box_gap_meteo" @change="handleCheckboxChange"> Combler
                    les manques météo
                  </label>
                </div>
                <div class="col-sm-6">
                  <button type="submit" :disabled="invalid " class="submit-button">
                    <span>Enregistrer</span>
                  </button>
                </div>

              </form>
              <div style="background-color:rgb(225, 225, 225);margin:0.4rem">
                <p>
                  VIGILANCE sur la fiabilité des indices maladies calculés sur la période d’ajout et les 2 semaines
                  suivantes.
                  Les données météo utilisées sont des données spatialisées, basées sur du prévisionnel, donc moins
                  précises que les données météo de station.
                </p>
              </div>
            </validation-observer>
          </v-card-text>
        </v-card>
      </div>

      <!-- MODAL pour modification des trous meteo -->
      <v-dialog v-model="showModalEditReplacementMeteo" max-height="96vh" width="80%">
        <div>
          <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
            <v-card-title>
              <span class="headline">Modification d'un ajout météo</span>
              <v-btn icon @click="showModalEditReplacementMeteo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text style="height: auto; margin: 0 auto;">
              <p v-if="select_replacement_meteo" style="margin: 0.5rem;">
                Vous modifiez l'ajout météo débutant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_start_replacement_meteo }}</span> et
                terminant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{  initial_date_end_replacement_meteo }}</span>
              </p>
              <p>Saisissez vos nouvelles dates :</p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submitFormPutReplacement" class="row text-center">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <label for="form_date_start_replacement_meteo">Date de début</label><br>
                    <input style="background-color:white" type="date" id="form_date_start_replacement_meteo"
                      v-model="form_date_start_replacement_meteo" required class="input_field">
                  </div>
                  <div class="col-sm-6">
                    <label for="form_date_end_replacement_meteo">Date de fin</label><br>
                    <input style="background-color:white" type="date" id="form_date_end_replacement_meteo"
                      v-model="form_date_end_replacement_meteo" required class="input_field">
                  </div>
                  <div class="col-12 col-sm-6 offset-sm-3 text-center">
                    <button type="submit" :disabled="invalid" class="submit-button">Modifier</button>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>

      <!---->
      <!--MODAL pour Suppression des trous meteos-->
      <v-dialog v-model="showModalDeleteReplacementMeteo" max-height="96vh">
        <div>
          <v-card style="background-color:rgb(225, 225, 225);margin:0.4rem">
            <v-card-title>
              <span class="headline">Suppression d'un ajout météo </span>
              <v-btn icon @click="showModalDeleteReplacementMeteo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text style="height: auto; margin: 0 auto;">
              <p v-if="select_replacement_meteo" style="margin: 0.5rem;">
                Vous vous apprêtez à supprimer l'ajout météo débutant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_start_replacement_meteo }}</span> et
                terminant le <span
                  style="font-weight: bold; color: rgb(126, 199, 0);">{{ initial_date_end_replacement_meteo }}</span>
              </p>
              <p>
                Confirmez-vous cette suppression ?
              </p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submitFormDeleteReplacement" class="row text-center">
                  <!-- Champ caché pour l'ID de la parcelle -->
                  <input type="hidden" v-model="select_replacement_meteo.id">
                  <div class="col-md-6 col-sm-10 offset-sm-1 offset-md-3 text-center">
                    <button type="submit" :disabled="invalid" class="submit-button">Supprimer</button>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
      <!---->
      <br>
      <!--TABLEAU des corrections météo de la parcelle -->
      <div v-if="hasAnyReplacements">
        <h4>Corrections météo de la parcelle :</h4>
        <v-data-table 
          :headers="headers_replacement_meteo" 
          :items="combinedReplacements" 
          item-key="id"
          class="elevation-1" 
          mobile-breakpoint="0">
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.type !== 'Jours manquants'">
              <v-icon 
                style="color:black !important; background-color:transparent !important;"  small  @click="editIReplacementMeteo(item)">
                mdi-pencil
              </v-icon>
              <v-icon 
                style="color:black !important; background-color:transparent !important;" small @click="deleteItemReplacementMeteo(item)">
                mdi-delete
              </v-icon>
              </div>
            </template>
          </v-data-table>
        </div>

        <div v-else>
          <p style="font-size:1.2rem !important">  Cette parcelle ne possède pas de corrections météos</p>
        </div>

      <!--Fin du tableau des AJOUTS météo-->
    </section>

      <!-- *  INDICES/Infos METEOS -->
      <v-container v-if="!isMobile">   
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <div style="overflow:hidden;">
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_debut_indice_meteo_risk" label="Date début" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_debut_indice_meteo_risk"></v-date-picker>
            </v-menu>
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_fin_indice_meteo_risk" label="Date fin" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_fin_indice_meteo_risk"></v-date-picker>
            </v-menu>
          </div>   
          <v-data-table id="indices_meteo_tableau" mobile-breakpoint="0" :headers="headers_temp_hum_heure_risk"
            :search="searchWordIndice" :items="indices_meteo_risk_data_table" class="elevation-1" :items-per-page="24"   :footer-props="{'items-per-page-options': [24, 50, 75, 100]}"
            dense :sort-by="['date']" :sort-desc="sortDescInd" >
            <!-- En-tête pour 'Hygro et/ou Pluie' -->
            <template v-slot:[`column.header.indice_hum`]="{ column }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ column.text }}</span>
                </template>
                <span>Humidité &lt; 87% et P = 0 mm<br>Absence de risque<br>&gt;= 87% ou P &gt; 0 mm<br>Présence de
                  risque</span>
              </v-tooltip>
            </template>
            <!-- Contenu des lignes du tableau -->
            <template v-slot:item="{ item }">
              <tr :key="item.date">
                <td>{{ formatDateTime(item.date) }}</td>
                <td :class="getHumClass(item.indice_hum)" :title="getHumTooltip(item.indice_hum)">
                  {{ item.description_hum || '-' }}</td>
                <td :class="getTempClass(item.indice_temp)" :title="getTempTooltip(item.indice_temp)">
                  {{ item.description_temp || '-' }}</td>
                <td>{{ item.indexconta }}</td>
                <td>
                  <v-icon small class="mr-2" :color="getColorRisque(item.risque)"
                    :title="getInfoRisqueTooltip(item.risque)">
                    {{ getIconRisqueIndiceMeteo(item.risque) }}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
          <v-btn small color="info" class="mt-2 white--text">
            <download-excel :data="indices_meteo_risk_data_table" :name="getNomFichier('day')"
              :fields="excelfields_temp_hum_heure_risk">
              Export Excel
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
            </download-excel>
          </v-btn>
        
        <br>
      </v-container>
      <!-- * FIN INDICES METEOS -->


      <h3 v-if="!isMobile" style="margin-top:10px;"> Interventions et observations </h3>
      <div v-if="!isMobile">

        <!-- TRAITEMENTS -->
        <v-row>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 10" :offset="$vuetify.breakpoint.smAndDown ? 0 : 1">
            <v-card class="mt-5 pl-5 pt-2 pb-2" id='traitements'>
              <v-icon small class="mr-2">
                mdi-tractor
              </v-icon>
              Traitements
              <div v-if="parcelle!=null && parcelle.traitements!=null">
                <template>
                  <v-simple-table fixed-header height="200px"
                    style="width:100%">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Date (UTC)</th>
                          <th class="text-left">Produit</th>
                          <th class="text-left">Type</th>
                          <th class="text-left">Dose</th>
                          <th class="text-left">Rémanence (j)</th>
                          <th class="text-left">Rémanence modifiée</th>
                          <th class="text-left">Lessivage (mm)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="trait in sortTab(JSON.parse(parcelle.traitements),'treated_at')"
                          :key="trait.treated_at">
                          <td style="height:12px">{{ getDateHeure(trait.treated_at) }}</td>
                          <td style="height:12px">{{ trait.product_name }}</td>
                          <td style="height:12px">{{ trait.product_type }}</td>
                          <td style="height:12px">{{ trait.product_dose }}{{trait.dose_unit}}</td>
                          <td style="height:12px">{{ trait.standard_persistence }}</td>
                          <td style="height:12px">{{ trait.modified_persistence  }}</td>
                          <td style="height:12px">{{ trait.washout_resistance }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>
              <br><br>            
              <p style=" font-weight:bolder; font-size: 16px !important;">Traitement(s) évité(s)</p>
            <v-row>
              <v-col :cols="6">
                <p v-if="lateblightTreatments.length > 0" style="font-size:16px !important;">
                  Traitements évités pour le mildiou :
                </p>
                <p v-else style="font-size:16px !important;">
                  Aucun traitement évité pour le mildiou
                </p>
                <ul>
                  <li v-for="(traitement, index) in lateblightTreatments" :key="'lateblight_' + index">
                    {{ formatDateTraitementEvite(traitement.skipped_at) }}
                  </li>
                </ul>
              </v-col>
              <v-col :cols="6">
                <p v-if="alternariaTreatments.length > 0" style="font-size:16px !important;">
                  Traitements évités pour l'alternariose :
                </p>
                <p v-else style="font-size:16px !important;">
                  Aucun traitement évité pour l'alternariose
                </p>
                <ul>
                  <li v-for="(traitement, index) in alternariaTreatments" :key="'alternaria_' + index">
                    {{ formatDateTraitementEvite(traitement.skipped_at) }}
                  </li>
                </ul>
              </v-col>
              <v-col :cols="6">
   
              </v-col>
            </v-row>
            <v-btn v-if="isMyPlot" class="add_button pl-5 add_intervention" @click="GoPageGestionParcelle('traitement')"> + </v-btn>
            </v-card>
          </v-col>
        </v-row>
        
        <v-row>
          <!-- IRRIGATIONS -->
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 5" :offset="$vuetify.breakpoint.smAndDown ? 0 : 1">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-water
              </v-icon>
              Irrigations
              <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>L’irrigation impacte les indices de risque mildiou pour chaque jour où elle est présente de 19h à
                  00h J+1 (UTC), soit de 21h à 2h J+1 (heure locale en France métropolitaine) : 6 heures de risque sont
                  additionnées sur cette période.</span>
              </v-tooltip>
              <div v-if="parcelle!=null && parcelle.irrigations!=null">
                <template>
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Date debut (UTC)</th>
                          <th class="text-left">Date fin (UTC)</th>
                          <th class="text-left">Quantité (mm)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="irrig in sortTab(JSON.parse(parcelle.irrigations),'begin_at')" :key="irrig.begin_at">
                          <td style="height:12px">{{ getDateHeure(irrig.begin_at) }}</td>
                          <td style="height:12px">{{ getDateHeure(irrig.end_at) }}</td>
                          <td style="height:12px">{{ irrig.water_dose }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>
              <v-btn v-if="isMyPlot" class="add_button pl-5 add_intervention" @click="GoPageGestionParcelle('irrigation')"> + </v-btn>
            </v-card>
          </v-col>
          <!-- OBSERVATION -->
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-eye
              </v-icon>
              Observations
              <div v-if="parcelle!=null && parcelle.observations!=null && JSON.parse(parcelle.observations).length>0">
                Dates d'observation de mildiou dans la parcelle :
                <div v-for="date in JSON.parse(parcelle.observations)" :key="date">{{date}}</div>
              </div>
              <div v-else>
                Aucune observation
              </div>
              <v-btn v-if="isMyPlot" class="add_button pl-5 add_intervention" @click="GoPageGestionParcelle('observation')"> + </v-btn>
            </v-card>
          </v-col>
        </v-row>

      </div>


      <!-- ALTERNARIA ONLY -->
      <h3 v-if="is_alternaria_true" style="margin-top:10px;"> Alternariose </h3>
      <div v-if="is_alternaria_true">
        <!--DIV DES TABLEAUX ALTERNARIA-->
        <v-row>
          <v-col :style="{ width: isMobile ? '100%' : 'auto' }"
            style="margin: 0 auto;" :cols="isMobile ? 12 : 8">
            <v-chart style="height:250px" :option="chartOptionsHeatMapAlternaria" />
          </v-col>
        </v-row>

          <v-tabs v-model="tabTypeData">
            <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#day">Données journalières</v-tab>
            <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#hour">Données horaires</v-tab>
          </v-tabs>

          <div style="overflow:hidden;">
            <!-- <v-checkbox v-model="view_reel_alt" label="Réel" class="float mt-5"></v-checkbox>
            <v-checkbox v-model="view_previ_alt" label="Prévisionnel" class="float mt-5"></v-checkbox> -->
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_debut_alt" label="Date début" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_debut_alt"></v-date-picker>
            </v-menu>
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_fin_alt" label="Date fin" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_fin_alt"></v-date-picker>
            </v-menu>
          </div>
          <v-tabs-items v-model="tabTypeData">
            <v-tab-item value="day">
              <v-card>
                <v-card-title>
                  <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                    <span>Données prévisionnelles</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche" single-line
                    hide-details></v-text-field>
                </v-card-title>
                <!-- *TABLEAU JOUR-->
                <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                  :headers="headersJourAlt" :items="parcelle_indices_alt" item-key="index" :options.sync="optionsTab"
                  :sort-by="sortByTab" :sort-desc="sortDescTab" class="my-awesome-table elevation-1"
                  :item-class="rowStyleAlt" dense>
                  <!--Champ RISQUE 10 - 50 -->
                  <template v-slot:item.alt_risk_chiffre="{ item }">
                    <v-icon small class="mr-2" :color="getColorRisk(item.alt_risk)">
                      {{ getIconRisk(item.alt_risk) }}
                    </v-icon>
                  </template>
                  <!--Champ EVOLUTION PHYSIOLOGIQUE -->
                  <template v-slot:[`item.alt_risk`]="{ item }">
                    <v-tooltip bottom max-width="350">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mr-2" :color="getColorRisque(item.alt_risk)" v-bind="attrs" v-on="on">
                          {{evaluerSensibiliteAlt(item.alt_risk)}}
                        </v-icon>
                      </template>
                      <span v-html="evaluerSensibiliteAlt(item.alt_risk)"></span>
                    </v-tooltip>
                  </template>
                  <!--Champ Protection-->
                  <template v-slot:[`item.alt_prot`]="{ item }">
                    <v-tooltip bottom max-width="350">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mr-2" :color="getColorProtection(item.alt_prot)" v-bind="attrs" v-on="on">
                          {{getIconProtection(item.alt_prot)}}
                        </v-icon>
                      </template>
                      <span v-html="getInfoProtection(item.alt_prot)"></span>
                    </v-tooltip>
                  </template>

                </v-data-table>
              </v-card>
              <!-- * FIN TABLEAU JOUR-->
              <v-btn small color="info" class="mt-2 white--text">
                <download-excel :data="parcelle_indices_alt" :name="getNomFichier('day')" :fields="excelfields">
                  Export Excel
                  <v-icon small class="mr-2">
                    mdi-download
                  </v-icon>
                </download-excel>
              </v-btn>
            </v-tab-item>

            <v-tab-item value="hour">
              <v-card>
                <v-card-title>
                  <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                    <span>Données prévisionnelles</span>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <!-- * TABLEAU HEURES-->
                <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                  :headers="headersHorairesAlt" :items="parcelle_indices_horaires_alt" item-key="index"
                  :sort-by="sortByTabHoraires" :sort-desc="sortDescTabHoraires" class="my-awesome-table elevation-1"
                  :item-class="rowStyleAlt" :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100]}" dense>
                  <!--Champ RISQUE 10 - 50 -->
                  <template v-slot:item.alt_risk_chiffre="{ item }">
                    <v-icon small class="mr-2" :color="getColorRisk(item.alt_risk)">
                      {{ getIconRisk(item.alt_risk) }}
                    </v-icon>
                  </template>
                  <!--Champ SENSIBLE INSENSIBLE  -->
                  <template v-slot:[`item.alt_risk`]="{ item }">
                    <v-tooltip bottom max-width="350">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mr-2" :color="getColorRisque(item.alt_risk)" v-bind="attrs" v-on="on">
                          {{getIconRisqueAlt(item.alt_risk)}}
                        </v-icon>
                      </template>
                      <span v-html="evaluerSensibiliteAlt(item.alt_risk)"></span>
                    </v-tooltip>
                  </template>
                  <!--Champ Protection-->
                  <template v-slot:[`item.alt_prot`]="{ item }">
                    <v-tooltip bottom max-width="350">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mr-2" :color="getColorProtection(item.alt_prot)" v-bind="attrs" v-on="on">
                          {{getIconProtection(item.alt_prot)}}
                        </v-icon>
                      </template>
                      <span v-html="getInfoProtection(item.alt_prot)"></span>
                    </v-tooltip>
                  </template>
                  <!--Champ EVOLUTION PHYSIOLOGIQUE-->
                  <!-- <template v-slot:[`item.evolution_physiological_sensitivity_al`]="{ item }">
                      <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2"
                            v-bind="attrs" v-on="on">
                            {{evaluerSensibiliteAlt(item.alt_risk) - item.alt_risk}}
                          </v-icon>
                        </template>
                      </v-tooltip>
                    </template> -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="showItem(item)">
                      mdi-eye
                    </v-icon>
                  </template>
                  <template
                    v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
                </v-data-table>
                <!--FIN TABLEAU HEURES-->
              </v-card>

              <v-btn small color="info" class="mt-2 white--text">
                <download-excel :data="parcelle_indices_horaires_alt" :name="getNomFichier('hour')"
                  :fields="excelfieldsHoraires">
                  Export Excel
                  <v-icon small class="mr-2">
                    mdi-download
                  </v-icon>
                </download-excel>
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
      </div>
      <!--FIN DIV DES TABLEAUX ALTERNARIA-->

    </v-container>
  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import * as echarts from 'echarts';
import VChart from 'vue-echarts';
import { getDate,checkIsMobile } from "@/helpers/functions";
import html2canvas from 'html2canvas';
import { ValidationObserver } from 'vee-validate';

export default {
  name: "DetailParcelle_Mildiou",
  components: {
    downloadExcel,
    parcellesMildiouService,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    VChart,
    getDate,
    checkIsMobile,
    ValidationObserver
  },
  data() {
    return {
      loading: false,
      isMobile: false,
      showCarte: false,
      showModalMapMobile: false,
      isMyPlot: false,

      showModalIndicesClimatiqueMobile : false,
      showModalEditReplacementMeteo: false,
      showModalDeleteReplacementMeteo: false,

      initial_date_start_replacement_meteo: '',
      initial_date_end_replacement_meteo: '',        
      form_date_start_replacement_meteo:'',
      form_date_end_replacement_meteo:'',
      select_replacement_meteo: null,

      form_check_box_gap_meteo:false,
      form_date_obs: '',
      form_parcelle_obs: '',

      form_date_debut_irrig: '',
      form_date_fin_irrig: '',
      form_qte_mm: '',
      form_parcelle_irrig: '',

    parsedTreatments: [],
    lateblightTreatments: [],
    alternariaTreatments: [],
      
      // all_products:[],
      // selectedProduct:null,
      form_date_traitement: '',
      form_product_name_traitement: '',
      form_product_type_traitement: '',
      form_persistence_traitement: '',
      form_washout_resistance_traitement: '',
      form_product_dose_traitement: '',
      form_parcelle_traitement: '',
      form_remanence_traitement:'',
      form_lessivage_traitement:'',
      form_parcelle_stades:'',
      form_washout_resistance_al_traitement:'',
      form_persistence_al_traitement:'',
      form_dose_unit_traitement:'L/ha',

      parcelle : [],
      afficherGraph: false,
      showModalAddStades:false,
      showModalIndicesMaladiesJournaliers :false,
      showModalIndicesMaladiesHoraires:false,
      droits: this.$store.state.user.user.droitsTPC,
      showDetailParcelle: true,
      showDetailParcelleMeteo: false,
      showCalculsPlot: false,
      showDivCalculs: false,
      parcelle_stades : [],
      parcelle_indices : [],
      parcelle_indices_horaires: [],
      parcelle_DM : [], 
      station: [],
      id_station_in_this_parcelle:'', 
      dataloaded: false,
      filter: null,
      tabTypeData: null,
      searchWord: "",
      optionsTab: {itemsPerPage: 10,},
      sortByTab: ['date','conseil'],
      sortDescTab: [true,false],
      sortByTabHoraires: 'numligne',
      sortDescTabHoraires: true,
      view_reel:true,
      view_previ:true,
      view_reel_alt:true,
      view_previ_alt:true,
      indices_date_debut: '',
      indices_date_fin: '',
      indices_date_debut_alt: '',
      indices_date_fin_alt: '',
      indices_date_debut_indice_meteo_risk: '',
      indices_date_fin_indice_meteo_risk: '',
      data_calcul : null,
      data_calcul_part_data : null,
      data_calcul_part_results : null,
      scenario_code : null,
      selectedOptionScenario : null ,
      is_alternaria_true : null,
      parcelle_indices_horaires_alt : [] ,
      parcelle_indices_alt : [],
      parcelle_indices_hum_temp : [],
      indices_hum_temp_data : [],
      indices_meteo_risk_data_table : [],
      sortByIndDate: 'datetime', 
      sortDescInd: true ,
      searchWordIndice: '',
      items_replacements_meteo:[],
      treatments_skipped:[],
      transformedDiseases: [],
      headers_replacement_meteo: [
        { text: 'Type', value: 'type' },
        { text: 'Date(s)', value: 'date' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      section_data: [{
          title: 'Données',
          subSections: [],
          isExpanded: false,
        }],

        section_calculs: [{
          title: 'Calculs',
          subSections: [],
          isExpanded: false,
        }
      ],
      headers_cc_heures: [
        { text: "Date & heure", value: "d"},
        { text: "Température (°C)", value: "t"},
        { text: "Humidité (%)", value: "u"},
        { text: "Pluie (mm)", value: "rr"}
      ],

      headers_temp_hum_heure_risk: [
          { text: 'Date et Heure', value: 'date' },
          { text: 'Hygro et/ou Pluie', value: 'description_hum' },
          { text: 'Température', value: 'description_temp' },
          { text: "Index de conta", value: "indexconta" },
          { text: 'Risque mildiou', value: 'risque' },
      ],

      excelfields_temp_hum_heure_risk: {
        "Date et Heure": "date",
        "Hygronométrie et/ou Pluie": "description_hum",
        "Température": "description_temp",
        "Index de contamination": "indexconta",
        "Risque mildiou": "risque",
        "Réserve de spores": "l10spores",
        "Poids de contamination": "l10poids" ,
        "Potentiel de sporulation": "l10spospo" ,
      },

      headers_cc_jour: [
        { text: "Date", value: "d", width: '100px'},
        { text: "Température Moyenne (°C)", value: "t_avg"},
        { text: "Hygrométrie Moyenne (%)", value: "u_avg"},
        { text: "Pluie Totale (mm)", value: "rr_sum"},
        { text: "T°C Min", value: "t_min"},
        { text: "T°C Max", value: "t_max"},
        { text: "Hygrométrie Min", value: "u_min"},
        { text: "Hygrométrie Max", value: "u_max"}
        
        ],

      headers: [
        { text: "Date", value: "date", width: '100px'},
        { text: "Réserve spores", value: "l10spores"},
        { text: "Index conta", value: "indexconta"},
        { text: "Poids conta", value: "l10poids" },
        { text: "Potentiel sporulation", value: "l10spospo" },
        { text: "Protection", value: "parcelleprotegee"},
        { text: "Risque Mildiou", value: "risque"},
        { text: "Pluie (mm)", value: "pluie" },
        { text: "Lessivage (mm)", value: "resistance_lessivage"},
      ],
      headers_mobile: [
        { text: "Date", value: "date", width: '100px'},
        { text: "Risque", value: "risque"},
        { text: "Protection", value: "parcelleprotegee"},
        { text: "Poids conta", value: "l10poids" },
        { text: "Index conta", value: "indexconta"},
        { text: "Réserve spores", value: "l10spores"},
        { text: "Index conta", value: "indexconta"},
        { text: "Potentiel sporulation", value: "l10spospo" },
        { text: "Pluie (mm)", value: "pluie" },
        { text: "Lessivage (mm)", value: "resistance_lessivage"},
      ],

      excelfields: {
        'Date':'date',
        'Réserve de spores':'l10spores',
        'Index contamination':'indexconta',
        'Potentiel sporulation':'l10spospo',
        'Poids contamination':'l10poids',
        'Protection':'parcelleprotegee',
        "Risque": {
          field: "risque", // nested attribute supported
          callback: (value) => {
            return this.getTexteRisque(value);
          },
        },
        'Alerte en cours':{
          field: "conseil", // nested attribute supported
          callback: (value) => {
            return this.$t("mildiou.conseil_abrege."+value)
          },
        },
        'Pluie (mm)':'pluie',
        "Lessivage du produit restant (mm)": {
          field: "resistance_lessivage", // nested attribute supported
          callback: (value) => {
            return this.getTexteLessivage(value);
          },
        },
      },

      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },

      headersHoraires: [
        //colonnes non visibles mais permettant d'ordonner le contenu
        { text: 'Ligne', value: 'numligne', align: ' d-none'},
        //colonnes visibles
        { text: "Date", value: "datetime", width: '175px'},
        { text: "Réserve spores", value: "l10spores"},
        { text: "Index conta", value: "indexconta" },
        { text: "Poids conta", value: "l10poids" },
        { text: "Potentiel sporulation", value: "l10spospo" } ,
        { text: "Protection", value: "parcelleprotegee"},
        { text: "Risque Mildiou", value: "risque"}
      ],
  
      headersJourAlt: [
        { text: "Date", value: "date", width: '100px'},
        { text: "Risque", value: "alt_risk_chiffre" },
        // { text: "alt_cumpoids", value: "alt_cumpoids", width: '100px' },
        { text: "Poids de conta_al", value: "alt_cumpoidssup1" },
        { text: "Cumul évolution physiologique", value: "alt_cumpdays" },
        { text: "Préconisation_al", value: "alt_conseil" },
        { text: "Evolution physiologique", value: "alt_risk" },
        { text: "Protection_al", value: "alt_prot" },
        // { text: "Evolution physiologique", value: "evolution_physiological_sensitivity_al physiologique" },
      ],

      headersHorairesAlt: [
        { text: "Date", value: "date", width: '100px'},
        { text: "Risque", value: "alt_risk_chiffre" },
        // { text: "alt_cumpoids", value: "alt_cumpoids", width: '100px' },
        { text: "Poids de conta_al", value: "alt_cumpoidssup1" },
        { text: "Cumul évolution physiologique", value: "alt_cumpdays" },
        { text: "Préconisation_al", value: "alt_conseil" },
        { text: "Evolution physiologique", value: "alt_risk" },
        { text: "Protection_al", value: "alt_prot" },
        // { text: "Evolution physiologique", value: "evolution_physiological_sensitivity_al physiologique" },
      ],

      excelfieldsHoraires: {
        'Date':'date',
        'Heure':'heure',
        'Réserve de spores':'l10spores',
        'Potentiel de sporulation':'l10spospo',
        'Index de contamination':'indexconta',  
        'Poids de contamination':'l10poids',
        'Protection':'parcelleprotegee',
        'Risque Mildiou':'risque'
      },
      
      excelfieldsHeuresMeteo: {
        'Date':'d',
        'Température':'t',
        'U (Humidité)':'u',
        'RR (Pluie)':'rr'
      },
      excelfieldsJourMeteo: {
        'Date':'d',
        'T°C MIN':'t_min',
        'T°C MAX':'t_max',
        'Humidité MIN':'u_min',
        'Humidité MAX':'u_max',
        'RR somme du jour':'rr_sum'
      },
      
      chartOptions: {
        title: {
          text: 'Risque de contamination mildiou'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Poids de contamination', 'Réserve de spores', 'Potentiel de sporulation', 'Index de contamination', 'Cumul poids de contamination','Seuil poids de contamination'],
          y: 'bottom'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: [{
          type: 'value',
          name: 'Potentiel de sporulation \n et poids de contamination',
          min: 0,
          max: 11,
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse:false,
          axisLine : {
            lineStyle : {color: '#616161'}
          },
          splitLine: {
              lineStyle: {
                  color: '#616161'
              }
          }
        },{
          type: 'value',
          name: 'Index de\n\rcontam.',
          position: 'right',
          min: 0,
          max: 50,
          axisLabel: {
            formatter: '{value}'
          },
          axisLine : {
            lineStyle : {color: '#B0B0B0'}
          },
          splitLine: {
              lineStyle: {
                  color: '#DEDEDE'
              }
          }
        },{
          type: 'value',
          name: 'Cumul poids\n\rcontam.',
          position: 'right',
          min: 0,
          max: 50,
          offset: 65,

          axisLabel: {
            formatter: '{value}'
          },
          axisLine : {
            lineStyle : {color: '#FF0000'}
          },
        }
        ],
        series: [
          {
            name: 'Poids de contamination',
            type: 'bar',
            data: [],
            lineStyle: {color: '#99cc33'},
            showSymbol:false,
            itemStyle: {color: '#99cc33'},
            yAxisIndex: 0,
          },
          {
            name: 'Réserve de spores',
            type: 'line',
            data: [],
            lineStyle: {color: '#f2883c'},
            showSymbol:false,
            itemStyle: {color: '#f2883c'},
          },
          {
            name: 'Potentiel de sporulation',
            type: 'line',
            data: [],
            lineStyle: {color: '#000'},
            showSymbol:false,
            itemStyle: {color: '#000'},
            yAxisIndex: 0,
          },
          {
            name: 'Index de contamination',
            type: 'line',
            data: [],
            lineStyle: {color: '#b45db0'},
            showSymbol:false,
            itemStyle: {color: '#b45db0'},
            yAxisIndex: 1,
          },
          {
            name: 'Cumul poids de contamination',
            type: 'line',
            data: [],
            lineStyle: {color: '#FF0000'},
            showSymbol:false,
            itemStyle: {color: '#FF0000'},
            yAxisIndex: 2,
          },
          {
            name: 'Seuil poids de contamination',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#99cc33'},
            itemStyle:{color:'#99cc33'},
          },
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '75%',
            start: 45,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },

      chartOptionsHeatMap:{
          title: {
          top: 30,
          left: 'center',
          text: 'Calendrier de risque mildiou \nP = parcelle protégée'
        },
        tooltip: {
          show: true,
          formatter: function (a) {
              return 'Date : ' + a.value[0] + '<br>Risque : ' + a.value[3] + '<br>' + a.value[4]
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
          },
        visualMap: [{
          dimension: 2,
          pieces: [{
              min: 0,
              max: 11,
              label: 'Pas de risque',
              color: 'green',
              opacity: 0.7
            },
            {
              min: 12,
              max: 41,
              label: 'Vigilance',
              color: 'orange'
            },
            {
              min: 42,
              max: 60,
              label: 'Risque',
              color: 'red'
            },
          ],
          orient: 'horizontal',
          left: 'center',
          top : 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear()+'-01-01',new Date().getFullYear()+'-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: { show: true },
          dayLabel: {
            firstDay:1, 
            nameMap : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap : ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: [],
          label: {
            show: true,
            color: "white",
            fontWeight : "bold",
            formatter: (param) => {
                if (param.data[1] ===  1) {
                  return 'P';
                } else {
                  return '';
                } 
            }
          },
        }
      },
      
      chartOptionsDMHeatMap: {
        title: {
          top: 30,
          left: 'center',
          text: `Planning de reception des données météo \n`
        },
        tooltip: {
          show: true,
          formatter: function (a) {
            return 'Date : ' + a.value[0] + '<br>Nb heures manquantes : ' + a.value[1]  + '<br>' + a.value[2]
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
          },
        visualMap: [{
          dimension: 1,
          pieces: [{
              min: 0,
              max: 5,
              label: 'Complet',
              color: 'green',
              opacity: 0.7,
              symbol: 'rect'
            },
            {
              min: 6,
              max: 12,
              label: 'Partiel',
              color: 'orange',
              symbol: 'rect'
            },
            {
              min: 13,
              max: 24,
              label: 'Absence',
              color: 'red',
              symbol: 'rect'
            }
            ,
            {
              min: 25, // VALEUR HORS CHAMPS NECESSAIRE POUR OBTENIR UNE LEGENDE SANS AVOIR LES CORRECTIONS QUI REMPLACENT LES AUTRES VALEURS DANS LE HEATMAP
              max: 25,
              label: 'Correction',
              color: 'lightgray',
              symbol: 'triangle', // Utiliser un triangle comme symbo
            }
          ],
          orient: 'horizontal',
          left: 'center',
          top: 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear() + '-01-01', new Date().getFullYear() + '-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: {
            show: true
          },
          dayLabel: {
            firstDay: 1,
            nameMap: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap: ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: [],
          label: {
            show: true,
            color: "white",
            fontWeight : "bold",
            formatter: (param) => {
              // La date du jour J dans le HEATMAP
              let dateHeatmap = param.data[0]; 
              let heatmapDate = new Date(dateHeatmap);

              let correspondancePeriods = false;
              let correspondanceMissingDays = false;

              // Vérifier la présence de données sous "periods" et les extraire
              if (this.items_replacements_meteo && 
                  Array.isArray(this.items_replacements_meteo.periods) && 
                  this.items_replacements_meteo.periods.length > 0) {
                  
                  correspondancePeriods = this.items_replacements_meteo.periods.some(item => {
                      if (item && item.begin && item.end) {  // Vérification des propriétés begin et end
                          let beginDate = new Date(item.begin);
                          let endDate = new Date(item.end);
                          return heatmapDate >= beginDate && heatmapDate <= endDate;
                      }
                      return false;
                  });
              }

              // Vérifier la présence de données sous "missing_days" et les extraire
              if (this.items_replacements_meteo && 
                  Array.isArray(this.items_replacements_meteo.missing_days) && 
                  this.items_replacements_meteo.missing_days.length > 0) {

                  correspondanceMissingDays = this.items_replacements_meteo.missing_days.some(day => {
                      if (day) {  // Vérification que day est bien défini
                          let missingDay = new Date(day);
                          return missingDay.getTime() === heatmapDate.getTime();
                      }
                      return false;
                  });
              }

              // Si une correspondance est trouvée dans les périodes ou les Jours manquants, retournez le symbole
              let correspondance = correspondancePeriods || correspondanceMissingDays;
              return correspondance ? '▲' : '';
          }

          },
        }
      },
      
      chartOptionsHeatMapAlternaria:{
          title: {
          top: 30,
          left: 'center',
          text: 'Représentation du risque Alternariose au cours du temps\nP = parcelle protégée'
        },
        tooltip: {
          show: true,
          formatter: function (a) {
              return 'Date : ' + a.value[0] + '<br>Risque : ' + a.value[3] + '<br>' + a.value[4]
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
          },
        visualMap: [{
          dimension: 2,
          pieces: [{
              min: 0,
              max: 10,
              label: 'Pas de risque',
              color: 'green',
              opacity: 0.7
            },
            {
              min: 11,
              max: 60,
              label: 'Risque',
              color: 'red'
            },
          ],
          orient: 'horizontal',
          left: 'center',
          top : 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear()+'-01-01',new Date().getFullYear()+'-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: { show: true },
          dayLabel: {
            firstDay:1, 
            nameMap : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap : ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: [],
          label: {
            show: true,
            color: "white",
            fontWeight : "bold",
            formatter: (param) => {
                if (param.data[1] ===  1) {
                  return 'P';
                } else {
                  return '';
                } 
            }
          },
        }
      },


    };
  },

  created(){
    // Initialiser la valeur parcelle ID dans les formulaires
    this.form_parcelle_irrig = this.parcelle.id;
    this.form_parcelle_obs = this.parcelle.id;
    this.form_parcelle_traitement = this.parcelle.id;
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    checkDroitLectureExterne() {
      return this.$store.getters['user/checkDroit']('EXTERNE','lecture')
    },
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },

    combinedReplacements() {
    let periods = [];
    let missingDays = null;

    // Vérifier et traiter les "periods"
    if (this.items_replacements_meteo &&
        Array.isArray(this.items_replacements_meteo.periods) &&
        this.items_replacements_meteo.periods.length > 0) {
      
      periods = this.items_replacements_meteo.periods.map(item => ({
        type: 'Periode',
        date: `Du ${item.begin} au ${item.end}`
      }));
    }

    // Vérifier et traiter les "missing_days"
    if (this.items_replacements_meteo &&
        Array.isArray(this.items_replacements_meteo.missing_days) &&
        this.items_replacements_meteo.missing_days.length > 0) {
      
      missingDays = {
        type: 'Jours manquants',
        date: this.items_replacements_meteo.missing_days.join(', '), // Regroupe toutes les dates en une seule chaîne
        id: 'missing_days' // Identifiant unique pour cette entrée
      };
    }

    // Retourner une combinaison des résultats valides
    return missingDays ? [...periods, missingDays] : periods;
  },
  hasAnyReplacements() {
    return (
      (this.items_replacements_meteo?.periods?.length > 0) ||
      (this.items_replacements_meteo?.missing_days?.length > 0)
    );
  }


  },

  watch: {
    view_reel: function() {
      this.filterTabIndices()
    },
    view_previ: function() {
      this.filterTabIndices()
    },
    indices_date_debut: function() {
      this.filterTabIndices()
    },
    indices_date_fin: function() {
      this.filterTabIndices()
    },
    indices_date_debut_indice_meteo_risk: function() {
      this.filterTabIndicesMeteoRisk()
    },
    indices_date_fin_indice_meteo_risk: function() {
      this.filterTabIndicesMeteoRisk()
    },

    choixSelected: function () {
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },

    // *ALTERNARIA ONLY
    view_reel_alt: function() {
      this.filterTabIndicesAlt()
    },
    view_previ_alt: function() {
      this.filterTabIndicesAlt()
    },
    indices_date_debut_alt: function() {
      this.filterTabIndicesAlt()
    },
    indices_date_fin_alt: function() {
      this.filterTabIndicesAlt()
    },

  },

  async mounted() {   

    // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();
    
    this.parcelle = await parcellesMildiouService.getInfosParcelle(this.$route.params.id);
    this.parcelle = this.parcelle[0];
    if (this.parcelle) {
      this.checkPlotOwnership();
    }   

    // this.all_products = await parcellesMildiouService.getInfosAllProducts(this.$route.params.id);

    this.is_alternaria_true = this.parcelle.alternaria;
    this.selectedOptionScenario = this.parcelle.scenario;
    
    this.id_station_in_this_parcelle = this.parcelle.iot_id;
    if(this.id_station_in_this_parcelle != null && this.id_station_in_this_parcelle != "") {
      this.station =  await parcellesMildiouService.getStation(this.id_station_in_this_parcelle);
      this.station = this.station[0];
    }

    // Charger Les indices climatiques si utilisateur en DESKTOP
    if(!this.isMobile){
      this.chargerIndicesClimatiques()
    }

    // Replacement METEO 
    this.items_replacements_meteo = await parcellesMildiouService.getAllReplacementsMeteoForThePlot(this.$route.params.id);

    this.parcelle_DM = await parcellesMildiouService.getInfosDMParcelle(this.$route.params.id);
    this.parcelle_stades = JSON.parse(this.parcelle.stades);

    this.parcelle_indices = await parcellesMildiouService.getIndicesParcelle(this.$route.params.id);
    this.parcelle_indices.forEach((item, i) => {
      item.index = i + 1;
    })
  
    this.$store.commit('mildiou/defineParcelleIndices', {indices:this.parcelle_indices})

    this.$store.commit('mildiou/defineParcelleIndicesHoraires', {indices:this.parcelle_indices_horaires})
    this.$store.commit('mildiou/defineParcelleIndicesMeteoRiskDataTable', {indices:this.indices_meteo_risk_data_table})

    this.dataloaded = true;


    // * MILDIOU
    if(this.parcelle_indices.length>0 ){ // SI EN DESKTOP uniquement
      this.chartOptions.xAxis.data = this.getTabChart('date');
      this.chartOptions.series[0].data = this.getTabChart('l10poids');
      this.chartOptions.series[1].data = this.getTabChart('l10spores');
      this.chartOptions.series[2].data = this.getTabChart('l10spospo');
      this.chartOptions.series[3].data = this.getTabChart('indexconta');
      /*this.chartOptions.series[4].data = this.getTabChart('parcelleprotegee');
      this.chartOptions.series[5].data = this.getTabChart('resistance_lessivage');
      this.chartOptions.series[6].data = this.getTabChart('risque');*/
      this.chartOptions.series[4].data = this.getTabChart('l10cumulpoidsconta');
      this.chartOptions.series[5].markLine.data = [{name: 'Seuil poids de contamination', yAxis: this.parcelle.seuiltrait}];
    
      this.chartOptionsHeatMap.calendar.range = this.getTabChartHeatMapRange(); //TODO a commenter en DEV et à de-commenter en PROD
      this.chartOptionsHeatMap.series.data = this.getTabChartHeatMap();
      this.chartOptionsDMHeatMap.calendar.range = this.getTabChartDMHeatMapRange();
      this.chartOptionsDMHeatMap.series.data = this.getTabChartDMHeatMap(); 

    }

    await this.fetchAndTransformData();

    this.scenario_code_DB = await parcellesMildiouService.getCodeScenario(this.$route.params.id); // Les choix de scenarios en DB
    
    let obj_null = {description: 'NULL', code:'NULL' }; //Ajout du 'NULL' aux choix precedents, pour permettre d'enlever le scenario
    this.scenario_code = this.scenario_code_DB.concat(obj_null);
    
        // ! SEULEMENT SI ALTERNARIA PRESENTE EN BDD !
        if(this.is_alternaria_true){
          this.parcelle_indices_alt = await parcellesMildiouService.getIndicesParcelleAlternaria(this.$route.params.id);
          this.parcelle_indices_horaires_alt = await parcellesMildiouService.getIndicesAlternariaHorairesParcelle(this.$route.params.id);

          this.$store.commit('mildiou/defineParcelleIndicesAlt', {indices:this.parcelle_indices_alt})
          this.$store.commit('mildiou/defineParcelleIndicesHorairesAlt', {indices:this.parcelle_indices_horaires_alt})

          if(this.parcelle_indices_alt.length>0){
            this.chartOptionsHeatMapAlternaria.calendar.range = this.getTabChartHeatMapRange(); // * TODO a commenter en DEV et à de-commenter en PROD
            this.chartOptionsHeatMapAlternaria.series.data = this.getTabChartHeatMapAlternaria();
          }
        }
    
        console.log(JSON.stringify(this.parcelle_stades));

  },
  
  methods: {

    async chargerIndicesClimatiques() {
      if (this.isMobile) {
        this.loading = true; // Activer l'icone de 'chargement' sur le bouton visible en mobile
      }
      try {
        let id_parcelle = this.$route.params.id;

        // * Obtenir l'ensemble des DONNEES de la PARCELLE, 
        this.data_calcul = await parcellesMildiouService.getCalculsMildiouPlot(id_parcelle)

        if (this.data_calcul.includes("Error")){
          console.log('Erreur lors de la recuperations des données pour indices climatiques');
          return;
        }
        // Obtenir les données de TEMP et HUMIDITE en passant par le calcul   
        let data_meteo = JSON.parse(this.data_calcul).data.meteo;

        let data_hum_temp = []; // Tableau final
        let lines = data_meteo.split("\r"); // Split des lignes

        // Boucle à partir de la deuxième ligne (après l'en-tête)
        for (let i = 1; i < lines.length; i++) {
          // Split des valeurs
          let values = lines[i].split(";");

          // Création de l'objet pour stocker les données
          let entry = {
            'date': values[0], // Date et heure
            'temperature': parseFloat(values[1]), // Température
            'hygrometrie': parseInt(values[2]), // Humidité
            'pluie': parseFloat(values[3]) // Pluie
          };

          // Calcul des indicateurs climatiques hum et temp
          entry['indice_temp'] = this.getTemperatureIndicator(entry.temperature);
          entry['indice_hum'] = this.getHumidityIndicator(entry.hygrometrie, entry.pluie);

          // Obtention des 'descriptions' des indices
          entry['description_temp'] = this.getTemperatureDescription(entry.temperature);
          entry['description_hum'] = this.getHumidityDescription(entry.hygrometrie, entry.pluie);

          // Ajout de l'entrée au résultat final
          data_hum_temp.push(entry);
        }

        // Affichage du résultat [date: "2024-03-01 00:00" hygrometrie: 90 indic_hum: 250 indic_temp: 130 pluie: 0.2 temperature: 6.3]

        ////////////////////////
        // Obtenir les données index conta , risque mildiou  etc... présent dans calcul_tab_2
        let parse_data_calcul = JSON.parse(this.data_calcul);
        let indice_horaire;
        let tab_2_data;

        if (parse_data_calcul.results && parse_data_calcul.results.tab_2) {
          // Extraire et traiter tab_2
          tab_2_data = parse_data_calcul.results.tab_2;
          indice_horaire = this.parseAndFilterTab2Data(tab_2_data);
        }

        if(this.isMobile){// Afficher la modal
          this.showModalIndicesClimatiqueMobile = true;
        }
    

        // Gestion de l'ajout du risque et contamination aux données précédentes si données disponibles dans INDICE HORAIRE
        if (indice_horaire === undefined) {
          this.indices_meteo_risk_data_table = this.indices_hum_temp_data;
        } else {
          this.indices_meteo_risk_data_table = this.addRiskContaToIndMeteo(indice_horaire, data_hum_temp);
        }

        if (this.isMobile) { // Enlever l'icone de chargement visible en mobile
          this.loading = false;
        }
      } catch (error) {
        console.error("Erreur lors du chargement des indices climatiques :", error);
        if (this.isMobile) { // Enlever l'icone de chargement visible en mobile
          this.loading = false;
        }
      }
    },
    
    getPositionCarte() {
      // Markers sur carte
      let array = [];

      // Affectation des données des parcelles
      let temp_parcelles_mildiou = this.isMobile ? this.parcelle : this.parcelle;

      if (temp_parcelles_mildiou['latitude'] != null && temp_parcelles_mildiou['longitude'] != null) {
          array.push({
              id: temp_parcelles_mildiou['id'],
              coordinates: [temp_parcelles_mildiou['latitude'], temp_parcelles_mildiou['longitude']],
              imageUrl: require('@/assets/map_icon_grey.png')
          });

          // Définir le centre de la carte sur les coordonnées du premier marqueur
          this.carte.center = array[0].coordinates;
      }

      this.carte.markers = array;
      this.carte.zoom = 7;
    },
    
    launchAddStadesToPlot(form_parcelle_obs,stade){
      this.message_retour = parcellesMildiouService.addStadePlotInApi(form_parcelle_obs,stade);
    },
    
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },

    centerUpdated (center) {
      this.center = center;
    },

    GoPageGestionParcelle(type_intervention) {
      let id_plot = this.$route.params.id;
      this.$store.dispatch('mildiou/setSelectedParcelleFromDetail', { id: id_plot,intervention: type_intervention});
      this.$router.push({ name: 'gestion_parcelles' });
  },

  /**
   * Verifie si la parcelle en question est bien attribuée à l'utilisateur connecté et donc accorder des droits de gestion de la parcelle
   */
  // async isMyPlot(){
  //   return this.parcelle.farmer.toUpperCase() === this.$store.state.user.login_user.toUpperCase();
  // },


    // Modal 
      closeChoix() {
        this.$store.dispatch("choix/unsetChoix");
      },

      async scenario_code_change(){
        this.lauchSetCodeScenario(this.selectedOptionScenario);
        this.closeChoix()
      },

      modal_scenario_code_change(){
        this.$store.dispatch("choix/setChoix", {
          question: "Etes-vous sûr de vouloir mettre ce scenario ?",
          choix: {
            choix1: { text: "Oui", function: "scenario_code_change" },
            choix2: { text: "Non", function: "closeChoix" },
          },
        });
      },
    //Fin Modal 

    // Code scenario 
    lauchSetCodeScenario(code_scenario){
          if(code_scenario ==='NULL'){code_scenario = null}// Enlever le scenario de la BDD
          let id_parcelle = this.parcelle['id'];
          parcellesMildiouService.setCodeScenario(code_scenario,id_parcelle);
        },

    getInfoStation(nbr_heure) {
      if (nbr_heure < 6) {
        return `Historique de données météo complet et actualisé dans les 24 dernières heures.`
      } else if (nbr_heure >= 6 && nbr_heure < 13) {
        return `Identification d’un ou plusieurs trous de moins de 6h de données météo, sans impact sur le fonctionnement des calculs.`
      } else if (nbr_heure >= 13) {
        return `Identification d’un ou plusieurs trous de 6h ou plus de données météo, bloquant les calculs. Il est nécessaire de les combler pour les relancer à contactez l’opérateur météo.`
      }
    },  
    filterTabIndices(){
      this.parcelle_indices = this.$store.getters['mildiou/FilterTabIndices']('day',this.view_reel,this.view_previ,this.indices_date_debut,this.indices_date_fin)
      this.parcelle_indices_horaires = this.$store.getters['mildiou/FilterTabIndices']('hour',this.view_reel,this.view_previ,this.indices_date_debut,this.indices_date_fin)
    },
    filterTabIndicesAlt(){
      this.parcelle_indices_alt = this.$store.getters['mildiou/FilterTabIndicesAlt']('day',this.view_reel_alt,this.view_previ_alt,this.indices_date_debut_alt,this.indices_date_fin_alt)
      this.parcelle_indices_horaires_alt = this.$store.getters['mildiou/FilterTabIndicesAlt']('hour',this.view_reel_alt,this.view_previ_alt,this.indices_date_debut_alt,this.indices_date_fin_alt)
    },
    filterTabIndicesMeteoRisk(){
      this.indices_meteo_risk_data_table = this.$store.getters['mildiou/FilterTabIndicesMeteoRisk'](this.indices_date_debut_indice_meteo_risk,this.indices_date_fin_indice_meteo_risk)
    },
    showItem(id_station, type = null) {
        // Stockage de la parcelle sélectionnée dans le store
        //this.$store.dispatch("parcelle/setParcelleSelected", parcelle);
        let route = this.$router
          .resolve({
            name: 'detail-station',
            params: {
              id: id_station
            }
          });
        if (type == 'href') {
          return route.href;
        } else {
          window.open(route.href);
        }
      },
    getPreconisation(){
      var conseil = this.$t("mildiou.conseil."+this.parcelle.conseil)
      var chars = {'{DATE}':this.parcelle.date,'{DATE-TRAIT}':this.parcelle.datetrait};
      conseil = conseil.replace(/{DATE}|{DATE-TRAIT}/gi, m => chars[m]);
      return conseil
    },


    getStylePreconisation(){
      var style = "color:#7ec700;"
      var tabAlert = ['TRAIT','TRAITRETRO','TRAITCURATIF']
      if(tabAlert.includes(this.parcelle.conseil)){
        style = style = "color:red;"
      }

      return style
    },
    getTabChart(nom_var){
      var array=[]
         var indices = this.parcelle_indices
        indices.forEach(element => 
          array.push(element[nom_var]),
        );


      return array
    },

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },
    getTabChartHeatMap(){
      var array=[]

      var indices = this.parcelle_indices
      
      indices.forEach(element => {
        var nomRisque = this.getNomRisque(element['risque'])
        var infoRisque = this.getInfoRisque(element['risque'])
        array.push([
          element['date'],
          element['parcelleprotegee'],
          element['risque'],
          nomRisque,
          infoRisque
        ])
      }
      );
    return array
    },

    getTabChartHeatMapAlternaria(){
      var array=[]

      var indices = this.parcelle_indices_alt
      
      indices.forEach(element => {
        var nomRisque = this.getNomRisque(element['risque'])
        var infoRisque = this.getInfoRisque(element['risque'])
        array.push([
          element['date'],
          element['parcelleprotegee'],
          element['alt_risk'],
          nomRisque,
          infoRisque
        ])
      }
      );
    return array
    },
    

    getTabChartHeatMapRange(){
      var year = this.parcelle_indices[0]['date']
      year = year.substring(0,4)
      var debut = year+'-03-01'
      var fin = year+'-10-31'

      return [debut,fin]
    },

    getTabChartDMHeatMap() {
    var array = [];

    var DM = this.parcelle_DM;

    DM.forEach(element => {
        //Récupérer les trois valeurs
        let t_value = element['t_values'];
        let u_value = element['u_values'];
        let rr_value = element['rr_values'];

        //Calculer la valeur la plus faible des trois
        let min_value = Math.min(t_value, u_value, rr_value);
        let missing_hours = 24 - min_value;
        //Récupérer les informations de la station pour les heures manquantes
        let infoLegend = this.getInfoStation(missing_hours);

        // Ajouter les données dans le tableau
        array.push([
            element['date'],
            missing_hours,
            infoLegend
        ]);
        });
        
        return array;
    },
    
    getTabChartDMHeatMapRange(){
      var year = this.parcelle_DM[0]['date']
      year = year.substring(0,4)
      var debut = year+'-03-01'
      var fin = year+'-10-31'

      return [debut,fin]
    },
    getNomRisque (statut) {
        if (statut == 10) return 'Pas de risque'
        else if (statut == 50) return 'Elevé'
        else if (statut == 40) return 'Vigilance'
        else return ''
    },
    getColorRisque (statut) {
        if (statut == 10) return 'green'
        else if (statut == 50) return 'red'
        else if (statut == 40) return 'orange'
        else return 'grey'
    },
    getIconRisque (statut) {
        if (statut == 10) return 'mdi-emoticon-happy-outline'
        else if (statut == 50) return 'mdi-emoticon-sad-outline'
        else if (statut == 40) return 'mdi-emoticon-neutral-outline'
        else return 'grey'
    },

    getIconRisqueIndiceMeteo (risque) {
        if (risque == 10) return 'mdi-emoticon-happy-outline'
        else if (risque == 50) return 'mdi-emoticon-sad-outline'
        else if (risque == 40) return 'mdi-emoticon-neutral-outline'
        else return '-'
    },

    getInfoRisque (statut) {
        if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante. Il n'y a donc <b>pas de risque</b> de contamination sur la parcelle."
        else if (statut == 50) return "Le climat et la quantité de spores sont favorables, il y a donc la présence d'un <b>risque de contamination</b> sur la parcelle."
        else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de <b>rester vigilant</b> au cas où les conditions climatiques deviennent favorables. Un risque de contamination serait alors présent."
        else return ''
    },

    getInfoRisqueTooltip (statut) {
        if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante.\n  Il n'y a donc pas de risque de contamination sur la parcelle."
        else if (statut == 50) return "Le climat et la quantité de spores sont favorables,\n  il y a donc la présence d'un risque de contamination sur la parcelle."
        else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. \n Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de rester vigilant au cas où les conditions climatiques deviennent favorables.\n  Un risque de contamination serait alors présent."
        else return ''
    },

    getTexteRisque (statut) {
        if (statut == 10) return "faible"
        else if (statut == 50) return "moyen"
        else if (statut == 40) return "élevé"
        else return ''
    },
    getTexteLessivage (lessivage) {
        if (lessivage < 200 && lessivage > 0) return lessivage
        else return "-"
    },
    getColorProtection (statut) {
        if (statut == 1) return 'green'
        else return 'red'
    },
    getIconRisk(statut) {
      if (statut === 10) {
        return 'mdi-circle' // classe CSS pour une pastille verte
       } else if (statut === 50) {
        return 'mdi-circle' // classe CSS pour une pastille rouge
      }
    },
    getColorRisk (statut) {
        if (statut == 10) return 'green'
        else return 'red'
    },
    getIconProtection (statut) {
        if (statut == 1) return 'mdi-shield'
        else return 'mdi-shield-off'
    },
    getInfoProtection (statut) {
        if (statut == 1) return 'Parcelle protégée'
        else return 'Parcelle non protégée'
    },
    getDateHeure (dateIso) {
      var date = dateIso.split("T")[0];
      
      const dateObj = new Date(dateIso);
      var hour = dateObj.getHours();
      var minute = dateObj.getMinutes();

      if(minute==0){
        minute = ''
      }

      return date+' '+hour+'h'+minute
    },
    goTo(nomDiv){
      this.$vuetify.goTo('#'+nomDiv)
    },
    rowStyle(item) {
      if (item.conseil == 'PASCONSEILPREVI') {
        return ["orange-line"];
      } else {
        return ''
      }
    },
    rowStyleAlt(item) {
      const today = new Date();
      // Convertir la date de l'item en objet Date
      let itemDate = new Date(item.date);
        // Comparer les dates
      if (itemDate > today) {
        return ["orange-line"];
      } else {
        return ''
      }
    },
    rowStyleMeteo(item) {
      let date_ligne = new Date(item.d)
      if (date_ligne > new Date(this.station.last_data)) {
        return ["orange-line"];
      } else {
        return ''
      }
    },

    sortTab(tab,orderBy) {
      tab=tab.sort((a,b)=> (a[orderBy] < b[orderBy] ? 1 : -1))
      return tab
    },

    getNomFichier(type){
      var now = new Date()
      now = getDate(now,'yyyymmdd')

      if(type == 'day'){
        return now+'_ExportMildiou_'+this.parcelle.farmer+'_parcelle_'+this.parcelle.id
      } else {
        return now+'_ExportMildiouHoraire_'+this.parcelle.farmer+'_parcelle_'+this.parcelle.id
      }
    },

    evaluerSensibiliteAlt(evolution_physiological_sensitivity_al) {
      if (evolution_physiological_sensitivity_al == 10) {
        return "Insensible";
      } else if (evolution_physiological_sensitivity_al == 40) {
        return "Presque sensible";
      } else if (evolution_physiological_sensitivity_al ==50) {
        return "Sensible";
      }
    },

    getIconRisqueAlt (statut) {
        if (statut == 10) return 'mdi-emoticon-happy-outline'
        else if (statut == 50) return 'mdi-emoticon-sad-outline'
        else if (statut == 40) return 'mdi-emoticon-neutral-outline'
        else return 'grey'
    },

    // Obtenir les indices par type d'indicateur desiré
    getExtractDataIndicator(data, indicator) {
      return data
        .filter(element => element.indicator === indicator)
        .map(element => {
          // Utiliser split pour séparer la date et l'heure
          const [date, fullTime] = element.datetime.split(' ');

          // Utiliser split pour séparer l'heure et ignorer le fuseau horaire
          const [hour, minute, second] = fullTime.split(':');

          return {
            date: date,
            time: `${hour}:${minute}:${second}`,
            value: element.value
          };
        });
    },

  getDataHeatMapByIndice(data, indice) {
    let arr_data_indice = [];
    // Filtrer les données pour l'indice spécifié
    let filteredData = data.filter(entry => entry.value === parseInt(indice));
    // Parcourir les données filtrées et les formater
    filteredData.forEach(({ date, time, value }) => {
      // Supprimer le "+01" de la valeur de time
      let timeWithoutOffset = time.replace(/\+\d{2}/, '');
      // Diviser la chaîne de temps en heures, minutes et secondes
      let timeParts = timeWithoutOffset.split(':');
      // Utiliser uniquement l'heure
      let heure = parseInt(timeParts[0]);
      // Creer un tableau de tableau exploitable 
      arr_data_indice.push([date, heure, value]);
    });
    return arr_data_indice;
  },

    getYaxisHeatMapIndice(){
      let y_axe = [];

      //Se baser sur 24 heures
      for (let index = 0; index < 24; index++) {
        y_axe.push(index);
      }
      return y_axe
    },

    async fetchAndTransformData() {
    // 1. Obtenez les données
    this.treatments_skipped = await parcellesMildiouService.getAllTreatmentSkipped(this.$route.params.id);

      // Ajouter des logs pour vérifier les données obtenues
    // 2. Vérifiez si plot_skip est null
    if (this.treatments_skipped.some(item => item.plot_skip === null)) {
      return;
    }

       // 2. Parsez le JSON imbriqué et transformez les données
      this.parsedTreatments = this.treatments_skipped.flatMap(item => JSON.parse(item.plot_skip));

      // 3. Filtrez les traitements pour le mildiou
      this.lateblightTreatments = this.parsedTreatments.filter(traitement => traitement.disease === 'lateblight');
      // 3. Filtrez les traitements pour l'alternariose
      this.alternariaTreatments = this.parsedTreatments.filter(traitement => traitement.disease === 'alternaria');
    
  },

  formatDateTraitementEvite(dateString) {
    let date = new Date(dateString);
    return date.toLocaleDateString();
  },

    /**
     * Au click du bouton accessibles aux admin : Lancer calcul MILDIOU et obtenir des données en retour visibles sous l'accordeon
     */
    async launchCalculsMildiou() {

      //Si accordéons déjà remplit d'éléments d'une précedente requête, => les vider.
      if (this.section_data[0].subSections) {this.section_data[0].subSections = []}
      if (this.section_calculs[0].subSections) {this.section_calculs[0].subSections = []}

      let id_parcelle = this.$route.params.id;
      
      // * Obtenir l'ensemble des DONNEES de la PARCELLE, .....  puis les separer en 2 parties => DATA et CALCUL
      this.data_calcul = await parcellesMildiouService.getCalculsMildiouPlot(id_parcelle);

      var temp_obj = {};
      ////////////////////////////////////////////
      // * La partie DATA des données récupérées ;  correspondant à DONNEES sur l'interface.

        temp_obj = JSON.parse(this.data_calcul);
        this.data_calcul_part_data = temp_obj.data;
        let numEntitiesData = Object.keys(this.data_calcul_part_data).length;

        // Mettre les donnees obtenues ("DATA") dans le content de l accordeon DONNEES
        this.section_data[0].content = this.data_calcul_part_data;

        // * Création des sous niveau (subLvl) de l'accordeon en fonction du nombre d'entitées obtenues précedement
        for (let i = 0; i < numEntitiesData; i++) {

          let str_content_data = Object.values(this.data_calcul_part_data)[i].toString();
          let content_with_multiligne = str_content_data.split(/\r/)

          // * Création du HEADER du futur V-DATA-TABLE du sous-niveau de l'accordeon.
          let arr_header_data = {} ;
          let temp_first_index_data = content_with_multiligne[0].split(";"); 
          arr_header_data = temp_first_index_data;
      
          let keys = content_with_multiligne[0].split(';');
            // * Creation de la variable HEADER acceptable pour le futur V-DATA-TABLE => { text: "date", value: "date" }, :
              let header_data = arr_header_data.map(item => {
                return {text: item.charAt(0).toUpperCase() + item.slice(1), value: item};
              });
              
          // * Recuperer le reste des Données qui alimenteront la partie ITEMS du futur V-DATA-TABLE (avec le header précedent).
          let rows_data = content_with_multiligne.slice(1).map((item) => {
            let values = item.split(';');
            let obj = keys.reduce((acc, key, index) => {
              acc[key] = values[index];
              return acc;
            }, {});
            return obj;
          });

          let newSubMenu = {};// * Alimentation du nouveau sous menu, avec soit : 
          // - les composantes HEADER et ROWS_DATA précedement obtenues si V-DATA-TABLE nécessaire.
          if(rows_data.length>1){
            newSubMenu = {
              need_v_data_table: true,
              title: Object.keys(this.data_calcul_part_data)[i],
              headers_data : header_data ,
              items_data : rows_data,
              isExpanded: false,
            };
          }else{
            // - un <p>paragraphe</p> si le format  V-DATA-TABLE n'est PAS VRAIMENT nécessaire.
            newSubMenu = {
              need_v_data_table:false,
              title: Object.keys(this.data_calcul_part_data)[i],
              content : content_with_multiligne,
              isExpanded: false,
            };
          }
          //Ajout des données finalisées dans le sous-menu de l'accordeon CALCULS.
          this.section_data[0].subSections.push(newSubMenu);
        }

      /////////////////////////////////////////////
      // * La partie RESULTS des données récupérées ; correspondant à CALCULS sur l'interface.

          temp_obj = JSON.parse(this.data_calcul);
          this.data_calcul_part_results = temp_obj.results;
          let numEntitiesResults = Object.keys(this.data_calcul_part_results).length;

          // Mettre les donnees obtenues ("results") dans le content de l accordeon CALCULS
          this.section_calculs[0].content = this.data_calcul_part_results;

           // * Création des sous niveau (subLvl) de l'accordeon en fonction du nombre d'entitées obtenues précedement
          for (let i = 0; i < numEntitiesResults; i++) {

          let str_content_data = Object.values(this.data_calcul_part_results)[i].toString();
          let content_with_multiligne = str_content_data.split(/\r/)

            // * Création du HEADER du futur V-DATA-TABLE du sous-niveau de l'accordeon.
            let arr_header_data = {} ;
            let temp_first_index_data = content_with_multiligne[0].split(";"); 
            arr_header_data = temp_first_index_data;

            let keys = content_with_multiligne[0].split(';');
            // * Creation de la variable HEADER acceptable pour le futur V-DATA-TABLE => { text: "date", value: "date" }, :
              let header_data = arr_header_data.map(item => {
                return {text: item.charAt(0).toUpperCase() + item.slice(1), value: item};
              });
          
            // * Recuperer le reste des Données qui alimenteront la partie ITEMS du futur V-DATA-TABLE (avec le header précedent).
            let rows_data = content_with_multiligne.slice(1).map((item) => {
              let values = item.split(';');
              let obj = keys.reduce((acc, key, index) => {
                acc[key] = values[index];
                return acc;
                }, {});
                return obj;
              });  

              let newSubMenu = {};// * Alimentation du nouveau sous menu, avec soit : 
              // - les composantes HEADER et ROWS_DATA précedement obtenues si V-DATA-TABLE nécessaire.
              if(rows_data.length>1){
                newSubMenu = {
                  need_v_data_table: true,
                  title: Object.keys(this.data_calcul_part_results)[i],
                  headers_data : header_data ,
                  items_data : rows_data,
                  isExpanded: false,
                };
              }else{
                // - un <p>paragraphe</p> si le format  V-DATA-TABLE n'est PAS VRAIMENT nécessaire.
                newSubMenu = {
                  need_v_data_table:false,
                  title: Object.keys(this.data_calcul_part_results)[i],
                  content : content_with_multiligne,
                  isExpanded: false,
                };
              }
            //Ajout des données finalisées dans le sous-menu de l'accordeon 'CALCULS' .
            this.section_calculs[0].subSections.push(newSubMenu);
        }
    },

    /**
   * Lancer calcul MILDIOU et obtenir des données à utiliser pour les données horaires de la parcelle au click sur DONNEES HORAIRES
   */
    async launchCalculsMildiouForIndicesHoraires() {
      let id_parcelle = this.$route.params.id;

      if (!this.data_calcul) { // Obtenir l'ensemble des DONNEES de la PARCELLE en lancant le CALCUL
        this.data_calcul = await parcellesMildiouService.getCalculsMildiouPlot(id_parcelle);

      }  // Données déjà récuperée lors d'un précedent appel
        let parse_data_calcul = JSON.parse(this.data_calcul);
        let tab_2_data;

        if (parse_data_calcul.results && parse_data_calcul.results.tab_2) {
          // Extraire et traiter tab_2
          tab_2_data = parse_data_calcul.results.tab_2;
          let filteredData = this.parseAndFilterTab2Data(tab_2_data);

          // Ajout de la nouvelle propriété datetime
          filteredData = filteredData.map(item => {
            if (item.date && item.heure) {
              item.datetime = `${item.date} ${item.heure}`;
            }
            return item;
          });

          //Change  la notation scientifique en nombres décimaux 
          filteredData.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (key.startsWith('l10')) {
                    let value = obj[key];
                    // Vérifie si la valeur est une chaîne au format de notation scientifique
                    if (/^\d+(\.\d+)?[eE][+-]?\d+$/.test(value)) {
                        // Convertit la valeur en nombre décimal
                        obj[key] = parseFloat(value);
                    }
                }
            });
        });
          this.parcelle_indices_horaires = filteredData;
      }
    },

      // Fonction pour tronquer les valeurs à deux chiffres après la virgule
      truncateToTwoDecimals(value) {
        if (!isNaN(value)) {
            let parts = value.split('.');
            if (parts.length === 2 && parts[1].length > 2) {
                return parts[0] + '.' + parts[1].substring(0, 2);
            }
        }
        return value;
      },

      // Fonction pour calculer l'indicateur de température
      getTemperatureIndicator(temp) {
          if (temp === null) {
              return null;
          } else if (temp < 6) {
              return 110;
          } else if (temp < 10 && temp >= 6) {
              return 130;
          } else if (temp < 14 && temp >= 10) {
              return 140;
          } else if (temp < 18 && temp >= 14) {
              return 150;
          } else if (temp < 30 && temp >= 18) {
              return 190; // Valeur MAX
          } else {
              return 100; // Valeur MIN
          }
      },

      // Fonction pour calculer l'indicateur d'humidité
      getHumidityIndicator(hum, rain) {
          if (hum === null || rain === null) {
              return null;
          } else if (hum < 87 && rain == 0) {
              return 200; // Valeur MIN
          } else if (hum >= 87 && rain > 0) {
              return 250;
          } else {
              return 250; // Valeur MAX
          }
      },

      // Fonction pour calculer la description de l'indicateur de température
      getTemperatureDescription(temp) {
        if (temp === null ){
            return ' - ';
        } else if (temp < 6) {
              return '[ < 6°C]';
          } else if (temp < 10 && temp >= 6) {
              return '[6°C ; 10°C]';
          } else if (temp < 14 && temp >= 10) {
              return '[10°C ; 14°C]';
          } else if (temp < 18 && temp >= 14) {
              return '[14°C ; 18°C]';
          } else if (temp < 30 && temp >= 18) {
              return '[18°C ; 30°C]';
          } else if (temp >= 30){
              return '[ >= 30°C]';
          } 
      },

      // Fonction pour calculer la description de l'indicateur d'humidité
      getHumidityDescription(hum, rain) {

        if (hum === null || rain === null){
            return ' - ';
        } else if(hum < 87 && rain == 0) {
              return '[H < 87% et P = 0 mm]';
          } else if (hum >= 87 && rain > 0) {
              return '[H >= 87% ou P > 0 mm]';
          } else {
            return '[H >= 87% ou P > 0 mm]';
          }
      },

    parseAndFilterTab2Data(tabData) {
      let lines = tabData.split('\r');
      let headers = lines[0].split(';');
      let rows = lines.slice(1);

      // Mapping des noms de colonnes
      let columnMapping = {
          "date": "date",
          "heure": "heure",
          "L10SPORES": "l10spores",
          "GRAVI": "indexconta", // arrondi(gravi, 2) x 4 +8
          "L10POIDS": "l10poids",
          "L10SPOSPO": "l10spospo",
          "PARCELLEPROTEGEE":"parcelleprotegee",
          "RISQUE":"risque"
      };

      // Indices des colonnes à conserver
      let indicesToKeep = headers.map((header, index) => columnMapping[header] ? index : -1).filter(index => index !== -1);

      // Nouveau tableau de données filtrées
      let filteredData = rows.map(row => {
          let values = row.split(';');
          let filteredRow = {};
          indicesToKeep.forEach(index => {
            let header = headers[index];
            let value = values[index];

            // Applique la troncation si c'est une colonne numérique spécifiée
            if (["L10SPORES", "L10POIDS", "L10SPOSPO"].includes(header)) {
                value = this.truncateToTwoDecimals(value);
            }

            // Applique le calcul spécifique pour "GRAVI"
            if (header === "GRAVI") {
              let graviValue = parseFloat(value);
              // Calcul de l'index conta
              graviValue = (graviValue * 4) + 8;
              // Troncature à deux décimales
              graviValue = Math.floor(graviValue * 100) / 100;
              value = graviValue.toString();
            }

            filteredRow[columnMapping[header]] = value;
        });
        return filteredRow;
    });

    return filteredData;
},


    /**
     * Gerer la fermeture / ouverture dans l'accordeons DATA
     */
    toggleFirstLvlData(index) {
      this.section_data[index].isExpanded = !this.section_data[index].isExpanded;
    },
    /**
     * Gerer la fermeture / ouverture des sous-niveaux l'accordeons DATA
     */
    toggleSubLvlData(sectionIndex, subIndex) {
      this.section_data[sectionIndex].subSections[subIndex].isExpanded = !this.section_data[sectionIndex].subSections[subIndex].isExpanded;
    },
     /**
     * Gerer la fermeture / ouverture dans l'accordeons CALCULS
     */
    toggleFirstLvlCalcul(index) {
      this.section_calculs[index].isExpanded = !this.section_calculs[index].isExpanded;
    },
    /**
     * Gerer la fermeture / ouverture des sous-niveaux l'accordeons CALCULS
     */
    toggleSubLvlCalcul(sectionIndex, subIndex) {
      this.section_calculs[sectionIndex].subSections[subIndex].isExpanded = !this.section_calculs[sectionIndex].subSections[subIndex].isExpanded;
    },

    getHumClass(value) {
      return {
        'hum-red': value === 250,
        'hum-green': value === 200
      };
    },

    getTempClass(value) {
      return {
        'temp-light-pink': value === 100,
        'temp-green': value === 110,
        'temp-light-red': value === 130,
        'temp-dark-red': value === 140,
        'temp-darker-red': value === 150,
        'temp-red': value === 190
      };
    },


    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}h${date.getMinutes().toString().padStart(2, '0')}`;
      return formattedDate;
    },


    getHumTooltip(value) {
      let tooltipText = 'Humidité ';
      
      if (value == 200) {
        tooltipText += '<87% et P = 0 mm \n Absence de risque ';
      } else if (value == 250) {
        tooltipText += '>= 87% ou P > 0 mm \n Présence de risque ';
      } 

      return tooltipText;
    },

    getTempTooltip(value) {
      let tooltipText = ' Température ';
      
      if (value == 110 ) {
        tooltipText += ' <6°C \n Absence de risque';
        //Défavorable : Arrêt du cycle de prolifération pouvant aller jusqu\'à la destruction d\'une partie des spores sous 4°C';
      } else if (value == 130 ) {
        tooltipText += ' entre 6°C et 10°C \n Augmentation (faible) ';
        //Favorable';
      } else if (value == 140) {
        tooltipText += ' entre 10°C et 14°C \n Augmentation (moyen)';
        //Favorable';
      } else if (value == 150) {
        tooltipText += ' entre 14°C et 18°C \n Augmentation (fort)';
        //Très favorable';
      } else if (value == 190) {
        tooltipText += ' entre 18°C et 30°C \n Augmentation (très fort)';
        //Très favorable, Optimum';
      } else if (value == 100) {
        tooltipText += ' >30°C \n Diminution du risque';
        //Très défavorable : destruction d\'une partie des spores';
      }

      return tooltipText;
    },

    /**
     * Méthode pour ajouter "risque mildiou" et "indexconta" à indices_hum_temp_data
     */
    addRiskContaToIndMeteo(indices_horaires, indices_hum_temp) {
      let updatedIndicesHumTemp = [...indices_hum_temp];

      // Trouver la date de début dans indices_horaires
      let startDateIndicesRisque = indices_horaires[0].date;

      // Filtrer les éléments de notre variable indices HUM et TEMP à partir de la même date de début que celle de indices_horaires (01/03/YYYY)
      let filteredIndicesHumTemp = updatedIndicesHumTemp.filter(item => {
        let datetimeIndiceHumTemp = item.date;
        return datetimeIndiceHumTemp >= startDateIndicesRisque;
      });

      // Boucler sur les variables et ajouter le risque et index contaet et autres indicateurs à notre variable filteredIndicesHumTemp
      for (let i = 0; i < filteredIndicesHumTemp.length; i++){
          let datetimeIndiceRisqueHoraire = new Date(indices_horaires[i].date + 'T' + indices_horaires[i].heure);
        // Recherche de la date correspondante dans filteredIndicesHumTemp
        let correspondingIndex = filteredIndicesHumTemp.findIndex(item => {
          let datetime2 = new Date(item.date);
          // Comparaison des dates
          return datetimeIndiceRisqueHoraire.getTime() === datetime2.getTime();
        });

        // Si une correspondance est trouvée, mettre à jour les valeurs en y ajoutant les nouvelles proprietees
        if (correspondingIndex !== -1) {
          filteredIndicesHumTemp[correspondingIndex].risque = indices_horaires[i].risque;
          filteredIndicesHumTemp[correspondingIndex].indexconta = indices_horaires[i].indexconta;
          filteredIndicesHumTemp[correspondingIndex].l10poids = indices_horaires[i].l10poids;
          filteredIndicesHumTemp[correspondingIndex].l10spospo = indices_horaires[i].l10spospo;
          filteredIndicesHumTemp[correspondingIndex].l10spores = indices_horaires[i].l10spores;
        }
      }
      return filteredIndicesHumTemp;
    },

    getDescriptionForIndiceTemp(value) {
      if (value === 110) {
          return "[ < 6°C]";
      } else if (value === 130) {
          return "[6°C ; 10°C]";
      } else if (value === 140) {
          return "[10°C ; 14°C]";
      } else if (value === 150) {
          return "[14°C ; 18°C]";
      } else if (value === 190) {
          return "[18°C ; 30°C]";
      } else if (value === 100) {
          return "[ > 30°C]";
      } else {
          return " - "; // Retourne un - si la valeur n'est pas reconnue
      }
    },

    getDescriptionForIndiceHum(value) {
      if (value === 200) {
          return "[H<87% et P = 0 mm]";
      } else if (value === 250) {
          return "[H>= 87% ou P > 0 mm]";
      }  else {
          return " - "; // Retourne un - si la valeur n'est pas reconnue
      }
    },

    formatUTCDate(date) {
      // Créer un objet Date à partir de la chaîne de date fournie
      var utcDate = new Date(date);
        // Ignorer les minutes et les secondes etc...
        utcDate.setUTCMinutes(0);
        utcDate.setUTCSeconds(0);
        utcDate.setUTCMilliseconds(0);

        // Formater la date au format ISO 8601 avec le suffixe "Z" et uniquement jusqu'aux secondes pour correspondre au format des API
        var formattedDate = utcDate.toISOString().slice(0, -5) + "Z";
        return formattedDate;
      },

    submitFormAddStade() {
    // Créer un tableau d'objets avec les noms des stades et leurs dates respectives
    let stades_data = [
        { "name": "PLANTATION", "date": this.parcelle_stades.plantation},
        { "name": "30PERCENT-EMERGENCE", "date": this.parcelle_stades.levee30},
        { "name": "RAPID-HAULM-GROWTH", "date": this.parcelle_stades.croissanceactive},
        { "name": "STABLE-CANOPY", "date": this.parcelle_stades.croissancestabilisee},
        { "name": "HAULM-SENESCENCE", "date": this.parcelle_stades.debutsenescence},
        { "name": "HAULM-DESSICATION", "date":this.parcelle_stades.defanage }
      ];


    // Appel de la méthode pour ajouter les stades à la parcelle
    this.launchAddStadesToPlot(this.form_parcelle_stades,stades_data);
    },


    //////////////////////////////////////////////
    // * REPLACEMENT METEO DE LA PARCELLE

    // Fonction pour mettre à jour les données après action CRUD sur les replacements meteo
    updateDataAfterAction(id_plot, successMessage) {
      parcellesMildiouService.getAllReplacementsMeteoForThePlot(id_plot)
        .then(data_replacements_meteo => {
          this.items_replacements_meteo = data_replacements_meteo || {};
          alert(successMessage);
          this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });


          parcellesMildiouService.getInfosDMParcelle(id_plot)
            .then((data_meteo) => {
              this.parcelle_DM = data_meteo;
              this.chartOptionsDMHeatMap.series.data = this.getTabChartDMHeatMap();
            })
            .catch(error => {
              console.error("Erreur lors de la récupération des données mises à jour:", error);
            });
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des remplacements météo:", error);
        });
    },

    handleCheckboxChange() {
        // Réinitialiser les valeurs des champs de date si le checkbox est coché
        if (this.form_check_box_gap_meteo) {
            this.form_date_start_replacement_meteo = '';
            this.form_date_end_replacement_meteo = '';
        }
    },

    editIReplacementMeteo(item) {
      // Extraire les dates de début et de fin à partir de la chaîne formatée
      let datePattern = /Du (\d{4}-\d{2}-\d{2}) au (\d{4}-\d{2}-\d{2})/;
      let matches = item.date.match(datePattern);

      if (matches) {
        // Stocker la date de début initiale
        this.initial_date_start_replacement_meteo = matches[1];
        this.initial_date_end_replacement_meteo = matches[2];

        // Assigner les dates extraites à la modal
        this.form_date_start_replacement_meteo = matches[1];
        this.form_date_end_replacement_meteo = matches[2];
      }
        this.select_replacement_meteo = item;
        this.showModalEditReplacementMeteo = true;
    },

    submitFormPutReplacement() {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      // Créer un objet contenant les données du traitement
        let replacement_data = [{
            'select_begin_replacement_meteo':this.initial_date_start_replacement_meteo ,
            "begin": this.form_date_start_replacement_meteo,
            "end": this.form_date_end_replacement_meteo
        }];
        let id_plot = this.$route.params.id;

        //  Modification météo de la parcelle 
        parcellesMildiouService.editOneReplacementsMeteoForThePlot(id_plot, replacement_data)
         // Une fois que l'ajout bien réalisé, recuperation des données mises à jour
          .then(() => {
            this.updateDataAfterAction(id_plot, 'Modification de remplacement de parcelle effectué avec succès !');
            this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

          })
          .catch(error => {
            console.error("Erreur lors de la modification de remplacement de parcelle:", error);
          });
        
      // Réinitialiser les champs après la soumission du formulaire
      this.form_date_start_replacement_meteo = '';
      this.form_date_end_replacement_meteo = '';
      this.select_replacement_meteo = '';

      this.showModalEditReplacementMeteo = false;
    },

    submitFormAddReplacement() {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      let id_plot = this.$route.params.id;
      // * Vérifier si l'utilisateur a coché la case pour combler les manques météo
      if (this.form_check_box_gap_meteo) {

          // Appeler la fonction pour chaque ID de parcelle que l'utilisateur a sélectionné
          parcellesMildiouService.searchFoundMissingReplacementMeteoForPlot(id_plot)
            .then(() => {
              this.updateDataAfterAction(id_plot,'Recherche et intégration des dates de données météos manquantes effectué avec succès !');
            })
            .catch(error => {
              console.error("Erreur lors de l'ajout de remplacement de parcelle:", error);
            });

        // Réinitialiser les champs après la soumission du formulaire
        this.form_check_box_gap_meteo = false;

      } else {

        // Créer un objet contenant les données de l'ajout
          let replacement_data = [{
            "begin": this.form_date_start_replacement_meteo,
            "end": this.form_date_end_replacement_meteo
            //"all_gap": this.form_check_box_gap_meteo
          }];
        // ➕ Ajout météo de la parcelle 
        parcellesMildiouService.addReplacementPlot(id_plot, replacement_data)
          .then(() => { // Une fois l'ajout bien réalisé, recuperation des données mises à jour
            this.updateDataAfterAction(id_plot, 'Ajout de remplacement de parcelle effectué avec succès !');
            this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
          })
          .catch(error => {
            console.error("Erreur lors de l'ajout de remplacement de parcelle:", error);
          });

        // Réinitialiser les champs après la soumission du formulaire
        this.form_date_start_replacement_meteo = '';
        this.form_date_end_replacement_meteo = '';
      }
    },

    deleteItemReplacementMeteo(item){
       // Extraire les dates de début et de fin à partir de la chaîne formatée
       let datePattern = /Du (\d{4}-\d{2}-\d{2}) au (\d{4}-\d{2}-\d{2})/;
      let matches = item.date.match(datePattern);

      if (matches) {
        // Stocker la date de début initiale
        this.initial_date_start_replacement_meteo = matches[1];
        this.initial_date_end_replacement_meteo = matches[2];

        // Assigner les dates extraites à la modal
        this.form_date_start_replacement_meteo = matches[1];
        this.form_date_end_replacement_meteo = matches[2];
      }
      this.select_replacement_meteo = item;
      this.showModalDeleteReplacementMeteo = true;
    },

    submitFormDeleteReplacement() {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      let id_plot = this.$route.params.id;
      let date_debut_periode = this.initial_date_start_replacement_meteo
        
      //  Suppression 1 correction météo de la parcelle 
      parcellesMildiouService.deleteOneReplacementsMeteoForThePlot(id_plot, date_debut_periode)
        .then(() => {
          this.updateDataAfterAction(id_plot,'Suppression effectuée avec succès !');
        })
        .catch(error => console.error("Erreur lors de la suppression du remplacement météo de parcelle:", error));
      
        // Réinitialisation de la variable 
        this.showModalDeleteReplacementMeteo = false;
    },


    async checkPlotOwnership() {

      // Vérifiez que les données nécessaires sont disponibles
      if (this.parcelle && this.parcelle.farmer && this.$store.state.user.login_user) {
        this.isMyPlot = this.parcelle.farmer.toUpperCase() === this.$store.state.user.login_user.toUpperCase();
      } else {
        this.isMyPlot = false;
      }
    },

    



  }// FIN de la PARTIE des METHODES

};
  

</script>

<style scoped>
.chart {
  height: 450px;
}
.float {
  float: left;
  margin-right: 20px;
}

.double_accordion {
  display: flex;
  flex-direction: column;
}

.first_lvl_accordion {
  margin: 1rem 1.6rem;
  padding: 0.5rem;
  background-color: rgba(58, 102, 102, 0.08);
  color: rgb(58, 102, 102);
  font-size: 1.2rem;
  font-weight: bold;
}

.double_accordion_header {
  padding: 10px;
  cursor: pointer;
  color:#087b7a;
}

.double_accordion_content {
  padding: 10px;
}

.double_accordion_subsection {
  margin-bottom: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
}

.double_accordion_subheader {
  padding: 5px;
  background-color: #fafafa;
  cursor: pointer;
}

.double_accordion_subcontent{
  background-color: #fff;
  color:black;
  font-size: 0.8rem;
  font-weight: normal;
}

.heatmapIndice{
  padding:1.5rem;
}

.hum-red {
  background-color: #ff0000;
}
.hum-green {
  background-color: #92d050;
}
.temp-green {
  background-color: #92d050;
}

.temp-blue {
  background-color: #00b0f0;
}
.temp-light-red {
  background-color: #ffa7a7;
}
.temp-dark-red {
  background-color: #ff7575;
}
.temp-darker-red {
  background-color: #ff5757;
}
.temp-red {
  background-color: #ff0000;
}

.temp-light-pink{
  background-color: #ffcece;
}

button{
    background-color: #82ac00;
    color: #fff !important;
  }



/* EN MOBILE  */
@media only screen and (max-width: 960px) {

  .v-dialog{
    height: 90vh !important;
    background-color: whitesmoke !important;
    padding:0.4rem;
  }

  .map_mobile{
    margin: 0 auto !important;
    height: 400px !important;
    width:400px !important;
    height:90vh !important;
    width:90vh !important;
  }

}

.input_field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }

  .submit-button {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: #82ac00;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  
  .add_button{
    width:1.5rem !important;
    height:2.5rem !important;
    font-size:1.5rem !important;
    background-color:#82ac00 !important;
    margin:0.4rem !important;
  }

  i.icon_h3{
    font-size:1.15em !important;
  }

  #switch-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}

  #label-left {
    margin: -1rem 1rem 0 0;
  }

  #label-right {
    margin: -1rem 1rem 0 0;  
  }

</style>
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    waitingOverlayLoading: false,
    waitingOverlayFullPage: true
  },
  mutations: {
    setWaitingOverlay(state, payload) {
      Vue.set(state, 'waitingOverlayLoading', payload.loading);
      Vue.set(state, 'waitingOverlayFullPage', payload.fullpage);
    },
    reset: (state) => {
      state.waitingOverlayLoading = false;
      state.waitingOverlayFullPage = true;
    }
  },
  getters: {
    waitingOverlayLoading: state => state.waitingOverlayLoading,
    waitingOverlayFullPage: state => state.waitingOverlayFullPage
  },
  actions: {
    setWaitingOverlay({commit}, payload) {
      commit('setWaitingOverlay', payload);
    },
    reset: ({commit}) => {
      commit('reset');
    }
  }
};
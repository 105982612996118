<template>
  <v-container>
    <v-card style="padding:10px;" id="detail-conseiller">

      <v-row>
        <v-col cols="5">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <form @submit.prevent="submit">
              <v-row>
                <v-col cols="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nom"
                    rules="required"
                  >
                    <v-text-field
                      v-model="infos_conseiller.nom_conseiller"
                      :error-messages="errors"
                      label="Nom"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Prénom"
                    rules="required"
                  >
                    <v-text-field
                      v-model="infos_conseiller.prenom_conseiller"
                      :error-messages="errors"
                      label="Prénom"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="infos_conseiller.mail"
                      :error-messages="errors"
                      label="Email"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Organisme"
                    rules=""
                  >
                    <v-text-field
                      v-model="infos_conseiller.organisme"
                      :error-messages="errors"
                      label="Organisme"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Numéro de téléphone"
                    rules=""
                  >
                    <v-text-field
                      v-model="infos_conseiller.telephone"
                      :error-messages="errors"
                      label="Numéro de téléphone"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                  <v-select
                    label="Type"
                    :items="['SUPPORT', 'AGRO']"
                    v-model="infos_conseiller.type_conseiller"
                    :error-messages="errors"
                  ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              

              <v-btn
                class="mr-4 float-right"
                type="submit"
                small
                :disabled="invalid"
                @click="saveConseiller"
              >
                Enregistrer
              </v-btn>

            </form>
          </validation-observer>
        </v-col>
        <v-col cols="5" v-if="id_conseiller !== null">
          <div v-if="infos_conseiller.abonnements !== null">
            <v-data-table
              :headers="headers"
              :items="infos_conseiller.abonnements"
              item-key="id_abo"
              class="my-awesome-table elevation-1"
              dense
            >

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="modifyAbo(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="confirmDeleteAbo(item)">
                  mdi-close-thick
                </v-icon>
              </template>
            </v-data-table>
          </div>
          <v-btn small color="info" class="mt-2 mb-50 white--text" @click="show_abo=true;initializeAbo()">
            <v-icon small class="mr-2">
              mdi-plus-thick
            </v-icon>
            Abonnement
          </v-btn>

          <!--<div v-if="show_abo">-->
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
          <form @submit.prevent="submit" v-if="show_abo">
            <v-row style="mt-10">
              <v-col cols="4">
                <v-menu
                  ref="menu_dt_debut"
                  v-model="menu_dt_debut"
                  :close-on-content-click="false"
                  :return-value.sync="infos_abo.date_debut"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="infos_abo.date_debut"
                        label="Date début"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="height:25px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="infos_abo.date_debut"
                  no-title
                  scrollable
                  :max="infos_abo.date_fin"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu_dt_debut = false"
                  >
                      Fermer
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_dt_debut.save(infos_abo.date_debut)"
                  >
                      OK
                  </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="menu_dt_fin"
                  v-model="menu_dt_fin"
                  :close-on-content-click="false"
                  :return-value.sync="infos_abo.date_fin"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="infos_abo.date_fin"
                        label="Date fin"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="height:25px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="infos_abo.date_fin"
                  no-title
                  scrollable
                  :min="infos_abo.date_debut"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu_dt_fin = false"
                  >
                      Fermer
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_dt_fin.save(infos_abo.date_fin)"
                  >
                      OK
                  </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <validation-provider
                    v-slot="{ errors }"
                    name="Service"
                    rules="required"
                  >
                  <v-select
                    label="Service"
                    :items="['MILDIOU', 'BILANHYDRIQUE']"
                    v-model="infos_abo.service"
                    :error-messages="errors"
                    required
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="d-block">
              <v-btn
                class="mr-4 float-right"
                type="submit"
                small
                :disabled="invalid"
                @click="saveAbo"
              >
                Enregistrer
              </v-btn>
            </v-row>
          <!--</div>-->
          </form>
            </validation-observer>

        </v-col>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import administrationConseillersService from "@/service/administrationConseillers";
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} ne peut pas être vide',
  })

  extend('email', {
    ...email,
    message: 'Email doit être valide',
  })

  export default {
    components: {
      administrationConseillersService,
      ValidationProvider,
      ValidationObserver
    },
    props:{
      id_conseiller:Number,
    },
    data() {
      return {
          infos_conseiller: {id_conseiller:null,nom_conseiller:'',prenom_conseiller:'',organisme:'',telephone:'',mail:'', type_conseiller: '', abonnements:null},
          infos_abo: {id_abo:null,date_debut:null,date_fin:null,service:null},
          headers: [
            { text: "Date début", value: "date_debut"},
            { text: "Date fin", value: "date_fin"},
            { text: "Service", value: "service" },
            { text: "", value: "actions", sortable: false },
          ],
          show_abo: false,
          selected_abo: null,
          menu_dt_debut: false,
          menu_dt_fin: false,
          delete_id: null
}
    },
    computed: {
      choixSelected() {
        return this.$store.state.choix.choixSelected;
      },
    },
    watch: {
      id_conseiller: {
        immediate: true,
        handler() {
          if(this.id_conseiller!=null){
            this.getConseiller();
          } else {
            this.initializeConseiller()
          }
        },
      },
      choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
    },

    methods: {
      closeChoix() {
        this.$store.dispatch("choix/unsetChoix");
      },
      async getConseiller() {
        this.infos_conseiller = await administrationConseillersService.getInfosConseillerById(this.id_conseiller);
        this.infos_conseiller = this.infos_conseiller[0]
        this.initializeAbo()
        this.$vuetify.goTo('#detail-conseiller')
      },
      initializeConseiller(){ //resetConseiller(){
          this.infos_conseiller = {id_conseiller:null,nom_conseiller:'',prenom_conseiller:'',organisme:'',telephone:'',mail:'', type_conseiller: '', abonnements:null}
      },
      async saveConseiller(){
        var isDoneConseiller = null
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        if(this.infos_conseiller.id_conseiller==null){
            isDoneConseiller = await administrationConseillersService.postConseiller(this.infos_conseiller);
        } else {
            isDoneConseiller = await administrationConseillersService.putConseiller(this.infos_conseiller);
        }
        
        if(isDoneConseiller != true){
            alert('Un problème est survenu.')
        } else {
          this.$router.go()
        }
        
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      },
      async saveAbo(){
        var isDoneAbo = null
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        if(this.infos_abo.id_abo==null){
            isDoneAbo = await administrationConseillersService.postConseillerAbo(this.id_conseiller,this.infos_abo);
        } else {
            isDoneAbo = await administrationConseillersService.putConseillerAbo(this.infos_abo.id_abo,this.infos_abo);
        }

        if(isDoneAbo != true){
          alert('Un problème est survenu.')
        } else {
          this.getConseiller()
          this.show_abo = false
        }
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      },
      confirmDeleteAbo(item){
        this.delete_id = item.id_abo
        this.$store.dispatch("choix/setChoix", {
          question: "Etes-vous sûr de vouloir supprimer cet élément?",
          choix: {
            choix1: { text: "Oui", function: "deleteAbo" },
            choix2: { text: "Annuler", function: "closeChoix" },
          },
        });
      },
      async deleteAbo(){
          this.closeChoix()
          var isDeleted = null
          this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
          isDeleted = await administrationConseillersService.deleteConseillerAbo(this.delete_id);
          

          if(isDeleted == true){
            this.getConseiller()
          } else {
            alert('Un problème est survenu.')
          }
          this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
          this.delete_id = null;
      },
      modifyAbo(item){
          this.show_abo= true,
          this.infos_abo={id_abo:item.id_abo,date_debut:item.date_debut,date_fin:item.date_fin,service:item.service}
      },
      initializeAbo(){
          this.infos_abo={id_abo:null,date_debut:null,date_fin:null,service:null}
      },
      submit () {
        this.$refs.observer.validate()
      },
    },
  }
</script>

<style>

</style>
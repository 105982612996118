
export function handleServiceParams(params) {
  let search = new URLSearchParams();
  if (params) {
    for (let paramKey in params) {
      if (Array.isArray(params[paramKey])) {
        for (let paramItem of params[paramKey]) {
          if (paramItem != undefined) {
            search.append(`${paramKey}[]`, paramItem);
          }
        }
      } else {
        if (params[paramKey] != undefined) {
          search.append(paramKey, params[paramKey])
        }
      }
    }
  }
  return search;
}

export function errorDescription(code) {
  if(code==401){
    return "Vous n'êtes pas autoriser à effectuer cette action";
  } else {
    return "Erreur API (services.errors)"
  }
}

export function none(){}
<template>
  <div>
    <v-container style="margin-top: 10px">

      <h3>Parcelles Mycolis</h3>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchWord_cap"
            append-icon="mdi-magnify"
            label="Recherche par mot clé"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

      <!--https://codepen.io/wolfpup/pen/gOadmPx-->
        <v-data-table
          :items="all_info_about_stades"
          item-key="id_plot"
          :headers="headers"
          :search="searchWord_cap"
          dense
        >
        </v-data-table>
      </v-card>
      <!--EXCEL-->
      <v-btn small color="info" class="mt-2 mr-5 white--text">
        <download-excel :data="all_info_about_stades" :name="getNomFichier()">
          Export Excel
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>

      <!--  Import CSV / XLS -->
      <h3>Import de parcelles Mycolis</h3>
      <div style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="3">
            <v-btn small color="white" class="mt-2 white--text">
              <a href="./import_parcellesMycolis_fichierType.csv"
                download="import_parcellesMycolis_fichierType.csv">Obtenir format csv d'import</a>
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selected_user_mycolis"
              :items="liste_users_mycolis"
              item-text="nom_user"
              item-value="code_organisme"
              menu-props="auto"
              label="Client"
              hide-details
              chips
              dense
            ></v-select>
          </v-col>
          <v-col offset="1" cols="3">
            <div class="form-group">
              <v-file-input
                label="Fichier"
                outlined
                dense
                v-model="chosenFile"
                
              ></v-file-input>

            </div>
            <p v-if="chosenFile!=null"> Le fichier actuel contient <strong>{{nbr_plot_csv}}</strong> parcelle(s).</p>
          </v-col>
          <v-col>
            <v-btn small color="info" class="white--text" @click="dialog=true" v-if="chosenFile!=null && selected_user_mycolis!=null"> Importer </v-btn>
          </v-col>
          
        </v-row>
          <br>
          <!--APERCU DU CSV -->
          <div>
            <v-dialog v-model="dialog">
            
              <v-card style="overflow:auto;">
                <v-toolbar color="primary" dark class="mb-5"> Aperçu du fichier à importer </v-toolbar>
                <v-card-text style="height:300px;overflow:auto;">
                   <table style="font-size: smaller; margin:0.5rem;overflow:scroll;width:1800px!important">
                    <thead>
                      <tr>
                        <th v-for="key in parse_header" :key="key.id" @click="sortBy(key)"
                          :class="{ active: sortKey == key }">
                          {{ key | capitalize }}
                          <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="csv_line in parse_csv" :key="csv_line.id">
                      <td v-for="key in parse_header" :key="key.id">
                        {{csv_line[key]}}
                      </td>
                    </tr>
                  </table> 
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="info" class="mt-2 mb-50 white--text"
                    @click="launchAddPlotInApi()">
                    <v-icon small class="mr-2">
                      mdi-database-import-outline
                    </v-icon>
                    Importer les données
                  </v-btn>
                  <v-btn color="primary" text @click="dialog = false">
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--DIALOGUE FIN D IMPORT -->
          </div>
          <!--FIN APERCU DU CSV -->
      </div>
      <!-- FIN  Import CSV / XLS -->
      

    </v-container>
    
  </div>
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesStadeService from "@/service/parcellesStade";
import { getDate } from "@/helpers/functions";

export default {
  name: "Mycolis",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesStadeService
  },
  data() {
    return {
      dialog: false,
      dialog_api : false,
      chosenFile: null,
      //csv: null,
      searchWord_cap: '',
      all_info_about_stades:[],
      headers: [
        //colonnes visibles
        { text: "Code client", value: "nom_operateur"},
        { text: "id_operateur", value: "id_operateur"},
        { text: "id_user", value: "id_user"},
        { text: "nom_user", value: "nom_user"},
        { text: "code_parcelle_os", value: "code_parcelle_os"},
        { text: "id_plot", value: "id_plot"},
        { text: "codeespece", value: "codeespece"},
        { text: "date_semis", value: "date_semis"},
        { text: "variete", value: "variete"},
        { text: "labour", value: "labour"},
        { text: "code_precedent", value: "code_precedent"},
        { text: "a_calculer", value: "a_calculer"},
        { text: "date_calcul", value: "date_calcul"},
        { text: "date_fin_previsions", value: "date_fin_previsions"}
      ],
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      nbr_plot_csv:0,
      message_api:'',
      array_message_erreur_api:[],
      arr_data_base_64:[],
      arr_data_plot_foreach_plot : [],
      arr_all_data_plot_foreach_plot: [],
      liste_users_mycolis: [],
      selected_user_mycolis: null
    };
  },

  watch: {
    message_api: function(){
      this.resultat_message(this.message_api)
    },
    chosenFile: function(){
      this.viewCSV()
    },
  },

  computed: {},

  async mounted() {

    /*if((this.$store.getters['cap/ParcellesList']()).length==0){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.all_info_about_stades = await parcellesStadeService.getInfos(this.$route.params.id);
      this.$store.commit('cap/defineParcellesList',{stades:this.all_info_about_stades});
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    } 

    this.all_info_about_stades = this.$store.getters['cap/ParcellesMycolis']*/

    if ((this.$store.getters['cap/ParcellesListMycolis']()).length == 0) {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.all_info_about_stades = await parcellesStadeService.getInfos('mycolis');
      this.$store.commit('cap/defineParcellesListMycolis', {
        stades: this.all_info_about_stades
      });
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    } else {
      this.all_info_about_stades = this.$store.getters['cap/ParcellesListMycolis']()
    }

    
    this.liste_users_mycolis = await parcellesStadeService.getUsersMycolis();

  },

  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },

  methods: {

    checkCodeStation(item) {
      if (item.code_station != undefined || item.code_station != '') {
        return true
      }
    },

  
    resultat_message(message_api){
    
      var message_erreur_api = ''
      var titre = "Import réalisé avec succés"
      var nb_erreur = Object.keys(message_api.erreur).length
      if(nb_erreur>0){
        for (const [key, value] of Object.entries(message_api.erreur)) {
          message_erreur_api += '<br>'+key+' : '+value
          console.log(`${key}: ${value}`);
        }
        titre = "Import avec erreur"
      }

      var description = `<div>
        <h4>Récapitulatif de votre import :</h4><br>
      
        <h5>Parcelles : </h5>
          <p><span style="color:darkgreen">${message_api.ajout}</span> création(s) de nouvelle(s) parcelle(s)<br>
        <h5>Erreurs : </h5>
          <p><span style="color:darkred">${nb_erreur}</span> erreur(s) :</p>
          <p>${message_erreur_api}</p>
      </div>`

      //Affichage du message de confirmation d'import de parcelle
      this.$store.dispatch('informations/setInfo', { message: {titre:titre,description:description} });

    },


    /* GESTION DE L IMPORT DE CSV */
    async launchAddPlotInApi() {
      this.dialog = false;

      var size = 500
      var csv_small = []
      var data = {};
      var message_retour = {ajout:0,erreur:{}};
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      for (var i=0; i<this.parse_csv.length; i+=size) {
        csv_small = this.parse_csv.slice(i,i+size);
        //console.log(csv_small)
        data = {csv:csv_small,client:this.selected_user_mycolis};

        //Afficher message retour
        var resultat = await parcellesStadeService.addPlotMycolisInApi(data);
        message_retour.ajout += resultat.ajout;
        Object.assign(message_retour.erreur, resultat.erreur);
      }

      this.dialog_api = true;
      this.message_api=message_retour;
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      /* */

    },

    sortBy: function (key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
    },

    /**
     * Convertir le CSV transmit en Objet JSON
     */
    csvToJSON(csv) {
      var vm = this
      var lines = csv.split("\r\n")
      var result = []
      var headers = lines[0].split(";")
      vm.parse_header = lines[0].split(";")
      lines[0].split(";").forEach(function (key) {
        vm.sortOrders[key] = 1
      })

      lines.map(function (line, indexLine) {
        if (indexLine < 1) return // Jump header line
        var obj = {}
        var currentline = line.split(";")
        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader]
        })
        result.push(obj)
      })
      result.pop() // remove the last item because undefined values
      //console.log('result CSV  in JSON : ' + JSON.stringify(result))
      return result // JavaScript object
    },

    /**
     *   Lire le fichier importé et s'assurer de sa bonne lecture avant lancement du traintement
     */
    viewCSV() {   
      if (window.FileReader) {  
        var reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsText(this.chosenFile);
        reader.onload = () => {
          this.data = reader.result;
        }
        reader.onload = () => {

          var csv = reader.result;
          this.parse_csv = this.csvToJSON(csv);
          this.nbr_plot_csv = this.parse_csv.length;
        
        }
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Le fichier ne peut pas être lu !");
          }
        };
      } else {
        alert('FileReader n\'est pas comptabible avec votre navigateur.');
      }
    },
  /* FIN GESTION DE L IMPORT DE CSV */

    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')
      return now+'_import_mycolis.xls'
    },

  }

};

</script>

<style>

h3{
  margin:18px 0px 12px 0px;
  padding:0.5rem;
}

#form col{
  padding:1rem;
}


</style>

import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  async getInfosParcellesMaisGrain() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previMais/grain/plots`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosParcellesMaisFourrage() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previMais/fourrage/plots`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  // CC
  async getInfos(type) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/plots?type=${type}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosForOnePlot(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/plots/${id}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getResultatsForOnePlot(id,date) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/resultats/plots/${id}/show/${date}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          //Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getUsersMycolis() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/mycolis/users`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async addPlotInApi(data) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previCap/plots/add`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async addPlotMycolisInApi(data) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previCap/plots/mycolis/add`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async deletePlot(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/plots/${id}`, {
        method: 'DELETE',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async generate_graph_rouille_jaune(data) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previCap/generate_graph_agrobox_rouille_jaune`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async generate_graph_pv(data) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previCap/generate_graph_agrobox_pv`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async generate_graph_rouille_brune(data) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}previCap/generate_graph_agrobox_rouille_brune`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async getCalculsForOnePlot(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/plots/calculs/${id}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getLogged() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}apitoken/vigicultures`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  
  async getListPlotVigiculture() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}vigicultures/valorisations/parcelles`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        console.log(response);
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     console.log(json.data);
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getObsPlotVigiculture() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}vigicultures/valorisations/parcelles/observations`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        console.log(response);
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
     console.log(json.data);
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

async getNomStationSepto(code_station) {
  const local_dossier = await
  fetch(`${process.env.VUE_APP_BASE_API}previCap/stations/${code_station}/nom`, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        //Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();

      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
  return local_dossier;
},

async getDataMeteo(data) {
  const local_dossier = await
  fetch(`${process.env.VUE_APP_BASE_API}meteo/localisation`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getters.tokenValue}`,
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((json) => {
    return json.data;
  })
  .catch((error) => {
    store.dispatch('errors/setError', {
      message: {
        code: error.status,
        description: errorDescription(error.status)
      }
    });
  }, );
  return local_dossier;
},

};

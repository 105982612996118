<template>
  <div>
    <v-container style="margin-top: 10px">

      <h3>Informations personnelles</h3>
      
      <v-row style="margin-top: 10px">
        <!--FORM-->
        <v-col cols="12" md="6">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <v-row>
                <v-col offset="1" cols="5">
                  <validation-provider v-slot="{ errors }" name="Nom" rules="required">
                    <v-text-field v-model="infos_agri.nom_agri" :error-messages="errors" label="Nom*" dense required
                      outlined></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="5">
                  <validation-provider v-slot="{ errors }" name="Prénom" rules="required">
                    <v-text-field v-model="infos_agri.prenom_agri" :error-messages="errors" label="Prénom*" dense
                      required outlined></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1" cols="10">
                  <validation-provider v-slot="{ errors }" name="Raison sociale" rules="required">
                    <v-text-field v-model="infos_agri.raison_sociale" :error-messages="errors" label="Raison sociale*"
                      dense required outlined></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1" cols="6">
                  <validation-provider v-slot="{ errors }" name="Email" rules="email">
                    <v-text-field v-model="infos_agri.mail" :error-messages="errors" label="Email" dense outlined>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider v-slot="{ errors }" name="Numéro de téléphone" rules="">
                    <v-text-field v-model="infos_agri.telephone" :error-messages="errors" label="Numéro de téléphone"
                      dense outlined></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1"  cols="10">
                  <validation-provider v-slot="{ errors }" name="Adresse" rules="">
                    <v-text-field v-model="infos_agri.adresse" :error-messages="errors" label="Adresse" dense outlined>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col offset="1"  cols="3">
                  <validation-provider v-slot="{ errors }" name="Code postal" rules="">
                    <v-text-field v-model="infos_agri.code_postal" :error-messages="errors" label="Code postal" dense
                      outlined></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="7">
                  <validation-provider v-slot="{ errors }" name="Ville" rules="">
                    <v-text-field v-model="infos_agri.ville" :error-messages="errors" label="Ville" dense outlined>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col offset="1">
                  <validation-provider v-slot="{ errors }" rules="required" name="checkbox">
                    <v-checkbox v-model="infos_agri.cgu" :error-messages="errors" required class="ml-5">
                      <template v-slot:label>
                        <div>
                          *J'accepte les
                          <button @click.stop v-on:click="dialog=true">
                            <b><u>CGU</u></b>
                          </button>
                        </div>
                      </template>
                    </v-checkbox>
                  </validation-provider>
                </v-col>
                <v-col>
                  <v-btn class="mr-4 float-right" type="submit" small :disabled="invalid" @click="saveAgri">
                    Enregistrer
                  </v-btn>
                </v-col>
              </v-row>

              

            </form>
          </validation-observer>
        </v-col>
        <!--FIN FORM-->
        <v-col style="padding:1rem" cols="12" md="6" class="text-justify">
          Ce site mis à disposition par ARVALIS vous permet de donner l’autorisation à un/des conseiller(s)
          spécialisé(s) d’accéder à vos données parcellaires. Une fois l’autorisation accordée, ces derniers pourront
          visualiser vos données parcellaires, indépendamment de l’OAD que vous utilisez, afin de vous apporter un suivi
          agronomique durant la campagne.
          <br>Vous pouvez à tout moment modifier les informations vous concernant ainsi que les autorisations de partage
          de données.
          <br><br>
          Une équipe est à votre écoute pour toute question : support.api@arvalis.fr
        </v-col>
      </v-row>
      

      <!--FENETRE CGU-->
      <v-dialog
        max-width="600"
        v-model="dialog"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title class="flex text-center">CGU</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div style="text-align:justify;margin-top:20px" v-html="$t('cgu')"></div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog = false"
              >Fermer</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <!--FIN FENETRE CGU-->

    </v-container>
  </div>
</template>

<script>
import consentementService from "@/service/consentement";
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} ne peut pas être vide',
})

extend('email', {
  ...email,
  message: 'Email doit être valide',
})


export default {
  name: "Compte",
  components: {
    consentementService,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      code_agri_operateur: null,
      code_operateur: null,
      service: null,
      infos_agri: {id_agri:null,nom_agri:'',prenom_agri:'',raison_sociale:'',telephone:'',mail:'', adresse:'',code_postal:'',ville:'', cgu:null},
      dialog: false,
    };
  },

  created: function() {
    if(this.$route.query.agri!=undefined && this.$route.query.operateur!=undefined && this.$route.query.client!=undefined && this.$route.query.service!=undefined){
      this.code_agri_operateur = this.$route.query.agri;
      this.code_operateur = this.$route.query.operateur;
      this.code_client = this.$route.query.client;
      this.service = this.$route.query.service;
      this.$store.commit('consentement/defineAgri', {service:this.service,code_agri_operateur:this.code_agri_operateur,code_operateur:this.code_operateur,code_client:this.code_client})
    } else {
      var infos = this.$store.getters['consentement/InfosAgri']
      this.code_agri_operateur = infos.code_agri_operateur;
      this.code_operateur = infos.code_operateur;
      this.code_client = infos.code_client;
      this.service = infos.service;
    }
  },
  mounted(){
    this.getAgriculteur()
  },
  methods: {
    async getAgriculteur() {
      this.infos_agri_api = await consentementService.getInfosAgriculteurById(this.code_agri_operateur,this.code_operateur,this.code_client);
      if(this.infos_agri_api.length>0){
        this.infos_agri = this.infos_agri_api[0]
        if(this.infos_agri.cgu!=process.env.VUE_APP_VERSION_CGU){
          this.infos_agri.cgu=null;
        }
      }

      if(this.infos_agri.id_agri!=null && this.infos_agri.cgu!=null && this.$route.query.code_agri!=undefined && this.$route.query.operateur!=undefined && this.$route.query.service!=undefined){
        this.$router
        .push("/consentement/gestion")
        .catch(err => {
          console.log("err", err);
        });
      }
    },
    async saveAgri(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isDoneAgri = null
      if(this.code_agri_operateur!=undefined && this.code_operateur!=undefined && this.code_client!=undefined){
        isDoneAgri = await consentementService.postAgriculteur(this.code_agri_operateur,this.code_operateur,this.code_client,this.infos_agri);
      }

      if(isDoneAgri == true){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"Vos données ont bien été enregistrées. Vous pouvez dès à présent donner accès à vos données à des conseillers en vous rendant sur l'onglet \"Mes Conseillers\"."} });
      }
      
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      
    },
    submit () {
      this.$refs.observer.validate()
    },
  },
  };
  

</script>

<style>
</style>
import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  async getTdbBh() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}bhs/tableaudebord`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getFacturationBh() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}bhs/facturation`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getTdbMildiou() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/tableaudebord`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getStatusMildiou () {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/tableaudebord/statuts`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getTdbPrevilis() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}previCap/tableaudebord/plots/nbr`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

};

<template>
  <v-container style="margin-top:20px;" v-if="listes!={}">
    <div v-if="!isMobile" id="filtres">
      <v-row class="mb-5">
        <v-col cols="2" class="pt-0 pb-0">
          <v-select v-model="selectedCountry" :items="listes.country" item-text="country" menu-props="auto" @change="parcelles_filtrees_map=true"
            label="Pays" hide-details prepend-icon="mdi-map-marker" single-line chips></v-select>
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <v-autocomplete
            v-model="selectedDepartement"
            :items="filteredDepartements"
            item-text="departement"
            item-value="departement"
            label="Département"
            prepend-icon="mdi-scatter-plot"
            single-line
            chips
            multiple
            :disabled="disabledDepartement"
          />
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <v-autocomplete
            v-model="selectedRisque"
            :items="filteredRisque"
            :item-text="getRisqueListe"
            item-value="risque"
            label="Risque"
            prepend-icon="mdi-alert-octagon"
            single-line
            chips
            multiple
          />
        </v-col>
        <v-col cols="2" class="pt-0 pb-0">
          <v-autocomplete
            v-model="selectedConseil"
            :items="filteredConseil"
            :item-text="getConseilListe"
            item-value="conseil"
            label="Préconisation"
            prepend-icon="mdi-lightbulb-on"
            single-line
            chips
            multiple
          />
        </v-col>
        <v-col cols="3" class="pt-0 pb-0">
          <v-autocomplete
            v-model="selectedAgri"
            :items="listes.agri"
            :item-text="getAgriText"
            item-value="farmer"
            label="Agri"
            prepend-icon="mdi-account"
            single-line
            chips
            multiple
          />

        </v-col>
    
        <v-col cols="1">
          <v-btn @click="resetFiltres()" color="info" class="h-70">
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" >
                  mdi-rotate-left
                </v-icon>
              </template>
              <span>Effacer les filtres</span>
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-5" v-if="this.$store.state.user.user.externe!=1">
        <v-col cols="3">
          <v-autocomplete
            v-model="selectedIntegrateur"
            :items="listes.integrateur"
            item-text="nom_integrateur"
            item-value="id_integrateur"
            label="Intégrateur"
            prepend-icon="mdi-code-tags"
            single-line
            chips
            multiple
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="selectedDistributeur"
            :items="filteredDistributeurs"
            item-text="distributeur"
            label="Distributeur"
            prepend-icon="mdi-arrow-decision"
            single-line
            chips
            multiple
          />
        </v-col>
      </v-row>
    </div>   <!-- FIN des FILTRES en DESKTOP -->
 
  <!-- Bouton FILTRER pour mobile -->
  <h3 v-if="isMobile" style="margin-top: 20px; cursor: pointer;" @click="toggleModalFiltre">
    Filtrer <v-icon>mdi-filter-outline</v-icon>
  </h3>

  <section id="indicators">
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-row class="mt-3">
          <!-- Nb d'agriculteurs -->
          <v-col :cols="3">
            <v-card class="info-card d-flex align-center justify-center">Nb d'agriculteurs :<span style="font-size:0.9rem ; font-weight:bolder">{{nbAgris}}</span></v-card>
          </v-col>
          <!-- Nb de parcelles -->
          <v-col :cols="3">
            <v-card class="info-card d-flex align-center justify-center">Nb de parcelles :<span style="font-size:0.9rem ; font-weight:bolder">{{nbParcelles}}</span></v-card>
          </v-col>
          <!-- Nb de parcelles à traiter -->
          <v-col :cols="3">  
            <v-card class="info-card d-flex align-center justify-center">Nb de parcelles à traiter : <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                  mdi-information-outlinev
                </v-icon>
              </template>
              <span>Parcelle avec un risque de contamination mildiou en cours et sans protection</span>
            </v-tooltip><span
              style="font-size:0.9rem ; font-weight:bolder">{{this.parcelles_mildiou.filter(x => ['TRAIT','TRAITRETRO','TRAITCURATIF'].includes(x.conseil)).length}}</span>
          </v-card>
          </v-col>
          <!-- Nb de parcelles en erreur -->
          <v-col :cols="3">
              <v-card class="info-card d-flex align-center justify-center">Nb de parcelles en erreur :
            <span style="font-size:0.9rem ; font-weight:bolder">{{this.parcelles_mildiou.filter(x => x.statut=='donneesmanquantes').length}} </span> <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Parcelles sans calcul. Cela peut être dû :<br>- à l'absence de données météo<br>- à un manque de
                plus de 6h de données météo<br>- à plusieurs interventions du même type renseignées sur la même
                période de temps (chevauchement)<br></span>
            </v-tooltip>
          </v-card>
          </v-col>
      
    </v-row>

    </div>
    <div v-if="isMobile">
      <v-row class="mt-3">
        <v-col :cols="7" >
          <!-- Nb d'agriculteurs -->
          <v-card class="info-card">Nb d'agriculteurs :<span style="font-weight:bolder">{{nbAgris}}</span></v-card>
          <!-- Nb de parcelles -->
          <v-card class="info-card">Nb de parcelles :<span style="font-weight:bolder">{{nbParcelles}}</span></v-card>
          <!-- Nb de parcelles à traiter -->
          <v-card class="info-card">Nb de parcelles à traiter : <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                  mdi-information-outlinev
                </v-icon>
              </template>
              <span>Parcelle avec un risque de contamination mildiou en cours et sans protection</span>
            </v-tooltip><span
              style="font-weight:bolder">{{this.parcelles_mildiou.filter(x => ['TRAIT','TRAITRETRO','TRAITCURATIF'].includes(x.conseil)).length}}</span>
          </v-card>
          <!-- Nb de parcelles en erreur -->
          <v-card class="info-card">Nb de parcelles en erreur :
            <span style="font-weight:bolder">{{this.parcelles_mildiou.filter(x => x.statut=='donneesmanquantes').length}} </span> <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Parcelles sans calcul. Cela peut être dû :<br>- à l'absence de données météo<br>- à un manque de
                plus de 6h de données météo<br>- à plusieurs interventions du même type renseignées sur la même
                période de temps (chevauchement)<br></span>
            </v-tooltip>
          </v-card>
        </v-col>
        <v-col :cols="5">
            <v-btn color="info" @click="showModalMapMobile = true; showCarte = !showCarte">
            <!-- Bouton MAP pour mobile -->
            <v-icon>
              mdi-map-marker
            </v-icon>Carte
          </v-btn>
          <v-btn @click="showModalRisqueMobile = true ; getIndicateurs()" color="info">
            Résumé du risque
          </v-btn><!-- Bouton pour RESET les filtres-->
          <v-btn @click="resetFiltres()" color="info">
                 <!-- Bouton MAP pour mobile -->
            <v-icon>
              mdi-rotate-left
            </v-icon><span>Effacer les filtres</span>
          </v-btn>
          
        </v-col>
      </v-row>
    </div>

    <!-- <v-row class="mt-3">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 4" :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1">
        <v-card class="info-card">Nb d'agriculteurs :<br><span class="info-text">{{nbAgris}}</span></v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 4" :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1">
        <v-card class="info-card">Nb de parcelles :<br><span class="info-text">{{nbParcelles}}</span></v-card>
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card class="info-card">Nb de parcelles selon le risque  :
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on" >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Parcelles selon le niveau de risque de contamination mildiou.</span>
          </v-tooltip><br>
          <span class="info-text">{{this.parcelles_mildiou.filter(x => x.risque==50).length}}<span style="font-size: 0.5em;">(Risque)</span> / {{this.parcelles_mildiou.filter(x => x.risque==40).length}}<span style="font-size: 0.5em;">(Vigilance)</span> / {{this.parcelles_mildiou.filter(x => x.risque==10).length}}<span style="font-size: 0.5em;">(Pas de risque)</span></span></v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 4" :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1">
        <v-card class="info-card">Nb de parcelles à traiter :
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on" >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Parcelle avec un risque de contamination mildiou en cours et sans protection</span>
          </v-tooltip><br><span class="info-text">{{this.parcelles_mildiou.filter(x => ['TRAIT','TRAITRETRO','TRAITCURATIF'].includes(x.conseil)).length}}</span></v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 4" :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1">
        <v-card class="info-card" style="width:350px">Nb de parcelles en erreur :
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on" >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Parcelles sans calcul. Cela peut être dû :<br>- à l'absence de données météo<br>- à un manque de plus de 6h de données météo<br>- à plusieurs interventions du même type renseignées sur la même période de temps (chevauchement)<br></span>
          </v-tooltip>
          <br><span class="info-text">{{this.parcelles_mildiou.filter(x => x.risque==null).length}} </span></v-card>
      </v-col>
      <v-col :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1">-->
        <!-- Bouton MAP pour mobile -->

  </section><!-- FIN des INDICATEURS -->

    <!-- Modal pour la carte en MOBILE -->
    <v-dialog id="modal_carte" v-model="showModalMapMobile" max-height="96vh">
        <v-card>
            <v-card-title>
                <span class="headline">Localisation des parcelles</span>
                <v-btn icon @click="showModalMapMobile = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text style="height:90vh">
              <!-- carte  -->
              <div style="margin-top:20px;" v-if="showCarte">
                <p style="font-style:italic;color:grey; margin-bottom:16px;font-size: 1.1rem !important;"> &#8593; - Selectionnez / utilisez les filtres pour cibler les parcelles à afficher sur la carte.</p>
                <v-row>
                  <div style="height:80% ; width:100%">
                    <l-map :center="carte.center" style="margin: 0 auto" :zoom="carte.zoom" class="map" ref="map" @update:zoom="zoomUpdated"
                      @update:center="centerUpdated">
                      <l-tile-layer :url="carte.url">
                      </l-tile-layer>
                      <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                        <l-icon ref="icon" :icon-url="marker.imageUrl">
                        </l-icon>
                        <l-popup :content="marker.infobulle" />
                      </l-marker>
                    </l-map>
                  </div>
                  <!--Legende-->
                  <div style="height:20% ; width:100%">
                    <br>
                    <h4 class="mt-30">Niveau de risque :</h4>
                    <br>
                    <div class="legende_map_mobile">
                      <div>
                        <v-img src="@/assets/map_icon_green.png" width="2em" /><br>
                        <span v-html="getTexteRisque(10)"></span>
                      </div>
                      <div>
                        <v-img src="@/assets/map_icon_orange.png" width="2em" /><br>
                        <span v-html="getTexteRisque(40)"></span>
                      </div>
                      <div>
                        <v-img src="@/assets/map_icon_red.png" width="2em" /><br>
                        <span v-html="getTexteRisque(50)"></span>
                      </div>
                      <div>
                        <v-img src="@/assets/map_icon_grey.png" width="2em" /><br>
                        <span v-html="getTexteRisque(100)"></span>
                      </div>
                    </div>
                  </div>
                </v-row>
              </div>
            </v-card-text>
        </v-card>
    </v-dialog><!--FIN MODAL de la MAP en MOBILE-->


    <!-- Modal pour les FILTRES en MOBILE -->
    <v-dialog v-model="showModalFiltreMobile" max-height="40vh">
      <v-card id="filtres_mobile" style="height:90%">
        <v-card-title>
          <span class="headline">Filtres</span>
        </v-card-title>
        <v-row>
          <v-col cols="6" class="pt-0 pb-0">
            <v-autocomplete
              v-model="selectedDepartement"
              :items="filteredDepartements"
              item-text="departement"
              item-value="departement"
              label="Département"
              prepend-icon="mdi-scatter-plot"
              single-line
              chips
              multiple
              :disabled="disabledDepartement"
            />
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-autocomplete
              v-model="selectedRisque"
              :items="filteredRisque"
              :item-text="getRisqueListe"
              item-value="risque"
              label="Risque"
              prepend-icon="mdi-alert-octagon"
              single-line
              chips
              multiple
            />
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-autocomplete
              v-model="selectedConseil"
              :items="filteredConseil"
              :item-text="getConseilListe"
              item-value="conseil"
              label="Préconisation"
              prepend-icon="mdi-lightbulb-on"
              single-line
              chips
              multiple
            />
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-autocomplete
            v-model="selectedAgri"
            :items="listes.agri"
            :item-text="getAgriText"
            item-value="farmer"
            label="Agri"
            prepend-icon="mdi-account"
            single-line
            chips
            multiple
          />
          </v-col>
        </v-row>
        <v-row class="mb-5" v-if="this.$store.state.user.user.externe!=1">
          <v-col cols="6">
            <v-autocomplete
              v-model="selectedIntegrateur"
              :items="listes.integrateur"
              item-text="nom_integrateur"
              item-value="id_integrateur"
              label="Intégrateur"
              prepend-icon="mdi-code-tags"
              single-line
              chips
              multiple
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="selectedDistributeur"
              :items="filteredDistributeurs"
              item-text="distributeur"
              label="Groupement"
              prepend-icon="mdi-arrow-decision"
              single-line
              chips
              multiple
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" style="margin-top:-2rem">
          <v-select v-model="selectedCountry" :items="listes.country" item-text="country" menu-props="auto"
            label="Pays" hide-details prepend-icon="mdi-map-marker" single-line chips></v-select>
        </v-col>
        </v-row>
        <v-row>
          <v-col offset="1" cols="6">
            <v-btn @click="toggleModalFiltre()" color="info" class="h-70 " style=" margin:0.5rem ; padding:1rem ; height: 1rem">
              OK
            </v-btn>
          </v-col>
          <v-col offset="2" cols="3">
            <v-btn @click="resetFiltres()" color="info" class="h-70 mobile_button">
              <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" >
                    mdi-rotate-left
                  </v-icon>
                </template>
                <span>Effacer les filtres</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        
      </v-card>
    </v-dialog> <!-- FIN de la MODAL des FILTRES sur MOBILE-->
   

  <div style="margin-top:20px;" v-if="!isMobile"><!-- MAP en DESKTOP ONLY-->
    <h3 style="margin-top:20px;" v-on:click="showCarte = !showCarte">
      Localisation des parcelles
      <v-icon small class="mr-2" v-if="!showCarte">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon> 
    </h3>
    <div style="margin-top:20px;" v-if="showCarte">
      <p v-if="!parcelles_bien_filtrees()" style="font-style:italic;color:grey; font-size: 1.1rem !important;"> &#8593; - Selectionnez / utilisez les filtres en haut de la page pour cibler les parcelles à afficher sur la carte.</p>
      <v-row v-if="parcelles_bien_filtrees()">
        <v-col cols="8" offset="1">
          <!--
            https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
            https://vue2-leaflet.netlify.app/examples/custom-icons.html
            -->
          <l-map
            :center="carte.center"
            :zoom="carte.zoom"
            class="map"
            ref="map"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            
          >
            <l-tile-layer
              :url="carte.url"
            >
            </l-tile-layer>
            <l-marker
              v-for="marker in carte.markers"
              :key="marker.id"
              :lat-lng="marker.coordinates"
            >
              <l-icon 
                ref="icon"
                :icon-url="marker.imageUrl"
              >
              </l-icon>
              <l-popup :content="marker.infobulle"/>
            </l-marker>
          </l-map>
        </v-col>
        <v-col cols="3">
          <div class="mt-30">Niveau de risque :
          <table>
            <v-row><v-col><v-img src="@/assets/map_icon_green.png" width="20" style="float:left;margin-right:10px;"/><span v-html="getTexteRisque(10)" style="float:left;"></span></v-col></v-row>
            <v-row><v-col><v-img src="@/assets/map_icon_orange.png" width="20" style="float:left;margin-right:10px;"/><span v-html="getTexteRisque(40)" style="float:left;"></span></v-col></v-row>
            <v-row><v-col><v-img src="@/assets/map_icon_red.png" width="20" style="float:left;margin-right:10px;"/><span v-html="getTexteRisque(50)" style="float:left;"></span></v-col></v-row>
            <v-row><v-col><v-img src="@/assets/map_icon_grey.png" width="20" style="float:left;margin-right:10px;"/><span v-html="getTexteRisque(100)" style="float:left;"></span></v-col></v-row>
          </table>
          </div>
        </v-col>
      </v-row>
    </div>
  </div> <!-- FIN de la MAP en DESKTOP -->


<div v-if="isMobile">
  <!-- Modal pour le résumé des risques -->
    <v-dialog v-model="showModalRisqueMobile" max-height="50vh">
      <v-card>
        <v-card-title>Résumé des risques</v-card-title>
        <v-card-text>
          <v-data-table
            dense :headers="headers_tableauRisque"
            :items="tableauRisque" item-key="type"
            hide-default-footer class="elevation-1"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.j_reel`]="{ item }" style="background-color:#afe278!important">
              {{ item.j_reel }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div><!--FIN des RISQUES en MOBILE-->

  <!--Les RISQUES en DESKTOP -->
  <h3 v-if="!isMobile" style="margin-top:20px;" v-on:click="showResume = !showResume">
    Résumé des risques
    <v-icon small class="mr-2" v-if="!showResume">
      mdi-chevron-down
    </v-icon>
    <v-icon small class="mr-2" v-else>
      mdi-chevron-up
    </v-icon> 
  </h3>
  <div style="margin-top:20px;" v-if="showResume">
    <v-data-table
      dense 
      :headers="headers_tableauRisque"
      :items="tableauRisque"
      item-key="type"
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.j_reel`]="{ item }" style="background-color:#afe278!important">{{ item.j_reel }}</template>
    </v-data-table>
  </div><!--FIN - RISQUES en DESKTOP -->

  <div style="margin-top: 20px">
    <h3>Liste des parcelles</h3>
    <div style="margin-bottom:20px;">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchWord"   
            append-icon="mdi-magnify"      
            label="Recherche"      
            single-line      
            hide-details>
        </v-text-field>
        </v-card-title>
        <v-data-table
          :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
          :headers="isMobile ? headers_plots_mobile : headers" 
          :items="parcelles_mildiou"  
          :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100]}" 
          :items-per-page="15" 
          item-key="id" :options.sync="optionsTab"
          class="my-awesome-table elevation-1"
          dense fixed-header
          mobile-breakpoint="0"
        >

        <template v-for="(header, index) in headers" v-slot:[`header.${header.value}`]>
          <div v-bind:key="index">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ header.tooltipContent }}</span>
            </v-tooltip>
          </div>
        </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showItem(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.exploitant`]="{ item }">{{ item.nom_agri }} {{ item.prenom_agri }} [{{ item.farmer }}]</template>
          <template v-slot:[`item.parcelle`]="{ item }">
          {{ item.plot_name }}
          <span v-if="item.scenario"> [{{ item.scenario }}]</span>
          [{{ item.id }}]
        </template>
          <template v-slot:[`item.stade`]="{ item }">{{ $tc("mildiou.stades."+item.stade) }}</template>
          <template v-slot:[`item.l10poids_j_reel`]="{ item }"><b>{{ item.l10poids_j_reel }}</b></template>
          <template v-slot:[`item.l10poids_j_previ`]="{ item }"><b>{{ item.l10poids_j_previ }}</b></template>
          <template v-slot:[`item.risque`]="{ item }">
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on" >
                  {{getIconRisque(item.risque)}}
                </v-icon>
              </template>
              <span v-html="getInfoRisque(item.risque)"></span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.risque_alt`]="{ item }">
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" :color="getColorRisque(item.risque_alt)" v-bind="attrs" v-on="on" >
                  {{getIconRisque(item.risque_alt)}}
                </v-icon>
              </template>
              <span v-html="getInfoRisqueAlt(item.risque_alt)"></span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.parcelleprotegee`]="{ item }">
            <v-tooltip bottom max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs" v-on="on" >
                  {{getIconProtection(item.parcelleprotegee)}}
                </v-icon>
              </template>
              <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.conseil`]="{ item }">{{ $tc("mildiou.conseil_abrege."+item.conseil) }}</template>
        </v-data-table>
      </v-card>

      <v-btn small color="info" class="mt-2 white--text">
        <download-excel :data="parcelles_mildiou" :name="getNomFichier()" :fields="excelfields">
          Export Excel 
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
      </download-excel>
      </v-btn>
      
    </div>
  </div>
</v-container>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import VChart from "vue-echarts";
import { getDate } from "@/helpers/functions";

export default {
name: "Tdb_Mildiou",
components: {
  downloadExcel,
  parcellesMildiouService,
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  VChart,
  getDate
},
data() {
  return {
    showModalMapMobile: false,
    showModalFiltreMobile: false,
    showModalRisqueMobile: false,
    isMobile: false,
    showMobileResumeRisque:false,
    dataloaded: false,
    showCarte: false,
    showFiltre: false,
    showResume: false,
    displayResult: true,
    filter: null,
    parcelles_filtrees_map:false,
    searchWord: "",
    parcelles_mildiou: [],
    liste_possibilites: null,
    listes: {
        country: [],
        departement: [],
        agri: [],
        integrateur: [],
        distributeur: [],
        risque: [],
        conseil: [],
        prenom_agri: [],
        nom_agri:[]
      },
    selectedCountry: [],
    selectedDepartement: [],
    disabledDepartement: false,
    selectedAgri: [],
    selectedIntegrateur: [],
    selectedDistributeur: [],
    selectedRisque: [],
    selectedConseil: [],
    nbAgris: 0,
    nbParcelles: 0,
    surfaceTotale: 0,
    nbHeuresPasAJour: 6,
    tableauRisque: null,
    optionsTab: {itemsPerPage: 15,},
    headers: [
      //colonnes non visibles mais permettant de faire une recherche sur le contenu
      { text: 'Nom parcelle', value: 'plot_name', align: ' d-none' },
      { text: 'Id parcelle', value: 'id', align: ' d-none' },
      { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
      { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
      { text: 'Code agri', value: 'farmer', align: ' d-none' },
      //colonnes visibles
      { text: "", value: "actions", sortable: false},
      { text: "Agri", value: "exploitant", width: '120px', filterable: true},
      { text: "Parcelle", value: "parcelle"},
      { text: "Raison sociale", value: "raison_sociale"},
      { text: "Risque Mildiou", value: "risque"},
      { text: "Risque Alternariose", value: "risque_alt"}, //TODO
      //{ text: "Résistance variétale", value: "seuiltrait", width: '100px' },
      //{ text: "Date de levée", value: "levee30", width: '100px' },
      { text: "Stade", value: "stade" },
      { text: "Protection", value: "parcelleprotegee" },
      { text: "Préconisation", value: "conseil", width: '100px' },
      { text: "J-2", value: "l10poids_jm2_reel", width: '100px',tooltipContent: 'Poids de contamination mildiou' },
      { text: "J-1", value: "l10poids_jm1_reel", width: '100px',tooltipContent: 'Poids de contamination mildiou' },
      { text: "Conta J", value: "l10poids_j_reel", width: '100px',tooltipContent: 'Poids de contamination mildiou' }, 
      { text: "Prév conta J", value: "l10poids_j_previ", width: '100px',tooltipContent: 'Prévision poids de contamination mildiou. J' },       
      { text: "Prév J+1", value: "l10poids_jp1_previ", width: '100px',tooltipContent: 'Poids de contamination mildiou' },
      { text: "Prév J+2", value: "l10poids_jp2_previ",tooltipContent: 'Poids de contamination mildiou' },{ text: "Surface", value: "area",tooltipContent: 'Surface (ha)' },
 
      // { text: "Nb heure depuis dernière maj météo", value: "nb_heure_last_meteo_data", width: '100px'}
      //{ text: "Localisation", value: "city"},
      // { text: "Intégrateur", value: "nom_integrateur" },
      // { text: "Distributeur", value: "distributeur" }
    ],
    excelfields: {
      'Agriculteur': {
          callback: (value) => {
              return value.nom_agri+' '+value.prenom_agri;
          }
      },
      "Code agriculteur": "farmer",
      "Parcelle": {
        callback: (item) => {
          let scenario = item.scenario ? '['+item.scenario+']' : '';
          return `${item.plot_name} ${item.id} ${scenario}`;
        }
      },
      "Code parcelle": "id",
      "Risque": {
        field: "risque", // nested attribute supported
        callback: (value) => {
          return this.getTexteRisque(value);
        },
      },
      "Résistance variétale":'seuiltrait',
      "Date de levée":'levee30',
      "Stade actuel":'stade',
      "Protegée?":'parcelleprotegee',
      "Préconisation":'conseil',
      "Poids de conta J-2":'l10poids_jm2_reel',
      "Poids de conta J-1":'l10poids_jm1_reel',
      "Poids de conta J":'l10poids_j_reel',
      "Prévision poids de conta J":'l10poids_j_previ',
      "Prévision poids de conta J+1":'l10poids_jp1_previ',
      "Prévision poids de conta J+2":'l10poids_jp2_previ',
      "Localisation":"city",
      "Intégrateur":'nom_integrateur',
      "Distributeur":'distributeur',
      "Surface (ha)":'area'
    },
    headers_plots_mobile: [
      //colonnes non visibles mais permettant de faire une recherche sur le contenu
      { text: 'Nom parcelle', value: 'plot_name', align: ' d-none' },
      { text: 'Id parcelle', value: 'id', align: ' d-none' },
      { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
      { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
      { text: 'Code agri', value: 'farmer', align: ' d-none' },
      //colonnes visibles
      { text: "", value: "actions", sortable: false},
      { text: "Agriculteur", value: "exploitant", width: '120px', filterable: true},
      { text: "Parcelle", value: "parcelle"},
      { text: "Protection", value: "parcelleprotegee" },
      { text: "Risque", value: "risque"},
    ],

    headers_tableauRisque: [
      { text: '', value: 'type'},
      { text: 'J-2', value: 'jm2'},
      { text: 'J-1', value: 'jm1'},
      { text: 'J reel', value: 'j_reel'},
      { text: 'J previ', value: 'j_previ'},
      { text: 'J+1', value: 'jp1'},
      { text: 'J+2', value: 'jp2'}
    ],
    carte:{
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [ 46.76782, 2.43129 ],
      zoom: 6,
      markers: [],
    }
  };
},

watch: {
  selectedCountry: function(val) {
    if(val!='france'){
      this.selectedDepartement = [];
      this.disabledDepartement = true;
    } else {
      this.disabledDepartement = false;
    }
    //this.filterListes()
    this.filterParcelles();
  },
  selectedDepartement: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  selectedAgri: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  selectedIntegrateur: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  selectedDistributeur: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  selectedRisque: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  selectedConseil: function() {
    //this.filterListes()
    this.filterParcelles();
    this.parcelles_filtrees_map = true;

  },
  parcelles_mildiou: function() {
    this.getIndicateurs()
  },
  liste_possibilites: function() {
    this.getListes()
  }
},

computed: {

  filteredDepartements() {
    return this.listes.departement.map(item => ({ departement: item.departement }));    
  },
  filteredCountries() {
    return this.listes.country.map(item => ({ country: item.country }));
  },
  filteredAgri() {
    return this.listes.agri.map(item => ({ nom_agri: item.farmer }));
  },
  // filteredIntegrateurs() {
  //   return this.listes.integrateur.map(item => ({ nom_integrateur: item.nom_integrateur }));
  // },
  filteredDistributeurs() {
    return this.listes.distributeur.map(item => ({ distributeur: item.distributeur }));
  },
  filteredRisque() {
    return this.listes.risque.map(item => ({ risque: item.risque }));
  },

  filteredConseil() {
    return this.listes.conseil.map(item => ({ conseil: item.conseil }));
  }
  // filteredDepartements() {
  //   return this.listes.departement.filter(item => item.departement !== null);
  // }

},

async mounted() {
  
  // this.liste_possibilites = await parcellesMildiouService.getListes(); 
  // console.log(JSON.stringify(this.liste_possibilites));
  // this.$store.commit('mildiou/defineListes', {liste:this.liste_possibilites})


   // Vérifier la taille de l'écran une fois la page chargée
  this.checkIsMobile();
  // Ecouteur d'événement pour surveiller les changements de taille de fenêtre
  window.addEventListener('resize', this.checkIsMobile);
  
  this.parcelles_mildiou = await parcellesMildiouService.getInfosParcelles();
  this.$store.commit('mildiou/defineParcelles', {parcelles:this.parcelles_mildiou})
  this.parcelles_mildiou = this.$store.getters['mildiou/FilterParcelles'](this.selectedCountry,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,this.selectedRisque,this.selectedConseil)
  
  this.$store.commit('mildiou/defineListes', null)
  this.liste_possibilites = this.$store.state.mildiou.listes

  // ! COMMENTAIRE ; pas de besoin actuel de filtrer sur FRANCE par defaut
  // var isFrance = this.liste_possibilites.find(o => o.country === 'france');
  // if(isFrance != undefined){
  //   this.selectedCountry = 'france'
  // }
  //this.filterListes()

  
  this.dataloaded = true;

},

created() {
  // Initialiser les headers affichés au démarrage
  this.updateDisplayedHeaders();
  // Écouter les changements de taille de l'écran
  window.addEventListener('resize', this.updateDisplayedHeaders);
},
beforeDestroy() {
  // Retirer les écouteurs d'événements lors de la destruction du composant
  window.removeEventListener('resize', this.checkIsMobile);
  window.removeEventListener('resize', this.updateDisplayedHeaders);
},

methods: {

  toggleModalFiltre() {
  this.showModalFiltreMobile = !this.showModalFiltreMobile;
},
  
  checkIsMobile() {
    this.isMobile = window.innerWidth < 960; // Déterminer si l'écran est bien un mobile
  },

  updateDisplayedHeaders() {
    this.checkIsMobile(); // Vérifie si l'écran est mobile
    // Définir les headers à afficher en fonction de la taille de l'écran
    this.displayedHeaders = this.isMobile ? this.headers_plots_mobile : this.headers;
  },

  sortData (tab,propriete) {
    var result = []
    if(propriete == 'risque'){
      var order = [10,40,50,null]
      result = tab.slice().sort(function(a,b) {
          return order.indexOf( a[propriete] ) - order.indexOf( b[propriete] );
      });
    } else {
      result = tab.slice().sort((a, b) => {
        var fa = a[propriete]!=null ? a[propriete].toLowerCase() : a[propriete]
        var fb = b[propriete]!=null ? b[propriete].toLowerCase() : b[propriete]
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
      });
    }

    return result
  },
  /*async filterListes(){
    this.liste_possibilites = this.$store.getters['mildiou/FilterListes'](this.selectedCountry,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur)
  },*/
  async filterParcelles(){
    this.parcelles_mildiou = this.$store.getters['mildiou/FilterParcelles'](this.selectedCountry,this.selectedDepartement,this.selectedAgri,this.selectedIntegrateur,this.selectedDistributeur,this.selectedRisque,this.selectedConseil)
    if(this.parcelles_filtrees_map && this.showCarte){
      this.getPositionCarte()
    }
  
  },
  getListes () {
    this.listes.integrateur = this.sortData(this.liste_possibilites, 'nom_integrateur').filter(item => item.nom_integrateur !== null);
    this.listes.country = this.sortData(this.liste_possibilites, 'country').filter(item => item.country !== null);
    this.listes.departement = this.sortData(this.liste_possibilites, 'departement').filter(item => item.departement !== null);
    this.listes.agri = this.sortData(this.liste_possibilites, 'nom_agri');
    this.listes.distributeur = this.sortData(this.liste_possibilites, 'distributeur').filter(item => item.distributeur !== null);
    this.listes.risque = this.sortData(this.liste_possibilites, 'risque');
    this.listes.conseil = this.sortData(this.liste_possibilites, 'conseil').filter(item => item.conseil !== null);
    this.listes.prenom_agri = this.sortData(this.liste_possibilites, 'prenom_agri').filter(item => item.prenom_agri !== null); 
    this.listes.nom_agri = this.sortData(this.liste_possibilites, 'nom_agri').filter(item => item.nom_agri !== null);
  },

  getAgriText(item) {
    // console.log("l'item precis : "+JSON.stringify(item));
    var text = '['+item.farmer+']'
    if(item.nom_agri!=null){
      text = item.nom_agri+' '+item.prenom_agri+' '+text
    }
    return text;
  },

  parcelles_bien_filtrees(){
    return this.parcelles_filtrees_map && this.parcelles_mildiou.length >= 1 && this.parcelles_mildiou.length <= 100;
  },

  resetFiltres(){
    if(this.selectedCountry!='france'){this.selectedCountry = []}
    this.selectedDepartement = []
    this.selectedAgri = []
    this.selectedIntegrateur = []
    this.selectedDistributeur = []
    this.selectedRisque = []
    this.selectedConseil = []
  },
  getIndicateurs(){
    //indicateurs principaux
    const uniqueArr = [... new Set(this.parcelles_mildiou.map(data => data.farmer))]
    this.nbAgris = uniqueArr.length

    this.nbParcelles = this.parcelles_mildiou.length
    
    this.surfaceTotale = this.parcelles_mildiou.reduce((sum, data) => sum + data.surface, 0)

    //tableau des indicateurs
    var nbParcelles_jm2 = this.parcelles_mildiou.filter(x => x.risque_jm2_reel != null).length
    var nbParcelles_jm1 = this.parcelles_mildiou.filter(x => x.risque_jm1_reel != null).length
    var nbParcelles_j_reel = this.parcelles_mildiou.filter(x => x.risque != null).length
    var nbParcelles_j_previ = this.parcelles_mildiou.filter(x => x.risque_j_previ != null).length
    var nbParcelles_jp1 = this.parcelles_mildiou.filter(x => x.risque_jp1_previ != null).length
    var nbParcelles_jp2 = this.parcelles_mildiou.filter(x => x.risque_jp2_previ != null).length
    this.tableauRisque = [
      {
        type: "Nb de parcelles",
        jm2: nbParcelles_jm2, //parcelles_Jm2.length,
        jm1: nbParcelles_jm1, //parcelles_Jm1.length,
        j_reel: nbParcelles_j_reel, //parcelles_J_reel.length,
        j_previ: nbParcelles_j_previ, //parcelles_J_previ.length,
        jp1: nbParcelles_jp1, //parcelles_Jp1.length,
        jp2: nbParcelles_jp2, //parcelles_Jp2.length
      },        
      {
        type: "Nb à risque",
        jm2: this.parcelles_mildiou.filter(x => x.risque_jm2_reel == 50).length, //parcelles_risque_Jm2.length,
        jm1: this.parcelles_mildiou.filter(x => x.risque_jm1_reel == 50).length, //parcelles_risque_Jm1.length,
        j_reel: this.parcelles_mildiou.filter(x => x.risque == 50).length, //parcellesRisqueEleve.length,
        j_previ: this.parcelles_mildiou.filter(x => x.risque_j_previ == 50).length, //parcelles_risque_J_previ.length,
        jp1: this.parcelles_mildiou.filter(x => x.risque_jp1_previ == 50).length, //parcelles_risque_Jp1.length,
        jp2: this.parcelles_mildiou.filter(x => x.risque_jp2_previ == 50).length, //parcelles_risque_Jp2.length
      },
      {
        type: "% à risque",
        jm2: (nbParcelles_jm2>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque_jm2_reel == 50).length/nbParcelles_jm2*100,2):"-",
        jm1: (nbParcelles_jm1>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque_jm1_reel == 50).length/nbParcelles_jm1*100,2):"-",
        j_reel: (nbParcelles_j_reel>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque == 50).length/nbParcelles_j_reel*100,2):"-",
        j_previ: (nbParcelles_j_previ>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque_j_previ == 50).length/nbParcelles_j_previ*100,2):"-",
        jp1: (nbParcelles_jp1>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque_jp1_previ == 50).length/nbParcelles_jp1*100,2):"-",
        jp2: (nbParcelles_jp2>0)? Math.round(this.parcelles_mildiou.filter(x => x.risque_jp2_previ == 50).length/nbParcelles_jp2*100,2):"-"
      }
    ]
  },


  getPositionCarte() {
    let array = []
    let latitudes = []
    let longitudes = []

    this.parcelles_mildiou.forEach(element => {
      if (element['latitude'] != null && element['longitude'] != null) {
        let agri = '[' + element['farmer'] + ']'
        if (element['nom_agri'] != '') {
          agri = element['nom_agri'] + ' ' + element['prenom_agri'] + ' ' + agri
        }
        let parcelle = '[' + element['id'] + ']'
        if (element['plot_name'] != null) {
          parcelle = element['plot_name'] + ' ' + parcelle
        }
        let random_latitude = (Math.round(Math.random()) * 2 - 0.5) / 10000
        let random_longitude = (Math.round(Math.random()) * 2 - 0.5) / 10000
        array.push({
          id: element['id'],
          coordinates: [parseFloat(element['latitude']) + random_latitude, parseFloat(element['longitude']) + random_longitude],
          imageUrl: require('@/assets/map_icon_' + this.getColorCarte(element) + '.png'),
          infobulle: '<a href="' + this.showItem(element['id'], 'href') + '" target="_blank">Voir le détail de la parcelle</a><br>Parcelle: ' + parcelle + '<br>Poids de conta: ' + element['l10poids_j_reel'] + '<br>Intégrateur: ' + element['nom_integrateur'] + '<br>Distributeur: ' + element['distributeur'] + '<br>Agriculteur: ' + agri + '<br>Variété: ' + element['variete'] + '<br>Résistance variétale: ' + element['seuiltrait'] + '<br>Stade actuel: ' + element['stade']
        })
        latitudes.push(parseFloat(element['latitude']))
        longitudes.push(parseFloat(element['longitude']))
      }
    });

    let minLat = Math.min(...latitudes)
    let maxLat = Math.max(...latitudes)
    let minLng = Math.min(...longitudes)
    let maxLng = Math.max(...longitudes)

    let centerLat = (minLat + maxLat) / 2
    let centerLng = (minLng + maxLng) / 2

    this.carte.markers = array
    this.carte.zoom = 6
    this.carte.center = [centerLat, centerLng]
  },



  showItem(id_parcelle, type=null) {
    // Stockage de la parcelle sélectionnée dans le store
    //this.$store.dispatch("parcelle/setParcelleSelected", parcelle);

    let route = this.$router
      .resolve({ name: 'mildiou-detail-parcelle', params: { id: id_parcelle } });

    if(type=='href'){
      return route.href;
    } else {
      window.open(route.href);
    }
  },
  getColorRisque (statut) {
      if (statut == 10) return 'green'
      else if (statut == 50) return 'red'
      else if (statut == 40) return 'orange'
      else return 'grey'
  },
  getColorCarte (element) {
    //A AJOUTER : VERIF PAS EN ERREUR EN PLUS DU FAIT QUE LES DONNEES METEO DOIVENT ETRE RECENTES
      if (element.nb_heure_last_meteo_data<=this.nbHeuresPasAJour){
        if (element.risque == 10) return 'green'
        else if (element.risque == 50) return 'red'
        else if (element.risque == 40) return 'orange'
        else return 'grey'
      } else {
        return 'grey'
      }
  },
  getIconRisque (statut) {
      if (statut == 10) return 'mdi-emoticon-happy-outline'
      else if (statut == 50) return 'mdi-emoticon-sad-outline'
      else if (statut == 40) return 'mdi-emoticon-neutral-outline'
      else return 'mdi-close-thick'
  },
  getInfoRisque (statut) {
      if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante. Il n'y a donc <b>pas de risque</b> de contamination sur la parcelle."
      else if (statut == 50) return "Le climat et la quantité de spores sont favorables, il y a donc la présence d'un <b>risque de contamination</b> sur la parcelle."
      else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de <b>rester vigilant</b> au cas où les conditions climatiques deviennent favorables. Un risque de contamination serait alors présent."
      else return ''
  },
  getInfoRisqueAlt (statut) {
      if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante. Il n'y a donc <b>pas de risque</b> de contamination sur la parcelle."
      else if (statut == 50) return "Le climat et la quantité de spores sont favorables, il y a donc la présence d'un <b>risque de contamination</b> sur la parcelle."
      else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. Il n'y a donc pour le moment pas de risque de contamination alternariose sur la parcelle, mais il est nécessaire de <b>rester vigilant</b> au cas où les conditions climatiques deviennent favorables. Un risque de contamination serait alors présent."
      else return ''
  },
  getTexteRisque (statut) {
      if (statut == 10) return "Pas de risque"
      else if (statut == 50) return "Risque"
      else if (statut == 40) return "Vigilance"
      else return 'En erreur'
  },
  getRisqueListe (item) {
    return this.getTexteRisque(item.risque)
  },
  getConseilListe (item) {
    return this.$t("mildiou.conseil_abrege."+item.conseil)
  },
  getColorProtection (statut) {
      if (statut == 1) return 'green'
      else return 'red'
  },
  getIconProtection (statut) {
      if (statut == 1) return 'mdi-shield'
      else return 'mdi-shield-off'
  },
  getInfoProtection (statut) {
      if (statut == 1) return 'Parcelle protégée'
      else return 'Parcelle non protégée'
  },
  zoomUpdated (zoom) {
    this.zoom = zoom;
  },
  centerUpdated (center) {
    this.center = center;
  },
  getNomFichier(){
    var now = new Date()
    now = getDate(now,'yyyymmdd')

    return now+'_ExportMildiou_total_parcelle'
  }
}
};
</script>

<style>
.map {
height: 500px !important;
width: 600px !important;
position: relative !important;
z-index:0;
}
.leaflet-container {
height: 500px;
width: 600px;
}
.leaflet-pane {
z-index: 0
}

#indicators .info-card {
font-size: 0.75rem;
height: auto;
}

/* EN MOBILE  */
@media only screen and (max-width: 960px) {

.map{
  width:100% !important;
}

#indicators div.info-card {
  min-height: 1rem;
    height: auto !important;
    text-align: left;
    margin:0.4rem;
}

#indicators button {
  height: 42px;
  width: 90%;
  margin: 0.4rem;
}

/*Barre du MENU ASIDE rendu le plus petit possible sous la forme d'un bouton à gauche de l'écran */
aside.v-navigation-drawer{
border:1px solid rgb(9, 123, 123);
height: auto !important;
border-radius: 10px;
margin:4px;
top:1.75rem !important;
z-index:5 !important;
box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

/* Carte en MODAL sur MOBILE  */
#modal_carte{
  width:100% !important;
  height:80vh;
  background-color: #fff;
}
/* MODAL de filtre */
#filtres_mobile{
  width:100% !important;
  height:40vh;
  background-color: #fff;
  padding:1rem;
}


.legende_map_mobile{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.legende_map_mobile>div{
  width:25%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.mobile_button{
margin:0.5rem !important;
padding:1rem !important;
height: 1rem !important;
width:1rem !important;
}


}


</style>

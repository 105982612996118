<template>
  <div>
    <v-row fill-height>
      <v-col>
        <menucontextuel :menu="menu"></menucontextuel>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 11 : 12">
        <TdbComponent v-if="showTdbComponent" />
        <ParcelleComponent v-if="showDetailParcelleComponent" />
        <GapMeteo v-if="showDetailGapMeteo" />
        <ManagePlot v-if="showAddNewPlotMildiou" />
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Register from "@/components/auth/Register.vue";
import TdbComponent from "@/components/mildiou/Tableaudebord.vue";
import GapMeteo from "@/components/mildiou/Gap_meteo.vue";
import ManagePlot from "@/components/mildiou/gestion_parcelles.vue";
import ParcelleComponent from "@/components/mildiou/Parcelle.vue";
import Menucontextuel from '../components/Menucontextuel.vue';

export default {
  name: "Mildiou",
  components: {
    TdbComponent,
    GapMeteo,
    ManagePlot,
    ParcelleComponent,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : [
            {
                id:1,
                chemin:"/mildiou/tableaudebord",
                nom:"Tableau de bord",
                icon: "mdi-format-list-text"
            }
            ,
            {
                id:2,
                chemin:"/mildiou/gap_meteo",
                nom:"+ Météo",
                icon: "mdi-weather-cloudy"
            },
            {
                id:3,
                chemin:"/mildiou/gestion_parcelles",
                nom:"Gérer mes Parcelles",
                icon: "mdi-cog-outline"
            }
            // ,
            // {
            //     id:2,
            //     chemin:"/mildiou/parcelle/:id",
            //     nom:"Détail d'une parcelle",
            //     icon: "mdi-magnify"
            // }
        ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showTdbComponent() {
      return this.currentRouteName == "mildiou-tdb";
    },
    showDetailParcelleComponent() {
      return this.currentRouteName == "mildiou-detail-parcelle";
    },
    showDetailGapMeteo() {
      return this.currentRouteName == "gap_meteo";
    },
    showAddNewPlotMildiou() {
      return this.currentRouteName == "gestion_parcelles";
    }
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    }
  }
};
</script>

<style >



  @media only screen and (max-width: 960px) {


}

</style>
<template>
  <div>
    <v-row fill-height>
      <v-col cols="1">
        <menucontextuel :menu="menu"></menucontextuel>
      </v-col>

      <v-col cols="11">
        <GestionAgrisComponent v-if="showGestionAgris" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import GestionAgrisComponent from "@/components/gestion/GestionAgris.vue";
import Menucontextuel from '../components/Menucontextuel.vue';

export default {
  name: "Administration",
  components: {
    GestionAgrisComponent,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : [],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showGestionAgris() {
      return this.currentRouteName == "gestion-agris";
    },
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>
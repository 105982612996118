<template>
  <div>
    <v-container style="margin-top: 10px">

      <!-- DETAIL PARCELLE -->
      <h3 v-on:click="showDetailParcelle = !showDetailParcelle">
        Détail de la parcelle
        <v-icon small class="mr-2"  v-if="!showDetailParcelle">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div style="margin-top:20px;" v-if="showDetailParcelle">
        <v-row>
          <v-col cols="6">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              INFORMATIONS PARCELLE
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Intégrateur :</span><span
                    class="parcelle-card-val">{{dataPlot.nom_operateur}}</span>
                </div>
                <div><span class="parcelle-card-title">Client :</span><span
                    class="parcelle-card-val">{{dataPlot.nom_user}}</span>
                </div>
                <div><span class="parcelle-card-title">Culture :</span><span
                    class="parcelle-card-val" v-if="dataPlot.codeespece">{{$store.getters['cap/NomEspeceSelonCode'](dataPlot.codeespece)}}</span>
                </div>
                <div><span class="parcelle-card-title">Code parcelle :</span><span
                    class="parcelle-card-val" style="width:280px;">{{dataPlot.code_parcelle_os}}</span>
                </div>
                <div><span class="parcelle-card-title">Variété :</span><span
                    class="parcelle-card-val">{{dataPlot.variete}}</span>
                </div>
                <div><span class="parcelle-card-title">Département :</span><span
                    class="parcelle-card-val">{{dataPlot.code_dept}}</span>
                </div>
                <div><span class="parcelle-card-title">Date semis :</span><span
                    class="parcelle-card-val">{{dataPlot.date_semis}}</span>
                </div>
                <div><span class="parcelle-card-title">Sol :</span><span
                    class="parcelle-card-val">{{dataPlot.codesol}}</span>
                </div>
                <div><span class="parcelle-card-title">Labour :</span><span
                    class="parcelle-card-val">{{dataPlot.labour}}</span>
                </div>
                <div><span class="parcelle-card-title">Précédent :</span><span
                    class="parcelle-card-val">{{dataPlot.code_precedent}}</span>
                </div>
                <div><span class="parcelle-card-title">Risque fusa :</span><span
                    class="parcelle-card-val" :style="colorOfRisque(dataPlot.risque_fusa)">{{dataPlot.risque_fusa}}</span>
                </div>
                <div><span class="parcelle-card-title">Risque (don classerisque) :</span><span
                    class="parcelle-card-val">{{dataPlot.don_classerisque}}</span>
                </div>
                <div><span class="parcelle-card-title">Date calcul :</span><span
                    class="parcelle-card-val">{{dataPlot.date_calcul}}</span>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4">
            <!--
            https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
            https://vue2-leaflet.netlify.app/examples/custom-icons.html
            -->
            <l-map :center="carte.center" :zoom="carte.zoom" class="map" ref="map" @update:zoom="zoomUpdated"
              @update:center="centerUpdated">
              <l-tile-layer :url="carte.url">
              </l-tile-layer>
              <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                <l-icon ref="icon" :icon-url="marker.imageUrl">
                </l-icon>
              </l-marker>
            </l-map>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              INFORMATIONS MALADIES 
              <div class="parcelle-card">
                
                <ul v-if="dataPlot.codeespece==27">
                  <li>Helminthosporiose
                    <ul>
                      <li>Visite 1 : {{dataPlot.helmintho_date1}}</li>
                      <li>Visite 2 : {{dataPlot.helmintho_date2}}</li>
                    </ul>
                  </li>
                  <li>Rhynchosporiose
                    <ul>
                      <li>Visite 1 : {{dataPlot.rhyncho_date1}}</li>
                      <li>Visite 2 : {{dataPlot.rhyncho_date2}}</li>
                    </ul>
                  </li>
                </ul>
                <ul v-else>
                  <li>Piétin verse
                    <ul>
                      <li>Visite 1 : {{dataPlot.pv_datealerte}}</li>
                      <li>Visite 2 : {{dataPlot.pv_datet1}}</li>
                    </ul>
                  </li>
                  <li>Fusariose
                    <ul>
                      <li>Alerte : {{dataPlot.fusa_datealerte}}</li>
                      <li>Traitement : {{dataPlot.fusa_datet1}}</li>
                    </ul>
                  </li>
                  <li>Rouille jaune
                    <ul>
                      <li>Visite 1 : {{dataPlot.rouillejaune_crusty_date1}}</li>
                      <li>Visite 2 : {{dataPlot.rouillejaune_crusty_date2}}</li>
                      <li>Visite 3 : {{dataPlot.rouillejaune_crusty_date3}}</li>
                    </ul>
                  </li>
                  <li>Rouille brune
                    <ul>
                      <li>Visite : {{dataPlot.rouillebrune_datealerte}}</li>
                      <li>Traitement : {{dataPlot.rouillebrune_datet1}}</li>
                    </ul>
                  </li>
                  <li>Septoriose
                    <ul>
                      <li>Alerte 1 : {{dataPlot.septo_datealerte}}</li>
                      <li>Traitement 1 : {{dataPlot.septo_datet1}}</li>
                      <li>Alerte 2 : {{dataPlot.septo_datealerte2}}</li>
                      <li>Traitement 2 : {{dataPlot.septo_datet2}}</li>
                    </ul>
                  </li>
                </ul>         
              </div>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              TRAITEMENTS
              <div class="parcelle-card" v-if="dataPlot.traitements">
                <div v-for="maladie in JSON.parse(dataPlot.traitements)" :key="maladie.maladie">
                  <ul>
                    <li>{{maladie.maladie}}
                      <ul v-for="trait in maladie.traitements" :key="trait.date"><li>{{trait.date}} ({{trait.dose}} - {{trait.produit}})</li></ul>
                    </li>
                  </ul>
                </div>     
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              STADES
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Levée :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_levee}}</span><span v-if="isStadeObs('levee')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("levee")}}</span></div>
                <div><span class="parcelle-card-title">Epi 1 cm :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_epi1cm}}</span><span v-if="isStadeObs('epi1cm')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("epi1cm")}}</span></div>
                <div><span class="parcelle-card-title">1 noeud :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_1n}}</span><span v-if="isStadeObs('1n')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("1n")}}</span></div>
                <div><span class="parcelle-card-title">2 noeuds :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_2n}}</span><span v-if="isStadeObs('2n')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("2n")}}</span></div>
                <div><span class="parcelle-card-title">DFP :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_dfp}}</span><span v-if="isStadeObs('dfp')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("dfp")}}</span></div>
                <div><span class="parcelle-card-title">DFE :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_meiose}}</span><span v-if="isStadeObs('meiose')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("meiose")}}</span></div>
                <div><span class="parcelle-card-title">Epiaison :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_epiaison}}</span><span v-if="isStadeObs('epiaison')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("epiaison")}}</span></div>
                <div><span class="parcelle-card-title">Floraison :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_floraison}}</span><span v-if="isStadeObs('floraison')!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs("floraison")}}</span></div>
                <div><span class="parcelle-card-title">Maturité :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_maturite}}</span></div>
                <div><span class="parcelle-card-title">Récolte :</span><span
                    class="parcelle-card-val">{{dataPlot.stade_recolte}}</span></div>
                    _____________
                <div v-if="dataPlot.e1cm_observe!=null">
                  <div><span class="parcelle-card-title">Epi 1 cm réel :</span><span
                      class="parcelle-card-val">{{dataPlot.stade_epi1cm}}</span></div>   
                  <div><span class="parcelle-card-title">Epi 1 cm prévu :</span><span
                      class="parcelle-card-val">{{dataPlot.stade_epi1cm_prevu}}</span></div>
                  <div><span class="parcelle-card-title">Epi 1 cm observation :</span><span
                      class="parcelle-card-val">{{dataPlot.e1cm_observe}} ({{dataPlot.hauteur_moyenne}}mm)</span></div>
                </div>
                <div v-else>
                  Pas d'observation d'épi 1 cm
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- FIN DETAIL PARCELLE -->

      <!-- DETAIL STADE -->
      <h3>
        Détail des stades et maladies
      </h3>
      <div style="margin-top:20px;">
        <v-row>
          <v-col cols="8 mx-auto">
              <v-chart style="height:450px" :option="chartOptions" />
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateResultat"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    style="height:25px;"
                ></v-text-field>
              </template>
              <v-date-picker
              v-model="dateResultat"
              no-title
              scrollable
              >
              </v-date-picker>
            </v-menu>
            <br><v-icon small class="mr-2" v-on:click="getInfosResultats()">
                mdi-information-variant-circle-outline
              </v-icon>
          </v-col>
          <v-col cols="9">
            <v-data-table
              :headers="headersResultats"
              :items="ResultatsPastille"
              item-value="name"
              class="elevation-1"
              :hide-default-footer=true
              dense
            >
              <template v-slot:[`item.j1`]="{ item }">
                <v-chip :color="getColorResultat(item.j1)" dark dense></v-chip>
              </template>
              <template v-slot:[`item.j2`]="{ item }">
                <v-chip :color="getColorResultat(item.j2)" dark></v-chip>
              </template>
              <template v-slot:[`item.j3`]="{ item }">
                <v-chip :color="getColorResultat(item.j3)" dark></v-chip>
              </template>
              <template v-slot:[`item.j4`]="{ item }">
                <v-chip :color="getColorResultat(item.j4)" dark></v-chip>
              </template>
              <template v-slot:[`item.j5`]="{ item }">
                <v-chip :color="getColorResultat(item.j5)" dark></v-chip>
              </template>
              <template v-slot:[`item.j6`]="{ item }">
                <v-chip :color="getColorResultat(item.j6)" dark></v-chip>
              </template>
              <template v-slot:[`item.j7`]="{ item }">
                <v-chip :color="getColorResultat(item.j7)" dark></v-chip>
              </template>
              <template v-slot:[`item.j8`]="{ item }">
                <v-chip :color="getColorResultat(item.j8)" dark></v-chip>
              </template>
            </v-data-table>

          </v-col>
          
        </v-row>
      </div>
      <!-- FIN DETAIL STADE -->
  

  <!-- DETAIL STADE -->
  <h3 v-on:click="showDetailCalculs = !showDetailCalculs">
    Détail des derniers calculs
    <v-icon small class="mr-2"  v-if="!showDetailCalculs">
      mdi-chevron-down
    </v-icon>
    <v-icon small class="mr-2" v-else>
      mdi-chevron-up
    </v-icon>
  </h3>
  <div style="margin-top:20px;" v-if="showDetailCalculs">
    <div> 
      <v-card>
        <p>20 derniers calculs de la parcelle.</p>
        <!--https://codepen.io/wolfpup/pen/gOadmPx-->
        <v-data-table :loading="!dataloadedCalculs" loading-text="Chargement des données...."  item-key="id_sortie" :headers="headersCalculs" :items="dataCalculs" dense>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showItem(item.id_plot)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.date_calcul`]="{ item }">{{ item.date_calcul.slice(0,-7) }}</template>
        </v-data-table>
      </v-card>
      
      <v-btn small color="info" class="mt-2 white--text">
        <download-excel :data="dataCalculs" :name="getNomFichier()" :fields="excelfieldsCalculs">
          Export Excel
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>
    </div>
  </div>
  <!-- FIN DETAIL STADE -->

    </v-container>
  </div>
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesStadeService from "@/service/parcellesStade";
import VChart from "vue-echarts";
import { getDate, getNewDateFromFr } from "@/helpers/functions";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';

export default {
  name: "Suivi_Stade_Cap",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesStadeService,
    VChart,
    getDate,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      dataloadedCalculs: false,
      showTableur : false,
      showDetailParcelle: true,
      showDetailCalculs: false,
      dataPlot : [],
      dataMeteo : [],
      dataCalculs : [],
      calendar : {},
      menuDate: false,
      dateResultat : null,
      headersResultats: [],
      Resultats: [],
      ResultatsPastille: [],
      headersCalculs: [
        //colonnes visibles
        { text: "id_sortie", value: "id_sortie"},
        { text: "id_plot", value: "id_plot"},
        { text: "date_calcul", value: "date_calcul"},
        { text: "date_fin_previsions", value: "date_fin_previsions"},
        { text: "stade_levee", value: "stade_levee"},
        { text: "stade_debuttallage", value: "stade_debuttallage"},
        { text: "stade_epi1cm", value: "stade_epi1cm"},
        { text: "stade_1n", value: "stade_1n"},
        { text: "stade_2n", value: "stade_2n"},
        { text: "stade_dfp", value: "stade_dfp"},
        { text: "stade_meiose", value: "stade_meiose"},
        { text: "stade_epiaison", value: "stade_epiaison"},
        { text: "stade_floraison", value: "stade_floraison"},
        { text: "stade_maturite", value: "stade_maturite"},
        { text: "stade_recolte", value: "stade_recolte"},
        { text: "fusa_datealerte", value: "fusa_datealerte"},
        { text: "fusa_datet1", value: "fusa_datet1"},
        { text: "don_classerisque", value: "don_classerisque"},
        { text: "septo_datealerte", value: "septo_datealerte"},
        { text: "septo_datet1", value: "septo_datet1"},
        { text: "rouillebrune_datealerte", value: "rouillebrune_datealerte"},
        { text: "rouillebrune_datet1", value: "rouillebrune_datet1"},
        { text: "rouillejaune_datealerte", value: "rouillejaune_datealerte"},
        { text: "rouillejaune_datet1", value: "rouillejaune_datet1"},
        { text: "pv_datealerte", value: "pv_datealerte"},
        { text: "pv_datet1", value: "pv_datet1"},
        { text: "rouillejaune_ydebride_datealerte", value: "rouillejaune_ydebride_datealerte"},
        { text: "rouillejaune_ydebride_datet1", value: "rouillejaune_ydebride_datet1"},
        { text: "rouillejaune_crusty_date1", value: "rouillejaune_crusty_date1"},
        { text: "rouillejaune_crusty_date2", value: "rouillejaune_crusty_date2"},
        { text: "rouillejaune_crusty_date3", value: "rouillejaune_crusty_date3"},
        { text: "pv_cumul_top", value: "pv_cumul_top"},
        { text: "risque_fusa", value: "risque_fusa"},
        { text: "septo_datealerte2", value: "septo_datealerte2"},
        { text: "septo_datet2", value: "septo_datet2"},
        { text: "risque_agronomique", value: "risque_agronomique"},
        { text: "score_don_moyenne", value: "score_don_moyenne"},
        { text: "helmintho_date1", value: "helmintho_date1"},
        { text: "rhyncho_date1", value: "rhyncho_date1"},
        { text: "septo_datet1_observe", value: "septo_datet1_observe"},
        { text: "helmintho_date2", value: "helmintho_date2"},
        { text: "rhyncho_date2", value: "rhyncho_date2"},
        { text: "pv_score_actualise", value: "pv_score_actualise"},
        { text: "epi1cm_decalageoadjours", value: "epi1cm_decalageoadjours"},
        { text: "epiaison_decalageoadjours", value: "epiaison_decalageoadjours"},
        { text: "date_fix_t1", value: "date_fix_t1"},
        { text: "date_fix_t2", value: "date_fix_t2"},
        { text: "septo_datealerte3", value: "septo_datealerte3"},
        { text: "septo_datet3", value: "septo_datet3"}
      ],
      excelfieldsCalculs:  { 
        "id_sortie": "id_sortie",
        "id_plot": "id_plot",
        "date_calcul": "date_calcul",
        "date_fin_previsions": "date_fin_previsions",
        "stade_levee": "stade_levee",
        "stade_debuttallage": "stade_debuttallage",
        "stade_epi1cm": "stade_epi1cm",
        "stade_1n": "stade_1n",
        "stade_2n": "stade_2n",
        "stade_dfp": "stade_dfp",
        "stade_meiose": "stade_meiose",
        "stade_epiaison": "stade_epiaison",
        "stade_floraison": "stade_floraison",
        "stade_maturite": "stade_maturite",
        "stade_recolte": "stade_recolte",
        "fusa_datealerte": "fusa_datealerte",
        "fusa_datet1": "fusa_datet1",
        "don_classerisque": "don_classerisque",
        "septo_datealerte": "septo_datealerte",
        "septo_datet1": "septo_datet1",
        "rouillebrune_datealerte": "rouillebrune_datealerte",
        "rouillebrune_datet1": "rouillebrune_datet1",
        "rouillejaune_datealerte": "rouillejaune_datealerte",
        "rouillejaune_datet1": "rouillejaune_datet1",
        "pv_datealerte": "pv_datealerte",
        "pv_datet1": "pv_datet1",
        "rouillejaune_ydebride_datealerte": "rouillejaune_ydebride_datealerte",
        "rouillejaune_ydebride_datet1": "rouillejaune_ydebride_datet1",
        "rouillejaune_crusty_date1": "rouillejaune_crusty_date1",
        "rouillejaune_crusty_date2": "rouillejaune_crusty_date2",
        "rouillejaune_crusty_date3": "rouillejaune_crusty_date3",
        "pv_cumul_top": "pv_cumul_top",
        "risque_fusa": "risque_fusa",
        "septo_datealerte2": "septo_datealerte2",
        "septo_datet2": "septo_datet2",
        "risque_agronomique": "risque_agronomique",
        "score_don_moyenne": "score_don_moyenne",
        "helmintho_date1": "helmintho_date1",
        "rhyncho_date1": "rhyncho_date1",
        "septo_datet1_observe": "septo_datet1_observe",
        "helmintho_date2": "helmintho_date2",
        "rhyncho_date2": "rhyncho_date2",
        "pv_score_actualise": "pv_score_actualise",
        "epi1cm_decalageoadjours": "epi1cm_decalageoadjours",
        "epiaison_decalageoadjours": "epiaison_decalageoadjours",
        "date_fix_t1": "date_fix_t1",
        "date_fix_t2": "date_fix_t2",
        "septo_datealerte3": "septo_datealerte3",
        "septo_datet3": "septo_datet3"
      },

      carte: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [46.76782, 2.43129],
        zoom: 10,
        markers: [],
      },
      chartOptions: {
        title: {
          text: 'Dates des stades et maladies et données climatiques'
        },
        tooltip: {
          show: true,
          trigger: 'axis'
          },
        legend: {
          data: [],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: [{
            type: 'value',
            name: 'Température (°C), Pluviométrie (mm)',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            max:70,
            alignTicks: true
          },
          {
            type: 'value',
            name: 'Température cumulée (°C)',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 50,
            alignTicks: true
        }],
        series: [],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '75%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          left: '15%', // Position according to the parent element (in the middle)
          top: '15%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 2,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'),
              width: 165,
              height: 32
          }
        }]
      },

    };
  },

  watch: {
    view_reel: function() {
      this.filterTabIndices()
    },
    view_previ: function() {
      this.filterTabIndices()
    },
    indices_date_debut: function() {
      this.filterTabIndices()
    },
    indices_date_fin: function() {
      this.filterTabIndices()
    },
    showDetailCalculs: function() {
      this.getLastCalculs()
    },
    dateResultat: function() {
      this.getResultats()
    },
    dataMeteo : function(){
      this.constructGraph()
    }
  },


  computed: {

  },

  async mounted() {   
    
    this.dataPlot = await parcellesStadeService.getInfosForOnePlot(this.$route.params.id);
    this.dataPlot = this.dataPlot[0];
    if(this.dataPlot.stades_obs!=null){
      this.dataPlot.stades_obs = JSON.parse(this.dataPlot.stades_obs)
    }

    this.getDataMeteo()

    var now = new Date()
    this.dateResultat = getDate(now,'yyyy-mm-dd')
    
    //MAP
    this.carte.markers = [{
      id: this.dataPlot.id,
      coordinates: [this.dataPlot.x, this.dataPlot.y],
      imageUrl: require('@/assets/map_icon_red.png')
    }]
    this.carte.center = [this.dataPlot.x, this.dataPlot.y];

  },

  
  methods: {
    isStadeObs(code_stade) {
      var date = false
      if(this.dataPlot.stades_obs!=null && this.dataPlot.stades_obs.length>0){
        this.dataPlot.stades_obs.forEach((element) => {
          if(element.code_arvalis==code_stade){
            date = element.date
          }
        });
      }

      return date
      
    },

    async getLastCalculs(){
      this.dataCalculs = await parcellesStadeService.getCalculsForOnePlot(this.$route.params.id);
      this.dataloadedCalculs = true;
    },

    showItem(id_station, type = null) {
        // Stockage de la parcelle sélectionnée dans le store
        let route = this.$router
          .resolve({
            name: 'detail-station',
            params: {
              id: id_station
            }
          });
        if (type == 'href') {
          return route.href;
        } else {
          window.open(route.href);
        }
      },

    colorOfRisque(item){
      if (item == "faible") return {color: 'green'} 
      else if (item == "moyen") return {color: 'orange'} 
      else if (item == "élevé") return {color: 'red'}  
    },

    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')
      return now+'default.xls'
    },

   
    //MAP
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
      
    },

    
    getTabChartDate() {
        //Recuperation et mise en forme de la liste de date avec format yyyy-mm-dd
        var date_min = getNewDateFromFr(this.dataPlot.date_semis);
        //var date_semis_us = getDate(date_semis,'yyyy-mm-dd');
        var date_max = new Date(this.dataPlot['stade_maturite']);
        var calendar = this.getDatesInRange(date_min,date_max);

        this.calendar = calendar;

        return calendar;
    },

    getTabChartStade(){
      var stades = ['stade_debuttallage','stade_epi1cm','stade_1n','stade_2n','stade_dfp','stade_meiose','stade_epiaison','stade_floraison']
      var array_stades = []
      stades.forEach(element => {
        array_stades.push({ name: element.replace('stade_',''), xAxis: this.dataPlot[element], lineStyle: {
              color: "#33ce29de",
              type: 'solid',
              width: 3                     
            }, label:{show:true,formatter: '{b}', rotate:90, position:'end',align:'right', offset:[-8,-3]}})
      
      });
      return array_stades
    },

    getTabChartMeteo(type){
      var array_meteo = []
      var meteo = this.dataMeteo
      meteo.forEach(element => {
        array_meteo.push([element['DateDebut'],element[type]])
      
      });
      return array_meteo
    },

    //Création du "calendrier" de Dates entre 2 dates [min ; max]
    getDatesInRange(date_min, date_max) {
      const date = date_min;
      const calendar = [];
      while (date <= date_max) {
        calendar.push(new Date(date).toISOString().slice(0, 10));
        date.setDate(date.getDate() + 1);
      }
      return calendar;
    },

    async getResultats(){
      var results= await parcellesStadeService.getResultatsForOnePlot(this.$route.params.id,this.dateResultat);
      this.Resultats=results
      results = results[0]['risques']
      var tab_results = new Array()
      var tab_headers = [{ text: 'Maladie', align: 'start', sortable: false, value: 'maladie'}]
      var jour
      var j1
      var j2
      var j3
      var j4
      var j5
      var j6
      var j7
      var j8
      var headers = false
      results.forEach(element =>{
        jour = 1
        element['previ'].forEach(dt => {
          eval("j"+ jour +" = "+dt['risque']);
          if(headers==false){
            tab_headers.push({ text: dt['date'], align: 'middle', sortable: false, value: "j"+ jour })
          }
          jour+=1
        });
        headers=true
        tab_results.push({maladie:element['maladie'],j1:j1,j2:j2,j3:j3,j4:j4,j5:j5,j6:j6,j7:j7,j8:j8})
        
      });
      this.ResultatsPastille=tab_results
      this.headersResultats=tab_headers

    },

    getColorResultat(valeur){
      var color = 'grey'
      if(valeur==10){
        color='green'
      } else if(valeur==20){
        color='orange'
      } else if(valeur==30){
        color='red'
      }

      return color
    },

    getInfosResultats(){
      this.$store.dispatch('informations/setInfo', { message: {titre:"Resultat API",description:this.Resultats} });
    },

    async getDataMeteo(){
      
      var date_semis = getNewDateFromFr(this.dataPlot.date_semis);
      var date_semis_us = getDate(date_semis,'yyyy-mm-dd');
      
      var date_semis_11mois = getNewDateFromFr(this.dataPlot.date_semis);
      date_semis_11mois = date_semis_11mois.setMonth(date_semis_11mois.getMonth() + 11);
      var date_fin_previ = new Date();
      date_fin_previ.setDate(date_fin_previ.getDate() + 6);
      var date_fin = new Date(Math.min(...[date_semis_11mois,date_fin_previ]));
      date_fin = getDate(new Date(date_fin),'yyyy-mm-dd'); 

      var parametres = {mixte:{brut:['TMini', 'TMaxi','Pluie'],cumul:['TMoy'],types:['Observé','Prévisionnel']},frequentiel:{cumul:['TMoy']}};
      var results= await parcellesStadeService.getDataMeteo({latitude:this.dataPlot.x, longitude:this.dataPlot.y, code_station:this.dataPlot.code_station, date_debut:date_semis_us, date_fin:date_fin, parametres:parametres});

      this.dataMeteo=results
    },

    constructGraph(){
      this.chartOptions.xAxis.data = this.getTabChartDate();
      this.chartOptions.series= [
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: this.getTabChartStade(),
              tooltip: {
                show:true,
                formatter: function (a) {
                  return 'Date : ' + a.data.xAxis
                }
              },
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'Pluie',
            type: 'bar',
            data: this.getTabChartMeteo('Pluie'),
            itemStyle: {color: '#127CE6'},
          },
          {
            name: 'T° max',
            type: 'line',
            data: this.getTabChartMeteo('TMaxi'),
            itemStyle: {color: '#E63812'},
            showSymbol: false,
          },
          {
            name: 'T° min',
            type: 'line',
            data: this.getTabChartMeteo('TMini'),
            itemStyle: {color: '#62E612'},
            showSymbol: false,
          },
          {
            name: 'cumul T°',
            type: 'line',
            data: this.getTabChartMeteo('cumul_TMoy'),
            itemStyle: {color: '#863ADD'},
            showSymbol: false,
            yAxisIndex: 1,
          },
          {
            name: 'cumul T° frequentiel',
            type: 'line',
            data: this.getTabChartMeteo('freq_cumul_TMoy'),
            itemStyle: {color: '#968EA0'},
            showSymbol: false,
            yAxisIndex: 1,
          }
        ]
      this.chartOptions.legend.data = ['Stades', 'Pluie','T° max','T° min','cumul T°','cumul T° frequentiel']

      if(this.dataPlot.codeespece==27){
        if(this.dataPlot.helmintho_date1!=null || this.dataPlot.helmintho_date2!=null)
        {
          this.chartOptions.series.push({
            name: 'Helminthosporiose',
            type: 'scatter',
            data: [[this.dataPlot.helmintho_date1,40,'Helminthosporiose','Visite 1'],[this.dataPlot.helmintho_date2,40,'Helminthosporiose','Visite 2']],
            itemStyle: {color: '#984807'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Helminthosporiose');
        }
        if(this.dataPlot.rhyncho_date1!=null || this.dataPlot.rhyncho_date2!=null)
        {
          this.chartOptions.series.push({
            name: 'Rhynchosporiose',
            type: 'scatter',
            data: [[this.dataPlot.rhyncho_date1,50,'Rhynchosporiose','Alerte'],[this.dataPlot.rhyncho_date2,50,'Rhynchosporiose','Traitement']],
            itemStyle: {color: '#FD7E00'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Rhynchosporiose');
        }

      } else {
        if(this.dataPlot.fusa_datealerte!=null || this.dataPlot.fusa_datet1!=null)
        {
          this.chartOptions.series.push({
            name: 'Fusariose alerte',
            type: 'scatter',
            data: [[this.dataPlot.fusa_datealerte,40,'Fusariose','Alerte']],
            itemStyle: {color: '#E49F9F'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Fusariose alerte');
          this.chartOptions.series.push({
            name: 'Fusariose traitement',
            type: 'scatter',
            data: [[this.dataPlot.fusa_datet1,40,'Fusariose','Traitement']],
            itemStyle: {color: '#C93F3F'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Fusariose traitement');
        }
        if(this.dataPlot.septo_datealerte!=null || this.dataPlot.septo_datet1!=null || this.dataPlot.septo_datealerte2!=null || this.dataPlot.septo_datet2!=null)
        {     
          this.chartOptions.series.push({
            name: 'Septoriose alerte',
            type: 'scatter',
            data: [[this.dataPlot.septo_datealerte,45,'Septoriose','Alerte 1'],[this.dataPlot.septo_datealerte2,45,'Septoriose','Alerte 2']],
            itemStyle: {color: '#A9C09A'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Septoriose alerte');
          this.chartOptions.series.push({
            name: 'Septoriose traitement',
            type: 'scatter',
            data: [[this.dataPlot.septo_datet1,45,'Septoriose','Traitement 1'],[this.dataPlot.septo_datet2,45,'Septoriose','Traitement 2']],
            itemStyle: {color: '#548235'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Septoriose traitement');
        }
        if(this.dataPlot.rouillebrune_datealerte!=null || this.dataPlot.rouillebrune_datet1!=null)
        {
          this.chartOptions.series.push({
            name: 'Rouille brune visite',
            type: 'scatter',
            data: [[this.dataPlot.rouillebrune_datealerte,50,'Rouille brune','Visite']],
            itemStyle: {color: '#CBA383'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Rouille brune visite');
          this.chartOptions.series.push({
            name: 'Rouille brune traitement',
            type: 'scatter',
            data: [[this.dataPlot.rouillebrune_datet1,50,'Rouille brune','Traitement']],
            itemStyle: {color: '#984807'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Rouille brune traitement');
        }
        if(this.dataPlot.rouillejaune_crusty_date1!=null || this.dataPlot.rouillejaune_crusty_date2!=null || this.dataPlot.rouillejaune_crusty_date!= null)
        {
          this.chartOptions.series.push({
            name: 'Rouille jaune',
            type: 'scatter',
            data: [[this.dataPlot.rouillejaune_crusty_date1,55,'Rouille jaune','Visite 1'],[this.dataPlot.rouillejaune_crusty_date2,55,'Rouille jaune','Visite 2'],[this.dataPlot.rouillejaune_crusty_date3,55,'Rouille jaune','Visite 3']],
            itemStyle: {color: '#e2570f'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Rouille jaune');
        }
        if(this.dataPlot.pv_datealerte!=null || this.dataPlot.pv_datet1!=null)
        {
          this.chartOptions.series.push({
            name: 'Piétin verse',
            type: 'scatter',
            data: [[this.dataPlot.pv_datealerte,60,'Piétin verse','Visite 1'],[this.dataPlot.pv_datet1,60,'Piétin verse','Visite 2']],
            itemStyle: {color: '#4F81BD'},
            symbolSize: 12,
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                  return 'Maladie : ' + a.value[2] + '<br>Type : ' + a.value[3] + '<br>Date : ' + a.value[0]
                }
              },
            })
          this.chartOptions.legend.data.push('Piétin verse');
        }


      }
    
    }

  },
  };
  

</script>

<style scoped>
.chart {
  height: 450px;
}
.float {
  float: left;
  margin-right: 20px;
}

.map {
    height: 415px !important;
    width: 500px !important;
    position: relative !important;
    z-index:0;
 }

</style>
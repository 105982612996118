<template>
  <div>
    <v-container style="margin-top: 10px">
    <!--API PREVI-LIS-->
      <v-card class="mb-15">
        <h3>API Prévi-LIS</h3>
        <v-row class="pl-10 pt-5" v-if="infos_previlis">
      
            <v-col cols="8">
              <v-simple-table v-if="infos_previlis" fixed-header height="200px" style="width:80%">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Espece
                      </th>
                      <th class="text-left">
                        Nb de Parcelles
                      </th>
                      <th class="text-left">
                        % Parcelles Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ligne in infos_previlis" :key="ligne.codeespece">
                      <td style="height:12px">{{ ligne.libelle_espece }}</td>
                      <td style="height:12px">{{ ligne.nbr_espece }}</td>
                      <td style="height:12px">{{ parseFloat(ligne.pourcentage).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
    <!--FIN API PREVI-LIS-->
    </v-container>
  </div>

</template>

<script>
import downloadExcel from "vue-json-excel";
import administrationTdbService from "@/service/administrationTdb";
import { getDate } from "@/helpers/functions";


export default {
  name: "Tdb",
  components: {
    downloadExcel,
    administrationTdbService,
    getDate
  },
  data() {
    return {
      infos_previlis : null,
    };

  
  },
  async mounted() {

    //DATA pour le TdB en interface dans le contenur API Previ-Lis
    this.infos_previlis = await administrationTdbService.getTdbPrevilis();

  },

  methods: {
    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')

      return now+'_Export_parcelles_previlis'
    }
  }
};
</script>

<style>
</style>

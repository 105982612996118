<template>
  <div>
    <v-row fill-height>
      <v-col cols="1">
        <menucontextuel :menu="menu"></menucontextuel>
      </v-col>

      <v-col cols="11">
        <SuiviComponent v-if="showSuiviComponent" />
        <ParcelleComponent v-if="showDetailParcelleComponent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import SuiviComponent from "@/components/chn/Suivi.vue";
import ParcelleComponent from "@/components/chn/Parcelle.vue";
import Menucontextuel from '../components/Menucontextuel.vue';



export default {
  name: "CHN",
  components: {
    SuiviComponent,
    ParcelleComponent,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : [
            {
                id:1,
                chemin:"/chn/suivi",
                nom:"Suivi global",
                icon: "mdi-format-list-text"
            },
            {
                id:2,
                chemin:"/chn/parcelle/:id",
                nom:"Détail d'une parcelle",
                icon: "mdi-magnify"
            }
        ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showSuiviComponent() {
      return this.currentRouteName == "chn-suivi";
    },
    showDetailParcelleComponent() {
      return this.currentRouteName == "chn-detail-parcelle";
    }
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>
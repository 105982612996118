<template>
  <v-row justify="center">
    <loading :active="isLoading" :is-full-page="fullPage" />
    <v-dialog v-model="dialog_init" persistent max-width="950px">
      <v-card>
        <v-container>
          <v-row class="mx-auto">
            <v-col cols="12 mx-auto">
              <v-data-table v-if="parcelles_temp" :search="searchWord" :loading="!dataloaded"
                loading-text="Chargement des données...." :headers="headers_plots_list" :items="parcelles_temp"
                item-key="id" :options.sync="optionsTab" :sort-by="sortByTab" :sort-desc="sortDescTab"
                class="my-awesome-table elevation-1" dense>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="showItem(item.id)">
                    mdi-eye
                  </v-icon>
                </template>
                <template v-slot:[`item.exploitant`]="{ item }">{{ item.nom_agri }} {{ item.prenom_agri }}
                  [{{ item.farmer }}]</template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeListeParcelle()">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ChoixModal from "@/components/modals/ChoixModal.vue";
import { VueEditor } from "vue2-editor";
import parcellesMildiouService from "@/service/parcellesMildiou";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "liste_parcelles",
  components: {
    ChoixModal,
    VueEditor,
    parcellesMildiouService,
    Loading
  },
  props: {
    dialog_init: Boolean,
    oad: String,
  },
  data() {
    return {
      dataloaded: false,
      isLoading: false,
      fullPage: true,
      parcelles_temp : [],
      menu: false,
      searchWord: "",
      sortByTab:'date',
      sortDescTab: [true, false],

      headers_plots_list: [{
          text: "",
          value: "actions",
          sortable: false
        },
        {
          text: "Parcelle",
          value: "parcelle"
        },
        {
          text: "Agriculteur",
          value: "exploitant"
        },
        {
          text: "Editeur OAD",
          value: "nom_integrateur"
        },
        {
          text: "Distributeur",
          value: "organism"
        }
      ],
      optionsTab: {
        itemsPerPage: 10,
      },
    };  
    
  },

  async mounted() {
  },
  watch: {
    dialog_init: async function () {
      if(this.oad=='mildiou'){
        this.parcelles_temp = await parcellesMildiouService.getInfosParcelleStation(this.$route.params.id);
      } else {
        this.parcelles_temp = []
      }
    
      this.dataloaded = true;
    },
  },
  computed: {},
  methods: {
    
    closeListeParcelle(){
      this.$emit("closeListeParcelle", false);
    },

    showItem(id_parcelle) {
      var nom_route =''

      if(this.oad=='mildiou'){
        nom_route = 'mildiou-detail-parcelle'
      }

      let route = this.$router
        .resolve({ name: nom_route, params: { id: id_parcelle } });

      window.open(route.href);
      
    },


  },
};
</script>

<style>
</style>
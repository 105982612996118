<template>
    <div ref="formulaire">

      <v-card style="padding:15px">
        <div v-if="typeRecalage=='stade'" style="overflow:hidden">
          <div>
            <v-row>
              <v-col cols="8">
                <v-icon>mdi-map-marker</v-icon>
                Date de semis et lieu : 

                <v-row>
                  <v-col cols="3">
                    <v-menu
                      v-model="stadeMenuDateDebut"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedDateDebut"
                          label="Date début semis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="selectedDateDebut" 
                        @input="stadeMenuDateDebut = false"
                        :allowed-dates="allowedDatesSemisDebut"
                        :min="getMinMaxDateSemisRecalage('min')"
                        :max="getMinMaxDateSemisRecalage('max')"
                        :picker-date="defaultShowMonthDebut"
                        @update:picker-date="defaultShowMonthDebut=null"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="stadeMenuDateFin"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedDateFin"
                          label="Date fin semis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="selectedDateFin" 
                        @input="stadeMenuDateFin = false"
                        :allowed-dates="allowedDatesSemisFin"
                        :min="getMinMaxDateSemisRecalage('min')"
                        :max="getMinMaxDateSemisRecalage('max')"
                        :picker-date="defaultShowMonthFin"
                        @update:picker-date="defaultShowMonthFin=null"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="selectedDepartement"
                      :items="listeDepartement"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="Departement(s)"
                      chips
                      multiple
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-icon>mdi-tag</v-icon>
                Recalages spécifiques : 

                <v-row dense  justify="center" align="center">
                  <v-col  cols="5">
                    <v-autocomplete
                      v-model="selectedStation"
                      :items="listeStations"
                      item-text="name"
                      item-value="code"
                      label="Station(s)"
                      chips
                      multiple
                      dense
                    />
                  </v-col>
                  <v-col cols="1" align="center">
                    -
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="selectedPra"
                      :items="listePra"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="PRA"
                      multiple
                      chips
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense  justify="center" align="center">
                  <v-col cols="5">
                    <v-select
                      v-model="selectedSolPanoramix"
                      :items="listeSolPanoramix"
                      menu-props="auto"
                      label="Sol Panoramix"
                      hide-details
                      multiple
                      chips
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="1" align="center">
                    ou
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="selectedSolBase"
                      :items="listeSolBase"
                      item-text="name"
                      item-value="code"
                      menu-props="auto"
                      label="Code base sol"
                      hide-details
                      multiple
                      chips
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <v-icon>mdi-note-text</v-icon>
                    Commentaire :
                    <v-textarea
                      v-model="commentaireRecalage"
                      solo
                      name="commentaireRecalage"
                      label="Commentaire"
                      rows="12"
                      row-height="20"
                      style="margin-top:10px;"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div style="margin-top:20px;">
            <v-icon>mdi-sprout</v-icon>
            Culture :
            <v-row dense style="margin-top:20px;">
              <v-col>
                <v-radio-group v-model="selectedEspece" row>
                  <v-radio v-for="elem in listeEspece" :key="elem.id"
                    :label="elem.name"
                    :value="elem.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense justify="center" align="center">
              <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <span>Dans le cas où toutes les variétés doivent être ciblées, ne pas sélectionner de précocité.</span>
              </v-tooltip>
              <v-col cols="3" >
                <v-select
                  v-model="selectedPrecoMontaison"
                  :items="listePrecoMontaison"
                  menu-props="auto"
                  label="Précocité montaison"
                  hide-details
                  multiple
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="1" align="center">
                ou
              </v-col>  
              <v-col cols="3">
                <v-select
                  v-model="selectedPrecoEpiaison"
                  :items="listePrecoEpiaison"
                  menu-props="auto"
                  label="Précocité épiaison"
                  hide-details
                  multiple
                  chips
                  dense
                ></v-select>
              </v-col>
              <v-col cols="1" align="center">
                ou
              </v-col>            
              <v-col cols="3" v-if="listeVariete!=[]">
                <v-autocomplete
                  v-model="selectedVariete"
                  :items="listeVariete"
                  item-text="Nom"
                  item-value="Nom"
                  menu-props="auto"
                  label="Variété"
                  hide-details
                  multiple
                  chips
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>

          <div style="margin-top:30px;">
            <v-icon>mdi-pencil</v-icon>
            Valeur de recalage :      
            <v-row dense style="margin-top:20px;">
              <v-col cols="3">
                <v-radio-group v-model="typeStadeRecalage" row dense>
                  <v-radio
                    label="Epi 1 cm"
                    value="Epi 1 cm"
                  ></v-radio>
                  <v-radio
                    label="Epiaison"
                    value="Epiaison"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Valeur"
                  type="number"   
                  step="any"
                  ref="input"
                  v-model="valStadeRecalage"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="8" style="font-size:0.8rem;padding-left:15px">
                Valeur positive -> retarde le modèle (ex : si stade E1C au 18/03, un recalage de 2 aura pour conséquence d'obtenir une date au 20/03)
                <br>Valeur négative -> avance le modèle (ex : si stade E1C au 18/03, un recalage de -2 aura pour conséquence d'obtenir une date au 16/03)
              </v-col>
            </v-row>
            <v-row dense style="margin-top:20px;">
              <v-col cols="5">
                <v-radio-group v-model="typeDemandeRecalage" row dense>
                  <v-radio
                    label="Nouvelle demande"
                    value="Nouvelle demande"
                    :disabled="disabledNewDemande"
                  ></v-radio>
                  <v-radio
                    label="Correction"
                    value="Correction"
                    :disabled="disabledCorrection"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>

          <v-btn small color="info" class="mt-2 mb-50 white--text" @click="verifEnvoiRecalageStade()" style="float:right">
              <v-icon small class="mr-2">
                mdi-upload
              </v-icon>
              Envoyer la demande
          </v-btn>

        </div>
        <div v-if="typeRecalage=='maladie'">

        </div>
      </v-card>
    </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Stade_Form",
  components: {
    downloadExcel,
    recalageService
  },
  props: {
    infosRecalage: Object
  },
  data() {
    return {
      isLoading: false,
      disabledNewDemande: false,
      disabledCorrection: true,
      typeRecalage: "stade",
      typeStadeRecalage: null,
      typeDemandeRecalage: 'Nouvelle demande',
      refRecalageCorrige: null,
      valStadeRecalage: null,
      activePicker: null,
      stadeMenuDateDebut: false,
      stadeMenuDateFin: false,
      defaultShowMonthDebut: null,
      defaultShowMonthFin: null,
      selectedDateDebut: null,
      selectedDateFin: null,
      selectedDepartement: [],
      selectedStation: [],
      selectedEspece: null,
      selectedVariete: [],
      selectedPrecoMontaison: [],
      selectedPrecoEpiaison: [],
      selectedSolPanoramix: [],
      selectedSolBase: [],
      selectedPra: [],
      warnings: '',
      listeDepartement: [],
      listeStations: [],
      listeEspece: [{id:17, code:'bth', name:'Blé tendre'},{id:40, code:'bdh', name:'Blé dur'},{id:27, code:'oh', name:'Orge d\'hiver'},{id:28, code:'op', name:'Orge de printemps'},{id:19, code:'triti', name:'Triticale'}],
      listeVariete: [],
      listePrecoMontaison: [0,1,2,3,4,5,6],
      listePrecoEpiaison: [3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8],
      listeSolPanoramix: ['Argile', 'Argilo calcaire profond', 'Argilo calcaire superficiel', 'Craie de Champagne', 'Graviers', 'Limon', 'Limon argileux', 'Sables'],
      listeSolBase: [],
      listePra: [],
      commentaireRecalage: null,
      listeToutesVarietes: null,
      horsPerimetreIR: false
    };
  },
  async mounted() {
    const el = this.$refs['formulaire']
    el.scrollIntoView({ behavior: 'smooth'})

    if((this.$store.getters['recalage/ListeVarietes']()).length==0){
      this.listeToutesVarietes = await recalageService.getVarietesCapNotes();
      this.$store.commit('recalage/defineListe', {liste:this.listeToutesVarietes})
    } else {
      this.listeToutesVarietes = this.$store.getters['recalage/ListeVarietes']()
    }

    var listeDep = null
    if((this.$store.getters['recalage/ListeDepartements']()).length==0){
      listeDep = await recalageService.getDepartementsUtilisateur();
      this.$store.commit('recalage/defineListeDepartements', {listeDepartements:listeDep})
    } 
    this.listeDepartement = this.$store.getters['recalage/ListeDepartements']()

    var listeStations = null
    if((this.$store.getters['recalage/ListeStations']()).length==0){
      listeStations = await recalageService.getAllStations();
      this.$store.commit('recalage/defineListeStations', {listeStations:listeStations})
    } 
    this.listeStations = this.$store.getters['recalage/ListeStations']()

    var listeSols = null
    if((this.$store.getters['recalage/ListeSols']()).length==0){
      listeSols = await recalageService.getAllSols();
      this.$store.commit('recalage/defineListeSols', {listeSols:listeSols})
    } 
    this.listeSolBase = this.$store.getters['recalage/ListeSols']()

    var listePra = null
    if((this.$store.getters['recalage/ListePra']()).length==0){
      listePra = await recalageService.getAllPra();
      this.$store.commit('recalage/defineListePra', {listePra:listePra})
    } 
    this.listePra = this.$store.getters['recalage/ListePra']()
    if(this.infosRecalage!==null){
      this.typeStadeRecalage= this.infosRecalage.stade,
      this.selectedEspece=this.infosRecalage.culture
      this.refRecalageCorrige= this.infosRecalage.idRef,
      this.valStadeRecalage= this.infosRecalage.jourdecalage,
      this.selectedDateDebut= this.infosRecalage.datedebut,
      this.selectedDateFin= this.infosRecalage.datefin,
      this.selectedDepartement= this.infosRecalage.departements.split(","),
      this.selectedStation= (this.infosRecalage.stations!=''?this.infosRecalage.stations.split(","):[])
      this.selectedVariete= (this.infosRecalage.varietes!=''?this.infosRecalage.varietes.split(","):[])
      this.selectedPrecoMontaison= (this.infosRecalage.precomontaison!=''?this.infosRecalage.precomontaison.split(",").map(function(item) {return parseFloat(item, 10);}):[]),
      this.selectedPrecoEpiaison= (this.infosRecalage.precoepiaison!=''?this.infosRecalage.precoepiaison.split(",").map(function(item) {return parseFloat(item, 10);}):[]),     
      this.selectedSolPanoramix= this.infosRecalage.typesolspanoramix.split(","),
      this.selectedSolBase= this.infosRecalage.typesolsarvalis.split(","),
      this.selectedPra= this.infosRecalage.pra.split(","),
      this.commentaireRecalage=this.infosRecalage.commentaire

      if(this.infosRecalage.idRef==null){
        this.typeDemandeRecalage= 'Nouvelle demande'
      } else {
        this.disabledNewDemande= true,
        this.disabledCorrection= false,
        this.typeDemandeRecalage= 'Correction'
      }
      
    } else {
      this.typeDemandeRecalage= 'Nouvelle demande'
      this.selectedEspece=17
    }
  },

  watch: {
    selectedEspece: function() {
      this.listeVariete = this.$store.getters['recalage/ListeVarietesEspece'](this.selectedEspece)
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
    stadeMenuDateDebut: function (val) {
      if(val==true){
        this.defaultShowMonthDebut = this.getDefaultMonthDateSemis('debut')
      }
    },
    stadeMenuDateFin: function (val) {
      if(val==true){
        this.defaultShowMonthFin = this.getDefaultMonthDateSemis('fin')
      }
    }
  },

  computed: {
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
  },


  methods: {
    async verifEnvoiRecalageStade () {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      var nbVarNonVide = 0
      if (this.selectedPrecoMontaison.length > 0){
        nbVarNonVide++;
        this.selectedPrecoMontaison.sort((a, b) => a - b);
      } 
      if (this.selectedPrecoEpiaison.length > 0){
        nbVarNonVide++;
        this.selectedPrecoEpiaison.sort((a, b) => a - b);
      }
      if (this.selectedVariete.length > 0) nbVarNonVide++;

      if(nbVarNonVide>1){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Formulaire erroné",description:"Les précocités épiaison et montaison ainsi que variétés ne peuvent être renseignées simultanément"} });
      } else if(this.selectedDebut!==null && this.selectedFin!==null && this.typeStadeRecalage!==null && this.ValStadeRecalage!==null && this.selectedDepartement.length>0  && this.typeDemandeRecalage!=null){
        //on vide la liste des précocité si elles sont toutes saisies
        if(this.selectedPrecoMontaison.length == this.listePrecoMontaison.length){
          this.selectedPrecoMontaison =  []
        }
        if(this.selectedPrecoEpiaison.length == this.listePrecoEpiaison.length){
          this.selectedPrecoEpiaison =  []
        }
        
        var donneesRecalageStade = {selectedDate:[this.selectedDateDebut,this.selectedDateFin], selectedDepartement:this.selectedDepartement,selectedStation:this.selectedStation,selectedEspece:this.selectedEspece,selectedVariete:this.selectedVariete,selectedPrecoMontaison:this.selectedPrecoMontaison,selectedPrecoEpiaison:this.selectedPrecoEpiaison,selectedSolPanoramix:this.selectedSolPanoramix,selectedSolBase:this.selectedSolBase,selectedPra:this.selectedPra,typeStadeRecalage:this.typeStadeRecalage,refRecalageCorrige:this.refRecalageCorrige}
        var recalagesSimilaires = await recalageService.getVerifRecalagesStades(donneesRecalageStade);
        this.warnings = '';

        if(recalagesSimilaires.length>0){
          recalagesSimilaires.forEach( element => {
            let stations = (element.stations!='')?(', stations : '+element.stations):'';
            let pra = (element.pra!='')?(', PRA : '+element.pra):'';
            let codeSolPanoramix = (element.typesolspanoramix!='')?(', sols Panoramix : '+element.typesolspanoramix):'';
            let codeSolArvalis = (element.typesolsarvalis!='')?(', sol Arvalis : '+element.typesolsarvalis):'';
            let precoMontaison = (element.precomontaison!='')?(', preco Montaison : '+element.precomontaison):'';
            let precoEpaison = (element.precoepiaison!='')?(', preco Epaison : '+element.precoepiaison):'';
            let varietes = (element.varietes!='')?(', varietes : '+element.varietes):'';
            this.warnings += '<li>ID '+element.id+' ('+element.stade+') : '+element.datedebut+' au '+element.datefin+', dep : '+element.departements+stations+pra+codeSolPanoramix+codeSolArvalis+precoMontaison+precoEpaison+varietes+'</li>'
          });
          this.confirmSaveRecalageSimilaire(this.warnings)
        } else {
          this.verifDroitRecalagesDepartements()
        }
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Formulaire incomplet",description:"Les informations suivantes sont obligatoires :\n- Plage de semis\n- Départements\n-Stade recalé\n- Valeur du recalage\n- Type de relacage (nouveau ou correction)"} });
      }
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },

    confirmSaveRecalageSimilaire(warnings){
      this.$store.dispatch("choix/setChoix", {
        question: "Attention!",
        detail: "Vous êtes sur le point de faire une demande de recalage alors qu'une demande sur des cas similaires existent. Cette dernière écrasera tout ou partie des précédentes demandes concernées.<br><br>"+warnings,
        choix: {
          choix1: { text: "Oui", function: "verifDroitRecalagesDepartements" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    verifDroitRecalagesDepartements(){
      var droits_recalage = this.$store.getters['recalage/DroitsRecalage']()
        var droits = 1
        if(this.selectedDepartement.length!=0){
          this.selectedDepartement.forEach(element => {
            if(!droits_recalage.includes(element)){
              droits = 0
            }
          });
        }
        if(this.selectedPra.length!=0){
          this.selectedPra.forEach(element => {
            var dep = element.substr(0,2)
            if(!droits_recalage.includes(dep)){
              droits = 0
            }
          });
        }

        this.horsPerimetreIR = false
        if(droits==0){
          this.confirmSaveRecalageHorsDroits()
          this.horsPerimetreIR = true
        } else {
          this.envoyerRecalageStade()
        }
    },

    confirmSaveRecalageHorsDroits(){
      this.$store.dispatch("choix/setChoix", {
        question: "Hors zone de compétence",
        detail: "Vous êtes sur le point de faire une demande de recalage sur des départements pour lesquels vous n'avez pas les droits. Confirmez vous vouloir effectuer l'enregistrement?",
        choix: {
          choix1: { text: "Oui", function: "envoyerRecalageStade" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    async envoyerRecalageStade () {
      this.closeChoix()
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isSent = null

      var donneesRecalageStade = {valStadeRecalage:this.valStadeRecalage, selectedDate:[this.selectedDateDebut,this.selectedDateFin], selectedDepartement:this.selectedDepartement,selectedStation:this.selectedStation,selectedEspece:this.selectedEspece,selectedVariete:this.selectedVariete,selectedPrecoMontaison:this.selectedPrecoMontaison,selectedPrecoEpiaison:this.selectedPrecoEpiaison,selectedSolPanoramix:this.selectedSolPanoramix,selectedSolBase:this.selectedSolBase,selectedPra:this.selectedPra,typeStadeRecalage:this.typeStadeRecalage,commentaireRecalage:this.commentaireRecalage,horsPerimetreIR:this.horsPerimetreIR,typeDemandeRecalage:this.typeDemandeRecalage,refRecalageCorrige:this.refRecalageCorrige,warnings:this.warnings}
      isSent = await recalageService.envoyerRecalageStade(donneesRecalageStade);
      
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(isSent !== true){
          alert('Un problème est survenu.')
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"La demande de recalage a bien été enregistrée."} });
        this.$emit("reloadRecalage")
        this.horsPerimetreIR = false
      }
    },
    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },

    getMinMaxDateSemisRecalage(type){
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();

      if(type=='min'){
        return (currentYear-1)+'-09-01'
      } else {
        return (currentYear)+'-03-01'
      }
    },

    getDefaultMonthDateSemis(type){
      if(type=='debut'){
        return this.getMinMaxDateSemisRecalage('min')
      } else {
        if(this.selectedDateDebut==null){
          return this.getMinMaxDateSemisRecalage('min')
        } else {
          return this.selectedDateDebut
        }
      }
    },

    allowedDatesSemisDebut(val){
      return this.allowedDatesSemis(val, 'debut')
    },

    allowedDatesSemisFin(val){
      return this.allowedDatesSemis(val, 'fin')
    },

    allowedDatesSemis(val, type){
      let dateObj = new Date(val);
      let day = dateObj.getDate()
      let dates_accepted = []

      if(type=='debut'){
        dates_accepted = [1, 6, 11, 16, 21, 26]
      } else {
        let lastDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0).getDate(); //on prend le mois suivant et on met le jour à 0 --> permet d'avoir le dernier jour du mois en cours
        dates_accepted = [5, 10, 15, 20, 25, lastDayOfMonth];
      }

      if(dates_accepted.includes(day)){
        return true
      } else {
        return false
      }
    },

    clearPickerDateDefault () {
      this.defaultShowMonthDebut = null
      this.defaultShowMonthFin = null
    }
  }
};
</script>

<style>
</style>

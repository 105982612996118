<template>
  <div>
    <v-container style="margin-top:20px;">

      <h3 v-on:click="showDetailStation = !showDetailStation">Station
        <v-icon small class="mr-2" v-if="!showDetailStation">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div id="detail_station" style="margin-top:20px;" v-if="showDetailStation">
        <v-row>
          <v-col cols="10" md="5">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              Informations
              <div class="parcelle-card">
                <br><div class="row">
                  <div class="col-sm-12">
                    <span class="parcelle-card-title">Station :</span>
                    <span class="parcelle-card-val">{{station.device_name}}</span>
                  </div>
                  <div class="col-sm-12">
                    <span class="parcelle-card-title">Code station Arvalis :</span>
                    <span class="parcelle-card-val">{{station.id}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <span class="parcelle-card-title">Opérateur météo :</span>
                    <span class="parcelle-card-val">{{station.constructor_name}}</span>
                  </div>
                  <div class="col-sm-12" v-if="!checkDroitLectureExterne">
                    <span class="parcelle-card-title">Code station Opérateur météo :</span>
                    <span class="parcelle-card-val">{{station.identification}}</span>
                  </div>
                </div>
                <div class="row" v-if="checkDroits('ADMIN','lecture')">
                  <!-- <div class="col-sm-12">
                    <span class="parcelle-card-title">Changement de localisation :</span>
                    <span class="parcelle-card-val">{{station.lastmovedate}}</span>
                  </div> -->
                  <div class="col-sm-12">
                    <span class="parcelle-card-title">Date de création :</span>
                    <span class="parcelle-card-val">{{station.p_dt_crea}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <span class="parcelle-card-title">Dernière réception météo (UTC) :</span>
                    <span class="parcelle-card-val">{{station.last_data}}</span>
                  </div>
                </div>
              </div>

            </v-card>
          </v-col>
          <v-col v-if="!isMobile" cols="7">
            <!--
            https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
            https://vue2-leaflet.netlify.app/examples/custom-icons.html
            -->
            <l-map :center="carte.center" :zoom="carte.zoom" class="map" ref="map" @update:zoom="zoomUpdated"
              @update:center="centerUpdated">
              <l-tile-layer :url="carte.url">
              </l-tile-layer>
              <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                <l-icon ref="icon" :icon-url="marker.imageUrl">
                </l-icon>
              </l-marker>
            </l-map>
          </v-col>
          <v-col v-else :cols="1">
            <v-btn color="info" @click="showModalMapMobile = true ; showCarte = !showCarte">
              <!-- Bouton MAP pour mobile -->
              <v-icon>
                mdi-map-marker
              </v-icon>
            </v-btn>
          </v-col>
          <v-col :cols="isMobile ? 10 : 5">      
    <v-btn style="width: 80%; margin: 0.5rem 3rem;" v-if="checkDroits('MILDIOU','lecture')" @click="showListeParcelle('mildiou')" class="acces-rapide">
        <v-icon small class="mr-2">
            mdi-eye
        </v-icon>
        Voir les parcelles Mildiou 
    </v-btn>
    <br>
    <StationsAndPlots :dialog_init="dialog" :oad="oad" v-on:closeListeParcelle="closeListeParcelle"/>
</v-col>

        </v-row>
      </div><br>
    
          <!-- Modal pour la carte -->
          <v-dialog id="modal_carte" v-model="showModalMapMobile" max-height="96vh">
          <v-card>
              <v-card-title>
                  <span class="headline">Localisation de la station</span><v-btn icon @click="showModalMapMobile = false">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text style="height:90vh">
                  <!-- carte  -->
                  <div style="margin-top:20px;" v-if="showCarte">
                <v-row>
                  <div style ="height:95% ; width:95%;margin:0 auto" >
                    <l-map :center="carte.center" :zoom="carte.zoom" class="map_mobile" ref="map" @update:zoom="zoomUpdated"
                    @update:center="centerUpdated">
                    <l-tile-layer :url="carte.url">
                    </l-tile-layer>
                    <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                      <l-icon ref="icon" :icon-url="marker.imageUrl">
                      </l-icon>
                    </l-marker>
                  </l-map>
                  </div>
                </v-row>
              </div>
              </v-card-text>
          </v-card>
        </v-dialog><!--FIN MODAL de la MAP en MOBILE-->

      <!--DONNEES JOUR/ HEURE - PREVISIONELLES / REELLES -->
      <v-card>
        <h3 style="margin-top:10px;">Données météo</h3>
        <div style="margin: 20px auto;">
          <v-row>
            <v-col cols="12" class="mx-auto">               
              <v-chart style="height:250px" :option="chartOptionsDMHeatMap" />
              </v-col>
          </v-row>
        </div>
        <br>
        <hr>
        <v-tabs v-model="typeAffichageData">
          <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#day">Données journalières</v-tab>
          <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#hour">Données horaires</v-tab>
        </v-tabs>

        <div style="overflow:hidden;">
          <!-- ligne : checkboxes -->
          <!-- <div class="d-flex flex-wrap justify-lg-content-center justify-content-start">
            <v-checkbox v-model="view_reel" label="Réel" class="mt-5 mr-2 text-center"></v-checkbox>
            <v-checkbox v-model="view_previ" label="Prévisionnel" class="mt-5 text-center"></v-checkbox>
          </div> -->

              <!-- ligne : champs de date -->
          <div class="d-flex flex-wrap justify-content-start mt-3">
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_debut" label="Date début" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="mr-2 text-left"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_debut"></v-date-picker>
            </v-menu>

            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="indices_date_fin" label="Date fin" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="text-left"></v-text-field>
              </template>
              <v-date-picker v-model="indices_date_fin"></v-date-picker>
            </v-menu>
          </div>
        </div>


        <v-tabs-items v-model="typeAffichageData">

          <!--PARTIE JOUR-->
          <v-tab-item value="day">
            <v-card>
              <!--TABLEUR -->
              <br>
              <h3 style="margin-top:10px;">Liste des relevés par jour</h3>
              <div style="margin-top:2rem; margin-bottom:20px;margin-top:20px; margin: 0 auto;">
                <v-card-title>
                  <!-- <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                    <span>Données prévisionnelles</span>
                  </div> -->
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche par mot clé" single-line
                    hide-details></v-text-field>
                </v-card-title>
                <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                  :headers="headers_daily" :items="meteo_data_daily" item-key="index" :options.sync="optionsTab"
                  :sort-by="sortByTab" :sort-desc="sortDescTab" class="my-awesome-table elevation-1"
                  :item-class="rowStyle" dense mobile-breakpoint="0" >
                </v-data-table>
              </div>
              <v-btn v-if="checkDroits('ADMIN','lecture')" small color="info" class="mt-2 white--text">
                <download-excel :data="meteo_data_daily" :name="getNomFichier('day')" :fields="excelfields">
                  Export Excel
                  <v-icon small class="mr-2">
                    mdi-download
                  </v-icon>
                </download-excel>
              </v-btn>
              <!--FIN TABLEUR-->
              <!-- GRAPHIQUE-->
              <section v-if="!isMobile">
                <h3 style="margin-top:10px;">Graphiques des données météo</h3>
              <div style="margin-top:2rem; margin-bottom:20px;margin-top:20px; margin: 0 auto;">
                <v-row>
                  <v-col cols="10 mx-auto">
                    <v-chart class="chart" :option="chartOptionsMeteoDay" />
                  </v-col>
                </v-row>
              </div>
              </section>
 
              <!--FIN  GRAPHIQUE-->

            </v-card>

          </v-tab-item>
          <!--FIN PARTIE Jour-->

          <!--PARTIE HEURE-->
          <v-tab-item value="hour">
            <v-card>
              <!--TABLEUR-->
              <h3 style="margin-top:10px;">Liste des relevés par heure</h3>
              <div style="margin-top:2rem; margin-bottom:20px;margin-top:20px; margin: 0 auto;">
                <v-card-title>
                  <div class="orange-line pr-2 pl-2 rounded" style="font-size:0.6em">
                    <span>Données prévisionnelles</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche par mot clé" single-line
                    hide-details></v-text-field>
                </v-card-title>
                <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                  :headers="headers_hourly" :items="meteo_data_hourly" item-key="index" :options.sync="optionsTab"
                  :sort-by="sortByTabHoraires" :sort-desc="sortDescTabHoraires" class="my-awesome-table elevation-1"
                  :item-class="rowStyle" :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100]}" mobile-breakpoint="0"  dense>
                </v-data-table>
                <v-btn small color="info" class="mt-2 white--text">
                  <download-excel :data="meteo_data_hourly" :name="getNomFichier('hour')" :fields="excelfieldsHoraires">
                    Export Excel
                    <v-icon small class="mr-2">
                      mdi-download
                    </v-icon>
                  </download-excel>
                </v-btn>
              </div>
              <!--FIN TABLEUR-->
              <!-- GRAPHIQUE-->
              <h3 style="margin-top:10px;">Graphiques des données météo</h3>
              <div style="margin-top:2rem; margin-bottom:20px;margin-top:20px; margin: 0 auto;">
                <v-row>
                  <v-col cols="10 mx-auto">
                    <v-chart class="chart" :option="chartOptionsMeteoHour" />
                  </v-col>
                </v-row>
              </div>
              <br>
              <hr>
              <!--FIN  GRAPHIQUE-->


            </v-card>

          </v-tab-item>
          <!--FIN PARTIE HEURE-->
        </v-tabs-items>
      </v-card>

      <!-- UTILISATION de la station-->
        <v-card>
          <!-- <h3>Utilisation de la station</h3>

          <v-data-table
            :headers="headers_use_station"
            :items="use_of_station"
            :items-per-page="10"
            class="elevation-1"
          >
          </v-data-table> -->

          <h3>Autorisations de cette station</h3>
          <v-data-table
            :headers="headers_authorisations"
            :items="station_authorisations"
            :items-per-page="10"
            class="elevation-1"
          >
          </v-data-table>
          
        </v-card>
    </v-container>
  </div>
</template>

<script>
import StationsAndPlots from "@/components/station/liste_parcelles_station.vue";
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import VChart from "vue-echarts";
import {
  getDate,checkIsMobile
} from "@/helpers/functions";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "Tdb_Mildiou_Meteo",
  components: {
    StationsAndPlots,
    downloadExcel,
    parcellesMildiouService,
    VChart,
    getDate,
    checkIsMobile,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      isMobile: false,
      showModalMapMobile: false,
      showCarte:false,
      dataloaded: false,
      droits: this.$store.state.user.user.droitsTPC,
      showDetailStation: true,
      oad: null,
      dialog: false,
      searchWord: "",
      station: [],
      selectedStation: "",
      typeData: "array",
      typeAffichageData: "day",
      date_debut: "",
      date_fin: "",
      view_reel:true,
      view_previ:true,
      sortByTab:'date',
      sortDescTab: [true, false],
      station_DM: [],
      //meteo_real_data_hourly:[],
      meteo_data_hourly: [],
      meteo_data_daily: [],
      stations_horaires:[],
      indices_date_debut: '',
      indices_date_fin: '',
      tabTypeData: null,
      use_of_station:[],
      station_authorisations:[],

      
      sortByTabHoraires: 'numligne',
      sortDescTabHoraires: true,
  
      carte: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [46.76782, 2.43129],
        zoom: 10,
        markers: [],
      },

      headers_hourly: [{
          text: "Date",
          value: "date"
        },
        {
          text: "Temperature (T°C)",
          value: "t"
        },
        {
          text: "Hygrométrie (%)",
          value: "u"
        },
        {
          text: "Pluie (mm)",
          value: "rr"
        }
      ],

      headers_daily: [
          {
            text: "Date",
            value: "date"
          },
          {
            text: "Température Moyenne (°C)",
            value: "t_avg"
          },
          {
            text: "Hygrométrie Moyenne (%)",
            value: "u_avg"
          },
          {
            text: "Pluie Totale (mm)",
            value: "rr_sum"
          },
          {
            text: "T°C Min",
            value: "t_min"
          },
          {
            text: "T°C Max",
            value: "t_max"
          },

          {
            text: "Hygrométrie Min",
            value: "u_min"
          },
          {
            text: "Hygrométrie Max",
            value: "u_max"
          }
      ],

      headers_authorisations:[
        { text: 'Service', value: 'service_code' },
        { text: 'OAD', value: 'platform_code' },
        { text: 'Début', value: 'begin_at' },
        { text: 'Fin', value: 'end_at' }
      ],

      headers_use_station:[
        { text: 'Service', align: 'start', sortable: true, value: 'service' },
        { text: 'Éditeurs OAD', align: 'start', sortable: true, value: 'editors_OAD' },
        { text: 'Début', align: 'start', sortable: true, value: 'start' },
        { text: 'Fin', align: 'start', sortable: true, value: 'end' },
        { text: 'Nb Utilisateurs', align: 'start', sortable: true, value: 'num_users' },
        { text: 'Nb Parcelles', align: 'start', sortable: true, value: 'num_parcelles' },
      ],



      excelfields:{
        'Date':'date',
        'T°C MIN':'t_min',
        'T°C MAX':'t_max',
        'T°C MOYENNE':'t_avg',
        'Hygrométrie MIN':'u_min',
        'Hygrométrie MAX':'u_max',
        'Hygrométrie MOYENNE':'u_avg',
        'Pluie Totale ':'rr_sum'
      },

      excelfieldsHoraires: {
        'Date':'date',
        'T°C':'t',
        'Hygrométrie ':'u',
        'Pluie':'rr'
      },

      optionsTab: {
        itemsPerPage: 10,
      },
      sortBy: 'numligne',
      sortDesc: true,

      chartOptionsMeteoDay: {
        title: {
          text: 'Suivi par jour'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['T°C Min', 'T°C Max','T°C Moyenne', 'Hygrométrie Min', 'Hygrométrie Max','Hygrométrie Moyenne', 'Pluie totale'],
          y: 'bottom',
          selected: {
          'T°C Moyenne': true,
          'Hygrométrie Moyenne': true,
          'T°C Min': false,
          'T°C Max': false,
          'Hygrométrie Min': false,
          'Hygrométrie Max': false
          }
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title: "Télécharger l'image"
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse: false,
          axisLine: {
            lineStyle: {
              color: '#000'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#000'
            }
          }
        },
        series: [{
            name: 'T°C Min',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#090ece'
            },
            showSymbol: false,
            itemStyle: {
              color: '#090ece'
            },

          },
          {
            name: 'T°C Max',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#af594f'
            },
            showSymbol: false,
            display: false,
            show: false,
            visible:false,
            itemStyle: {
              color: '#af594f'
            },

          },
          {
            name: 'Hygrométrie Min',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#8486e6'
            },
            showSymbol: false,
            itemStyle: {
              color: '#8486e6'
            },
          },
          {
            name: 'Hygrométrie Max',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#090ece'
            },
            showSymbol: false,
            itemStyle: {
              color: '#090ece'
            },
          },
          {
            name: 'Pluie totale',
            type: 'bar',
            data: [],
            lineStyle: {
              color: '#46c3f2'
            },
            showSymbol: false,
            itemStyle: {
              color: '#46c3f2'
            },
          },
          {
            name: 'T°C Moyenne',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#af594f'
            },
            showSymbol: false,
            itemStyle: {
              color: '#af594f'
            },
          },
          {
            name: 'Hygrométrie Moyenne',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#090ece'
            },
            showSymbol: false,
            itemStyle: {
              color: '#090ece'
            },
          },
        ],
        dataZoom: [{
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: '75%',
          start: 0,
          end: 100
        }],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0, // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
            image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
            width: 165,
            height: 32
          }
        }]
      },

      chartOptionsMeteoHour: {
        title: {
          text: 'Suivi par heure'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Température (°C)', 'Hygrométrie (%)', 'Pluie (mm)'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title: "Télécharger l'image"
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse: false,
          axisLine: {
            lineStyle: {
              color: '#000'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#000'
            }
          }
        },
        series: [{
            name: 'Température (°C)',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#af594f'
            },
            showSymbol: false,
            itemStyle: {
              color: '#af594f'
            },
          },
          {
            name: 'Hygrométrie (%)',
            type: 'line',
            data: [],
            lineStyle: {
              color: '#090ece'
            },
            showSymbol: false,
            itemStyle: {
              color: '#090ece'
            },
          },
          {
            name: 'Pluie (mm)',
            type: 'bar',
            data: [],
            lineStyle: {
              color: '#46c3f2'
            },
            showSymbol: false,
            itemStyle: {
              color: '#46c3f2'
            },
          },
        ],
        dataZoom: [{
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: '75%',
          start: 80,
          end: 100
        }],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0, // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
            image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
            width: 165,
            height: 32
          }
        }]
      },

      //HEATMAP RECEPTION DONNEES METEO
      chartOptionsDMHeatMap: {
        title: {
          top: 30,
          left: 'center',
          text: `Planning de reception des données météo`
        },
        tooltip: {
          show: true,
          formatter: function (a) {
            return 'Date : ' + a.value[0] + '<br>Nb heures manquantes : ' + a.value[1]  + '<br>' + a.value[2]
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
          },
        visualMap: [{
          dimension: 1,
          pieces: [{
              min: 0,
              max: 5,
              label: 'Complet',
              color: 'green',
              opacity: 0.7
            },
            {
              min: 6,
              max: 12,
              label: 'Partiel',
              color: 'orange'
            },
            {
              min: 13,
              max: 24,
              label: 'Absence',
              color: 'red'
            },
          ],
          orient: 'horizontal',
          left: 'center',
          top: 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear() + '-01-01', new Date().getFullYear() + '-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: {
            show: true
          },
          dayLabel: {
            firstDay: 1,
            nameMap: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap: ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: []
        }
      }
      //FIN HEATMAP RECEPTION DONNEES METEO

    };
  },

  watch: {
    view_reel: function () {
      this.filterTabIndices()
    },
    view_previ: function () {
      this.filterTabIndices()
    },
    indices_date_debut: function () {
      this.filterTabIndices()
    },
    indices_date_fin: function () {
      this.filterTabIndices()
    },
    stations: function () {
      this.getIndicateurs()
      this.getPositionCarte()
    },
    liste_possibilites: function () {
      this.getListes()
    }
  },

  computed: {
    currentRouteName() {
      console.log("currentRouteName", this.$route.name);
      return this.$route.name;
    },
    checkDroitLectureExterne() {
      return this.$store.getters['user/checkDroit']('EXTERNE','lecture')
    }
  },

  async mounted() {

    // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();
    // Ecouteur d'événement pour surveiller les changements de taille de fenêtre
   // window.addEventListener('resize', this.checkIsMobile);

    this.station = await parcellesMildiouService.getStation(this.$route.params.id);
    this.station_authorisations = await parcellesMildiouService.getInfosAuthorisationsStation(this.$route.params.id);

    this.station = this.station[0];

    //MAP
      var icon_color = this.getColorCarte(this.station);
      this.carte.markers = [{
        id: this.station.id,
        coordinates: [this.station.latitude, this.station.longitude],
        imageUrl: require('@/assets/map_icon_'+ icon_color +'.png')
      }]
      this.carte.center = [this.station.latitude, this.station.longitude]
    //FIN MAP


    //DATA
    //this.meteo_real_data_hourly = await parcellesMildiouService.getActualHourlyDataStation(this.$route.params.id);
    this.meteo_data_daily = await parcellesMildiouService.getDailyDataStation(this.$route.params.id);
    this.meteo_data_hourly = await parcellesMildiouService.getHourlyDataStation(this.$route.params.id);
    this.station_DM = await parcellesMildiouService.getInfosDMStation(this.$route.params.id);

    this.$store.commit('station/defineMeteoDataDaily', {indices:this.meteo_data_daily})
    this.$store.commit('station/defineMeteoDataHourly', {indices:this.meteo_data_hourly})

    //GRAPH - Heure
    this.chartOptionsMeteoHour.xAxis.data = this.getTabChartMeteo('date');
    this.chartOptionsMeteoHour.series[0].data = this.getTabChartMeteo('t');
    this.chartOptionsMeteoHour.series[1].data = this.getTabChartMeteo('u');
    this.chartOptionsMeteoHour.series[2].data = this.getTabChartMeteo('rr');
    //GRAPH - Jour
    this.chartOptionsMeteoDay.xAxis.data = this.getTabChartMeteoDay('date');
    this.chartOptionsMeteoDay.series[0].data = this.getTabChartMeteoDay('t_min');
    this.chartOptionsMeteoDay.series[1].data = this.getTabChartMeteoDay('t_max');
    this.chartOptionsMeteoDay.series[2].data = this.getTabChartMeteoDay('u_min');
    this.chartOptionsMeteoDay.series[3].data = this.getTabChartMeteoDay('u_max');
    this.chartOptionsMeteoDay.series[4].data = this.getTabChartMeteoDay('rr_sum');
    this.chartOptionsMeteoDay.series[5].data = this.getTabChartMeteoDay('t_avg');
    this.chartOptionsMeteoDay.series[6].data = this.getTabChartMeteoDay('u_avg');
    //HEATMAP
    this.chartOptionsDMHeatMap.calendar.range = this.getTabChartDMHeatMapRange();
    this.chartOptionsDMHeatMap.series.data = this.getTabChartDMHeatMap();

    this.use_of_station = await parcellesMildiouService.getInfosUseStation(this.$route.params.id);
    // console.log(JSON.stringify(   this.use_of_station));

    this.dataloaded = true;
  },

  beforeDestroy() {
  // Retirer les écouteurs d'événements lors de la destruction du composant
  window.removeEventListener('resize', this.checkIsMobile);
  },

  methods: {

    // checkIsMobile() {
    //   this.isMobile = window.innerWidth < 960; // Déterminer si l'écran est bien un mobile
    // },


    getInfoStation(nbr_heure) {
      if (nbr_heure < 6) {
        return `Historique de données météo complet et actualisé dans les 24 dernières heures.`
      } else if (nbr_heure >= 6 && nbr_heure < 13) {
        return `Identification d’un ou plusieurs trous de moins de 6h de données météo, sans impact sur le fonctionnement des calculs.`
      } else if (nbr_heure >= 13) {
        return `Identification d’un ou plusieurs trous de 6h ou plus de données météo, bloquant les calculs. Il est nécessaire de les combler pour les relancer à contactez l’opérateur météo.`
      }
    },

    showListeParcelle(oad) {
      this.oad = oad
      this.dialog=true
    },

    closeListeParcelle() {
      this.oad = null
      this.dialog=false
    },
   
    rowStyle(item) {
      let date_ligne = new Date(item.date)
      if (date_ligne > new Date(this.station.last_data)) {
        return ["orange-line"];
      } else {
        return ''
      }
    },
   
    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    filterTabIndices() {
      this.meteo_data_daily = this.$store.getters['station/FilterTabIndicesStations']('day',this.view_reel,this.view_previ,this.indices_date_debut,this.indices_date_fin)
      this.meteo_data_hourly = this.$store.getters['station/FilterTabIndicesStations']('hour', this.view_reel, this.view_previ, this.indices_date_debut, this.indices_date_fin)
    },

    getTabChartMeteo(nom_var) {
      var arrayMeteo = []

      var indicesMeteo = this.meteo_data_hourly
      indicesMeteo.forEach(element =>
        arrayMeteo.push(element[nom_var]),
      );
      return arrayMeteo
    },

    getTabChartMeteoDay(nom_var) {
      var arrayMeteo = []

      var indicesMeteo = this.meteo_data_daily
      indicesMeteo.forEach(element =>
        arrayMeteo.push(element[nom_var]),
      );
      return arrayMeteo
    },

    //// MAP

    getIndicateurs() {
      const uniqueArr = [...new Set(this.stations.map(data => data.code_agri))]
      // this.nbAgris = uniqueArr.length
      this.nbStations = this.stations.length
      this.surfaceTotale = Math.round(this.stations.reduce((sum, data) => sum + parseFloat(data.surface_irrig), 0), 2)
    },


    getColorCarte(element) {
      if (element.indicateur == 0) return 'green'
        else if (element.indicateur == 1) return 'yellow'
        else if (element.indicateur == 2) return 'red'
        else return 'grey'
      },


    zoomUpdated(zoom) {
      this.zoom = zoom;
      //console.log(this.markers)
    },

    centerUpdated(center) {
      this.center = center;
    },
    //FIN MAP


    //HEATMAP
    getTabChartDMHeatMap() {
        var array = []

        var donnees_meteo_heatmap = this.station_DM

        donnees_meteo_heatmap.forEach(element => {
          let infoLegend = this.getInfoStation(24-element['count'])
          array.push([
            element['date'],
            (24 - element['count']),
            infoLegend
          ])
        });
        return array
    },

    getTabChartDMHeatMapRange() {
      var year = this.station_DM[0]['date']
      year = year.substring(0, 4)
      var debut = year + '-01-01'
      var fin = year + '-12-31'

      return [debut, fin]
    },


    getNomFichier(type) {
      var now = new Date()
      now = getDate(now, 'yyyymmdd')

      if (type == 'day') {
        return now + '_ExportMildiou_station_'
      } else {
        return now + '_ExportMildiouHoraire_station'
      }
    },


  }
};

</script>

<style scoped>
  .chart {
    height: 450px;
  }
  .float {
    float: left;
    margin-right: 20px;
  }

  .map {
    height: 300px !important;
    width: 700px !important;
    position: relative !important;
    z-index:0;
 }

 button{
    background-color: #82ac00;
    color: #fff !important;
  }

 /* EN MOBILE  */
@media only screen and (max-width: 960px) {

  .v-dialog{
    height: 90vh !important;
    background-color: whitesmoke !important;
    padding:0.4rem;
  }

  .map_mobile{
    margin: 0 auto !important;
    height: 400px !important;
    width:400px !important;
    height:90vh !important;
    width:90vh !important;
  }

  #detail_station .col-sm-12{
    padding:0px !important;
  }



}
  
  </style>

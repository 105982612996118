export function getDate(date, format) {

  var dd = date.getDate();

  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  var modifDate = null
  if (format == 'dd/mm/yyyy') {
    modifDate = dd + '/' + mm + '/' + yyyy;
  } else if (format == 'yyyymmdd') {
    modifDate = yyyy.toString() + mm.toString() + dd.toString();
  } else if (format == 'yyyy-mm-dd') {
    modifDate = yyyy + '-' + mm + '-' + dd;
  }

  return modifDate

}

export function getNewDateFromFr(dateFr) {

  let [day, month, year] = dateFr.split('/');
  return new Date(+year, +month - 1, +day);

}

/**
 * Convertir le CSV transmit en Objet JSON
 */
export function csvToJSON(csv) {
  //var vm = this
  var lines = csv.split("\r\n")
  var result = []
  var headers = lines[0].split(";")

  lines.map(function (line, indexLine) {
    if (indexLine < 1) return // Jump header line
    var obj = {}
    var currentline = line.split(";")
    headers.map(function (header, indexHeader) {
      obj[header] = currentline[indexHeader]
    })
    result.push(obj)
  })
  result.pop() // remove the last item because undefined values

  return result // JavaScript object
}


/**
 * Déterminer si l'écran est bien un mobile selon une largeur de PX esperée pour un mobile
 */
export function checkIsMobile() {
  return window.innerWidth < 960;
}
<template>
  <div>
    <v-row fill-height>
      <v-col cols="1">
        <menucontextuel :menu="selectMenu()"></menucontextuel>
      </v-col>

      <v-col cols="11">
        <TdbBHComponent v-if="showTdbBHComponent" />
        <TdbPDTComponent v-if="showTdbPDTComponent" />
        <TdbStadesMaladiesComponent v-if="showTdbStadesMaladiesComponent" />
        <ConseillerComponent v-if="showConseillerComponent" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import TdbBHComponent from "@/components/administration/TableaudebordBH.vue";
import TdbPDTComponent from "@/components/administration/TableaudebordPDT.vue";
import TdbStadesMaladiesComponent from "@/components/administration/TableaudebordStadesMaladies.vue";
import ConseillerComponent from "@/components/administration/Conseiller.vue";
import Menucontextuel from '../components/Menucontextuel.vue';

export default {
  name: "Administration",
  components: {
    TdbBHComponent,
    TdbPDTComponent,
    TdbStadesMaladiesComponent,
    ConseillerComponent,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : {
        conseillers :[],
        tdb : [
          {
            id:1,
            chemin:"/administration/tableaudebord/stade",
            nom:"Stades et maladies",
            icon: "mdi-sprout"
          },
          {
            id:2,
            chemin:"/administration/tableaudebord/pdt",
            nom:"Pomme de terre",
            icon: "mdi-peanut"
          },
          {
            id:3,
            chemin:"/administration/tableaudebord/bh",
            nom:"Bilan hydrique",
            icon: "mdi-water"
          }
        ]
      },
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showTdbBHComponent() {
      return this.currentRouteName == "admin-tdb-bh";
    },
    showTdbPDTComponent() {
      return this.currentRouteName == "admin-tdb-pdt";
    },
    showTdbStadesMaladiesComponent() {
      return this.currentRouteName == "admin-tdb-stade";
    },
    showConseillerComponent() {
      return this.currentRouteName == "admin-conseillers";
    },
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    },
    selectMenu(){
      if(this.showTdbBHComponent || this.showTdbPDTComponent || this.showTdbStadesMaladiesComponent){
        return this.menu['tdb']
      }
      else if(this.showConseillerComponent){
        return this.menu['conseillers']
      }
    },
  }
};
</script>

<style>
</style>
<template>
  <v-container fluid>
    <v-responsive max-width="868" class="mx-auto" :aspect-ratio="16 / 9">
      <v-card class="border-color" elevation="0">
        <!-- <app-spinner
          v-if="$store.state.auth.user"
        /> -->
          <v-card-title class="module-bg-color-light white--text">{{
            $tc("profile.title")
          }}</v-card-title>


          <v-container fluid>
            <v-row no-gutters >
              <v-col cols="" sm="5">
                 <v-text-field
                    type="text"
                    v-model="apiData.login"
                    :label="$tc('user.login')"
                    autocomplete="userName"
                    name="userName"
                    readonly
                  ></v-text-field>
               
              </v-col>
              <v-col cols="12" sm="6" offset-sm="1">
               <v-text-field
                    type="text"
                    v-model="apiData.userName"
                    :label="$tc('user.username')"
                    autocomplete="userName"
                    name="userName"
                    readonly
                  ></v-text-field> 
              </v-col>
            </v-row>
            <v-row dense>
              <v-btn
                v-if="!isArvalis"
                class="mr-4 float-right"
                type="submit"
                small
                @click="changerMdp=true"
                color="info"
              >
                Modifier mon mot de passe
              </v-btn>

              <v-dialog
                v-model="changerMdp"
                max-width="500"
                :transition="false"
                @click:outside="changerMdp=false"
              >
                <v-card>
                  <v-card-title class="v-card-title-info"
                    ><h2 class="text-center">
                      Modifier mon mot de passe
                    </h2></v-card-title
                  >

                  <v-card-text class="pa-5" style="overflow:hidden">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                      v-model="oldMdp"
                      label="Ancien mot de passe"
                      required
                    ></v-text-field>

                    <v-text-field
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show2"
                      :type="show2 ? 'text' : 'password'"
                      v-model="newMdp"
                      label="Nouveau mot de passe"
                      required
                      :rules="rules.mdp"
                    ></v-text-field>

                    <v-text-field
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show3 = !show3"
                      :type="show3 ? 'text' : 'password'"
                      v-model="newMdp2"
                      label="Confirmer le nouveau mot de passe"
                      required
                    ></v-text-field>


                    <v-btn
                      class="mr-4 float-right"
                      color="info"
                      type="submit"
                      small
                      :disabled="isMdpValid"
                      @click="changeMdp"
                    >
                      Envoyer
                    </v-btn>

                  </v-card-text>

                </v-card>
              </v-dialog>

            </v-row>
            <v-row v-if="checkDroits('ADMIN','lecture')">
              <v-col>
                <v-text-field
                  v-model="mailInvite"
                  label="Mail"
                  required
                ></v-text-field>

                <v-btn
                  class="mr-4 float-right"
                  color="info"
                  type="submit"
                  small
                  @click="loginInvite"
                >
                  Connexion invité
                </v-btn>
              </v-col>
              
            </v-row>
            
          </v-container>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script>
import authService from "@/service/auth";

export default {
  name: "Profil",
  components: {
    authService
  },

  //mixins: [FormController, Validators],

  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC,
      confirmationPassword: "",
      showPassword: false,
      mailInvite: null,
      apiData: null,
      isFormValid: true,
      changerMdp: false,
      show1: false,
      show2: false,
      show3: false,
      oldMdp:'',
      newMdp:'',
      newMdp2:'',
      rules: {
        mdp:[ 
          v => !!v || "Ce champs est requis",
          v => ( v && v.length >= 12 ) || "Le mot de passe doit faire au minimum 12 caractères",
          v => /(?=.*\d)/.test(v) || 'Le mot de passe doit contenir au moins un chiffre',
          v => /([!@#$%_\--])/.test(v) || 'Le mot de passe doit contenir au moins un caractère spécial [!@#$%-_*]'
        ],
      },

    };
  },

  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.apiData = this.$store.state.user.user;
      if (this.$store.state.user.user) {
        //this.apiData = this.$store.state.auth.user;
        // this.apiData = Object.assign(
        //   {},
        //   this.apiData,
        //   this.$store.state.auth.user
        // );
      }
      this.confirmationPassword = "";
    },

    async changeMdp() {
      this.changerMdp = false;
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var mail = this.$store.state.user.user.email
      var result = await authService.changeMdp(mail,this.oldMdp,this.newMdp);
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(result['envoi']==1){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Modification du mot de passe",description:"Votre mot de passe a bien été modifé."} });
      } else {
        this.$store.dispatch('errors/setError', { message: {code:"",description:"Un problème est survenu. Veuillez recommencer. Si cela se reproduit merci de nous contacter."} });
      }
    },

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    loginInvite() {
      const login = this.mailInvite;
      this.$store
        .dispatch("loginInvite", { login })
        .then(() => {
            this.$store.dispatch("user/fetchUser");
            this.$router.push("/home").catch((err) => { console.log(err);});
        });
    },

  },

  computed: {
    isArvalis() {
      return this.$store.getters["user/isArvalis"] ;
    },
    isMdpValid() {
      if(this.newMdp.length>=10 && /\d/.test(this.newMdp) && /([!@#$%_\--])/.test(this.newMdp) && this.newMdp==this.newMdp2 && this.oldMdp!=''){
        return false
      } else {
        return true
      }
    },
  }

};
</script>

<template>
  <v-container>
    <!--<v-radio-group v-model="typeRecalage" row>
      <v-radio
        label="Stade"
        value="stade"
      ></v-radio>
      <v-radio
        label="Maladie"
        value="maladie"
      ></v-radio>
    </v-radio-group>-->
    <v-tabs v-model="typeRecalage">
      <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#stade">Stade</v-tab>
      <v-tab style="font-weight: bold; background-color: #fbfbfb " href="#maladie">Maladie</v-tab>
    </v-tabs>
    <v-tabs-items v-model="typeRecalage">
      <v-tab-item value="stade">
        <v-card><recalageStadeComponent /></v-card>
      </v-tab-item>
      <v-tab-item value="maladie">
        <v-card><recalageMaladieComponent /></v-card>
      </v-tab-item>
    </v-tabs-items>

    <!--<div v-if="typeRecalage=='stade'">
      <recalageStadeComponent />
    </div>
    <div v-else>
      <recalageMaladieComponent />
    </div>-->
  
  </v-container>
</template>

<script>
import recalageStadeComponent from "@/components/stade/Recalage_Stade.vue";
import recalageMaladieComponent from "@/components/stade/Recalage_Maladie.vue";

export default {
  name: "Recalage",
  components: {
    recalageStadeComponent,
    recalageMaladieComponent
  },
  data() {
    return {
      typeRecalage: "stade"
    };
  },

};
</script>

<style>
</style>

import Vue from 'vue';

export default {
  namespaced: true,
  state: {
     all_info_about_stades: [],
     all_info_about_stades_arvalis: [],
     all_info_about_stades_cliente1c: [],
     all_info_about_stades_mycolis: [],
     liste_especes : [{code:17, name:'Blé tendre'},{code:40, name:'Blé dur'},{code:27, name:'Orge d\'hiver'},{code:19, name:'Triticale'}]
  },

  actions: {
 
    defineParcellesList({ commit }, payload) {
      commit("defineParcellesList", payload); 
    },
    defineParcellesListArvalis({ commit }, payload) {
      commit("defineParcellesListArvalis", payload); 
    },
    defineParcellesListClientE1C({ commit }, payload) {
      commit("defineParcellesListClientE1C", payload); 
    },
    defineParcellesListMycolis({ commit }, payload) {
      commit("defineParcellesListMycolis", payload); 
    },
    reset: ({commit}) => {
      commit('reset'); 
    },
  },
  mutations: {

    defineParcellesList (state, payload) {
      state.all_info_about_stades = payload.stades
    },
    defineParcellesListArvalis (state, payload) {
      state.all_info_about_stades_arvalis = payload.stades
    },
    defineParcellesListClientE1C (state, payload) {
      state.all_info_about_stades_cliente1c = payload.stades
    },
    defineParcellesListMycolis (state, payload) {
      state.all_info_about_stades_mycolis = payload.stades
    },
    reset: (state) => {
      state.all_info_about_stades=[];
      state.all_info_about_stades_arvalis= [],
      state.all_info_about_stades_cliente1c= [],
      state.all_info_about_stades_mycolis= []
    }
  },

  getters: {
      
      FilterParcelles: (state) => (type, listeEspece,listeVariete,listeDepartement,listClient,date_debut,date_fin,login,castype,listMontaison,listEpiaison,codeParcelleContain) => {

      var tab = state.all_info_about_stades
      if(type=='arvalis'){
        tab = state.all_info_about_stades_arvalis
      } else if (type=='mycolis'){
        tab = state.all_info_about_stades_mycolis
      } else if (type=='clients_e1c'){
        tab = state.all_info_about_stades_cliente1c
      }

      if(type=='arvalis' && login!==undefined && login!==null){
        tab = tab.filter(parcelle => parcelle.code_parcelle_os.startsWith(login))
      }

      //filtre listeClient stade
      var tab2 = []
      if(listClient!=null && listClient.length>0 && tab.length>0){
        if(type!='clients_e1c'){
          listClient.map(function(value) {
            tab2 = tab2.concat(tab.filter(stade => stade.nom_user === value))
          });
        } else {
          if(listClient[0]=='BSV'){
            tab2 = tab.filter(stade => stade.nom_user.startsWith('BSV')==true)
          } else if(listClient[0]=='Hors-BSV'){
            tab2 = tab.filter(stade => stade.nom_user.startsWith('BSV')==false)
          } else {
            tab2 = tab
          }
        }
        tab = tab2
      }

      //filtre listeEspece stade
      var tab3 = []
      if(listeEspece!=null && listeEspece.length>0 && tab.length>0){
        listeEspece.map(function(value) {
          tab3 = tab3.concat(tab.filter(stade => stade.codeespece === value))
        });
        tab = tab3
      }

      //filtre liste variete
      var tab4 = []
      if(listeVariete!=null && listeVariete.length>0 && tab.length>0){

        listeVariete.map(function(value) {
          tab4 = tab4.concat(tab.filter(stade => stade.variete === value))
        });
        tab = tab4
      }

      //filtre liste departement
      var tab5 = []
      if(listeDepartement!=null && listeDepartement.length>0 && tab.length>0){

        listeDepartement.map(function(value) {
          tab5 = tab5.concat(tab.filter(stade => stade.code_dept === value))
        });
        tab = tab5
      }

      //filtre selon [ date debut ; date de fin ]
      var tab6 = []
      function checkDateDebut(element) {
        var dateParts = element.date_semis.split("/");
        var dateObject = new Date(+dateParts[2], dateParts[1]-1, +dateParts[0]);
        return dateObject >= new Date(date_debut);
      }
      if(date_debut!=null && date_debut!=''){
        tab6 = tab.filter(checkDateDebut)
      } else {
        tab6 = tab
      }
      tab = tab6;

      var tab7 = []
      function checkDateFin(element) {
        var dateParts = element.date_semis.split("/");
        var dateObject = new Date(+dateParts[2], dateParts[1]-1, +dateParts[0]);
        return dateObject <= new Date(date_fin);
      }
      if(date_fin!=null && date_fin!=''){
        tab7 = tab.filter(checkDateFin)
      } else {
        tab7 = tab
      }
      tab = tab7

      //filtre cas type
      if(castype!==undefined){         
        var tab8 = []
        if(castype!=null && castype.length>0 && tab.length>0){

          castype.map(function(value) {
            tab8 = tab8.concat(tab.filter(stade => stade.castype === value))
          });
          tab = tab8
        }
      }

      //filtre montaison
      if(listMontaison!==undefined){         
        var tab9 = []
        if(listMontaison!=null && listMontaison.length>0 && tab.length>0){

          listMontaison.map(function(value) {
            tab9 = tab9.concat(tab.filter(stade => stade.precocite_montaison === value))
          });
          tab = tab9
        }
      }

      //filtre epiaison
      if(listEpiaison!==undefined){         
        var tab10 = []
        if(listEpiaison!=null && listEpiaison.length>0 && tab.length>0){

          listEpiaison.map(function(value) {
            tab10 = tab10.concat(tab.filter(stade => stade.precocite_epiaison === value))
          });
          tab = tab10
        }
      }

      //filtre nom code parcelle
      if(codeParcelleContain!==undefined && codeParcelleContain!==null && codeParcelleContain!==''){       
        tab = tab.filter(parcelle => parcelle.code_parcelle_os.includes(codeParcelleContain))
      }

      //console.log('TAB RETURN  AFTER INTERVAL !: ' + JSON.stringify(tab))

      return tab
    },

    DefineListSelect: (state) => (selection,type)=>{
      var tab = [];
      var liste = state.all_info_about_stades
      if(type=='arvalis'){
        liste = state.all_info_about_stades_arvalis
      } else if (type=='mycolis'){
        liste = state.all_info_about_stades_mycolis
      }
      liste.forEach(element => { 
        tab.push({value:element[selection]})
      });

      //tri
      tab.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      return tab
    },

    DeleteParcelle: (state) => (id,type)=>{
      var liste = state.all_info_about_stades
      if(type=='arvalis'){
        liste = state.all_info_about_stades_arvalis
      } else if (type=='mycolis'){
        liste = state.all_info_about_stades_mycolis
      }

      liste = liste.filter(function( obj ) {
        return obj.id_plot !== id;
      });

      if(type=='arvalis'){
        state.all_info_about_stades_arvalis = liste
      } else if (type=='mycolis'){
        state.all_info_about_stades_mycolis = liste
      } else {
        state.all_info_about_stades = liste
      }

    },

    ParcellesList:(state) => ()=>{
      var tab = state.all_info_about_stades
            
      return tab
    },

    ParcellesListArvalis:(state) => ()=>{
      var tab = state.all_info_about_stades_arvalis

      return tab
    },

    ParcellesListClientE1C:(state) => ()=>{
      var tab = state.all_info_about_stades_cliente1c

      return tab
    },

    ParcellesListMycolis:(state) => ()=>{
      var tab = state.all_info_about_stades_mycolis

      return tab
    },

    ListeEspeces:(state) => ()=>{
      var tab = state.liste_especes

      return tab
    },

    NomEspeceSelonCode:(state) => (code)=>{
      var objnom = state.liste_especes.filter(function( obj ) {
        return obj.code == code;
      });

      return objnom[0]['name']
    },

  }
};
<template>
  <v-row justify="center">

    <v-dialog v-model="dialog_init" persistent max-width="600px">
      <!--<template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            style="height:30px;"
        >
            <v-icon small class="mr-2">
              mdi-comment-text-outline
            </v-icon>
        </v-btn>
        </template>-->
      <v-card>
        <v-toolbar color="primary" dark class="mb-5"> Commentaires </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row
              v-for="commentaire in commentaires"
              :key="commentaire.id_commentaire"
              class="mb-5"
            >
              <v-col>
                <span class="mr-2">{{ commentaire.p_dt_crea }}</span>
                <v-icon
                  small
                  class="mr-2"
                  @click="modifyCommentaire(commentaire)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="confirmDeleteCommentaire(commentaire.id_commentaire)"
                >
                  mdi-close-thick
                </v-icon>
                <br />
                <div v-html="commentaire.commentaire"></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <!--<v-text-field
                    v-model="date_commentaire"
                    label="Picker in menu"
                    prepend-icon="mdi-calendar"
                    readonly
                    style="height:25px;"
                ></v-text-field>-->
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date_commentaire"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_commentaire"
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="height: 25px"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date_commentaire" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Fermer
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date_commentaire)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <!--<v-textarea
                    solo
                    name="input-7-4"
                    label="Commentaire*"
                    v-model="contenu_commentaire"
                ></v-textarea>-->
                <VueEditor v-model="contenu_commentaire" />
              </v-col>
            </v-row>
          </v-container>
          <small>*champs obligatoires</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmCloseCommentaire()">
            Fermer
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveCommentaire()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ChoixModal from "@/components/modals/ChoixModal.vue";
import { VueEditor } from "vue2-editor";
import CommentairesService from "@/service/commentaires";

export default {
  name: "commentaire",
  components: {
    ChoixModal,
    VueEditor,
    CommentairesService
  },
  props: {
    dialog_init: Boolean,
    id_plot: [Number, String],
    oad: String,
  },
  data() {
    return {
      commentaires: null,
      date_commentaire: new Date().toISOString().slice(0, 10),
      contenu_commentaire: "",
      id_commentaire: null,
      menu: false,
    };
  },
  /*mounted() {
    this.getCommentaire();
  },*/
  watch: {
    id_plot: function () {
      this.getCommentaire();
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
  },
  computed: {
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
  },
  methods: {
    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },
    confirmCloseCommentaire() {
      if (this.contenu_commentaire != "") {
        this.$store.dispatch("choix/setChoix", {
          question: "Etes-vous sûr de ne pas vouloir enregistrer l'élément?",
          choix: {
            choix1: { text: "Oui", function: "closeCommentaire" },
            choix2: { text: "Annuler", function: "closeChoix" },
          },
        });
      } else {
        this.closeCommentaire();
      }
    }, 
    closeCommentaire() {
      this.closeChoix();
      this.$emit("closeCommentaire", false),
      this.initSaisie();
      this.commentaires = null;
    },
    modifyCommentaire(commentaire) {
      this.date_commentaire = commentaire.p_dt_crea;
      this.contenu_commentaire = commentaire.commentaire;
      this.id_commentaire = commentaire.id_commentaire;
    },
    confirmDeleteCommentaire(id) {
      this.id_commentaire = id;
      this.$store.dispatch("choix/setChoix", {
        question: "Etes-vous sûr de vouloir supprimer cet élément?",
        choix: {
          choix1: { text: "Oui", function: "deleteCommentaire" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },
    async deleteCommentaire() {
      this.closeChoix();
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isDeleted = await CommentairesService.deleteCommentaireParcelle(
        this.id_commentaire,
        this.oad
      );
      if (isDeleted == true) {
        this.getCommentaire();
      } else {
        alert("Un problème est survenu.");
      }
      this.id_commentaire = null;
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },
    async getCommentaire() {
      this.commentaires = await CommentairesService.getCommentairesParcelle(
        this.id_plot,
        this.oad
      );
    },
    async saveCommentaire() {
      var isDone = null;
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      if (this.id_commentaire == null) {
        isDone = await CommentairesService.postCommentaireParcelle(
          this.id_plot,
          this.oad,
          this.contenu_commentaire
        );
      } else {
        isDone = await CommentairesService.putCommentaireParcelle(
          this.id_commentaire,
          this.oad,
          this.contenu_commentaire
        );
      }

      if (isDone == true) {
        this.initSaisie();
        this.getCommentaire();
      }

      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },
    initSaisie() {
      this.date_commentaire = new Date().toISOString().slice(0, 10);
      this.contenu_commentaire = "";
      this.id_commentaire = null;
    },
  },
};
</script>

<style>
</style>
<template>
    <div>  
      <v-card style="padding:15px">
        Lancer une demande de calcul à la volée
        <v-row class="mt-5">
          <v-col cols="5">
            <v-row>
              <v-col>
                <v-menu
                  ref="menu_dt_semis"
                  v-model="menu_dt_semis"
                  :close-on-content-click="false"
                  :return-value.sync="selectedDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="selectedDate"
                        label="Date semis"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        style="height:25px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="selectedDate"
                  no-title
                  scrollable
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu_dt_semis = false"
                  >
                      Fermer
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_dt_semis.save(selectedDate)"
                  >
                      OK
                  </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedStation"
                  :items="listeStations"
                  item-text="name"
                  item-value="code"
                  label="Station(s)"
                  chips
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedSolBase"
                  :items="listeSolBase"
                  item-text="name"
                  item-value="code"
                  menu-props="auto"
                  label="Code base sol"
                  hide-details
                  chips
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group v-model="selectedEspece" row>
                  <v-radio v-for="elem in listeEspece" :key="elem.id"
                    :label="elem.name"
                    :value="elem.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="selectedVariete"
                  :items="listeVariete"
                  item-text="Nom"
                  item-value="Nom"
                  menu-props="auto"
                  label="Variété"
                  hide-details
                  chips
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn small color="info" class="mt-2 mb-50 white--text" @click="lancerSimulation()" style="float:right">
                  <v-icon small class="mr-2">
                    mdi-upload
                  </v-icon>
                  Lancer la simulation
              </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="1"  cols="5">
            <table v-if="simulation!=null">
              <thead>
                <tr>
                  <th class="text-center">
                    Nom
                  </th>
                  <th class="text-center">
                    Valeur
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in simulation.variables"
                  :key="item.nom"
                >
                  <td v-if="item.nom.includes('decalageoadjours')" style="font-weight:bold;color:red">{{ item.nom }}</td>
                  <td v-else>{{ item.nom }}</td>
                  <td v-if="item.nom.includes('decalageoadjours')" style="font-weight:bold;color:red">{{ item.valeur }}</td>
                  <td v-else>{{ item.valeur }}</td>
                </tr>
                <tr><td colspan="2">--------</td></tr>
                <tr
                  v-for="item in simulation.informations"
                  :key="item.nom"
                >
                  <td>{{ item.nom }}</td>
                  <td>{{ item.valeur }}</td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card>
    </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Stade_Form",
  components: {
    downloadExcel,
    recalageService
  },
  props: {
    infosRecalage: Object
  },
  data() {
    return {
      isLoading: false,
      activePicker: null,
      menu_dt_semis: false,
      selectedDate: null,
      selectedStation: null,
      selectedEspece: null,
      selectedVariete: null,
      selectedSolBase: null,
      listeStations: [],
      listeEspece: [{id:17, code:'bth', name:'Blé tendre'},{id:40, code:'bdh', name:'Blé dur'},{id:27, code:'oh', name:'Orge d\'hiver'},{id:28, code:'op', name:'Orge de printemps'},{id:19, code:'triti', name:'Triticale'}],
      listeVariete: [],
      listeSolBase: [],
      listeToutesVarietes: null,
      simulation: null
    };
  },
  async mounted() {

    if((this.$store.getters['recalage/ListeVarietes']()).length==0){
      this.listeToutesVarietes = await recalageService.getVarietesCapNotes();
      this.$store.commit('recalage/defineListe', {liste:this.listeToutesVarietes})
    } else {
      this.listeToutesVarietes = this.$store.getters['recalage/ListeVarietes']()
    }

    var listeStations = null
    if((this.$store.getters['recalage/ListeStations']()).length==0){
      listeStations = await recalageService.getAllStations();
      this.$store.commit('recalage/defineListeStations', {listeStations:listeStations})
    } 
    this.listeStations = this.$store.getters['recalage/ListeStations']()

    var listeSols = null
    if((this.$store.getters['recalage/ListeSols']()).length==0){
      listeSols = await recalageService.getAllSols();
      this.$store.commit('recalage/defineListeSols', {listeSols:listeSols})
    } 
    this.listeSolBase = this.$store.getters['recalage/ListeSols']()
    
  },

  watch: {
    selectedEspece: function() {
      this.listeVariete = this.$store.getters['recalage/ListeVarietesEspece'](this.selectedEspece)
    },
  },


  methods: {
    async lancerSimulation() {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isSent = null
      var donneesSimulation = {selectedDate:this.selectedDate, selectedStation:this.selectedStation,selectedEspece:this.selectedEspece,selectedVariete:this.selectedVariete,selectedSolBase:this.selectedSolBase}
      this.simulation = await recalageService.envoyerSimulation(donneesSimulation);
      
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      
    },
  }
};
</script>

<style>
</style>

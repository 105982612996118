import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    listes: [],
    parcelles_bh: []
  },

  actions: {
    defineListes({ commit }, payload) {
        commit("defineListes", payload);
    },
    defineParcelles({ commit }, payload) {
      commit("defineParcelles", payload);
    },
    reset: ({commit}) => {
      commit('reset');
    }
  },
  mutations: {
    defineListes (state, payload) {
      state.listes = payload.liste
    },
    defineParcelles (state, payload) {
      state.parcelles_bh = payload.liste
    },
    reset: (state) => {
      state.listes = [];
      state.parcelles_bh = [];
    }
  },
  getters: {
      FilterListes: (state) => (culture,departements,agris,integrateurs,distributeurs) => {
        var tab = state.listes

        //filtre culture
        var tab1 = []
        if(culture!=null && culture.length>0 && tab.length>0){
          culture.map(function(value) {
            tab1 = tab1.concat(tab.filter(data => data.culture === value))
          });
          tab = tab1
        }

        //filtre departements
        var tab2 = []
        if(departements!=null && departements.length>0 && tab.length>0){
          departements.map(function(value) {
            tab2 = tab2.concat(tab.filter(data => data.departement === value))
          });
          tab = tab2
        }

        //filtre agris
        var tab3 = []
        if(agris!=null && agris.length>0 && tab.length>0){
          agris.map(function(value) {
            tab3 = tab3.concat(tab.filter(data => data.code_agri === value))
          });
          tab = tab3
        }

        //filtre integrateur
        var tab4 = []
        if(integrateurs!=null && integrateurs.length>0 && tab.length>0){
          integrateurs.map(function(value) {
            tab4 = tab4.concat(tab.filter(data => data.id_integrateur === value))
          });
          tab = tab4
        }

        //filtre distributeur
        var tab5 = []
        if(distributeurs!=null && distributeurs.length>0 && tab.length>0){
          distributeurs.map(function(value) {
            tab5 = tab5.concat(tab.filter(data => data.distributeur === value))
          });
          tab = tab5
        }
        
        return tab
      },
      FilterParcelles: (state) => (culture,departements,agris,integrateurs,distributeurs,epuisement_rfu) => {
        var tab = state.parcelles_bh
        
        //filtre culture
        var tab1 = [] 
        if(culture!=null && culture.length>0 && tab.length>0){
          culture.map(function(value) {
            tab1 = tab1.concat(tab.filter(data => data.culture === value))
          });
          tab = tab1
        }

        //filtre departements
        var tab2 = []
        if(departements!=null && departements.length>0 && tab.length>0){
          departements.map(function(value) {
            tab2 = tab2.concat(tab.filter(parcelle => parcelle.departement === value))
          });
          tab = tab2
        }

        //filtre agris
        var tab3 = []
        if(agris!=null && agris.length>0 && tab.length>0){
          agris.map(function(value) {
            tab3 = tab3.concat(tab.filter(parcelle => parcelle.code_agri === value))
          });
          tab = tab3
        }

        //filtre integrateur
        var tab4 = []
        if(integrateurs!=null && integrateurs.length>0 && tab.length>0){
          integrateurs.map(function(value) {
            tab4 = tab4.concat(tab.filter(parcelle => parcelle.id_operateur === value))
          });
          tab = tab4
        }

        //filtre distributeur
        var tab5 = []
        if(distributeurs!=null && distributeurs.length>0 && tab.length>0){
          distributeurs.map(function(value) {
            tab5 = tab5.concat(tab.filter(parcelle => parcelle.id_grpt === value))
          });
          tab = tab5
        }

        //filtre epuisement rfu
        var tab6 = []
        if(epuisement_rfu!=null && tab.length>0){
          if(epuisement_rfu == 'Hors bilan'){
              tab6 = tab6.concat(tab.filter(data => data.epuisement_rfu === null))
          } else if(epuisement_rfu == '>70%'){
              tab6 = tab6.concat(tab.filter(data => data.epuisement_rfu > 70 && data.epuisement_rfu !== null))
          } else if(epuisement_rfu == '<40%'){
              tab6 = tab6.concat(tab.filter(data => data.epuisement_rfu <40 && data.epuisement_rfu !== null))
          } else {
              tab6 = tab6.concat(tab.filter(data => data.epuisement_rfu >=40 && data.epuisement_rfu <=70 && data.epuisement_rfu !== null))
          }
          
          tab = tab6
        }

        return tab
        
      }
  }
};
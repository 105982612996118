<template>
  <div class="reset-page">
    <v-form
      class="form"
      ref="form"
      v-model="valid"
      @submit.prevent="passForgotten"
    >
      <img src="@/assets/logo-app.png" />
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
      ></v-text-field>
      <v-btn
        block
        color="success"
        :disabled="!valid"
        type="submit"
        :loading="loginLoading"
        >{{ $tc("login.resetPassword") }}</v-btn
      >
      <div class="row linkHome">
        <div class="col-12">
          <v-item class="message" v-for="(link, index) in general" :key="index">
            <router-link :to="{ name: link.destination }">{{
              link.label
            }}</router-link>
          </v-item>
        </div>
      </div>
    </v-form>
  </div>
</template>


<script>
export default {
  name: "PasswordForgotten",
  data() {
    return {
      valid: true,
      loginLoading: false,
      email: "",
      emailRules: [
        (v) => !!v || "l'E-mail est obligatoire",
        (v) => /.+@.+\..+/.test(v) || "l'email n'est pas valide",
      ],
      general: [
        { label: "revenir à la page de connexion ", destination: "login" }
      ]
    };
  },
  methods: {
    passForgotten() {
      this.loginLoading = true;
      //this.$store.dispatch("retrieveToken", { email: this.email });
      this.loginLoading = false;
    },
  },
};
</script>


